<template>
  <vue-final-modal
      v-slot="{ params, close }"
      v-bind="$attrs"
      classes="modal-container"
      :content-class="className"
      v-on="$listeners"
  >
    <div v-if="showGoldHeader" class="gradient"></div>
    <div class="wrapper">
      <div class="border"></div>
      <div class="main-element">
        <div class="vmtitle">
          <slot name="title"></slot>
        </div>
        <div class="pt-3">
          <slot v-bind:params="params"></slot>
        </div>
        <div class="modal__action mb-4">
          <button v-if="showConfirm" class="p-1 m-2 confirm-style eggOne" @click="$emit('confirm', close)">{{confirmName}}</button>
          <button v-if="showCancel" class="p-1 m-2 cancel-style eggTwo" @click="$emit('cancel', close)">{{cancelName}}</button>
        </div>
      </div>

    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: "WaitingForWallet",
  props: {
    showGoldHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    showConfirm: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
    className: {
      type: String,
      required: false,
      default: "intro",
    },
    confirmName: {
      type: String,
      required: false,
      default: "Confirm",
    },
    cancelName: {
      type: String,
      required: false,
      default: "Cancel",
    }
  }
};
</script>

<style scoped>


.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.75);
}


.vmtitle {
  font-family: 'Mochiy Pop P One', sans-serif;
  position: fixed;
  top:8px;
  width: 319px;
  height: 40px;
  left:10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #ffffbb;
  color: #2d89a7;
}

.wrapper {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50%), -50%);
  box-sizing: content-box;
  padding-top: 100px;
  height: 170px;
  max-width: 540px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.75);
  margin-bottom: 30px;
  width: 340px;
  border-radius: 10%;
}

.main-element {
  position: absolute;
  top: 3%;
  left: 3%;
  display: block;
  height: 94%;
  width: 94%;
  align-self: center;
  background: repeating-linear-gradient(
      to top left,
      beige 0,
      beige 2em,
      moccasin 2em,
      moccasin 4em,
      wheat 4em,
      wheat 6em
  ),
  repeating-linear-gradient(
      to left,
      white 0,
      white 2em,
      wheat 2em,
      wheat 4em,
      beige 4em,
      beige 6em
  ), white;

  background-blend-mode: multiply;
  border-radius: 10%;
  z-index: 1;
  padding: 0.2rem;
  align-items: center;
}

.border {
  border-radius: 12px;
  position: absolute;
  display: block;
  top: -50%;
  left: -50%;
  z-index: 0;
  display: block;
  height: 200%;
  width: 200%;
  transform: rotate(-45deg);
  overflow: hidden;
  background: linear-gradient(to right, #8a6f3e 20%, #8a6f3e 40%, #ECD08C 50%, #ECD08C 55%, #a28a4b 70%, #a28a4b 100%);
  background-size: 200% auto;

  animation: shine 3s linear infinite;
}

/*Begin shimmer code*/

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

button {
  padding: 0.75rem 1.5rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  background-color: #1f1918;
  /*border-image: linear-gradient(#4e4024, #353932) 6;*/
  color: white;
  cursor: pointer;
  box-shadow: 0 8px 2px rgba(0, 0, 0, 0.75);
  outline: none;
}

button:hover,
button:active {
  border-color: #5cccd7;
}

.flat {
  background-color: transparent;
  color: #610000;
  border: none;
}

.flat:hover,
.flat:active {
  background-color: #ffd2d2;
}

.gradient {
  position: fixed;
  background: #999955;
  background-image: linear-gradient(#DAB046 20%,
  #be9736 20%,
  #a07e28 40%,
  #997825 40%,
  #735812 60%,
  #4f3b07 60%,
  #533c05 80%,
  #302400 80%);
  margin: 0 auto;
  width: 100%;
  height: 50px;
  top: 65px;
  left: 0;
}

.eggOne, .eggTwo, .eggThree {
  font-family: 'Mochiy Pop P One', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  position: relative;
  overflow:hidden;
  display: inline-block;
  width: 130px;
  height: 60px;
  box-shadow: inset -10px -10px rgba(0,0,0,0.1);
  top:10px;
  border-radius:  60% 40% 40% 60% / 50% 50% 50% 50%;
  outline: none;
  border-color:white;
}

.eggOne {
  background: repeating-linear-gradient(#80a861, #658b48 10px,
  #90be6d 10px, #90be6d 20px);
  transition: all .5s ease-out;
}

.eggOne:focus{ color: #fff; }
.eggOne:hover{
  top: 15px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
  #90be6d 10px, #90be6d 20px);
  text-shadow: 0 0 5px rgba(0,0,0,0.3);
  box-shadow: 0 0 0 6px rgba(255,113,41,0.3);
}
.eggOne:before{
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
  outline: none;
}
.eggOne:hover:before{
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}
@media only screen and (max-width: 767px){
  .eggOne{ margin-bottom: 20px; }
}

.eggTwo {
  left: 5px;
  background-color: #f94144;
  transition: all .5s ease-out;
}

.eggTwo:focus{ color: #fff; }
.eggTwo:hover{
  top: 15px;
  background-color: #f94144;
  text-shadow: 0 0 5px rgba(0,0,0,0.3);
  box-shadow: 0 0 0 6px rgba(255,113,41,0.3);
}
.eggTwo:before{
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}
.eggTwo:hover:before{
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}
@media only screen and (max-width: 767px){
  .eggTwo{ margin-bottom: 20px; }
}


.eggTwo:after {
  content:"";
  position: absolute;
  box-shadow: inset -10px -10px rgba(0,0,0,0.02);
  width: 126px;
  height: 180px;
  border-radius:  60% 40% 40% 60% / 50% 50% 50% 50%;
}

.eggThree {
  left:100px;
  background: repeating-linear-gradient(-45deg, #70c1b3, #70c1b3 20px,
  #247ba0 20px, #247ba0 40px);
}
</style>
