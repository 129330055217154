<template>
  <div id="app">
    <InspectPage :x="this.x"></InspectPage>
  </div>
</template>

<script>
import InspectPage from '../components/InspectPage.vue'

export default {
  name: 'Inspect',
  props: {
    x: {
      type: String,
      default: "0"
    },
  },
  components: {
    InspectPage
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
