<template>
  <button
      v-on="listeners"
      :class="computedClasses"
      :disabled="loadingState"
      class="button is-primary"
  >
    <slot name="loading" v-if="loadingState">PENDING..</slot>
    <slot name="success" v-else-if="isSuccess">SUCCESS!</slot>
    <slot name="error" v-else-if="hasError">ERRORED!</slot>
    <slot v-else />
  </button>
</template>
<script>
export default {
  inheritAttrs: false,
  data: () => ({
    isLoading: false,
    hasError: false,
    isSuccess: false
  }),
  props: {
    /**
     * How much time should the error or success state stay visible
     */
    time: {
      type: Number,
      default: 2000
    },
    /**
     * Override the loading state, if promise is not possible to be passed
     */
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners() {
      // spread the listeners passed from the parent, but override the click one
      return {
        ...this.$listeners,
        click: this.handleClick
      };
    },
    computedClasses() {
      return {
        'is-danger': this.hasError,
        'is-success': this.isSuccess,
        'is-loading': this.loadingState
      }
    },
    // uses the loading state from the prop or the internal one
    loadingState() {
      return this.loading || this.isLoading
    }
  },
  methods: {
    async handleClick(e) {
      try {
        // set the internal loading state
        this.isLoading = true;
        // call the parent click listener and await it
        // Using Async/Await lets us await even none promises
        // pass the Event so modifiers can work
        await this.$listeners.click(e);
        // set the isSuccess state and revert it back after this.time period
        this.resetDelayed("isSuccess");
      } catch (error) {
        // set the hasError state and revert it back after this.time period
        this.resetDelayed("hasError");
      } finally {
        this.isLoading = false;
      }
    },
    resetDelayed(property) {
      // if loading prop is passed, dont set the internal states
      // eslint-disable-next-line no-prototype-builtins
      if(this.$options.propsData.hasOwnProperty('loading')) {
        return
      }
      this[property] = true;
      setTimeout(() => {
        this[property] = false;
      }, this.time);
    }
  }
};
</script>
<style scoped>
button {
  padding: 0.38rem 0.75rem;
  font-family: inherit;
  background-color: #402b2b;
  border: 6px solid;
  border-image: linear-gradient(#4e4024, #353932) 6;
  color: white;
  cursor: pointer;
  transition: all 175ms cubic-bezier(0,0,.5,.5);
  transition-property: all;
  transition-duration: 175ms;
  transition-timing-function: cubic-bezier(0, 0, 0.5, 0.5);
  transition-delay: 0s;
  outline: none;
  /*box-shadow: 0 8px 2px rgba(0, 0, 0, 0.75);*/
}

button:hover,
button:active {
  background-color: rgba(200, 0, 0, 0.5);
  border-color: #C800007F;
  font-family: inherit;
}

.flat {
  background-color: transparent;
  color: #C800007F;
  border: none;
  font-family: inherit;
}

.flat:hover,
.flat:active {
  background-color: #ffd2d2;
  font-family: inherit;
}
</style>
