<template>
  <div id="app">
    <BurnPage :x="this.x"></BurnPage>
  </div>
</template>

<script>
import BurnPage from '../components/BurnPage.vue'

export default {
  name: 'Help',
  components: {
    BurnPage
  },
  props: {
    x: {
      type: String,
      default: "0",
      required: false
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
