<template>
  <div id="app">
    <HistoryPage></HistoryPage>
  </div>
</template>

<script>
import HistoryPage from '../components/HistoryPage.vue'

export default {
  name: 'History',
  components: {
    HistoryPage
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
