import Vue from 'vue'
import Vuex from 'vuex'
import accounts from "./modules/accounts.js"

Vue.use(Vuex);
//everything is in 1 single accounts module.. I guess it could have went here..
export const store = new Vuex.Store({
    state:{

    },
    getters:{

    },
    modules: {
        accounts,
    },
    mutations:{

    },
    actions:{

    },
});
