<template>
  <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow nav-bg-pattern">
    <div class="main_window">
      <div class="cloud" data-type="white_4" style="top: 238px;" data-speed="1"></div>
      <div class="cloud" data-type="white_2" style="top: 252px;" data-speed="2"></div>
      <div class="cloud" data-type="white_1" style="top: 481px;" data-speed="3"></div>
      <div class="cloud" data-type="white_1" style="top: 285px;" data-speed="4"></div>
      <div class="cloud" data-type="white_5" style="top: 391px;" data-speed="5"></div>
      <div class="cloud" data-type="white_4" style="top: 410px;" data-speed="6"></div>
      <div class="cloud" data-type="white_6" style="top: 494px;" data-speed="7"></div>
      <div class="cloud" data-type="white_6" style="top: 197px;" data-speed="8"></div>
      <div class="cloud" data-type="white_1" style="top: 254px;" data-speed="9"></div>
      <div class="cloud" data-type="white_2" style="top: 108px;" data-speed="10"></div>
      <div class="cloud" data-type="white_5" style="top: 133px;" data-speed="11"></div>
      <div class="cloud" data-type="white_5" style="top: 126px;" data-speed="12"></div>
      <div class="cloud" data-type="white_5" style="top: 291px;" data-speed="13"></div>
      <div class="cloud" data-type="white_5" style="top: 515px;" data-speed="14"></div>
      <div class="cloud" data-type="white_6" style="top: 410px;" data-speed="15"></div>
      <div class="cloud" data-type="white_1" style="top: 198px;" data-speed="16"></div>
      <div class="cloud" data-type="white_2" style="top: 130px;" data-speed="17"></div>
      <div class="cloud" data-type="white_6" style="top: 274px;" data-speed="18"></div>
      <div class="cloud" data-type="white_6" style="top: 129px;" data-speed="19"></div>
      <div class="cloud" data-type="white_2" style="top: 140px;" data-speed="20"></div>
      <div class="cloud" data-type="white_6" style="top: 312px;" data-speed="21"></div>
      <div class="cloud" data-type="white_6" style="top: 258px;" data-speed="22"></div>
      <div class="cloud" data-type="white_2" style="top: 133px;" data-speed="23"></div>
      <div class="cloud" data-type="white_1" style="top: 530px;" data-speed="24"></div>
      <div class="cloud" data-type="white_5" style="top: 541px;" data-speed="25"></div>
      <div class="cloud" data-type="white_1" style="top: 313px;" data-speed="26"></div>
      <div class="cloud" data-type="white_1" style="top: 552px;" data-speed="27"></div>
      <div class="cloud" data-type="white_1" style="top: 548px;" data-speed="28"></div>
      <div class="cloud" data-type="white_2" style="top: 438px;" data-speed="29"></div>
      <div class="cloud" data-type="white_2" style="top: 516px;" data-speed="30"></div>
      <div class="cloud" data-type="white_5" style="top: 556px;" data-speed="31"></div>
      <div class="cloud" data-type="white_5" style="top: 225px;" data-speed="32"></div>
      <div class="cloud" data-type="white_3" style="top: 286px;" data-speed="33"></div>
      <div class="cloud" data-type="white_2" style="top: 416px;" data-speed="34"></div>
      <div class="cloud" data-type="white_2" style="top: 105px;" data-speed="35"></div>
      <div class="cloud" data-type="white_6" style="top: 195px;" data-speed="36"></div>
      <div class="cloud" data-type="white_2" style="top: 281px;" data-speed="37"></div>
      <div class="cloud" data-type="white_1" style="top: 291px;" data-speed="38"></div>
      <div class="cloud" data-type="white_4" style="top: 196px;" data-speed="39"></div>
      <div class="cloud" data-type="white_6" style="top: 442px;" data-speed="40"></div>

    </div>


    <div v-if="$screen.width <= 576" class="tophead centered-title-mobile">
      <h1> <span>K</span><span>a</span>
        <span>d</span>
        <span>e</span>
        <span>n</span>
        <span>a</span>
        <span>&nbsp; </span>
        <span>G</span>
        <span>a</span>
        <span>l</span>
        <span>l</span>
        <span>i</span>
        <span>n</span>
        <span>a</span>
        <span>s</span></h1>
    </div>

    <div v-if="$screen.width > 576" class="tophead centered">
      <h1> <span>K</span><span>a</span>
        <span>d</span>
        <span>e</span>
        <span>n</span>
        <span>a</span>
        <span>&nbsp; </span>
        <span>G</span>
        <span>a</span>
        <span>l</span>
        <span>l</span>
        <span>i</span>
        <span>n</span>
        <span>a</span>
        <span>s</span></h1>
    </div>

    <VButton class="navbutton navbar-brand col-md-3 col-lg-2 me-0 px-3 column is-1" v-if="!this.accountConfirmedNavBar && $screen.width > 576 && this.getXWalletConnected() === false && this.getCloverWalletConnected() === false|| this.displayFixedAccountName === null || this.displayFixedAccountName === 'null'"
             @click="connectWallet" :loading="false">Connect Wallet
    </VButton>

    <VButton class="navbutton navbar-brand col-md-3 col-lg-2 me-0 px-3 column is-1"
             v-if="this.accountConfirmedNavBar === true && $screen.width > 576 && this.displayFixedAccountName !== null && this.displayFixedAccountName !== 'null' || this.getXWalletConnected() === true && this.displayFixedAccountName !== null && this.displayFixedAccountName !== 'null' || this.getCloverWalletConnected() === true && this.displayFixedAccountName !== null && this.displayFixedAccountName !== 'null'" @click="showMyWalletModal = true" :loading="false" >
      {{ this.displayFixedAccountName }}
    </VButton>

    <span v-tooltip="'Help'" class="stickychicken"><a aria-current="help" href="/help"><img class="stickychicken visible-lg" src="../../public/icons/chicken-top-corner.svg" alt="Gallinas" height="50px"></a></span>

    <ul v-if="$screen.width > 576" class="navbar-nav" style="flex-direction: row;">
      <li class="nav-item text-nowrap">

        <button class="navbutton navbar-toggler d-md-none collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
                aria-label="Toggle navigation">
          <div class="menubutton">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div>
        </button>
      </li>
    </ul>


    <vmodal v-if="showTransactionModal" :show-gold-header="false" content-class="darkMode"
            :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Confirm" cancel-name="Close"
            v-model="showTransactionModal" @confirm="clickConfirmTransaction"
            @cancel="closeTransactionModal" name="intro" modaltitle="Transaction Processing">
      <template v-slot:title>
        <div>
          Processing Recent Transaction
        </div>
      </template>

      <div v-if="this.getTransactionFailed() === true" class="row align-items-center justify-content-center">
        <div class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-notfound mt-2">
          Transaction Failed
        </div>
      </div>
      <div v-if="this.getTransactionFailed() === false" class="row align-items-center justify-content-center">
        <div class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-found mt-2">
          Transaction Confirmed
        </div>
      </div>
      <div v-if="this.getTransactionFailed() === null" class="row align-items-center justify-content-center">
        <div class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-found mt-2">
          Checking Kadena Blockchain..
        </div>
      </div>


      <div class="displayGuard">
        <div class="row align-items-center justify-content-center mb-0">
          <div v-if="this.getAccountExists() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-5 bubble-bg-found mt-2">
            Transaction Hash:
          </div>
        </div>

        <div class="row align-items-center justify-content-center mb-0">
          <div v-if="this.getAccountExists() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-10 bubble-bg-found mt-2" style="line-height: 30px; overflow-wrap: anywhere;">
            {{this.getTransactionHash()}}
          </div>
        </div>

      </div>

    </vmodal>

    <vmodal v-if="showXWalletFinalModal" :show-gold-header="false" content-class="darkMode"
            :prevent-click="true" class-name="connect-xwallet-modal" confirm-name="Confirm" cancel-name="Close"
            v-model="showXWalletFinalModal" @confirm="connectxwallet"
            @cancel="showXWalletFinalModal=!showXWalletFinalModal" name="intro" modaltitle="Connect My Account">
      <template v-slot:title>
        <div>
          Request X Wallet Account
        </div>
      </template>

<!--      <div class="row align-items-center justify-content-center">-->
<!--        <div v-if="this.getAccountExists() === true"-->
<!--             class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-found mt-2">-->
<!--          Account Found On Mainnet Chain 1-->
<!--        </div>-->
<!--      </div>-->

      <div class="row align-items-center justify-content-center">
        <div
             class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-found mt-2">
          Connect your X-Wallet
        </div>
      </div>

      <div v-if="this.getAccountExists() !== true" class="displayGuard">
        <div class="row align-items-center justify-content-center mt-4 mb-0">
          <div class="p-0 border-gold text-center connect-wallet-text alert bubble-bg-found col-10 mt-2">
            Connect to Gallinas.io inside your X-Wallet, then come back and Confirm!
          </div>
        </div>
      </div>

      <div v-if="this.getAccountExists() === true" class="displayGuard">
        <div class="row align-items-center justify-content-center mt-4 mb-0">
          <div class="p-0 border-gold text-center connect-wallet-text alert bubble-bg-found col-10 mt-2">
            X-Wallet connected! Please confirm!
          </div>
        </div>
      </div>


    </vmodal>


    <vmodal v-if="showXCloverFinalModal" :show-gold-header="false" content-class="darkMode"
            :prevent-click="true" class-name="connect-clover-modal" confirm-name="Confirm" cancel-name="Close"
            v-model="showXCloverFinalModal" @confirm="connectcloverwallet"
            @cancel="showXCloverFinalModal=false" name="clover" modaltitle="Connect My Account">
      <template v-slot:title>
        <div>
          Request Clover Wallet Account
        </div>
      </template>

      <div class="row align-items-center justify-content-center">
        <div
            class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-found mt-2">
          Connect your Clover Wallet
        </div>
      </div>

      <div v-if="this.getAccountExists() !== true" class="displayGuard">
        <div class="row align-items-center justify-content-center mt-4 mb-0">
          <div class="p-0 border-gold text-center connect-wallet-text alert bubble-bg-found col-10 mt-2">
            Connect to Gallinas.io inside Clover Wallet, then come back and Confirm!
          </div>
        </div>
      </div>

      <div v-if="this.getAccountExists() === true" class="displayGuard">
        <div class="row align-items-center justify-content-center mt-4 mb-0">
          <div class="p-0 border-gold text-center connect-wallet-text alert bubble-bg-found col-10 mt-2">
            Clover Wallet connected! Please confirm!
          </div>
        </div>
      </div>


    </vmodal>


    <VModalConnectWallet v-if="showConnectWalletModal" :show-gold-header="false" content-class="darkMode"
            :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Connect" :showXWallet="true"
            v-model="showConnectWalletModal" @confirm="clickConnect" @xwallet="connectxwallet" @clover="connectcloverwallet"
            @cancel="clickCancel" name="intro" modaltitle="Connect My Account">
      <template v-slot:title>
        <div>
          Connect My Kadena Account
        </div>
      </template>

      <div class="row align-items-center justify-content-center">
        <div v-if="this.getAccountExists() === false"
             class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-notfound mt-2">
          No Account Found On Mainnet Chain 1
        </div>
      </div>

      <div class="row align-items-center justify-content-center">
        <div v-if="this.getAccountExists() === true"
             class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-found mt-2">
          Account Found On Mainnet Chain 1
        </div>
      </div>

      <div class="row align-items-center justify-content-center">
        <div v-if="this.accountNameToVerify === ''"
             class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-null mt-2">
          Live on Kadena Mainnet Chain 1
        </div>
      </div>

      <input @change="autoVerify()" @input="accountNameToVerify = $event.target.value; autoVerify()"
             :v-model="accountNameToVerify" type="text" class="form__input" id="NameInput"
             placeholder="My k: Account Name" required=""/>
      <label for="NameInput" class="form__label">Enter your FULL Kadena k: Account Name <br> ('k:' included!)</label>


    </VModalConnectWallet>


    <vmodal v-if="showConnectWalletModalFinal" :show-gold-header="false" content-class="darkMode"
            :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Confirm"
            v-model="showConnectWalletModalFinal" @confirm="clickConfirm"
            @cancel="clickCancel" name="intro">
      <template v-slot:title>
        <div>
          Connect My Account
        </div>
      </template>
      <div class="row align-items-center justify-content-center">
        <div v-if="this.getAccountExists() === true"
             class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-null mt-4">
          Review Account Guard:
        </div>
      </div>

      <div class="displayGuard">
        <div class="row align-items-center justify-content-center mb-0">
          <div v-if="this.getAccountExists() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-5 bubble-bg-found mt-2">
            Account Name:
          </div>
          <div v-if="this.getAccountExists() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-4 bubble-bg-found mt-2">
            {{ this.displayFixedAccountName }}
          </div>
        </div>

        <div class="row align-items-center justify-content-center mt-0 mb-0"
             style="height: 20px; position: relative; top: -20px;">
          <div v-if="this.getAccountExists() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-5 bubble-bg-found mt-2">
            Signing Predicate:
          </div>
          <div v-if="this.getAccountExists() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-4 bubble-bg-found mt-2">
            {{ this.getAccountPredicate() }}
          </div>
        </div>

        <div v-if="this.getAccountKeys().length > 1"
             class="row align-items-center justify-content-center table-responsive"
             style="height: 70px; position: relative; left:0px; top: -5px; width: 100%;">
          <div v-for="(element, index) in this.getAccountKeys()"
               :key="index"
               class="p-0 border-gold text-center connect-wallet-text alert col-9 bubble-bg-found mt-0 mb-0 table-responsive">
            Account Key #{{ index + 1 }}: {{ element.slice(0, 6) }}....{{ element.slice(58, 64) }}
          </div>
          <!--        <div v-for="(element, index) in this.getAccountKeys()"
                       :key="index"
                       class="p-0 border-gold text-center connect-wallet-text alert col-5 bubble-bg-found mt-0 table-responsive">
                    {{element.slice(0,6)}}......{{element.slice(58,64)}}
                  </div>-->
        </div>

        <div v-if="this.getAccountKeys().length <= 1"
             class="row align-items-center justify-content-center table-responsive" style="height: 50px;">
          <div v-for="(element, index) in this.getAccountKeys()"
               :key="index"
               class="p-0 border-gold text-center connect-wallet-text alert col-4 bubble-bg-found mt-0 table-responsive">
            Public Key:
          </div>
          <div v-for="(element) in this.getAccountKeys()"
               :key="element"
               class="p-0 border-gold text-center connect-wallet-text alert col-5 bubble-bg-found mt-0 table-responsive">
            {{ element.slice(0, 6) }}....{{ element.slice(58, 64) }}
          </div>
        </div>
      </div>

    </vmodal>

    <vmodal v-if="showMyWalletModal" :show-gold-header="false" content-class="darkMode"
            :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Confirm"
            v-model="showMyWalletModal" @confirm="showMyWalletModal = false"
            @cancel="clickCancelButton" name="intro" cancel-name="Disconnect">
      <template v-slot:title>
        <div class="pt-2">
          My Account
        </div>
        <div @click="clickHistory" class="historybutton btntx btn-lg"><i class="fa fa-sync"></i></div>
      </template>

      <div class="displayGuard">
        <div class="row align-items-center justify-content-center mt-4 mb-0">
          <div v-if="this.getAccountConfirmed() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-5 bubble-bg-found mt-2">
            Account Name:
          </div>
          <div v-if="this.getAccountConfirmed() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-4 bubble-bg-found mt-2">
            {{ this.displayFixedAccountName }}
          </div>
        </div>

        <div class="row align-items-center justify-content-center mt-0 mb-0"
             style="height: 20px; position: relative; top: -20px;">
          <div v-if="this.getAccountConfirmed() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-5 bubble-bg-found mt-2">
            Signing Predicate:
          </div>
          <div v-if="this.getAccountConfirmed() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-4 bubble-bg-found mt-2">
            {{ this.getAccountPredicate() }}
          </div>
        </div>

        <div v-if="this.getAccountKeys().length > 1"
             class="row align-items-center justify-content-center table-responsive"
             style="height: 70px; position: relative; left:0px; top: -5px; width: 100%;">
          <div v-for="(element, index) in this.getAccountKeys()"
               :key="index"
               class="p-0 border-gold text-center connect-wallet-text alert col-9 bubble-bg-found mt-0 mb-0 table-responsive">
            Account Key #{{ index + 1 }}: {{ element.slice(0, 6) }}....{{ element.slice(58, 64) }}
          </div>
        </div>

        <div v-if="this.getAccountKeys().length <= 1"
             class="row align-items-center justify-content-center table-responsive" style="height: 50px;">
          <div v-for="(element, index) in this.getAccountKeys()"
               :key="index"
               class="p-0 border-gold text-center connect-wallet-text alert col-4 bubble-bg-found mt-0 table-responsive">
            Public Key:
          </div>
          <div v-for="(element) in this.getAccountKeys()"
               :key="element"
               class="p-0 border-gold text-center connect-wallet-text alert col-5 bubble-bg-found mt-0 table-responsive">
            {{ element.slice(0, 6) }}....{{ element.slice(58, 64) }}
          </div>
        </div>
      </div>

    </vmodal>

  </header>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import VButton from "@/components/VButton";
import vmodal from "@/components/VModal";
import VModalConnectWallet from "@/components/VModalConnectWallet";

export default {
  name: "Navbar",
  components: {
    VButton,
    vmodal,
    VModalConnectWallet,
  },
  watch: {
    xwalletconnected: function(val, oldval){
      if(this.debug){
        console.log("xwalletconnected change detected");
        console.log("val");
        console.log(val);
        console.log("oldval");
        console.log(oldval);
      }
    },
    cloverwalletconnected: function(val, oldval){
      if(this.debug){
        console.log("cloverwalletconnected change detected");
        console.log("val");
        console.log(val);
        console.log("oldval");
        console.log(oldval);
      }
    },
    getRequestPending: function (newValue, oldValue) { //transactionpending indicates we should start a timer for 2 minutes
      if(this.debug){
        console.log("Navbar.vue getTransactionPending change detected");
        console.log("Navbar.vue getTransactionPending newValue: " + newValue);
        console.log("Navbar.vue getTransactionPending oldValue: " + oldValue);
      }
      if(newValue === true){
        this.showTransactionButton = true;
        //this.getPosts();
        this.checkAndHandlePendingRequests();
      }
      else if(newValue === false){
        this.showTransactionButton = false;
        this.getPosts();
      }
    },

  },
  data() {
    return {
      debug: false,
      showConnectWalletModal: false, //Use t/f to show/hide connect wallet modal
      showConnectWalletModalFinal: false,
      showXCloverFinalModal: false,
      showTransactionButton: false,
      showTransactionModal: false,
      showMyWalletModal: false,
      showXWalletFinalModal: false,
      accountNameToVerify: "", //Account name to attempt to verify on connect wallet modal
      connectModalStep: 0,
      accountConfirmedNavBar: false,
      xwalletconnectednavbar: false,
      transactionChecker: null,
      posts: [],
    };
  },
  // watch: {
  //   transactionPolling: function (newValue, oldValue) {
  //     console.log("getTransactionPolling newValue: " + newValue);
  //     console.log("getTransactionPolling oldValue: " + oldValue);
  //     if (newValue === true) {
  //       this.showTransactionButton = true;
  //     } else if (newValue === false) {
  //       this.showTransactionButton = false;
  //     }
  //   },
  // },
  computed: {
    ...mapState("accounts", ["transactionPolling", "xwalletconnected", "userRequests", "cloverwalletconnected"]),



    displayFixedAccountName() {

      if(this.getXWalletConnected() === false && this.getCloverWalletConnected() === false){
        if (this.accountNameToVerify.length > 12) {
          return this.accountNameToVerify.slice(0, 12) + "....";
        } else {
          return this.accountNameToVerify;
        }
      } else if(this.getXWalletConnected() === true){
        if (this.getAccountName().length > 12) {
          return this.getAccountName().slice(0, 12) + "....";
        } else {
          return this.getAccountName();
        }
      }else if(this.getCloverWalletConnected() === true){
        if (this.getAccountName().length > 12) {
          return this.getAccountName().slice(0, 12) + "....";
        } else {
          return this.getAccountName();
        }
      }else {
        return this.accountNameToVerify;
      }


    },
  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountName", "getAccountKeys", "getAccountPredicate", "getAccountData", "getAccountConfirmed", "getTransactionPolling", "getTransactionConfirmed", "getTransactionFailed", "getTransactionHash", "getTransactionConfirmedResult", "getXWalletConnected", "getRequestPending", "getCloverWalletConnected"]),
    ...mapActions("accounts", ["getAccountVerification"]),

    async clickHistory(){

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }else {
        await this.$router.push({path: `/history`});
      }


    },



    async getPosts () {
      //let data = [];
      await this.$store.dispatch("storeGetUserTransactionInfo", this.getActiveAccount);

      if(this.userRequests.length > 0) {
        this.posts = [];
        this.pages = [];
        for (let i = 0; i < this.userRequests.length; i++) {

          let hashLink = this.userRequests[i].hash;

          this.posts.push({
            id: this.userRequests[i].id,
            command: this.userRequests[i].command,
            txhash: hashLink,
            status: this.userRequests[i].status
          });
        }
      }
    },

    async checkAndHandlePendingRequests(){

      if(this.transactionChecker=== null){
        if(this.debug){
          console.log("setting interval");
        }

        this.transactionClearCount = 0;
        let name = localStorage.getItem("accountName");
        this.transactionChecker = setInterval(async ()=>{

          if(name !== null && name !== "" && this.accountConfirmedNavBar === true) {

            await this.getPosts();


            let isPending = false;
            for (let i = 0; i < this.posts.length; i++) {
              if (this.posts[i] !== undefined && this.posts[i] !== '') {
                if (this.posts[i].status === "pending") {
                  isPending = true;
                }
              }
            }

            if(isPending === true){
              await this.$store.dispatch("accounts/storeSetTransactionPending", isPending);
            }


            //Here we should probably get
            //await this.$store.dispatch("bindUserInfo", this.getActiveAccount.toUpperCase());

            if( isPending === false){
              this.transactionClearCount++;
            }

            if(this.transactionClearCount >= 1)
            {
              this.transactionClearCount=0;
              clearInterval(this.transactionChecker);
              this.transactionChecker = null;
              await this.$store.dispatch("accounts/storeSetTransactionPending", false);
            }
            //await this.$store.dispatch("oldman/fetchAllItems");
            /*let payloaditem = {
              address: this.getActiveAccount,
            }
            await this.$store.dispatch("storeGetUserItemsFinalList", payloaditem)*/
          }

        }, 6000);
      }

    },

    playSound (sound) {
      if(sound) {
        let audio = new Audio(require('../../public/icons/rooster.mp3'));
        audio.play();
      }
    },

    async clickConfirmTransaction(){

      let transactionfailed = await this.getTransactionFailed();

      if(transactionfailed === null){
        this.closeTransactionModal();
      }else if (transactionfailed === true){
        this.closeTransactionModal();
        await this.$store.dispatch("accounts/clearTransactionPoll");
      }else if (transactionfailed === false){
        this.closeTransactionModal();
        await this.$store.dispatch("accounts/clearTransactionPoll");
      }else {
        this.closeTransactionModal();
        await this.$store.dispatch("accounts/clearTransactionPoll");
      }

    },

    closeTransactionModal(){
      this.showTransactionModal = !this.showTransactionModal;
    },

    clickTransactionsButton(){
      this.showTransactionModal = !this.showTransactionModal;
      if(this.debug) {
        console.log("click");
      }

    },

    async connectWallet() { //handles Connect Wallet button on top
      this.showConnectWalletModal = true;
      this.connectModalStep = 1;

    },

    async clickDisconnectXwallet(){

      if(this.debug){
        console.log("disconnecting");
      }


      let kadena = window.kadena;

      let accountResult = await kadena.request({method: 'kda_disconnect', networkId: 'mainnet01', domain: window.location.hostname});

      if(this.debug) {
        console.log(accountResult.status);
      }

    },

    async clickCancelButton(){

      if(this.getXWalletConnected() === false && this.getCloverWalletConnected() === false){
        await this.clickCancel();
      }else if(this.getXWalletConnected() === true){
        await this.clickCancel();
        await this.clickDisconnectXwallet();
      }else if(this.getCloverWalletConnected() === true){
        await this.clickCancel();
      }

    },

    async connectxwallet() { //handles Connect Wallet button on top

      this.showConnectWalletModal = false;
      this.showXWalletFinalModal = !this.showXWalletFinalModal;

      return await this.$store.dispatch("accounts/connectXwallet");

    },

    async connectcloverwallet() { //handles Connect Wallet button on top


      this.showConnectWalletModal = false;
      this.showXCloverFinalModal = !this.showXCloverFinalModal;

      return await this.$store.dispatch("accounts/connectCloverwallet");

    },

    async autoVerify() { //Used to verify if an account exists on kadena
      let payload = this.accountNameToVerify;
      await this.$store.dispatch("accounts/getAccountVerification", payload);
    },

    async clickConnect() {//handles when Connect is clicked inside the Connect wallet modal
      if(this.getAccountExists() === false || this.getAccountExists() === null || this.getAccountExists() === undefined)
      {
        alert("Please create an account on Kadena Chain 1 and come play with us at Gallinas.io!")
      }else {
        this.showConnectWalletModal = false;
        this.showMyWalletModal = false;
        this.showConnectWalletModalFinal = true;
        if(this.debug){
          console.log("Clicked clickConnect showing next window to confirm")
        }
      }


    },

    async clickConfirm() {
      this.showConnectWalletModalFinal = false;

      let t_keys = this.getAccountKeys();
      let t_pred = this.getAccountPredicate();
      let t_accountdata = this.getAccountData();

      if(this.debug) {
        console.log("keys got from store:");
        console.log(t_keys);
        console.log("predicate got from store:");
        console.log(t_pred);
        console.log("data got from store:");
        console.log(t_accountdata);
      }


      if(this.debug) {
        console.log("dispatching confirm to store with account name:");
        console.log(this.accountNameToVerify);
      }
      await this.$store.dispatch("accounts/confirmAccountExists", this.accountNameToVerify);

      if(this.debug) {
        console.log("setting local storage up.");
      }
      localStorage.setItem("isUsingXwallet", false);
      localStorage.setItem("accountName", this.accountNameToVerify);
      localStorage.setItem("isConnected", true);
      this.accountConfirmedNavBar = true;
    },

    async clickCancel() {
      this.showConnectWalletModal = false;
      this.showConnectWalletModalFinal = false;
      this.showMyWalletModal = false;
      this.accountNameToVerify = "";
      await this.$store.dispatch("accounts/resetAccountExists");
      localStorage.setItem("accountName", "");
      localStorage.setItem("isConnected", false);
      localStorage.setItem("isUsingXwallet", false);
      localStorage.setItem("isUsingCloverwallet", false);

      this.accountConfirmedNavBar = false;
      if(this.$route.name !== "home"){
        await this.$router.push({path: `/`});
      }
    },

  },
  async created() {

    let checkConfirmed = this.getAccountConfirmed();

    if(this.debug) {
      console.log("Navbar created!");
      console.log("Checking if we are already connected..");
      console.log("accountConfirmedNavBar:");
      console.log(this.accountConfirmedNavBar);
      console.log("checkConfirmed:");
      console.log(checkConfirmed);
      console.log("localstorage isConnected:")
      console.log(localStorage.getItem("isConnected"));
      console.log("localstorage accountName:")
      console.log(localStorage.getItem("accountName"));
      console.log("localstorage predicate:")
      console.log(localStorage.getItem("accountPredicate"));
      console.log("localstorage account public key:")
      console.log(localStorage.getItem("accountPublicKey"));
      console.log("isUsingXwallet");
      console.log(localStorage.getItem("isUsingXwallet"));
      // console.log("isUsingCloverwallet");
      // console.log(localStorage.getItem("isUsingCloverwallet"));
    }

    if (localStorage.getItem("accountName")) {

      this.accountNameToVerify = localStorage.getItem("accountName");

      await this.$store.dispatch("accounts/getAccountVerification", this.accountNameToVerify);
      await this.$store.dispatch("accounts/confirmAccountExists", this.accountNameToVerify);

      if(this.debug) {
        console.log("window.kadena");
        console.log(window.kadena);
        console.log("xwallet isKadena:");
        console.log(window.kadena.isKadena);
        // console.log("window clover");
        // console.log(window.clover_kadena);
      }

        if (window.kadena && window.kadena.isKadena === true) {

          if (this.debug) {
            console.log("XWallet is available");
          }

          let xwalletconnect = localStorage.getItem("isUsingXwallet");
          if (this.debug) {
            console.log("xwalletconnect:");
            console.log(xwalletconnect);
          }

          if (localStorage.getItem("isUsingXwallet") === "true") {

            if (this.debug) {
              console.log("XWallet is being used");
            }

            await this.$store.dispatch("accounts/setXWalletIsConnected");

          } else {

            if (this.debug) {
              console.log("Xwallet is NOT being used");
            }

          }

        }

        //clover isnt signing properly, maybe another time:
        // if (window.clover_kadena && window.clover_kadena.isCloverWallet === true){
        //
        //   if (this.debug) {
        //     console.log("Clover is available");
        //   }
        //
        //   let cloverwallet = localStorage.getItem("isUsingCloverwallet");
        //   if (this.debug) {
        //     console.log("cloverwalletconnect:");
        //     console.log(cloverwallet);
        //   }
        //
        //   if (localStorage.getItem("isUsingCloverwallet") === "true") {
        //
        //     if (this.debug) {
        //       console.log("Clover is being used");
        //     }
        //
        //     await this.$store.dispatch("accounts/setCloverWalletIsConnected");
        //
        //   } else {
        //
        //     if (this.debug) {
        //       console.log("Clover is NOT being used");
        //     }
        //
        //   }
        //
        // }


      this.accountConfirmedNavBar = true;

      if(this.debug) {
        console.log("this.accountNameToVerify:")
        console.log(this.accountNameToVerify);
      }

    }

  },

};
</script>

<style scoped>


.loader{
  background: repeating-linear-gradient(to bottom,#fff 10px,#f5f5f5 20px);
  height: 20px;
  width: 20px;
  margin: 0 auto 0;
  border: 2px solid #3498db;
  box-shadow: 0 0 5px #3498db inset;
  border-radius: 50%;
  position: relative;
  top:0px;
}
.loader:before,
.loader:after{
  content: '';
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translate(-50%);
  transform-origin: bottom;
  position: absolute;
  left: 50%;
  bottom: 50%;
}
.loader:before{
  background-color: #eb3b5a;
  width: 1px;
  height: 31%;
  animation: rotate 5s linear infinite;
}
.loader:after{
  background-color: #2c3e50;
  width: 1px;
  height: 40%;
  animation: rotate 2s linear infinite;
}
@keyframes rotate{
  from{ transform: rotate(0); }
  to{ transform: rotate(360deg); }
}

.stickychicken {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 95%;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.displayGuard {
  background-color: #2d89a7;
}

.connect-wallet-p {
  margin: 0;
}

.connect-wallet-text {
  color: #f6f6f6;
}

.navbutton {
  padding: 0.75rem 1.5rem;
  font-family: inherit;
  background-color: #4496ec;
  border: 6px solid;
  border-image: linear-gradient(#a0b3c4, #e1f0be) 30;
  color: white;
  cursor: pointer;
  box-shadow: 0 8px 2px rgba(104, 103, 103, 0.4);
  outline: none;
}

.navbutton:hover,
.navbutton:active {
  background-color: rgba(200, 0, 0, 0.5);
  border-color: rgba(200, 0, 0, 0.5);
}

.flat {
  background-color: transparent;
  color: rgba(200, 0, 0, 0.5);
  border: none;
}

.flat:hover,
.flat:active {
  background-color: #ffd2d2;
}

.menubutton {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  align-items: center;
}

.bar1, .bar2, .bar3 {
  height: 5px;
  background-color: #e2e2e2;
  margin: 4px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-5px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-7px, -8px);
}

.intro-border {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.20);
  /*border: 5px outset #a0b3c4;*/
  /*border-image: linear-gradient(#a0b3c4, #a0b3c4) 30;*/
  border-radius: 10px;
  margin: .5rem auto;
  background-color: #ffffff;
  /*-webkit-background-clip: text;*/
  width: 80%;
  color: #727272;
}

.nav-bg-pattern {
  background-color: #212529;
  background-image: radial-gradient(circle at 100% 150%, rgb(225, 240, 190) 24%, rgb(255, 217, 99) 100%, rgb(255, 217, 99) 28%, rgb(225, 240, 190) 28%, rgb(225, 240, 190) 36%, rgb(255, 217, 99) 36%, rgb(255, 217, 99) 40%, transparent 40%, transparent),
  radial-gradient(circle at 0 150%, rgb(225, 240, 190) 24%, rgb(255, 217, 99) 24%, rgb(255, 217, 99) 28%, rgb(225, 240, 190) 28%, rgb(225, 240, 190) 36%, rgb(255, 217, 99) 36%, rgb(255, 217, 99) 40%, transparent 40%, transparent),
  radial-gradient(circle at 50% 100%, rgb(255, 217, 99) 10%, rgb(225, 240, 190) 10%, rgb(225, 240, 190) 23%, rgb(255, 217, 99) 23%, rgb(255, 217, 99) 30%, rgb(225, 240, 190) 30%, rgb(225, 240, 190) 43%, rgb(255, 217, 99) 43%, rgb(255, 217, 99) 50%, rgb(225, 240, 190) 50%, rgb(225, 240, 190) 63%, rgb(255, 217, 99) 63%, rgb(255, 217, 99) 71%, transparent 71%, transparent),
  radial-gradient(circle at 100% 50%, rgb(255, 217, 99) 5%, rgb(225, 240, 190) 5%, rgb(225, 240, 190) 15%, rgb(255, 217, 99) 15%, rgb(255, 217, 99) 20%, rgb(225, 240, 190) 20%, rgb(225, 240, 190) 29%, rgb(255, 217, 99) 29%, rgb(255, 217, 99) 34%, rgb(225, 240, 190) 34%, rgb(225, 240, 190) 44%, rgb(255, 217, 99) 44%, rgb(255, 217, 99) 49%, transparent 49%, transparent),
  radial-gradient(circle at 0 50%, rgb(255, 217, 99) 5%, rgb(225, 240, 190) 5%, rgb(225, 240, 190) 15%, rgb(255, 217, 99) 15%, rgb(255, 217, 99) 20%, rgb(225, 240, 190) 20%, rgb(225, 240, 190) 29%, rgb(255, 217, 99) 29%, rgb(255, 217, 99) 34%, rgb(225, 240, 190) 34%, rgb(225, 240, 190) 44%, rgb(255, 217, 99) 44%, rgb(255, 217, 99) 49%, transparent 49%, transparent);
  background-size: 100px 50px;
}

.noweb3-border {
  padding: 1rem;
  -webkit-background-clip: text;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Press Start 2P', cursive;
  font-size: .7rem;
  color: #cfcfcf;
  margin: 0px auto .5rem;
}

.transaction-border {
  box-shadow: 0 0 40px 8px rgba(0, 0, 0, 0.75);
  border: 6px solid;
  border-image: linear-gradient(#4e4024, #353932) 6;
  padding: .5rem;
  margin: .5rem auto;
  background: linear-gradient(rgba(143, 131, 131, 1), rgba(94, 90, 76, 1));
}

/*MODAL STUFF*/
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.75);
}

::v-deep .confirm-style {
  height: 60px;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  position: absolute;
  top: 180px;
  left: 15px;
}

::v-deep .cancel-style {
  height: 60px;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  position: absolute;
  top: 180px;
  left: 160px;
}

::v-deep .modal__title {
  background-color: #fff;
  height: 10px;
}


::v-deep .main-element {
  background: repeating-linear-gradient(
      to top left,
      beige 0,
      beige 2em,
      moccasin 2em,
      moccasin 4em,
      wheat 4em,
      wheat 6em
  ),
  repeating-linear-gradient(
      to left,
      white 0,
      white 2em,
      wheat 2em,
      wheat 4em,
      beige 4em,
      beige 6em
  ), white;

  background-blend-mode: multiply;
  border-radius: 10%;

}

.border-20 {
  border-radius: 20%;
}

.border-gold {
  border: 1px solid #8a6f3e;
}

.bubble-bg-found {
  background-color: #028050;
}

.bubble-bg-notfound {
  background-color: #e22b41;
}

.bubble-bg-null {
  background-color: #2d89a7;
}

.bubble-bg-grey {
  background-color: #2a2a28;
}

.form__label {
  margin-top: 0.7rem;
  display: block;
  color: #000000;
  background-color: rgba(206, 173, 232, 0.56);
  height: 100px;
}

.form__input {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #bf9e33;
  font-size: 1rem;
  margin: 0 auto;
  border-radius: 1rem;
  background-color: #ffffbb;
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;

}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #bf9e33;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #bf9e33;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #bf9e33;
}

:-moz-placeholder { /* Firefox 18- */
  color: #bf9e33;
}


.main_window {
  overflow: hidden;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 200px;
  position: fixed;
  top: -135px;
  z-index: -1;
  background-color: #89cdf1;
}

.cloud {
  width: 200px;
  height: 50px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
}

[data-type=white_1]:before {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 20px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 80px 0 0 10px #fff, 40px 0 0 20px #fff, 110px 0 0 -5px #fff;
}

[data-type=white_2]:before {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 25px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 40px -10px 0 20px #fff, 80px -10px 0 10px #fff, 115px -5px 0 -10px #fff;
}

[data-type=white_3]:before {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 25px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 40px -10px 0 15px #fff, 90px 0px 0 15px #fff;
}

[data-type=white_4]:before {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 20px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 80px 0 0 10px #fff, 40px 0 0 20px #fff, 110px 0 0 -5px #fff,
  80px 25px 0 10px #fff, 40px 25px 0 20px #fff, 0 40px 0 -5px #fff;
}

[data-type=white_5]:before {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 25px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 40px -10px 0 20px #fff, 80px -10px 0 10px #fff, 115px -5px 0 -10px #fff,
  20px 30px 0 20px #fff, 70px 30px 0 10px #fff, 105px 40px 0 -10px #fff;
}

[data-type=white_6]:before {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 25px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 40px -10px 0 15px #fff, 90px 0 0 15px #fff,
  20px 30px 0 15px #fff, 80px 40px 0 15px #fff;
}

@-webkit-keyframes linemove {
  from {
    left: -200px;
  }
  to {
    left: 2456px;
  }
}

@-moz-keyframes linemove {
  from {
    left: -200px;
  }
  to {
    left: 2456px;
  }
}

@-ms-keyframes linemove {
  from {
    left: -200px;
  }
  to {
    left: 2456px;
  }
}

@-o-keyframes linemove {
  from {
    left: -200px;
  }
  to {
    left: 2456px;
  }
}

@keyframes linemove {
  from {
    left: -200px;
  }
  to {
    left: 2456px;
  }
}


[data-speed="1"] {
  animation: linemove 44.01936s;
  -webkit-animation: linemove 44.01936s;

}

[data-speed="2"] {
  animation: linemove 28.67113s;
  -webkit-animation: linemove 28.67113s;

}

[data-speed="3"] {
  animation: linemove 36.99853s;
  -webkit-animation: linemove 36.99853s;

}

[data-speed="4"] {
  animation: linemove 22.6682s;
  -webkit-animation: linemove 22.6682s;

}

[data-speed="5"] {
  animation: linemove 21.20363s;
  -webkit-animation: linemove 21.20363s;

}

[data-speed="6"] {
  animation: linemove 36.46404s;
  -webkit-animation: linemove 36.46404s;

}

[data-speed="7"] {
  animation: linemove 45.58272s;
  -webkit-animation: linemove 45.58272s;

}

[data-speed="8"] {
  animation: linemove 31.02697s;
  -webkit-animation: linemove 31.02697s;

}

[data-speed="9"] {
  animation: linemove 23.79332s;
  -webkit-animation: linemove 23.79332s;

}

[data-speed="10"] {
  animation: linemove 35.69691s;
  -webkit-animation: linemove 35.69691s;

}

[data-speed="11"] {
  animation: linemove 36.87823s;
  -webkit-animation: linemove 36.87823s;

}

[data-speed="12"] {
  animation: linemove 20.158s;
  -webkit-animation: linemove 20.158s;

}

[data-speed="13"] {
  animation: linemove 17.34752s;
  -webkit-animation: linemove 17.34752s;

}

[data-speed="14"] {
  animation: linemove 22.19251s;
  -webkit-animation: linemove 22.19251s;

}

[data-speed="15"] {
  animation: linemove 25.91683s;
  -webkit-animation: linemove 25.91683s;

}

[data-speed="16"] {
  animation: linemove 17.46605s;
  -webkit-animation: linemove 17.46605s;

}

[data-speed="17"] {
  animation: linemove 22.15188s;
  -webkit-animation: linemove 22.15188s;

}

[data-speed="18"] {
  animation: linemove 27.5939s;
  -webkit-animation: linemove 27.5939s;

}

[data-speed="19"] {
  animation: linemove 40.26929s;
  -webkit-animation: linemove 40.26929s;

}

[data-speed="20"] {
  animation: linemove 12.73873s;
  -webkit-animation: linemove 12.73873s;

}

[data-speed="21"] {
  animation: linemove 11.34359s;
  -webkit-animation: linemove 11.34359s;

}

[data-speed="22"] {
  animation: linemove 24.98947s;
  -webkit-animation: linemove 24.98947s;

}

[data-speed="23"] {
  animation: linemove 43.17049s;
  -webkit-animation: linemove 43.17049s;

}

[data-speed="24"] {
  animation: linemove 28.77146s;
  -webkit-animation: linemove 28.77146s;

}

[data-speed="25"] {
  animation: linemove 19.03643s;
  -webkit-animation: linemove 19.03643s;

}

[data-speed="26"] {
  animation: linemove 44.53789s;
  -webkit-animation: linemove 44.53789s;

}

[data-speed="27"] {
  animation: linemove 26.64436s;
  -webkit-animation: linemove 26.64436s;

}

[data-speed="28"] {
  animation: linemove 41.99245s;
  -webkit-animation: linemove 41.99245s;

}

[data-speed="29"] {
  animation: linemove 18.44574s;
  -webkit-animation: linemove 18.44574s;

}

[data-speed="30"] {
  animation: linemove 19.09319s;
  -webkit-animation: linemove 19.09319s;

}

[data-speed="31"] {
  animation: linemove 34.62887s;
  -webkit-animation: linemove 34.62887s;

}

[data-speed="32"] {
  animation: linemove 33.08773s;
  -webkit-animation: linemove 33.08773s;

}

[data-speed="33"] {
  animation: linemove 10.0134s;
  -webkit-animation: linemove 10.0134s;

}

[data-speed="34"] {
  animation: linemove 39.82966s;
  -webkit-animation: linemove 39.82966s;

}

[data-speed="35"] {
  animation: linemove 36.10109s;
  -webkit-animation: linemove 36.10109s;

}

[data-speed="36"] {
  animation: linemove 47.62616s;
  -webkit-animation: linemove 47.62616s;

}

[data-speed="37"] {
  animation: linemove 39.53287s;
  -webkit-animation: linemove 39.53287s;

}

[data-speed="38"] {
  animation: linemove 21.86695s;
  -webkit-animation: linemove 21.86695s;

}

[data-speed="39"] {
  animation: linemove 48.20212s;
  -webkit-animation: linemove 48.20212s;

}

[data-speed="40"] {
  animation: linemove 26.6844s;
  -webkit-animation: linemove 26.6844s;
}

.cloud {
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-delay: -50s;
}

.tophead {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 45px;
  font-family: 'Mochiy Pop P One', sans-serif;
  z-index: -1;
}

h1 {
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  margin: auto;
  display: block;
  text-align: center;
}

h1 span {
  position: relative;
  top: 5px;
  display: inline-block;
  -webkit-animation: bounce 0.3s ease infinite alternate;
  font-size: 2.5vw;
  color: #ffcb5c;
  text-shadow: 0 1px 0 #d7a747, 0 2px 0 #d7a747, 0 3px 0 #d7a747, 0 4px 0 #d7a747,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
}

@media (max-width: 576px) {
  h1 span {
    font-size: 7.1vw;
  }

  .tophead {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 55px;
    font-family: 'Mochiy Pop P One', sans-serif;
    z-index: -1;
  }
}

@media (min-width: 2559px) {
  h1 span {
    font-size: 2vw;
  }
}

h1 span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
}

h1 span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
}

h1 span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
}

h1 span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
}

h1 span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
}

h1 span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
}

h1 span:nth-child(8) {
  -webkit-animation-delay: 0.2s;
}

h1 span:nth-child(9) {
  -webkit-animation-delay: 0.3s;
}

h1 span:nth-child(10) {
  -webkit-animation-delay: 0.4s;
}

h1 span:nth-child(11) {
  -webkit-animation-delay: 0.5s;
}

h1 span:nth-child(12) {
  -webkit-animation-delay: 0.6s;
}

h1 span:nth-child(13) {
  -webkit-animation-delay: 0.7s;
}

h1 span:nth-child(14) {
  -webkit-animation-delay: 0.8s;
}

/* ANIMATION */
@-webkit-keyframes bounce {
  100% {
    top: -1px;
    text-shadow: 0 1px 0 #d7a747, 0 2px 0 #d7a747, 0 3px 0 #d7a747, 0 4px 0 #d7a747,
    0 5px 0 #d7a747, 0 6px 0 #d7a747, 0 7px 0 #d7a747, 0 8px 0 #d7a747, 0 9px 0 #d7a747,
    0 15px 15px rgba(0, 0, 0, 0.2);
  }
}


.historybutton{
  position: absolute;
  top: -23px;
  left: 275px;
  width: 33px;
  border-radius: 30px;
  padding: 0.4rem 1rem;
}


.btntx{
  color: #fff;
  background: #1bcad3;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 30px;
  border: none;
  overflow: hidden;
  height: 33px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.btntx:focus{ color: #fff; }
.btntx:hover{
  color: #fff;
  background: #1bcad3;
  border: none;
  box-shadow: 0 0 5px #5B86E5;
}
.btntx i{
  margin-left: -6px;
  transition: all 1s ease 0s;
}
.btntx:hover i{ transform: rotate(360deg); }
.btntx:before{
  content: '';
  background: linear-gradient(to right, transparent, #5B86E5);
  height: 100%;
  width: 20%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.btntx:hover:before{ width: 100%; }
@media only screen and (max-width: 767px){
  .btntx{ margin-bottom: 20px; }
}

</style>
