<template>
  <div class="main-wrap">
    <div class="loader" v-if="this.showLoadingModal === true">
      <div class="loader__bar"></div>
      <div class="loader__bar loader__bar--delay-1"></div>
      <div class="loader__bar loader__bar--delay-2"></div>
      <div class="loader__bar loader__bar--delay-3"></div>
      <div class="loader__bar loader__bar--delay-4"></div>
      <div class="loader__bar loader__bar--delay-5"></div>
    </div>

    <section class="moveup" v-if="this.showLoadingModal === false">
      <Scroll-Div width="98%" height="85vh" view-class="yourclassname" v-if="this.showLoadingModal === false">
        <div class="container">


          <div class="modal-box">
            <div class="mdialog" role="document">
              <div class="modal-content clearfix">
                <div class="modal-body">
                  <div class="row align-items-center justify-content-evenly">
                    <div class="col-md-4 d-flex align-items-center justify-content-evenly">
                      <img src="../../public/icons/breed-chicken-banner.svg" width="160px"
                           alt="Breed Gallinas"/>
                    </div>
                    <div class="col-md-4 d-flex align-items-center justify-content-evenly">
                      <h3 class="title">Breed Gallinas</h3>
                    </div>
                    <div class="col-md-4 d-flex align-items-center justify-content-evenly">
                      <a @click="showRulebookModal = !showRulebookModal" class="rulesbutton"> <img class="rulebookimage"
                                                                                                   src="../../public/icons/rulebook-icon.svg"
                                                                                                   alt="rules"
                                                                                                   width="60px"/></a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.mate1details.length > 0" class="row align-items-center justify-content-center mt-4">
            <div v-for="g in this.mate1details" :key="g.id"
                 class="col-md-4 d-flex align-items-center justify-content-center mt-5">
              <FlipCard :name="g.name" :id="g.id"
                        :showbuttons="false"
                        :generation="g.generation.toString()"
                        :gender="g.gender" :birthday="g.birthday" :nbd="g.nbd"
                        :motherid="g.motherid" :fatherid="g.fatherid"
                        :setcount="g.setcount.toString()"
                        :rarity="g.rarity.toString()"
                        :special="g.special.toString()"
                        :gene1h1="g.gene1h1.toString()" :gene2h1="g.gene2h1.toString()" :gene3h1="g.gene3h1.toString()"
                        :gene4h1="g.gene4h1.toString()" :gene5h1="g.gene5h1.toString()" :gene6h1="g.gene6h1.toString()"
                        :gene7h1="g.gene7h1.toString()" :gene8h1="g.gene8h1.toString()" :gene9h1="g.gene9h1.toString()"
                        :gene10h1="g.gene10h1.toString()"
                        :gene1h2="g.gene1h2.toString()" :gene2h2="g.gene2h2.toString()" :gene3h2="g.gene3h2.toString()"
                        :gene4h2="g.gene4h2.toString()" :gene5h2="g.gene5h2.toString()" :gene6h2="g.gene6h2.toString()"
                        :gene7h2="g.gene7h2.toString()" :gene8h2="g.gene8h2.toString()" :gene9h2="g.gene9h2.toString()"
                        :gene10h2="g.gene10h2.toString()"
                        :gene1h3="g.gene1h3.toString()" :gene2h3="g.gene2h3.toString()" :gene3h3="g.gene3h3.toString()"
                        :gene4h3="g.gene4h3.toString()" :gene5h3="g.gene5h3.toString()" :gene6h3="g.gene6h3.toString()"
                        :gene7h3="g.gene7h3.toString()" :gene8h3="g.gene8h3.toString()" :gene9h3="g.gene9h3.toString()"
                        :gene10h3="g.gene10h3.toString()"
                        :beak="g.gene1p.toString()" :belly="g.gene2p.toString()" :chest="g.gene3p.toString()"
                        :comb="g.gene4p.toString()" :eyes="g.gene5p.toString()" :feet="g.gene6p.toString()"
                        :head="g.gene7p.toString()" :legs="g.gene8p.toString()" :wattle="g.gene9p.toString()"
                        :wings="g.gene10p.toString()">
              </FlipCard>
            </div>

            <div class="plus-area col-md-4 d-flex align-items-center justify-content-center mt-5">
              <span class="plus-font"> {{ this.positiveOrNegative }}</span>
            </div>

            <div v-for="g in this.mate2details" :key="g.id"
                 class="col-md-4 d-flex align-items-center justify-content-center mt-5">
              <FlipCard :name="g.name" :id="g.id"
                        :showbuttons="false"
                        :generation="g.generation.toString()"
                        :gender="g.gender" :birthday="g.birthday" :nbd="g.nbd"
                        :motherid="g.motherid" :fatherid="g.fatherid"
                        :setcount="g.setcount.toString()"
                        :special="g.special.toString()"
                        :rarity="g.rarity.toString()"
                        :gene1h1="g.gene1h1.toString()" :gene2h1="g.gene2h1.toString()" :gene3h1="g.gene3h1.toString()"
                        :gene4h1="g.gene4h1.toString()" :gene5h1="g.gene5h1.toString()" :gene6h1="g.gene6h1.toString()"
                        :gene7h1="g.gene7h1.toString()" :gene8h1="g.gene8h1.toString()" :gene9h1="g.gene9h1.toString()"
                        :gene10h1="g.gene10h1.toString()"
                        :gene1h2="g.gene1h2.toString()" :gene2h2="g.gene2h2.toString()" :gene3h2="g.gene3h2.toString()"
                        :gene4h2="g.gene4h2.toString()" :gene5h2="g.gene5h2.toString()" :gene6h2="g.gene6h2.toString()"
                        :gene7h2="g.gene7h2.toString()" :gene8h2="g.gene8h2.toString()" :gene9h2="g.gene9h2.toString()"
                        :gene10h2="g.gene10h2.toString()"
                        :gene1h3="g.gene1h3.toString()" :gene2h3="g.gene2h3.toString()" :gene3h3="g.gene3h3.toString()"
                        :gene4h3="g.gene4h3.toString()" :gene5h3="g.gene5h3.toString()" :gene6h3="g.gene6h3.toString()"
                        :gene7h3="g.gene7h3.toString()" :gene8h3="g.gene8h3.toString()" :gene9h3="g.gene9h3.toString()"
                        :gene10h3="g.gene10h3.toString()"
                        :beak="g.gene1p.toString()" :belly="g.gene2p.toString()" :chest="g.gene3p.toString()"
                        :comb="g.gene4p.toString()" :eyes="g.gene5p.toString()" :feet="g.gene6p.toString()"
                        :head="g.gene7p.toString()" :legs="g.gene8p.toString()" :wattle="g.gene9p.toString()"
                        :wings="g.gene10p.toString()">
              </FlipCard>
            </div>


          </div>

          <div class="divider py-1 bg-white text-white text3d" style="margin-top: 100px;">
            <hr>
          </div>

          <!--          <div v-if="this.getMyGallinasCanBreed() === true"-->
          <!--               class="row align-items-center justify-content-center mt-4 mb-5">-->
          <!--            <div class="col-md-4 d-flex align-items-center justify-content-center mt-5">-->
          <!--              <a @click="this.clickBreed" class="breedbutton"><span class="heart"></span></a>-->
          <!--            </div>-->
          <!--          </div>-->

          <div v-if="this.getMyGallinasCanBreed() === true && showNewGallina === false"
               class="row align-items-center justify-content-center mt-4 mb-5">
            <div class="col-md-4 d-flex align-items-center justify-content-center mt-5">
              <div class="flip-container">
                <div class="flipper">
                  <div class="front">
                    <div class="card form-container">
                      <div class="timeline-year">1 KDA</div>
                      <div class="card__product-img">

                        <div v-if="this.getMyGallinasCanBreed() === true"
                             class="row align-items-center justify-content-center mt-2 mb-5">
                          <div class="col-md-12 d-flex align-items-center justify-content-center mt-4 mb-5">
                            <a @click="this.clickBreed" class="breedbutton"><span class="heart"></span></a>
                          </div>
                        </div>

                      </div>

                      <div class="card__content">

                        <div class="card__content-bottom">

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


          <div v-if="showNewGallina===true"  class="row align-items-center justify-content-center mt-4 mb-5">
            <div v-for="g in this.newGallinaList" :key="g.id"
                 class="col-md-4 d-flex align-items-center justify-content-center mt-5">
              <FlipCard :name="g.name" :id="g.id"
                        :showbuttons="false"
                        :generation="g.generation.toString()"
                        :gender="g.gender" :birthday="g.birthday" :nbd="g.nbd"
                        :motherid="g.motherid" :fatherid="g.fatherid"
                        :setcount="g.setcount.toString()"
                        :special="g.special.toString()"
                        :rarity="g.rarity.toString()"
                        :gene1h1="g.gene1h1.toString()" :gene2h1="g.gene2h1.toString()" :gene3h1="g.gene3h1.toString()"
                        :gene4h1="g.gene4h1.toString()" :gene5h1="g.gene5h1.toString()" :gene6h1="g.gene6h1.toString()"
                        :gene7h1="g.gene7h1.toString()" :gene8h1="g.gene8h1.toString()" :gene9h1="g.gene9h1.toString()"
                        :gene10h1="g.gene10h1.toString()"
                        :gene1h2="g.gene1h2.toString()" :gene2h2="g.gene2h2.toString()" :gene3h2="g.gene3h2.toString()"
                        :gene4h2="g.gene4h2.toString()" :gene5h2="g.gene5h2.toString()" :gene6h2="g.gene6h2.toString()"
                        :gene7h2="g.gene7h2.toString()" :gene8h2="g.gene8h2.toString()" :gene9h2="g.gene9h2.toString()"
                        :gene10h2="g.gene10h2.toString()"
                        :gene1h3="g.gene1h3.toString()" :gene2h3="g.gene2h3.toString()" :gene3h3="g.gene3h3.toString()"
                        :gene4h3="g.gene4h3.toString()" :gene5h3="g.gene5h3.toString()" :gene6h3="g.gene6h3.toString()"
                        :gene7h3="g.gene7h3.toString()" :gene8h3="g.gene8h3.toString()" :gene9h3="g.gene9h3.toString()"
                        :gene10h3="g.gene10h3.toString()"
                        :beak="g.gene1p.toString()" :belly="g.gene2p.toString()" :chest="g.gene3p.toString()"
                        :comb="g.gene4p.toString()" :eyes="g.gene5p.toString()" :feet="g.gene6p.toString()"
                        :head="g.gene7p.toString()" :legs="g.gene8p.toString()" :wattle="g.gene9p.toString()"
                        :wings="g.gene10p.toString()">
              </FlipCard>
            </div>
          </div>


          <br/>

          <div v-if="this.getMyGallinasCanBreed() === false"
               class="row align-items-center justify-content-center mt-4 mb-5">
            <div class="col-md-4 d-flex flex-column align-items-center justify-content-center mt-5 errorcard">

              <div id="svgWrapper">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="120px"
                     height="120px" viewBox="0 0 40 40" version="1.1">
                  <title>
                    red_status
                  </title>
                  <desc>
                    Error.
                  </desc>
                  <defs>
                    <path
                        d="M8.43205769 38.1906149C2.90905516 38.17423 0.630739568 34.2579195 3.34303632 29.4437542L16.0266682 6.93106212C18.7390819 2.11668929 23.1497045 2.11171534 25.8729434 6.91091845L38.7464812 29.5981394C41.4720152 34.4013872 39.1981662 38.2818882 33.6812163 38.2655212L8.43205769 38.1906149Z"
                        id="path-1"/>
                    <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-2">
                      <feOffset dx="-2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
                      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                      <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.245216259 0" type="matrix"
                                     in="shadowBlurOuter1"/>
                    </filter>
                    <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-3">
                      <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetInner1"/>
                      <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1"
                                   result="shadowInnerInner1"/>
                      <feColorMatrix values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.4 0" type="matrix"
                                     in="shadowInnerInner1"/>
                    </filter>
                  </defs>
                  <g id="red-exclamation" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                     transform="translate(0.000000, -1.000000)">
                    <g id="Rectangle-134">
                      <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"/>
                      <use fill="#F1342F" fill-rule="evenodd" xlink:href="#path-1"/>
                      <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-1"/>
                      <use xlink:href="#path-1"/>
                    </g>
                    <line class="exclamation" x1="21" y1="11" x2="21" y2="25" stroke-linecap="round" stroke="#fff"
                          stroke-width="4"/>
                    <circle class="dot" cx="21" cy="32" r="2" fill="#fff"/>
                  </g>
                </svg>
              </div>

              <br/>
              <span class="errortxt">Oh no! These Gallinas are unable to breed!
                <br/>
                Check your Gallinas for compatability!</span>
            </div>
          </div>


        </div>
      </Scroll-Div>
    </section>

    <vmodalrules v-if="showRulebookModal" :show-gold-header="false" content-class="darkMode" :show-cancel="false"
                 :show-confirm="false"
                 :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Ok"
                 v-model="showRulebookModal" @confirm="showRulebookModal = false"
                 @cancel="showRulebookModal = false" name="intro" cancel-name="Cancel">

      <template v-slot:title>
        <div class="notestitle">
          My Gallina Breeding Notes
        </div>
      </template>

      <div class="mt-4">
        After my Gallinas breed, a new Gallina will appear in my account! But..
        <br/>
        <br/>
        1) My Gallinas will only breed if they are different genders..
        <br/>
        <br/>
        2) I can't breed my Gallinas unless they are at least 7 days old..
        <br/>
        <br/>
        3) My Gallinas won't breed with their parents..
        <br/>
        <br/>
        4) My Gallinas will only breed on, or after their next breed date..
        <br/>
        <br/>
        5) Each time I breed my Gallinas there is a 1 KDA fee to register my new Gallina in the Gallina Breeders Index..
      </div>

      <div class="row rulesclosebutton">
        <div class="col-2">
          <button @click="showRulebookModal = !showRulebookModal" class="btnname">X</button>
        </div>
      </div>

    </vmodalrules>

    <vmodalwaitsignature v-if="showSignTransactionModal" :show-gold-header="false" content-class="darkMode"
                         :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Ok"
                         v-model="showSignTransactionModal" @confirm="showSignTransactionModal = false"
                         @cancel="showSignTransactionModal = false" name="intro" cancel-name="Cancel">

      <template v-slot:title>
        <div class="pt-2">
          Awaiting wallet response
        </div>
      </template>

      <div class="displayGuard">
        <div class="row align-items-center justify-content-center mt-4 mb-0">
          <div class="p-0 border-gold text-center connect-wallet-text alert col-10 mt-4">
            We just sent a request to your wallet.<br/>
            <b>Always review your wallet's transactions.</b> When you're ready, sign, come back and click OK!
          </div>
        </div>
      </div>

    </vmodalwaitsignature>

  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ScrollDiv from "vue-scroll-div";
import FlipCard from "@/components/FlipCard";
import vmodalwaitsignature from "@/components/VModalWaitSignature";
import vmodalrules from "@/components/VModalRules";


export default {
  name: "BreedPage",
  props: {
    x: {
      type: String,
      default: "0"
    },
    y: {
      type: String,
      default: "0"
    },
  },
  components: {
    ScrollDiv,
    FlipCard,
    vmodalwaitsignature,
    vmodalrules
  },
  data() {
    return {
      showLoadingModal: true,
      showSignTransactionModal: false,
      showRulebookModal: false,
      allUserGallinasList: [],
      allUserGallinasIDsList: [],
      allUserGallinasListAfterTX: [],
      showNewGallina: false,
      newGallinaList: [],
      mate1details: [],
      mate2details: [],
      positiveGenes: true,
      mate1dominance: null,
      mate2dominance: null,
      debug: false,
    };
  },
  watch: {
    transactionPolling: function (newValue, oldValue) { //transactionpending indicates we should start a timer for 2 minutes
      if(this.debug){
        console.log("BurnPage getTransactionPending change detected");
        console.log("BurnPage getTransactionPending newValue: " + newValue);
        console.log("BurnPage getTransactionPending oldValue: " + oldValue);
      }
      if(newValue === false){
        this.checkForNewGallina();
      }
    },
  },
  computed: {
    ...mapState("accounts", ["transactionPolling"]),
    positiveOrNegative(){
      if(this.positiveGenes === true){
        return "+";
      }else {
        return "-";
      }
    }
    },
  methods: {
    ...mapActions("accounts", ["getAllGameGallinas", "getAllGameEggs", "getBreedGallinasVerification", "bindGallinaSetCount", "bindGallinaRarity", "bindCheckPending"]),
    ...mapGetters("accounts", ["getAccountExists", "getGameTotalGallinas", "getGameTotalEggs", "getAccountConfirmed", "getMyGallinasCanBreed", "getUserGallinasList"]),

    async checkForNewGallina(){

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      if(this.debug){
        console.log("LOOKING FOR NEW GALLINAS!");
      }

      await this.$store.dispatch("accounts/bindUserInfoNew", name);

      this.allUserGallinasListAfterTX = this.getUserGallinasList();

      if(this.debug){
        console.log("allUserGallinasList");
        console.log(this.allUserGallinasList);
      }


      if(this.debug){
        console.log("allUserGallinasListAfterTX");
        console.log(this.allUserGallinasListAfterTX);
      }

      let hasNewGallina = false;

      for(let i = 0; i < this.allUserGallinasListAfterTX.length; i++){

        if(this.allUserGallinasIDsList.includes(this.allUserGallinasListAfterTX[i].gid)===false){

          if(this.debug){
            console.log("NEW GALLINA FOUND!");
          }

          try {

            let rarity = [];

            let rarityPayload = {
              dna: this.allUserGallinasListAfterTX[i]["dna"],
              special: this.allUserGallinasListAfterTX[i]["special"]
            };

            await this.bindGallinaRarity(rarityPayload).then((response) => {
              if (this.debug === true) {
                console.log("GALLINA NEW RARITY RESPONSE");
                console.log(response);
              }
              rarity = response;
            });

            await this.bindGallinaSetCount().then((response) => {

              let set = "col" + this.allUserGallinasListAfterTX[i]["special"].toString();

              if (this.debug === true) {
                console.log("NEW SET");
                console.log(set);
                console.log("NEW SETCOUNT");
                console.log(response);

                console.log("NEW RARITY");
                console.log(rarity);

                console.log("NEW RARITY LENGTH");
                console.log(rarity.length);
              }

              let gallinaDetailsObject2 = {
                name: this.allUserGallinasListAfterTX[i].name,
                id: this.allUserGallinasListAfterTX[i].gid,
                account: this.allUserGallinasListAfterTX[i].owner,
                gender: this.allUserGallinasListAfterTX[i].gender,
                motherid: this.allUserGallinasListAfterTX[i]["mother_id"],
                fatherid: this.allUserGallinasListAfterTX[i]["father_id"],
                birthday: this.allUserGallinasListAfterTX[i]["birthday"],
                nbd: this.allUserGallinasListAfterTX[i]["next_breed_time"],
                generation: this.allUserGallinasListAfterTX[i]["generation"],
                special: this.allUserGallinasListAfterTX[i]["special"],
                rarity: rarity.length,
                setcount: response[0][set],
                gene1p: this.allUserGallinasListAfterTX[i]["gene_1_p"],
                gene2p: this.allUserGallinasListAfterTX[i]["gene_2_p"],
                gene3p: this.allUserGallinasListAfterTX[i]["gene_3_p"],
                gene4p: this.allUserGallinasListAfterTX[i]["gene_4_p"],
                gene5p: this.allUserGallinasListAfterTX[i]["gene_5_p"],
                gene6p: this.allUserGallinasListAfterTX[i]["gene_6_p"],
                gene7p: this.allUserGallinasListAfterTX[i]["gene_7_p"],
                gene8p: this.allUserGallinasListAfterTX[i]["gene_8_p"],
                gene9p: this.allUserGallinasListAfterTX[i]["gene_9_p"],
                gene10p: this.allUserGallinasListAfterTX[i]["gene_10_p"],
                gene1h1: this.allUserGallinasListAfterTX[i]["gene_1_h1"],
                gene2h1: this.allUserGallinasListAfterTX[i]["gene_2_h1"],
                gene3h1: this.allUserGallinasListAfterTX[i]["gene_3_h1"],
                gene4h1: this.allUserGallinasListAfterTX[i]["gene_4_h1"],
                gene5h1: this.allUserGallinasListAfterTX[i]["gene_5_h1"],
                gene6h1: this.allUserGallinasListAfterTX[i]["gene_6_h1"],
                gene7h1: this.allUserGallinasListAfterTX[i]["gene_7_h1"],
                gene8h1: this.allUserGallinasListAfterTX[i]["gene_8_h1"],
                gene9h1: this.allUserGallinasListAfterTX[i]["gene_9_h1"],
                gene10h1: this.allUserGallinasListAfterTX[i]["gene_10_h1"],
                gene1h2: this.allUserGallinasListAfterTX[i]["gene_1_h2"],
                gene2h2: this.allUserGallinasListAfterTX[i]["gene_2_h2"],
                gene3h2: this.allUserGallinasListAfterTX[i]["gene_3_h2"],
                gene4h2: this.allUserGallinasListAfterTX[i]["gene_4_h2"],
                gene5h2: this.allUserGallinasListAfterTX[i]["gene_5_h2"],
                gene6h2: this.allUserGallinasListAfterTX[i]["gene_6_h2"],
                gene7h2: this.allUserGallinasListAfterTX[i]["gene_7_h2"],
                gene8h2: this.allUserGallinasListAfterTX[i]["gene_8_h2"],
                gene9h2: this.allUserGallinasListAfterTX[i]["gene_9_h2"],
                gene10h2: this.allUserGallinasListAfterTX[i]["gene_10_h2"],
                gene1h3: this.allUserGallinasListAfterTX[i]["gene_1_h3"],
                gene2h3: this.allUserGallinasListAfterTX[i]["gene_2_h3"],
                gene3h3: this.allUserGallinasListAfterTX[i]["gene_3_h3"],
                gene4h3: this.allUserGallinasListAfterTX[i]["gene_4_h3"],
                gene5h3: this.allUserGallinasListAfterTX[i]["gene_5_h3"],
                gene6h3: this.allUserGallinasListAfterTX[i]["gene_6_h3"],
                gene7h3: this.allUserGallinasListAfterTX[i]["gene_7_h3"],
                gene8h3: this.allUserGallinasListAfterTX[i]["gene_8_h3"],
                gene9h3: this.allUserGallinasListAfterTX[i]["gene_9_h3"],
                gene10h3: this.allUserGallinasListAfterTX[i]["gene_10_h3"],
              };

              if (this.debug === true) {
                console.log("gallinaDetailsObject");
                console.log(gallinaDetailsObject2);
              }


              if (this.newGallinaList.includes(gallinaDetailsObject2) === false) {
                this.newGallinaList.push(gallinaDetailsObject2);
                hasNewGallina = true;
              }

              if (this.debug === true) {
                console.log("newGallinaList");
                console.log(this.newGallinaList);
              }

            });


          } catch (error) {
            if (this.debug === true) {
              console.log("Get new Gallina error");
              console.log(error);
            }
          }




        }

      }

      if(hasNewGallina === true){
        this.showNewGallina = true;
      }

    },

    async clickBreed() {


      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      await this.bindCheckPending(name).then(async (response) => {

        if (this.debug === true) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }
        if (response !== undefined && response[0] !== undefined && response[0].length === 0 || response === 0) {
          let theAccountName = localStorage.getItem("accountName");

          let payload = {
            accountName: theAccountName,
            gallina1id: this.x,
            gallina2id: this.y
          };

          if (this.x !== "0" && this.y !== "0") {
            this.showSignTransactionModal = true;
            await this.$store.dispatch("accounts/breedGallinas", payload);
            if (this.debug === true) {
              console.log("Attempting to breed Gallinas with payload:");
              console.log(payload);
            }
          } else {
            console.log("Error loading page. Try accessing this page via your Gallina's breed button.");
          }
        } else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.");
        }
      }).catch((error) => {
        if (this.debug === true) {
          console.log("Gallinas.io communication error");
          console.log(error);
        }

      });


    },

    async convertGallinaIdToValue(id) {
      let idList = id.split("");
      let gallinaValue = 0;
      for (let i = 0; i < idList.length; i++) {
        let value = idList[i] === "a" ? 1 : idList[i] === "b" ? 2 : idList[i] === "c" ? 3 : idList[i] === "d" ? 4 : idList[i] === "e" ? 5 : idList[i] === "f" ? 6 : idList[i] === "g" ? 7 : idList[i] === "h" ? 8 : idList[i] === "i" ? 9 : idList[i] === "j" ? 10 : idList[i] === "k" ? 11 : idList[i] === "l" ? 12 : idList[i] === "m" ? 13 : idList[i] === "n" ? 14 : idList[i] === "o" ? 15 : idList[i] === "p" ? 16 : idList[i] === "q" ? 17 : idList[i] === "r" ? 18 : idList[i] === "s" ? 19 : idList[i] === "t" ? 20 : idList[i] === "u" ? 21 : idList[i] === "v" ? 22 : idList[i] === "w" ? 23 : idList[i] === "x" ? 24 : idList[i] === "y" ? 24 : idList[i] === "z" ? 25 : idList[i] === "A" ? 26 : idList[i] === "B" ? 27 : idList[i] === "C" ? 28 : idList[i] === "D" ? 29 : idList[i] === "E" ? 30 : idList[i] === "F" ? 31 : idList[i] === "G" ? 32 : idList[i] === "H" ? 33 : idList[i] === "I" ? 34 : idList[i] === "J" ? 35 : idList[i] === "K" ? 36 : idList[i] === "L" ? 37 : idList[i] === "M" ? 38 : idList[i] === "N" ? 39 : idList[i] === "O" ? 40 : idList[i] === "P" ? 41 : idList[i] === "Q" ? 42 : idList[i] === "R" ? 43 : idList[i] === "S" ? 44 : idList[i] === "T" ? 45 : idList[i] === "U" ? 46 : idList[i] === "V" ? 47 : idList[i] === "W" ? 48 : idList[i] === "X" ? 49 : idList[i] === "Y" ? 50 : idList[i] === "Z" ? 51 : idList[i] === "1" ? 52 : idList[i] === "2" ? 53 : idList[i] === "3" ? 54 : idList[i] === "4" ? 55 : idList[i] === "5" ? 56 : idList[i] === "6" ? 57 : idList[i] === "7" ? 58 : idList[i] === "8" ? 59 : idList[i] === "9" ? 60 : idList[i] === "0" ? 61 : idList[i] === "-" ? 62 : idList[i] === "_" ? 63 : 0;
        if (i % 2 === 0) {
          gallinaValue += value;
        } else {
          gallinaValue -= value;
        }
      }
      return gallinaValue;
    }

  },
  async created() {

    let name = localStorage.getItem("accountName");
    if (name === null || name === "") {
      await this.$router.push({path: `/`});
    }

    //Lets start by getting all this users gallinas from gio
    await this.$store.dispatch("accounts/bindUserInfoNew", name);

    let allUserGallinas = this.getUserGallinasList();
    this.allUserGallinasList = allUserGallinas;

    //Now we need to get both mates information out of the usersgallinas list
    if (this.allUserGallinasList.length > 0) {

      //For each mate id, we want to compare it to the ids in the users gallinas list and find a match
      for (let i = 0; i < this.allUserGallinasList.length; i++) {

        this.allUserGallinasIDsList.push(this.allUserGallinasList[i].gid);

        if (this.x === this.allUserGallinasList[i].gid) {
          //Mate 1s id has been found, we add this gallinas information to a list to be displayed:

          this.mate1dominance = await this.convertGallinaIdToValue(this.x);


          try {

            if (this.debug === true) {
              console.log("DNA");
              console.log(this.allUserGallinasList[i]["dna"]);
              console.log("SPECIAL");
              console.log(this.allUserGallinasList[i]["special"]);
            }

            let rarity = [];

            let rarityPayload = {
              dna: this.allUserGallinasList[i]["dna"],
              special: this.allUserGallinasList[i]["special"]
            };

            await this.bindGallinaRarity(rarityPayload).then((response) => {
              if (this.debug === true) {
                console.log("GALLINA 1 RARITY RESPONSE");
                console.log(response);
              }
              rarity = response;
            });

            await this.bindGallinaSetCount().then((response) => {

              let set = "col" + this.allUserGallinasList[i]["special"].toString();

              if (this.debug === true) {
                console.log("SET");
                console.log(set);
                console.log("SETCOUNT");
                console.log(response);

                console.log("RARITY");
                console.log(rarity);

                console.log("RARITY LENGTH");
                console.log(rarity.length);
              }

              let gallinaDetailsObject = {
                name: this.allUserGallinasList[i].name,
                id: this.allUserGallinasList[i].gid,
                account: this.allUserGallinasList[i].owner,
                gender: this.allUserGallinasList[i].gender,
                motherid: this.allUserGallinasList[i]["mother_id"],
                fatherid: this.allUserGallinasList[i]["father_id"],
                birthday: this.allUserGallinasList[i]["birthday"],
                nbd: this.allUserGallinasList[i]["next_breed_time"],
                generation: this.allUserGallinasList[i]["generation"],
                special: this.allUserGallinasList[i]["special"],
                setcount: response[0][set],
                rarity: rarity.length,
                gene1p: this.allUserGallinasList[i]["gene_1_p"],
                gene2p: this.allUserGallinasList[i]["gene_2_p"],
                gene3p: this.allUserGallinasList[i]["gene_3_p"],
                gene4p: this.allUserGallinasList[i]["gene_4_p"],
                gene5p: this.allUserGallinasList[i]["gene_5_p"],
                gene6p: this.allUserGallinasList[i]["gene_6_p"],
                gene7p: this.allUserGallinasList[i]["gene_7_p"],
                gene8p: this.allUserGallinasList[i]["gene_8_p"],
                gene9p: this.allUserGallinasList[i]["gene_9_p"],
                gene10p: this.allUserGallinasList[i]["gene_10_p"],
                gene1h1: this.allUserGallinasList[i]["gene_1_h1"],
                gene2h1: this.allUserGallinasList[i]["gene_2_h1"],
                gene3h1: this.allUserGallinasList[i]["gene_3_h1"],
                gene4h1: this.allUserGallinasList[i]["gene_4_h1"],
                gene5h1: this.allUserGallinasList[i]["gene_5_h1"],
                gene6h1: this.allUserGallinasList[i]["gene_6_h1"],
                gene7h1: this.allUserGallinasList[i]["gene_7_h1"],
                gene8h1: this.allUserGallinasList[i]["gene_8_h1"],
                gene9h1: this.allUserGallinasList[i]["gene_9_h1"],
                gene10h1: this.allUserGallinasList[i]["gene_10_h1"],
                gene1h2: this.allUserGallinasList[i]["gene_1_h2"],
                gene2h2: this.allUserGallinasList[i]["gene_2_h2"],
                gene3h2: this.allUserGallinasList[i]["gene_3_h2"],
                gene4h2: this.allUserGallinasList[i]["gene_4_h2"],
                gene5h2: this.allUserGallinasList[i]["gene_5_h2"],
                gene6h2: this.allUserGallinasList[i]["gene_6_h2"],
                gene7h2: this.allUserGallinasList[i]["gene_7_h2"],
                gene8h2: this.allUserGallinasList[i]["gene_8_h2"],
                gene9h2: this.allUserGallinasList[i]["gene_9_h2"],
                gene10h2: this.allUserGallinasList[i]["gene_10_h2"],
                gene1h3: this.allUserGallinasList[i]["gene_1_h3"],
                gene2h3: this.allUserGallinasList[i]["gene_2_h3"],
                gene3h3: this.allUserGallinasList[i]["gene_3_h3"],
                gene4h3: this.allUserGallinasList[i]["gene_4_h3"],
                gene5h3: this.allUserGallinasList[i]["gene_5_h3"],
                gene6h3: this.allUserGallinasList[i]["gene_6_h3"],
                gene7h3: this.allUserGallinasList[i]["gene_7_h3"],
                gene8h3: this.allUserGallinasList[i]["gene_8_h3"],
                gene9h3: this.allUserGallinasList[i]["gene_9_h3"],
                gene10h3: this.allUserGallinasList[i]["gene_10_h3"],
              };

              if (this.debug === true) {
                console.log("gallinaDetailsObject");
                console.log(gallinaDetailsObject);
              }


              if (this.mate1details.includes(gallinaDetailsObject) === false && this.mate2details.includes(gallinaDetailsObject) === false) {
                this.mate1details.push(gallinaDetailsObject);
              }

              if (this.debug === true) {
                console.log("mate1details");
                console.log(this.mate1details);
              }

            }).catch((err) => {
              if (this.debug === true) {
                console.log("setcount error 2");
                console.log(err);
              }
            });


          } catch (error) {
            if (this.debug === true) {
              console.log("setcount error");
              console.log(error);
            }
          }


        } else if (this.y === this.allUserGallinasList[i].gid) { //Lets do the same for the other gallina

          this.mate2dominance = await this.convertGallinaIdToValue(this.y);


          try {

            let rarity = [];

            let rarityPayload = {
              dna: this.allUserGallinasList[i]["dna"],
              special: this.allUserGallinasList[i]["special"]
            };

            await this.bindGallinaRarity(rarityPayload).then((response) => {
              if (this.debug === true) {
                console.log("GALLINA 1 RARITY RESPONSE");
                console.log(response);
              }
              rarity = response;
            });

            await this.bindGallinaSetCount().then((response) => {

              let set = "col" + this.allUserGallinasList[i]["special"].toString();

              if (this.debug === true) {
                console.log("SET");
                console.log(set);
                console.log("SETCOUNT");
                console.log(response);

                console.log("RARITY");
                console.log(rarity);

                console.log("RARITY LENGTH");
                console.log(rarity.length);
              }

              let gallinaDetailsObject2 = {
                name: this.allUserGallinasList[i].name,
                id: this.allUserGallinasList[i].gid,
                account: this.allUserGallinasList[i].owner,
                gender: this.allUserGallinasList[i].gender,
                motherid: this.allUserGallinasList[i]["mother_id"],
                fatherid: this.allUserGallinasList[i]["father_id"],
                birthday: this.allUserGallinasList[i]["birthday"],
                nbd: this.allUserGallinasList[i]["next_breed_time"],
                generation: this.allUserGallinasList[i]["generation"],
                special: this.allUserGallinasList[i]["special"],
                rarity: rarity.length,
                setcount: response[0][set],
                gene1p: this.allUserGallinasList[i]["gene_1_p"],
                gene2p: this.allUserGallinasList[i]["gene_2_p"],
                gene3p: this.allUserGallinasList[i]["gene_3_p"],
                gene4p: this.allUserGallinasList[i]["gene_4_p"],
                gene5p: this.allUserGallinasList[i]["gene_5_p"],
                gene6p: this.allUserGallinasList[i]["gene_6_p"],
                gene7p: this.allUserGallinasList[i]["gene_7_p"],
                gene8p: this.allUserGallinasList[i]["gene_8_p"],
                gene9p: this.allUserGallinasList[i]["gene_9_p"],
                gene10p: this.allUserGallinasList[i]["gene_10_p"],
                gene1h1: this.allUserGallinasList[i]["gene_1_h1"],
                gene2h1: this.allUserGallinasList[i]["gene_2_h1"],
                gene3h1: this.allUserGallinasList[i]["gene_3_h1"],
                gene4h1: this.allUserGallinasList[i]["gene_4_h1"],
                gene5h1: this.allUserGallinasList[i]["gene_5_h1"],
                gene6h1: this.allUserGallinasList[i]["gene_6_h1"],
                gene7h1: this.allUserGallinasList[i]["gene_7_h1"],
                gene8h1: this.allUserGallinasList[i]["gene_8_h1"],
                gene9h1: this.allUserGallinasList[i]["gene_9_h1"],
                gene10h1: this.allUserGallinasList[i]["gene_10_h1"],
                gene1h2: this.allUserGallinasList[i]["gene_1_h2"],
                gene2h2: this.allUserGallinasList[i]["gene_2_h2"],
                gene3h2: this.allUserGallinasList[i]["gene_3_h2"],
                gene4h2: this.allUserGallinasList[i]["gene_4_h2"],
                gene5h2: this.allUserGallinasList[i]["gene_5_h2"],
                gene6h2: this.allUserGallinasList[i]["gene_6_h2"],
                gene7h2: this.allUserGallinasList[i]["gene_7_h2"],
                gene8h2: this.allUserGallinasList[i]["gene_8_h2"],
                gene9h2: this.allUserGallinasList[i]["gene_9_h2"],
                gene10h2: this.allUserGallinasList[i]["gene_10_h2"],
                gene1h3: this.allUserGallinasList[i]["gene_1_h3"],
                gene2h3: this.allUserGallinasList[i]["gene_2_h3"],
                gene3h3: this.allUserGallinasList[i]["gene_3_h3"],
                gene4h3: this.allUserGallinasList[i]["gene_4_h3"],
                gene5h3: this.allUserGallinasList[i]["gene_5_h3"],
                gene6h3: this.allUserGallinasList[i]["gene_6_h3"],
                gene7h3: this.allUserGallinasList[i]["gene_7_h3"],
                gene8h3: this.allUserGallinasList[i]["gene_8_h3"],
                gene9h3: this.allUserGallinasList[i]["gene_9_h3"],
                gene10h3: this.allUserGallinasList[i]["gene_10_h3"],
              };

              if (this.debug === true) {
                console.log("gallinaDetailsObject");
                console.log(gallinaDetailsObject2);
              }


              if (this.mate2details.includes(gallinaDetailsObject2) === false && this.mate1details.includes(gallinaDetailsObject2) === false) {
                this.mate2details.push(gallinaDetailsObject2);
              }

              if (this.debug === true) {
                console.log("mate2details");
                console.log(this.mate2details);
              }

            });


          } catch (error) {
            if (this.debug === true) {
              console.log("setcount2 error");
              console.log(error);
            }
          }


        }

      }

      if(this.mate1dominance + this.mate2dominance > 0){
        this.positiveGenes = true;
      }else {
        this.positiveGenes = false;
      }

    }


    let mypayload = {
      gallina1id: this.x,
      gallina2id: this.y,
    };

    //check from blockchain if these gallinas can breed for confirmation
    await this.getBreedGallinasVerification(mypayload);

    //show rulebook if gallinas are not breedable
    if (this.getMyGallinasCanBreed() === false) {
      this.showRulebookModal = true;
    }

    //Close loading screen
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 10);
  },
};
</script>

<style scoped>

.timeline-year {
  color: #fff;
  background-color: #d13847;
  font-size: 2vh;
  line-height: 70px;
  text-wrap: none;
  height: 55px;
  width: 46%;
  transform: rotate(7deg);
  position: relative;
  left: -15px;
  top: 10px;
  font-family: 'Mochiy Pop P One', sans-serif;
}

.timeline-year:before {
  content: '';
  background-color: #fcfcfc;
  height: 12px;
  width: 12px;
  border: 3px solid #aaa;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  position: absolute;
  left: 8px;
  top: 8px;
}


.timeline-year {
  transform: rotate(-7deg);
  left: auto;
  right: -15px;
  z-index: 0;
}

.timeline-year:before {
  left: auto;
  right: 8px;
}

.timeline-year {
  background-color: #20b583;
}


.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}

.flip-container {
  min-height: 120px;
}

.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}

.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}

.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.front {
  z-index: 2;
}
</style>

<style scoped lang="scss">
.icon {
  display: flex;
}

.card {
  background-color: #a0b3c4;
  color: #8BACD9;
  border-radius: 16px;
  width: 300px;
  //height: 500px;
  padding-bottom: 0;
  box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.1);

  .card__product-img {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }

  .card__content {
    display: flex;
    flex-direction: column;

    .card__name {
      color: #285a79;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .card__description {
      font-size: 1rem;
      line-height: 2.6rem;
      font-weight: lighter;
      position: absolute;
      top: 0px;
    }

    .card__content-bottom {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 0;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price {
        color: #d50078;
      }
    }
  }

  .card__footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #2E405A;
    gap: 16px;
    padding-top: 16px;

    .card__avatar {
      display: flex;
      border-radius: 90px;
      border: 1px solid white;

      img {
        width: 33px;
      }
    }

    .card__autor--alt-color {
      color: white;
      cursor: pointer;

      &:hover {
        color: #00FFF8;
      }
    }
  }
}

//Transition
.fade-enter-active,
.fade-leave-active {
  transition: 0.25s ease-out;
}

.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}


.form-container {
  background: radial-gradient(
          circle at bottom left,
          transparent 0,
          transparent 2em,
          beige 2em,
          beige 4em,
          transparent 4em,
          transparent 6em,
          peachpuff 6em,
          peachpuff 8em,
          transparent 8em,
          transparent 10em
  ),
  radial-gradient(
          circle at top right,
          transparent 0,
          transparent 2em,
          beige 2em,
          beige 4em,
          transparent 4em,
          transparent 6em,
          peachpuff 6em,
          peachpuff 8em,
          transparent 8em,
          transparent 10em
  ),
  radial-gradient(
          circle at top left,
          transparent 0,
          transparent 2em,
          navajowhite 2em,
          navajowhite 4em,
          transparent 4em,
          transparent 6em,
          peachpuff 6em,
          peachpuff 8em,
          transparent 8em,
          transparent 10em
  ),
  radial-gradient(
          circle at bottom right,
          transparent 0,
          transparent 2em,
          palegoldenrod 2em,
          palegoldenrod 4em,
          transparent 4em,
          transparent 6em,
          peachpuff 6em,
          peachpuff 8em,
          transparent 8em,
          transparent 10em
  ), khaki;

  background-blend-mode: darken;
  background-size: 10em 10em;
  background-position: 0 0, 0 0, 5em 5em, 5em 5em;
  padding: 20px;
  padding-top: 40px;
  border-radius: 20px;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
}

.form-container .form-icon {
  color: #40ac98;
  font-size: 55px;
  text-align: center;
  line-height: 100px;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px #fff;
}

.form-container .title {
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-control {
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.form-container .form-horizontal .form-control:focus {
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}

.form-container .form-horizontal .form-control::placeholder {
  color: #808080;
  font-size: 14px;
}

.form-container .form-horizontal .btn {
  color: #000;
  background-color: #ac40ab;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  padding: 12px 15px 11px;
  border-radius: 20px;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px #fff;
  border: none;
  transition: all 0.5s ease 0s;
}

.form-container .form-horizontal .btn:hover,
.form-container .form-horizontal .btn:focus {
  color: #fff;
  letter-spacing: 3px;
  box-shadow: none;
  outline: none;
}

</style>


<style lang="scss" scoped>
.displayGuard {
  background-color: #2d89a7;
}

.connect-wallet-p {
  margin: 0;
}

.connect-wallet-text {
  color: #f6f6f6;
}

.feefont {
  font-family: 'Comic Neue', cursive;
  color: #5a4222;
  font-size: 16px;
}

.errortxt {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #c11313;
  margin-bottom: 20px;
}

.errorcard {
  border-radius: 20px;
  border: 5px dashed white;
  background-color: #ff8d97;
  margin-bottom: 20px;
}

.notestitle {
  position: absolute;
  top: 0px;
  left: 20px;
}

.btnname {
  color: #fff;
  background-color: #e84393;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 12px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnname:active {
  margin-left: 10px;
  margin-top: 10px;
}

.btnname:focus,
.btnname:hover {
  color: #fff;
}

.btnname:before,
.btnname:after {
  content: '';
  background-color: #ad1d63;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btnname:after {
  background-color: #821248;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btnname:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnname:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}

.rulesclosebutton {
  position: absolute;
  top: 0;
  right: 20px;
}

.rulebookimage {


}

.text3d {
  box-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
}

.rulesbutton {
  width: 80px;
  height: 80px;
  border-radius: 15px;
  background: var(--light-grey);
  box-shadow: var(--dark-grey) 0 15px 0 0;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in;
  margin-top: 20px;
}

.rulesbutton:active {
  background: var(--pink);
  box-shadow: var(--dark-pink) 0 5px 0 0;
  transform: translateY(10px);
}

.breedbutton {
  width: var(--button-width);
  height: var(--button-height);
  border-radius: 15px;
  background: var(--light-grey);
  box-shadow: var(--dark-grey) 0 15px 0 0;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in;
  margin-top: 20px;
}

.breedbutton:active {
  background: var(--pink);
  box-shadow: var(--dark-pink) 0 5px 0 0;
  transform: translateY(10px);
}

.heart {
  width: var(--heart-size);
  height: var(--heart-size);
  transform: rotate(45deg);
  background: var(--dark-grey);
  top: 5px;
  position: relative;
  display: flex;
  transition: 0.2s ease-in;
}

.breedbutton:hover > .heart {
  background: var(--pink);
}

.breedbutton:active > .heart {
  background: white;
}

.heart::before,
.heart::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: inherit;
  position: absolute;
}

.heart::before {
  left: -50%;
}

.heart::after {
  top: -50%;
}

.plus-area {

}

.plus-font {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 200px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.frontfooter {
  display: flex;
  position: relative;
  justify-content: center;
}

.modal-box .mdialog {
  width: 80%;
  margin: 70px auto 0;
  margin-top: 0px;

}

@media (max-width: 576px) {
  .modal-box .mdialog {
    width: 95%;
  }
}

.modal.fade .mdialog {
  opacity: 0;
  transform: translate(30%, 30%) scale(0.5);
}

.modal.fade.in .mdialog {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.modal-box .mdialog .modal-content {
  border: 5px solid #ffda86;
  border-radius: 35px;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
  background-color: #e8c898;
}

.modal-box .mdialog .modal-content .modal-body {
  padding: 65px 45px;
}

.modal-box .mdialog .modal-content .modal-body .icon {
  color: #827f3b;
  font-size: 80px;
  line-height: 145px;
  width: 100px;
  height: 100px;
  padding-left: 0px;
  padding-right: 10px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: 25px;
}

.modal-box .mdialog .modal-content .modal-body .inner-content {
  padding: 0 0 0 170px;
  margin: 0 0 30px;
  margin-top: 0px;
}

.modal-box .mdialog .modal-content .modal-body .title {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.modal-box .mdialog .modal-content .modal-body .description {
  color: #534f47;
  font-size: 15px;
  margin: 0;
  padding-top: 20px;
  font-family: 'Comic Neue', cursive;
}

.modal-box .mdialog .modal-content .modal-body .form-control {
  color: #6d5a36;
  background: #eee8dd;
  font-size: 15px;
  letter-spacing: 1px;
  height: 45px;
  padding: 2px 15px;
  border-radius: 50px 0 0 50px;
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control:focus {
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.modal-box .mdialog .modal-content .modal-body .subscribe {
  color: #fff;
  background: #ebbf53;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px 20px 11px;
  border: none;
  border-radius: 0 50px 50px 0;
  display: block;
  transition: all 0.3s;
}

.modal-box .mdialog .modal-content .modal-body .subscribe:hover,
.modal-box .mdialog .modal-content .modal-body .subscribe:focus {
  background: #827f3b;
}

#svgWrapper {
  display: inline-block;
  width: 120px;
  height: 120px;
  margin-top: 10px;

  svg {
    width: 120px;
    height: 120px;
    transition: transform .5s ease-out;

    #red-exclamation {
      animation: bounce .5s ease-out forwards;
      transform-origin: center center;

      .exclamation {
        opacity: 0;
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        animation: excl 1.5s ease forwards .5s;
      }

      .dot {
        opacity: 0;
        stroke-width: 30;
        stroke-linecap: round;
        animation: dot-fade .175s ease forwards .9s;
      }
    }
  }
}

@keyframes excl {
  0% {
    stroke-dashoffset: 100;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes dot-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(.6);
  }
  60% {
    transform: scale(1);
  }
  85% {
    transform: scale(.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

</style>
