<template>
  <div>

    <Navbar />


    <div class="container-fluid">
      <div class="row">

        <Sidebar />

        <main class="col-md-9 ms-sm-auto col-lg-10 gameborder">

          <transition name="fade" mode="out-in">
            <!--            <keep-alive>-->
            <router-view />
            <!--            </keep-alive>-->
          </transition>

        </main>
      </div>
    </div>

  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Sidebar from './components/Sidebar.vue';


export default {
  name: 'App',
  components: {
    Navbar,
    Sidebar
  },
  computed: {
  },
}
</script>

<style>
.body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.sd{
  height: calc(80vh - 10%);
  width: 97%;
}

.flip-horizontally {
  transform: scaleX(-1);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.gameborder {
  border: 10px solid;
  margin: 0px;
  padding: 1px;
  height: calc(100vh - 65px - (10px * 1) - (1px * 1) + 11px);
  border-image: linear-gradient(#ffd963, #a0865c) 30;
  overflow: hidden;
}

.center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
