<template>
  <vue-final-modal
      v-slot="{ params, close }"
      v-bind="$attrs"
      classes="modal-container"
      :content-class="className"
      v-on="$listeners"
  >
    <div v-if="showGoldHeader" class="gradient"></div>
    <div class="wrapper">
      <div class="main-element">
    <span class="modal__title mt-4 m-2">
      <slot name="title"></slot>
    </span>
        <div class="modal__content">
          <slot v-bind:params="params"></slot>
        </div>
        <div class="modal__action mb-4">
          <button v-if="showConfirm" class="p-1 m-2 text-success confirm-style" @click="$emit('confirm', close)">
            {{ confirmName }}
          </button>
          <button v-if="showCancel" class="p-1 m-2 text-danger cancel-style" @click="$emit('cancel', close)">
            {{ cancelName }}
          </button>
        </div>
      </div>
    </div>
    <!--    <button class="modal__close" @click="close">
          Close
        </button>-->
  </vue-final-modal>
</template>

<script>
export default {
  name: "Modal",
  props: {
    showGoldHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    showConfirm: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
    className: {
      type: String,
      required: false,
      default: "intro",
    },
    confirmName: {
      type: String,
      required: false,
      default: "Confirm",
    },
    cancelName: {
      type: String,
      required: false,
      default: "Cancel",
    }
  }
};
</script>

<style scoped>


.main-element {
  position: absolute;
  top: 5%;
  left: 4%;
  display: block;
  height: 92%;
  width: 92%;
  align-self: center;
  background: repeating-linear-gradient(
      to top left,
      beige 0,
      beige 2em,
      moccasin 2em,
      moccasin 4em,
      wheat 4em,
      wheat 6em
  ),
  repeating-linear-gradient(
      to left,
      white 0,
      white 2em,
      wheat 2em,
      wheat 4em,
      beige 4em,
      beige 6em
  ), white;

  background-blend-mode: multiply;
  z-index: 1;
  padding: 0.2rem;
  align-items: center;
  border-radius: 20px;

}

.modal__action {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.wrapper {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50%), -50%);
  box-sizing: content-box;
  padding: 1rem;
  height: 480px;
  max-width: 540px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.75);
  width: 90%;
  border-radius: 10%;
  background:
      radial-gradient(circle closest-side at 60% 43%, #b03 26%, rgba(187,0,51,0) 27%),
      radial-gradient(circle closest-side at 40% 43%, #b03 26%, rgba(187,0,51,0) 27%),
      radial-gradient(circle closest-side at 40% 22%, #d35 45%, rgba(221,51,85,0) 46%),
      radial-gradient(circle closest-side at 60% 22%, #d35 45%, rgba(221,51,85,0) 46%),
      radial-gradient(circle closest-side at 50% 35%, #d35 30%, rgba(221,51,85,0) 31%),

      radial-gradient(circle closest-side at 60% 43%, #b03 26%, rgba(187,0,51,0) 27%) 50px 50px,
      radial-gradient(circle closest-side at 40% 43%, #b03 26%, rgba(187,0,51,0) 27%) 50px 50px,
      radial-gradient(circle closest-side at 40% 22%, #d35 45%, rgba(221,51,85,0) 46%) 50px 50px,
      radial-gradient(circle closest-side at 60% 22%, #d35 45%, rgba(221,51,85,0) 46%) 50px 50px,
      radial-gradient(circle closest-side at 50% 35%, #d35 30%, rgba(221,51,85,0) 31%) 50px 50px;
  background-color:#b03;
  background-size:100px 100px;
}

button {
  padding: 0.75rem 1.5rem;
  font-family: inherit;
  background-color: #1f1918;
  border: 6px solid;
  border-image: linear-gradient(#4e4024, #353932) 6;
  color: white;
  cursor: pointer;
  box-shadow: 0 8px 2px rgba(0, 0, 0, 0.75);
}

button:hover,
button:active {
  background-color: #410000;
  border-color: #410000;
}

.flat {
  background-color: transparent;
  color: #610000;
  border: none;
}

.flat:hover,
.flat:active {
  background-color: #ffd2d2;
}

.gradient {
  position: fixed;
  background: #999955;
  background-image: linear-gradient(#DAB046 20%,
  #be9736 20%,
  #a07e28 40%,
  #997825 40%,
  #735812 60%,
  #4f3b07 60%,
  #533c05 80%,
  #302400 80%);
  margin: 0 auto;
  width: 100%;
  height: 50px;
  top: 65px;
  left: 0;

}
</style>
