<template>
  <div class="inspect-page-wrap centered" style="overflow-x: hidden; overflow-y: hidden ; height: calc(70vh); width: 80%;">
    <div class="loader" v-if="this.showLoadingModal===true" style="margin-top: -100px;">
      <div class="loader__bar"></div>
      <div class="loader__bar loader__bar--delay-1"></div>
      <div class="loader__bar loader__bar--delay-2"></div>
      <div class="loader__bar loader__bar--delay-3"></div>
      <div class="loader__bar loader__bar--delay-4"></div>
      <div class="loader__bar loader__bar--delay-5"></div>
    </div>

    <section class="moveup" v-if="this.showLoadingModal === false">
      <div width="100%" height="100%" view-class="yourclassname" v-if="this.showLoadingModal === false">
        <div class="container">

          <div v-if="this.gallinasToShowNowList2.length > 0" class="row align-items-center justify-content-center mt-4">
            <div v-for="g in this.gallinasToShowNowList2" :key="g.id"
                 class="col-md-4 d-flex align-items-center justify-content-center" :class='changemargin === false ? "chickenmargin" : "chickenmarginflipped" '>
              <FlipCard v-if="!showChicken" :name="g.name" :id="g.id" class="flipcardclass" @flipped="changemargin = true"
                        :showbuttons="false"
                        :showbuybutton="g.isforsale"
                        :showinspectbutton="false"
                        :special="g.special.toString()"
                        :price="g.price" :seller="g.seller"
                        :setcount="g.setcount.toString()"
                        :rarity="g.rarity.toString()"
                        :gender="g.gender" :birthday="g.birthday" :nbd="g.nbd"
                        :motherid="g.motherid" :fatherid="g.fatherid"
                        :generation="g.generation.toString()"
                        :gene1h1="g.gene1h1.toString()" :gene2h1="g.gene2h1.toString()" :gene3h1="g.gene3h1.toString()"
                        :gene4h1="g.gene4h1.toString()" :gene5h1="g.gene5h1.toString()" :gene6h1="g.gene6h1.toString()"
                        :gene7h1="g.gene7h1.toString()" :gene8h1="g.gene8h1.toString()" :gene9h1="g.gene9h1.toString()"
                        :gene10h1="g.gene10h1.toString()"
                        :gene1h2="g.gene1h2.toString()" :gene2h2="g.gene2h2.toString()" :gene3h2="g.gene3h2.toString()"
                        :gene4h2="g.gene4h2.toString()" :gene5h2="g.gene5h2.toString()" :gene6h2="g.gene6h2.toString()"
                        :gene7h2="g.gene7h2.toString()" :gene8h2="g.gene8h2.toString()" :gene9h2="g.gene9h2.toString()"
                        :gene10h2="g.gene10h2.toString()"
                        :gene1h3="g.gene1h3.toString()" :gene2h3="g.gene2h3.toString()" :gene3h3="g.gene3h3.toString()"
                        :gene4h3="g.gene4h3.toString()" :gene5h3="g.gene5h3.toString()" :gene6h3="g.gene6h3.toString()"
                        :gene7h3="g.gene7h3.toString()" :gene8h3="g.gene8h3.toString()" :gene9h3="g.gene9h3.toString()"
                        :gene10h3="g.gene10h3.toString()"
                        :beak="g.gene1p.toString()" :belly="g.gene2p.toString()" :chest="g.gene3p.toString()"
                        :comb="g.gene4p.toString()" :eyes="g.gene5p.toString()" :feet="g.gene6p.toString()"
                        :head="g.gene7p.toString()" :legs="g.gene8p.toString()" :wattle="g.gene9p.toString()"
                        :wings="g.gene10p.toString()">
              </FlipCard>

              <div v-if="showChicken" @click="clickchicken()" class="chickenclass" style="z-index: 2;">
              <Chicken  class="chickenclass mt-5" setwidth="30vh" :beak="g.gene1p.toString()" :belly="g.gene2p.toString()" :chest="g.gene3p.toString()" :comb="g.gene4p.toString()" :eyes="g.gene5p.toString()" :feet="g.gene6p.toString()"
                       :head="g.gene7p.toString()"
                       :legs="g.gene8p.toString()" :wattle="g.gene9p.toString()" :wings="g.gene10p.toString()" :special="g.special.toString()"/>
              </div>

              <div class="chickenclass2" style="z-index: 2;"></div>

            </div>

          </div>

          <div class="mt-4"><br/></div>

          <div v-if="!showLoadingModal && showChicken === false" style="z-index:3;" class="viewgallinadiv"><button type="button" class="mx-3 btnsearch" style="outline:none;" @click="clickshowchicken()" >
            View Gallina
          </button></div>

        </div>


      </div>

    </section>



  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
// import ScrollDiv from "vue-scroll-div";
import FlipCard from "@/components/FlipCard";
import Chicken from "@/components/Chicken";


export default {
  name: "InspectPage",
  props: {
    x: {
      type: String,
      default: "0"
    },
  },
  components: {
    // ScrollDiv,
    FlipCard,
    Chicken
  },
  data() {
    return {
      showLoadingModal: true,
      showSignTransactionModal: false,
      gallinaToShowNowList: [],
      gallinasToShowNowList2: [],
      marketGallinasForSaleListgio: [],
      gallinaDetailsList: [],
      showRulebookModal: false,
      showChicken: true,
      changemargin: false,
    };
  },
  methods: {
    ...mapActions("accounts", ["getAllGameGallinas", "getAllGameEggs", "getGallinaMate1Info", "getBreedGallinasVerification", "getInspectGallinaInfo", "bindGallinaRarity", "bindGallinaSetCount", "bindMarketGallinas", "bindInspectGallina"]),
    ...mapGetters("accounts", ["getAccountExists", "getGameTotalGallinas", "getGameTotalEggs", "getAccountConfirmed", "getMate1List", "getMate2List", "getMyGallinasCanBreed", "getInspectGallinaList"]),

    clickchicken(){
      this.showChicken = !this.showChicken;
      this.changemargin = !this.changemargin;
    },

    clickshowchicken(){
      this.showChicken = true;
      this.changemargin = !this.changemargin;
    }

  },
  async created() {

    //Get gallina stats
    await this.$store.dispatch("accounts/getInspectGallinaInfo", this.x);

    this.gallinaToShowNowList = await this.bindInspectGallina(this.x);

    //Next we get complete list of Gallinas that are for sale from gallinasio to list prices
    this.marketGallinasForSaleListgio = await this.bindMarketGallinas();

    let rarity = [];
    let gallinaDetailsList = [];
    let forsale = false;
    let price = 0;
    let seller = null;

    for(let m = 0; m<this.marketGallinasForSaleListgio.length; m++){

      if(this.gallinaToShowNowList[0].gid === this.marketGallinasForSaleListgio[m].gid){
        forsale = true;
        price = this.marketGallinasForSaleListgio[m].price;
        seller = this.marketGallinasForSaleListgio[m].owner;
      }

    }


    let rarityPayload = {
      dna: this.gallinaToShowNowList[0]["dna"],
      special: this.gallinaToShowNowList[0]["special"]
    };

    if(this.debug){
      console.log("RARITY PAYLOAD");
      console.log(rarityPayload);

      console.log("DNA");
      console.log(this.gallinaToShowNowList[0]["dna"]);
      console.log(this.gallinaToShowNowList[0]["special"]);
    }



    await this.bindGallinaRarity(rarityPayload).then((response) => {
      console.log("GALLINA 1 RARITY RESPONSE");
      console.log(response);
      rarity = response;
    });

    await this.bindGallinaSetCount().then((response) => {

      let set = "col" + this.gallinaToShowNowList[0]["special"].toString();

      if(this.debug){
        console.log("SET");
        console.log(set);
        console.log("SETCOUNT");
        console.log(response);

        console.log("RARITY");
        console.log(rarity);

        console.log("RARITY LENGTH");
        console.log(rarity.length);
      }



      let gallinaDetailsObject = {
        name: this.gallinaToShowNowList[0].gid,
        id: this.gallinaToShowNowList[0].gid,
        account: this.gallinaToShowNowList[0].owner,
        gender: this.gallinaToShowNowList[0].gender,
        motherid: this.gallinaToShowNowList[0]["mother_id"],
        fatherid: this.gallinaToShowNowList[0]["father_id"],
        birthday: this.gallinaToShowNowList[0]["birthday"],
        nbd: this.gallinaToShowNowList[0]["next_breed_time"],
        generation: this.gallinaToShowNowList[0]["generation"],
        special: this.gallinaToShowNowList[0]["special"],
        setcount: response[0][set],
        rarity: rarity.length,
        gene1p: this.gallinaToShowNowList[0]["gene_1_p"],
        gene2p: this.gallinaToShowNowList[0]["gene_2_p"],
        gene3p: this.gallinaToShowNowList[0]["gene_3_p"],
        gene4p: this.gallinaToShowNowList[0]["gene_4_p"],
        gene5p: this.gallinaToShowNowList[0]["gene_5_p"],
        gene6p: this.gallinaToShowNowList[0]["gene_6_p"],
        gene7p: this.gallinaToShowNowList[0]["gene_7_p"],
        gene8p: this.gallinaToShowNowList[0]["gene_8_p"],
        gene9p: this.gallinaToShowNowList[0]["gene_9_p"],
        gene10p: this.gallinaToShowNowList[0]["gene_10_p"],
        gene1h1: this.gallinaToShowNowList[0]["gene_1_h1"],
        gene2h1: this.gallinaToShowNowList[0]["gene_2_h1"],
        gene3h1: this.gallinaToShowNowList[0]["gene_3_h1"],
        gene4h1: this.gallinaToShowNowList[0]["gene_4_h1"],
        gene5h1: this.gallinaToShowNowList[0]["gene_5_h1"],
        gene6h1: this.gallinaToShowNowList[0]["gene_6_h1"],
        gene7h1: this.gallinaToShowNowList[0]["gene_7_h1"],
        gene8h1: this.gallinaToShowNowList[0]["gene_8_h1"],
        gene9h1: this.gallinaToShowNowList[0]["gene_9_h1"],
        gene10h1: this.gallinaToShowNowList[0]["gene_10_h1"],
        gene1h2: this.gallinaToShowNowList[0]["gene_1_h2"],
        gene2h2: this.gallinaToShowNowList[0]["gene_2_h2"],
        gene3h2: this.gallinaToShowNowList[0]["gene_3_h2"],
        gene4h2: this.gallinaToShowNowList[0]["gene_4_h2"],
        gene5h2: this.gallinaToShowNowList[0]["gene_5_h2"],
        gene6h2: this.gallinaToShowNowList[0]["gene_6_h2"],
        gene7h2: this.gallinaToShowNowList[0]["gene_7_h2"],
        gene8h2: this.gallinaToShowNowList[0]["gene_8_h2"],
        gene9h2: this.gallinaToShowNowList[0]["gene_9_h2"],
        gene10h2: this.gallinaToShowNowList[0]["gene_10_h2"],
        gene1h3: this.gallinaToShowNowList[0]["gene_1_h3"],
        gene2h3: this.gallinaToShowNowList[0]["gene_2_h3"],
        gene3h3: this.gallinaToShowNowList[0]["gene_3_h3"],
        gene4h3: this.gallinaToShowNowList[0]["gene_4_h3"],
        gene5h3: this.gallinaToShowNowList[0]["gene_5_h3"],
        gene6h3: this.gallinaToShowNowList[0]["gene_6_h3"],
        gene7h3: this.gallinaToShowNowList[0]["gene_7_h3"],
        gene8h3: this.gallinaToShowNowList[0]["gene_8_h3"],
        gene9h3: this.gallinaToShowNowList[0]["gene_9_h3"],
        gene10h3: this.gallinaToShowNowList[0]["gene_10_h3"],
        isforsale: forsale,
        price: price,
        seller: seller,
      };

      if(this.debug){
        console.log("SPECIAL");
        console.log(this.gallinaToShowNowList[0]["special"]);
        console.log("SPECIAL TYPOF");
        console.log(typeof (this.gallinaToShowNowList[0]["special"]));
        console.log("gallinaDetailsObject");
        console.log(gallinaDetailsObject);

      }


      gallinaDetailsList.push(gallinaDetailsObject);

    }).catch((err) => {

      if(this.debug){
        console.log("Get gallina info error");
        console.log(err);
      }


    });

    if(this.debug){
      console.log("GallinaListToShowThisPage:");
      console.log(gallinaDetailsList);
    }



    this.gallinasToShowNowList2 = gallinaDetailsList;

    //Close loading screen
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 100);
  },
};
</script>
<style lang="scss" scoped>

.viewgallinadiv{
  top: 0px;
  left: 0px;
  position: relative;
}

.chickenmargin {
  margin-top: 10%;
}

.chickenmarginflipped {
  margin-top: 4%;
}

.inspect-page-wrap {
  background: center 33% url("../../public/icons/inspect-cage-bg.svg");
  background-size: 80vw;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  margin-top: 8vh;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 80%;
  border-radius: 15%;
  border: 5px #ffda86;
  border-style: inset;
  transform: translate(-50%,5%);
}

@media (min-width: 2500px) {

  .inspect-page-wrap {
    background: center 33% url("../../public/icons/inspect-cage-bg.svg");
    background-size: 120%;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    margin-top: calc(20vh - 10px);
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 80%;
    border-radius: 15%;
    border: 5px #ffda86;
    border-style: inset;
    transform: translate(-50%,5%);
  }

}

@media (max-width: 576px) {

  .inspect-page-wrap {
    background: center 33% url("../../public/icons/inspect-cage-bg.svg");
    background-size: calc(120vh - 10px);
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    margin-top: calc(-5vh);
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 94% !important;
    border-radius: 10%;
    border: 5px #ffda86;
    border-style: inset;
    transform: translate(-50%,5%);
  }

}


body {
  background-color: #89cdf1 !important;
  margin: 0;
}

.flipcardclass{
  z-index: 2;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #bf9e33;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #bf9e33;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #bf9e33;
}

:-moz-placeholder { /* Firefox 18- */
  color: #bf9e33;
}


.main_window {
  overflow: hidden;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 200px;
  position: fixed;
  top: -135px;
  z-index: -1;
  background-color: #89cdf1;
}


@-webkit-keyframes linemove {
  from {
    left: -200px;
  }
  to {
    left: 2456px;
  }
}

@-moz-keyframes linemove {
  from {
    left: -200px;
  }
  to {
    left: 2456px;
  }
}

@-ms-keyframes linemove {
  from {
    left: -200px;
  }
  to {
    left: 2456px;
  }
}

@-o-keyframes linemove {
  from {
    left: -200px;
  }
  to {
    left: 2456px;
  }
}

@keyframes linemove {
  from {
    left: -200px;
  }
  to {
    left: 2456px;
  }
}

@keyframes move {
  0% { transform: translateX(0); }
  7.5% { transform: translateX(-10%); }
  10% { transform: translateX(-20%)  }

  17.5% { transform: translateX(-40%); }
  20% { transform: translateX(-100%) rotateX(180deg); }

  27.5% { transform: translateX(-50%) }
  30% { transform: translateX(-50%) rotateX(280deg); }

  37.5% { transform: translateX(-40%); }
  40% { transform: translateX(-40%) rotateX(-180deg); }

  47.5% { transform: translateX(-30%) rotateY(180deg) rotateX(-180deg); }
  50% { transform: translateX(-30%) rotateY(180deg) rotateX(180deg); }

  57.5% { transform: translateX(-30%) rotateY(180deg) rotateX(280deg); }
  60% { transform: translateX(-30%) rotateX(-180deg); }

  67.5% { transform: translateX(-20%) rotateX(280deg);}
  70% { transform: translateX(-20%) rotateX(280deg); }

  77.5% { transform: translateX(-10%)   }
  80% { transform: translateX(-10%) }

  87.5% { transform: translateX(0%)  }
  90% { transform: translateX(0%) }

  97.5% { transform: translateX(0%); }
  100% { transform: translateX(0%); }
}

@keyframes hop {
  0% { transform: translateY(0); animation-timing-function: ease-out; }
  50% { transform: translateY(-15px); animation-timing-function: ease-in; }
  80% { transform: translateY(0); }
}

@keyframes dance {
  25% {
    bottom: 0px;
    transform: rotate(3deg);
  }
  50% {
    bottom: 20px;
    transform: rotate(0deg);
  }
  60% {
    bottom: 30px;
    transform: rotate(-3deg) rotateX(-5deg) scale(1.01, 1.01) translateY(5px);
  }
  65% { transform: rotate(0deg) rotateX(0deg) scale(1.02, 1.02) translateY(7px);}
  70% {
    bottom: 20px;
    transform: rotate(1deg) scale(1.03, 1.03);
  }
  75% {
    bottom: 0px;
    transform: rotate(0deg);
  }
}
@keyframes danceShadow {
  0%   { z-index: 0; width: 150px; right: 40px;  bottom: -180px; }
  10%  { z-index: 0;  width: 150px; right: 40px; bottom: -180px; }
  30%  { z-index: 0;  width: 150px; right: 40px;  bottom: -180px }
  50%  { z-index: 0;  width: 150px; right: 40px;  bottom: -180px; }
  55% { z-index: 0;  width: 150px; right: 40px; bottom: -180px;}
  60% { z-index: 0;  width: 150px; right: 40px; bottom: -180px;}
  65% { z-index: 0;  width: 150px; right: 40px; bottom: -180px;}
  57%  { z-index: 0;  width: 150px; right: 40px;  bottom: -180px; }
  64%  { z-index: 0;  width: 150px; right: 40px;  bottom: -180px; }
  100% { z-index: 0;  width: 150px; right: 40px;  bottom: -180px; }
}

.bounce-7 {
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}
@keyframes bounce-7 {
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.05,.96)   translateY(0); }
  30%  { transform: scale(1,1.01)   translateY(-30px); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-1px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
}

.chickenclass {
  z-index:3;
  position: relative;
  top: calc(12vh - 48px);
  animation-name:
      bounce-7, dance;
  animation-duration:
      4000ms, 10000ms;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
  animation-iteration-count: infinite;
}

.dance {
  position: relative;
  animation: 15s move linear infinite;
}

.ground {
  background-color: #8bc34a;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30vh;
  z-index: 0;
}

.discordcolor{
  color: #ffffff !important;
}

.btnsearch {
  color: #fff;
  background-color: #5631c7;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 12px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnsearch:active {
  margin-left: 10px;
  margin-top: 10px;
  background-color: #310d9e;
}

.btnsearch:focus,
.btnsearch:hover {
  font-family: 'Mochiy Pop P One', sans-serif;
}

.btnsearch:hover:before,
.btnsearch:hover:after {
  content: '';
  z-index: -1;
}

.btnsearch:before,
.btnsearch:after {
  content: '';
  background-color: #230972;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btnsearch:after {
  background-color: #22096c;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btnsearch:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnsearch:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}

.bgdiscord{
  background-color: #5631c7;
}


.feefont{
  font-family: 'Comic Neue', cursive;
  color: #5a4222;
  font-size: 16px;
}

.errortxt{
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #c11313;
  margin-bottom: 20px;
}

.errorcard {
  border-radius: 20px;
  border: 5px dashed white;
  background-color: #ff8d97;
  margin-bottom: 20px;
}

.notestitle{
  position: absolute;
  top: 0px;
  left: 20px;
}

.btnname {
  color: #fff;
  background-color: #e84393;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 12px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnname:active {
  margin-left: 10px;
  margin-top: 10px;
}

.btnname:focus,
.btnname:hover {
  color: #fff;
}

.btnname:before,
.btnname:after {
  content: '';
  background-color: #ad1d63;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btnname:after {
  background-color: #821248;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btnname:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnname:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}

.rulesclosebutton{
  position: absolute;
  top: 0;
  right: 20px;
}

.rulebookimage{


}

.text3d {
  box-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
}

.rulesbutton {
  width: 80px;
  height: 80px;
  border-radius: 15px;
  background: var(--light-grey);
  box-shadow: var(--dark-grey) 0 15px 0 0;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in;
  margin-top: 20px;
}

.rulesbutton:active {
  background: var(--pink);
  box-shadow: var(--dark-pink) 0 5px 0 0;
  transform: translateY(10px);
}

.breedbutton {
  width: var(--button-width);
  height: var(--button-height);
  border-radius: 15px;
  background: var(--light-grey);
  box-shadow: var(--dark-grey) 0 15px 0 0;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in;
  margin-top: 20px;
}

.breedbutton:active {
  background: var(--pink);
  box-shadow: var(--dark-pink) 0 5px 0 0;
  transform: translateY(10px);
}

.heart {
  width: var(--heart-size);
  height: var(--heart-size);
  transform: rotate(45deg);
  background: var(--dark-grey);
  top: 5px;
  position: relative;
  display: flex;
  transition: 0.2s ease-in;
}

.breedbutton:hover > .heart {
  background: var(--pink);
}

.breedbutton:active > .heart {
  background: white;
}

.heart::before,
.heart::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: inherit;
  position: absolute;
}

.heart::before {
  left: -50%;
}

.heart::after {
  top: -50%;
}

.plus-area {

}

.plus-font {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 200px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.frontfooter {
  display: flex;
  position: relative;
  justify-content: center;
}

.modal-box .mdialog {
  width: 80%;
  margin: 70px auto 0;
  margin-top: 0px;

}

@media (max-width: 576px) {
  .modal-box .mdialog {
    width: 95%;
  }
}

.modal.fade .mdialog {
  opacity: 0;
  transform: translate(30%, 30%) scale(0.5);
}

.modal.fade.in .mdialog {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.modal-box .mdialog .modal-content {
  border: 5px solid #ffda86;
  border-radius: 35px;
  box-shadow: 14px 14px 20px #549926, -14px -14px 20px #549926;
  background-color: #e8c898;
}

.modal-box .mdialog .modal-content .modal-body {
  padding: 65px 45px;
}

.modal-box .mdialog .modal-content .modal-body .icon {
  color: #827f3b;
  font-size: 80px;
  line-height: 145px;
  width: 100px;
  height: 100px;
  padding-left: 0px;
  padding-right: 10px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: 25px;
}

.modal-box .mdialog .modal-content .modal-body .inner-content {
  padding: 0 0 0 170px;
  margin: 0 0 30px;
  margin-top: 0px;
}

.modal-box .mdialog .modal-content .modal-body .title {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.modal-box .mdialog .modal-content .modal-body .description {
  color: #534f47;
  font-size: 15px;
  margin: 0;
  padding-top: 20px;
  font-family: 'Comic Neue', cursive;
}

.modal-box .mdialog .modal-content .modal-body .form-control {
  color: #6d5a36;
  background: #eee8dd;
  font-size: 15px;
  letter-spacing: 1px;
  height: 45px;
  padding: 2px 15px;
  border-radius: 50px 0 0 50px;
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control:focus {
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.modal-box .mdialog .modal-content .modal-body .subscribe {
  color: #fff;
  background: #ebbf53;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px 20px 11px;
  border: none;
  border-radius: 0 50px 50px 0;
  display: block;
  transition: all 0.3s;
}

.modal-box .mdialog .modal-content .modal-body .subscribe:hover,
.modal-box .mdialog .modal-content .modal-body .subscribe:focus {
  background: #827f3b;
}

#svgWrapper {
  display: inline-block;
  width: 120px;
  height: 120px;
  margin-top: 10px;
  svg {
    width: 120px;
    height: 120px;
    transition: transform .5s ease-out;
    #red-exclamation {
      animation: bounce .5s ease-out forwards;
      transform-origin: center center;
      .exclamation {
        opacity: 0;
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        animation: excl 1.5s ease forwards .5s;
      }
      .dot {
        opacity: 0;
        stroke-width: 30;
        stroke-linecap: round;
        animation: dot-fade .175s ease forwards .9s;
      }
    }
  }
}

@keyframes excl {
  0% {
    stroke-dashoffset: 100;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes dot-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(.6);
  }
  60% {
    transform: scale(1);
  }
  85% {
    transform: scale(.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

</style>
