<template>
  <div v-bind:class="[flipped ? useexpand ? 'flip-container flipped' : 'flip-container flippedhm' : 'flip-container']">
    <div class="flipper">
      <div class="front">
        <div class="card form-container">
          <div class="card__product-img">
            <Chicken v-if="ishomepage===false" setwidth="220px" :beak="beak" :belly="belly" :chest="chest" :comb="comb"
                     :eyes="eyes" :feet="feet"
                     :head="head"
                     :legs="legs" :wattle="wattle" :wings="wings" :special="special" :shadow="shadow"/>
          </div>
          <div v-if="ishomepage===true" class="card__product-img">
            <Chicken setwidth="220px" :beak="randomDNAlist[0]" :belly="randomDNAlist[1]" :chest="randomDNAlist[2]"
                     :comb="randomDNAlist[3]" :eyes="randomDNAlist[4]" :feet="randomDNAlist[5]"
                     :head="randomDNAlist[6]"
                     :legs="randomDNAlist[7]" :wattle="randomDNAlist[8]" :wings="randomDNAlist[9]"
                     :special="randomDNAlist[10]" shadow="1"/>
          </div>
          <div class="card__content">
            <p v-if="showname" class="card__name">{{ name.slice(0, 20) }} </p>
            <div class="card__content-bottom">
              <div v-if="price > 0" class="card__price">
                <p>{{ price }} KDA</p>
              </div>
            </div>
          </div>
        </div>
        <a v-on:click="flipped=true; $emit('flipped', true)" class="btn btn-lg" href="#">
          <span> <img src="../../public/icons/dna-egg-icon.svg" alt=">" height="30px"/> </span>
        </a>
      </div>
      <div class="back">
        <slot name="back"></slot>
        <div class="card form-container">
          <div class="pricingTable">
            <div v-if="showinspectbutton === true" @click="clickInspectGallina" class="pricingTable-header">
              <h3 class="title-back">{{ name.slice(0, 20) }}</h3>
            </div>
            <div v-if="showinspectbutton === false" class="pricingTable-header">
              <h3 class="title-back">{{ name.slice(0, 20) }}</h3>
            </div>
            <ul class="pricing-content" style="user-select: text;">
              <li>Gender: {{ gender }}</li>
              <li>Birthday: {{ birthday }}</li>
              <li>Generation: {{ generation.toString() }}</li>
              <li>Next Breed: {{ nbd }}</li>
              <li v-if="ishomepage === true">Set: {{ randomDNAlist[10] }}</li>
              <li v-if="ishomepage === false">Set: {{ special }}</li>
              <li v-if="ishomepage === false" class="cardlisbg"><span v-tooltip="'There are '+rarity+' Gallina(s) with this DNA in this same set'">Rarity: {{ rarity.toString() }} / {{ setcount }}</span>
              </li>
              <li v-if="ishomepage === true" class="cardlisbg"><span v-tooltip="'There are '+rarity+' Gallina(s) with this DNA in this same set'">Total Gallinas: {{ setcount }}</span>
              </li>
              <li class="cardlisbg"
                  @mouseenter="showSplitDNA(beak, belly, chest, comb, eyes, feet, head, legs, wattle, wings, special)"
                  v-if="ishomepage===false && dnasplitted===false && splitupdna(beak,belly,chest,comb,eyes,feet,head,legs,wattle,wings,special)">
                DNA:
                {{ this.gallinaDNA }}
              </li>
              <li class="cardlisbg"
                  @mouseout="showSplitDNA(beak, belly, chest, comb, eyes, feet, head, legs, wattle, wings, special)"
                  v-if="ishomepage===false && dnasplitted===true">DNA:
                {{ this.gallinaDNA }}
              </li>

              <li class="cardlisbg"
                  @mouseenter="showSplitDNA(randomDNAlist[0], randomDNAlist[1], randomDNAlist[2], randomDNAlist[3], randomDNAlist[4], randomDNAlist[5], randomDNAlist[6], randomDNAlist[7], randomDNAlist[8], randomDNAlist[9], randomDNAlist[10])"
                  v-if="ishomepage===true && dnasplitted===false && splitupdna(randomDNAlist[0], randomDNAlist[1], randomDNAlist[2], randomDNAlist[3], randomDNAlist[4], randomDNAlist[5], randomDNAlist[6], randomDNAlist[7], randomDNAlist[8], randomDNAlist[9], '0')">
                DNA:
                {{ this.gallinaDNA }}
              </li>
              <li class="cardlisbg"
                  @mouseout="showSplitDNA(randomDNAlist[0], randomDNAlist[1], randomDNAlist[2], randomDNAlist[3], randomDNAlist[4], randomDNAlist[5], randomDNAlist[6], randomDNAlist[7], randomDNAlist[8], randomDNAlist[9], randomDNAlist[10])"
                  v-if="ishomepage===true && dnasplitted===true">DNA:
                {{ this.gallinaDNA }}
              </li>

              <li class="cardlisbg"
                  @mouseenter="showSplith1DNA(gene1h1, gene2h1, gene3h1, gene4h1, gene5h1, gene6h1, gene7h1, gene8h1, gene9h1, gene10h1)"
                  v-if="h1dnasplitted===false && splituph1dna(gene1h1, gene2h1, gene3h1, gene4h1, gene5h1, gene6h1, gene7h1, gene8h1, gene9h1, gene10h1)">
                h1DNA:
                {{ this.gallinah1DNA }}
              </li>
              <li class="cardlisbg"
                  @mouseout="showSplith1DNA(gene1h1, gene2h1, gene3h1, gene4h1, gene5h1, gene6h1, gene7h1, gene8h1, gene9h1, gene10h1)"
                  v-if="h1dnasplitted===true">h1DNA:
                {{ this.gallinah1DNA }}
              </li>
              <li class="cardlisbg"
                  @mouseenter="showSplith2DNA(gene1h2, gene2h2, gene3h2, gene4h2, gene5h2, gene6h2, gene7h2, gene8h2, gene9h2, gene10h2)"
                  v-if="h2dnasplitted===false && splituph2dna(gene1h2, gene2h2, gene3h2, gene4h2, gene5h2, gene6h2, gene7h2, gene8h2, gene9h2, gene10h2)">
                h2DNA:
                {{ this.gallinah2DNA }}
              </li>
              <li class="cardlisbg"
                  @mouseout="showSplith2DNA(gene1h2, gene2h2, gene3h2, gene4h2, gene5h2, gene6h2, gene7h2, gene8h2, gene9h2, gene10h2)"
                  v-if="h2dnasplitted===true">h2DNA:
                {{ this.gallinah2DNA }}
              </li>
              <li class="cardlisbg"
                  @mouseenter="showSplith3DNA(gene1h3, gene2h3, gene3h3, gene4h3, gene5h3, gene6h3, gene7h3, gene8h3, gene9h3, gene10h3)"
                  v-if="h3dnasplitted===false && splituph3dna(gene1h3, gene2h3, gene3h3, gene4h3, gene5h3, gene6h3, gene7h3, gene8h3, gene9h3, gene10h3)">
                h3DNA:
                {{ this.gallinah3DNA }}
              </li>
              <li class="cardlisbg"
                  @mouseout="showSplith3DNA(gene1h3, gene2h3, gene3h3, gene4h3, gene5h3, gene6h3, gene7h3, gene8h3, gene9h3, gene10h3)"
                  v-if="h3dnasplitted===true">h3DNA:
                {{ this.gallinah3DNA }}
              </li>
              <li>Dominance: {{ this.getDominance(id) }}</li>
              <li>ID: {{ id }}
                <button @click="clickCopyId" class="copybutton"><img src="../../public/icons/copy-icon.svg" alt="copy"
                                                                     width="15px"></button>
              </li>
              <li>Mother: {{ motherid }}</li>
              <li>Father: {{ fatherid }}</li>
            </ul>
            <input type="hidden" :id="'A'+id" :value="id">
          </div>

          <div v-if="showbuttons" class="row card-back-buttons">
            <div class="col-2" style="width: 65px;">
              <button @click="showChangeNameModal = !showChangeNameModal" class="btnname">Name</button>
            </div>
            <div class="col-2" style="width: 65px;">
              <button @click="showBreedModal = !showBreedModal" class="btnbreed">Breed</button>
            </div>
            <div class="col-2" style="width: 65px;">
              <button @click="showMarketChoiceModal = !showMarketChoiceModal" class="btnbreed">Sell</button>
            </div>
            <div class="col-2" style="width: 65px;">
              <button @click="transferToId = ''; showTransferModal = !showTransferModal" class="btnbreed"
                      style="right: 5px;">Send
              </button>
            </div>
          </div>

          <div v-if="showbuybutton" class="row card-back-buttons">
            <div class="col-2">
              <button @click="clickBuy" class="btnname">Buy</button>
            </div>
          </div>

        </div>
        <a v-on:click="flipped=false; $emit('flipped', false)" class="btn btn-lg" href="#">
          <span> <img src="../../public/icons/view-chicken-icon.svg" alt=">" height="36px"/> </span>
        </a>
        <a v-if="ishomepage" v-on:click="flipped=false; randomizeGallina(); $emit('flipped', false)" class="btn btn-lg"
           href="#">
          <span> <img src="../../public/icons/view-chicken-icon.svg" alt=">" height="36px"/> </span>
        </a>
      </div>
    </div>

    <vmodal2 v-if="showBreedModal" :show-gold-header="false" content-class="darkMode" :show-cancel="false"
             :show-confirm="false"
             :prevent-click="true" class-name="connect-wallet-modal" @cancel="clickCancelBreed"
             @confirm="clickOkSelectOneMore"
             cancel-name="Nevermind" v-model="showBreedModal" name="breed" modaltitle="Connect My Account">
      <template v-slot:title>
        <div class="text3d titlearea">
          Breed
        </div>
      </template>

      <div class="chicken-breed-first-modal">
        <Chicken setwidth="150px" :beak="beak" :belly="belly" :chest="chest" :comb="comb" :eyes="eyes" :feet="feet"
                 :head="head"
                 :legs="legs" :wattle="wattle" :wings="wings" :special="special"/>
      </div>

      <div class="row align-items-center justify-content-center">
        <div class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-notfound mt-2">
          Which other Gallina would you like to breed this one with?
        </div>
        <div class="col-10">
          <input @input="chickenMateIdInput = $event.target.value;"
                 :v-model="chickenMateIdInput" type="text" class="form__input" id="mateinput"
                 placeholder="Mate's ID" required=""/>
          <label for="mateinput" class="form__label">Enter the ID of this Gallina's Mate</label>
        </div>
      </div>

      <div class="breed-modal-buttons">
        <button v-if="chickenMateIdInput !== ''" class="p-1 m-2 breed-modal-buttons-style eggOne"
                @click="clickOkBreedBothGallinas">Breed
        </button>
        <button class="p-1 m-2 breed-modal-buttons-style eggTwo" @click="clickCancelBreed">Cancel</button>
      </div>


    </vmodal2>


    <vmodaltransfer v-if="showTransferModal" :show-gold-header="false" content-class="darkMode" :show-cancel="false"
                    :show-confirm="false"
                    :prevent-click="true" class-name="connect-wallet-modal" @cancel="clickCancelTransfer"
                    @confirm="clickOkTransfer" v-model="showTransferModal" name="breed" modaltitle="Connect My Account">
      <template v-slot:title>
        <div class="text3d titlearea">
          Transfer Gallina
        </div>
      </template>

      <div class="chicken-breed-first-modal">
        <Chicken setwidth="150px" :beak="beak" :belly="belly" :chest="chest" :comb="comb" :eyes="eyes" :feet="feet"
                 :head="head"
                 :legs="legs" :wattle="wattle" :wings="wings" :special="special"/>
      </div>

      <div class="row align-items-center justify-content-center">
        <div class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-null mt-2">
          Who would you like to send this Gallina to?
        </div>
        <div class="col-10">
          <input @change="autoVerify()" @input="transferToId = $event.target.value; autoVerify()"
                 :v-model="transferToId" type="text" class="form__input" id="transferidinput"
                 placeholder="Recipient k: Account Name" required=""/>
          <label for="transferidinput" class="form__label">Enter receiver's FULL Kadena k: Account Name <br> ('k:'
            included!)</label>
        </div>
      </div>

      <div class="row align-items-center justify-content-center">
        <div v-if="this.getSenderAccountExists() === false && this.transferToId !== ''"
             class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-notfound mt-2">
          No Account Found On Mainnet Chain 1
        </div>
      </div>

      <div class="row align-items-center justify-content-center">
        <div v-if="this.getSenderAccountExists() === true"
             class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-found mt-2">
          Account Found On Mainnet Chain 1
        </div>
      </div>

      <div class="breed-modal-buttons">
        <button v-if="this.getSenderAccountExists() === true" class="p-1 m-2 breed-modal-buttons-style eggOne"
                @click="clickOkTransfer">Send
        </button>
        <button class="p-1 m-2 breed-modal-buttons-style eggTwo" @click="clickCancelTransfer">Cancel</button>
      </div>


    </vmodaltransfer>

    <vmodalchangename v-if="showChangeNameModal" :show-gold-header="false" content-class="darkMode" :show-cancel="false"
                      :show-confirm="false"
                      :prevent-click="true" class-name="connect-wallet-modal" confirm-name="OK, Let me pick"
                      @cancel="clickCancelChangeName"
                      @confirm="clickOkChangeName"
                      cancel-name="Nevermind" v-model="showChangeNameModal" name="breed"
                      modaltitle="Connect My Account">
      <template v-slot:title>
        <div class="text3d titlearea">
          Change Name
        </div>
      </template>

      <div class="chicken-breed-first-modal">
        <Chicken setwidth="150px" :beak="beak" :belly="belly" :chest="chest" :comb="comb" :eyes="eyes" :feet="feet"
                 :head="head"
                 :legs="legs" :wattle="wattle" :wings="wings" :special="special"/>
      </div>

      <div class="row align-items-center justify-content-center">
        <div class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-notfound mt-2">
          What name would you like to give this Gallina?
        </div>
        <div class="col-10">
          <input @input="gallinaNameInput = $event.target.value;"
                 :v-model="gallinaNameInput" type="text" class="form__input" id="nameinput"
                 placeholder="New Name" required=""/>
          <label for="nameinput" class="form__label">Enter the Gallinas new name.</label>
        </div>
      </div>

      <div class="breed-modal-buttons">
        <button v-if="gallinaNameInput !== ''" class="p-1 m-2 breed-modal-buttons-style eggOne"
                @click="clickOkChangeName">Change Name
        </button>
        <button class="p-1 m-2 breed-modal-buttons-style eggTwo" @click="clickCancelChangeName">Cancel</button>
      </div>


    </vmodalchangename>

    <vmodalsellgallina v-if="showMarketChoiceModal" v-model="showMarketChoiceModal" :show-gold-header="false"
                       content-class="darkMode" :show-cancel="false" :show-confirm="false"
                       :prevent-click="true" class-name="connect-wallet-modal" name="choosemarket"
                       modaltitle="Create or Cancel Market Listing">
      <template v-slot:title>
        <div class="text3d titlearea-sell">
          Sell / Trade this Gallina?
        </div>
      </template>

      <div class="row align-items-center justify-content-center">
        <div
            class="p-0 border-20 text-center col-5">
          <div class="chicken-sell-modal">
            <Chicken setwidth="310px" :beak="beak" :belly="belly" :chest="chest" :comb="comb" :eyes="eyes" :feet="feet"
                     :head="head"
                     :legs="legs" :wattle="wattle" :wings="wings" :special="special"
                     style="position:relative; top:20px;"/>
          </div>
          <br/>
          <div
              style="background-color: #1f8a70; color: #fff; border-radius: 20px; position: relative; right: 10px; padding: 2px;">
            <span style="font-family: 'Mochiy Pop P One', sans-serif">{{ name }}</span>
          </div>
        </div>
        <div class="p-0 border-20 text-center options-bg col-5 flex-column">
          <span class="alert bubble-bg-sellgallina">For this Gallina, I want to:</span>
          <br/>
          <button class="p-1 m-4 breed-modal-buttons-style eggOne2" style="width: 200px; height: 80px;"
                  @click="clickCreateAListing">
            Create a Listing
          </button>
          <br/>
          <button class="p-1 m-2 breed-modal-buttons-style eggThree" style="width: 200px; height: 80px;"
                  @click="clickCancelAListing">
            Cancel Listing
          </button>
          <br/>
          <button class="p-1 m-2 breed-modal-buttons-style eggTwo2" style="width: 200px; height: 80px;"
                  @click="clickOkBurnGallina()">Adoption
          </button>
          <br/>
          <button class="p-1 m-2 breed-modal-buttons-style eggTwo" style="width: 200px; height: 80px;"
                  @click="showMarketChoiceModal = !showMarketChoiceModal">Nevermind
          </button>

        </div>
      </div>
    </vmodalsellgallina>

    <vmodalcreatemarketsale v-if="showSellGallinaModal" :show-gold-header="false" content-class="darkMode"
                            :show-cancel="false" :show-confirm="false"
                            :prevent-click="true" class-name="connect-wallet-modal" confirm-name="OK, Let me pick"
                            @cancel="clickCancelChangeName"
                            @confirm="clickOkChangeName"
                            cancel-name="Nevermind" v-model="showSellGallinaModal" name="breed"
                            modaltitle="Connect My Account">
      <template v-slot:title>
        <div class="text3d titlearea">
          Create Market Sale
        </div>
      </template>

      <div class="chicken-breed-first-modal">
        <Chicken setwidth="150px" :beak="beak" :belly="belly" :chest="chest" :comb="comb" :eyes="eyes" :feet="feet"
                 :head="head"
                 :legs="legs" :wattle="wattle" :wings="wings" :special="special"/>
      </div>

      <div class="row align-items-center justify-content-center">
        <div class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-notfound mt-2">
          How much KDA would you like to sell this Gallina for?
        </div>
        <div class="col-10">
          <!--          @input="gallinaCostInput = parseFloat($event.target.value);"-->
          <input @input="gallinaCostInput = parseFloat($event.target.value).toFixed(2);" @change="checkForTypes()"
                 :v-model="gallinaCostInput" type="number" class="form__input" id="sellpriceinput"
                 placeholder="Sale Price" required=""/>
          <label for="sellpriceinput" class="form__label">Enter the price in KDA this Gallina will cost.<br/> Example:
            5.01</label>
        </div>
      </div>

      <div class="breed-modal-buttons">
        <button v-if="gallinaCostInput !== ''"
                class="p-1 m-2 breed-modal-buttons-style eggOne" @click="clickOkAddToMarket">Add to Market
        </button>
        <button class="p-1 m-2 breed-modal-buttons-style eggTwo" @click="showSellGallinaModal = !showSellGallinaModal">
          Cancel
        </button>
      </div>


    </vmodalcreatemarketsale>

    <vmodalwaitsignature v-if="showSignTransactionModal" :show-gold-header="false" content-class="darkMode"
                         :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Ok"
                         v-model="showSignTransactionModal" @confirm="showSignTransactionModal = false"
                         @cancel="showSignTransactionModal = false" name="intro" cancel-name="Cancel">

      <template v-slot:title>
        <div class="pt-2">
          Awaiting wallet response
        </div>
      </template>

      <div class="displayGuard">
        <div class="row align-items-center justify-content-center mt-4 mb-0">
          <div class="p-0 border-gold text-center connect-wallet-text alert col-10 mt-4">
            We just sent a request to your wallet.<br/>
            <b>Always review your wallet's transactions.</b> When you're ready, sign, come back and click OK!
          </div>
        </div>
      </div>

    </vmodalwaitsignature>
  </div>
</template>

<script>

import Chicken from "@/components/Chicken";
import vmodal2 from "@/components/VModal2";
import vmodaltransfer from "@/components/VModalTransfer";
import vmodalchangename from "@/components/VModalChangeName";
import vmodalwaitsignature from "@/components/VModalWaitSignature";
import vmodalsellgallina from "@/components/VModalSellGallina";
import vmodalcreatemarketsale from "@/components/VModalCreateMarketSale";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FlipCard",
  components: {
    Chicken,
    vmodal2,
    vmodaltransfer,
    vmodalchangename,
    vmodalwaitsignature,
    vmodalsellgallina,
    vmodalcreatemarketsale,

  },
  props: {
    showbuttons: {
      type: Boolean,
      default: true,
    },
    showbuybutton: {
      type: Boolean,
      default: false,
    },
    showinspectbutton: {
      type: Boolean,
      default: true,
    },
    showname: {
      type: Boolean,
      default: true,
    },
    useexpand: {
      type: Boolean,
      default: true,
    },
    ishomepage: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
      required: true
    },
    id: {
      type: [String, Number],
      default: null
    },
    seller: {
      type: [String, Number],
      default: null
    },
    motherid: {
      type: [String, Number],
      default: null
    },
    fatherid: {
      type: [String, Number],
      default: null
    },
    price: {
      type: Number,
      default: 0,
      required: false
    },
    generation: {
      type: [String, Number],
      default: "?"
    },
    gender: {
      type: String,
      default: "?",
      required: false
    },
    birthday: {
      type: String,
      default: "?",
      required: false
    },
    nbd: {
      type: String,
      default: "?",
      required: false
    },
    special: {
      type: String,
      default: "0",
      required: false
    },
    setcount: {
      type: [String, Number],
      default: "?",
      required: false
    },
    rarity: {
      type: [String, Number],
      default: "?",
      required: false
    },
    feet: {
      type: String,
      default: "0",
      required: true
    },
    wings: {
      type: String,
      default: "0",
      required: true
    },
    legs: {
      type: String,
      default: "0",
      required: true
    },
    belly: {
      type: String,
      default: "0",
      required: true
    },
    chest: {
      type: String,
      default: "0",
      required: true
    },
    head: {
      type: String,
      default: "0",
      required: true
    },
    wattle: {
      type: String,
      default: "0",
      required: true
    },
    comb: {
      type: String,
      default: "0",
      required: true
    },
    beak: {
      type: String,
      default: "0",
      required: true
    },
    eyes: {
      type: String,
      default: "0",
      required: true
    },
    gene1h1: {
      type: String,
      default: "?",
      required: true
    },
    gene2h1: {
      type: String,
      default: "?",
      required: true
    },
    gene3h1: {
      type: String,
      default: "?",
      required: true
    },
    gene4h1: {
      type: String,
      default: "?",
      required: true
    },
    gene5h1: {
      type: String,
      default: "?",
      required: true
    },
    gene6h1: {
      type: String,
      default: "?",
      required: true
    },
    gene7h1: {
      type: String,
      default: "?",
      required: true
    },
    gene8h1: {
      type: String,
      default: "?",
      required: true
    },
    gene9h1: {
      type: String,
      default: "?",
      required: true
    },
    gene10h1: {
      type: String,
      default: "?",
      required: true
    },
    gene1h2: {
      type: String,
      default: "?",
      required: true
    },
    gene2h2: {
      type: String,
      default: "?",
      required: true
    },
    gene3h2: {
      type: String,
      default: "?",
      required: true
    },
    gene4h2: {
      type: String,
      default: "?",
      required: true
    },
    gene5h2: {
      type: String,
      default: "?",
      required: true
    },
    gene6h2: {
      type: String,
      default: "?",
      required: true
    },
    gene7h2: {
      type: String,
      default: "?",
      required: true
    },
    gene8h2: {
      type: String,
      default: "?",
      required: true
    },
    gene9h2: {
      type: String,
      default: "?",
      required: true
    },
    gene10h2: {
      type: String,
      default: "?",
      required: true
    },
    gene1h3: {
      type: String,
      default: "?",
      required: true
    },
    gene2h3: {
      type: String,
      default: "?",
      required: true
    },
    gene3h3: {
      type: String,
      default: "?",
      required: true
    },
    gene4h3: {
      type: String,
      default: "?",
      required: true
    },
    gene5h3: {
      type: String,
      default: "?",
      required: true
    },
    gene6h3: {
      type: String,
      default: "?",
      required: true
    },
    gene7h3: {
      type: String,
      default: "?",
      required: true
    },
    gene8h3: {
      type: String,
      default: "?",
      required: true
    },
    gene9h3: {
      type: String,
      default: "?",
      required: true
    },
    gene10h3: {
      type: String,
      default: "?",
      required: true
    },
    shadow: {
      type: String,
      default: "0",
      required: false
    },
  },
  data: function () {
    return {
      debug: false,
      flipped: false,
      showBreedModal: false,
      showChangeNameModal: false,
      chickenMateIdInput: "",
      transferToId: "",
      gallinaNameInput: "",
      gallinaCostInput: "",
      showSignTransactionModal: false,
      gallinaDNA: "",
      dnasplitted: false,
      gallinah1DNA: "",
      h1dnasplitted: false,
      gallinah2DNA: "",
      h2dnasplitted: false,
      gallinah3DNA: "",
      h3dnasplitted: false,
      showSellGallinaModal: false,
      showMarketChoiceModal: false,
      showTransferModal: false,
      randomDNAlist: ["1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "0"],
    };
  },
  computed: {





  },
  methods: {
    ...mapActions("accounts", ["bindCheckPending"]),
    ...mapGetters("accounts", ["getSenderAccountExists", "getReceiverAccountData"]),

    getDominance(id) {
      let idList = id.split("");
      let gallinaValue = 0;
      for (let i = 0; i < idList.length; i++) {
        let value = idList[i] === "a" ? 1 : idList[i] === "b" ? 2 : idList[i] === "c" ? 3 : idList[i] === "d" ? 4 : idList[i] === "e" ? 5 : idList[i] === "f" ? 6 : idList[i] === "g" ? 7 : idList[i] === "h" ? 8 : idList[i] === "i" ? 9 : idList[i] === "j" ? 10 : idList[i] === "k" ? 11 : idList[i] === "l" ? 12 : idList[i] === "m" ? 13 : idList[i] === "n" ? 14 : idList[i] === "o" ? 15 : idList[i] === "p" ? 16 : idList[i] === "q" ? 17 : idList[i] === "r" ? 18 : idList[i] === "s" ? 19 : idList[i] === "t" ? 20 : idList[i] === "u" ? 21 : idList[i] === "v" ? 22 : idList[i] === "w" ? 23 : idList[i] === "x" ? 24 : idList[i] === "y" ? 24 : idList[i] === "z" ? 25 : idList[i] === "A" ? 26 : idList[i] === "B" ? 27 : idList[i] === "C" ? 28 : idList[i] === "D" ? 29 : idList[i] === "E" ? 30 : idList[i] === "F" ? 31 : idList[i] === "G" ? 32 : idList[i] === "H" ? 33 : idList[i] === "I" ? 34 : idList[i] === "J" ? 35 : idList[i] === "K" ? 36 : idList[i] === "L" ? 37 : idList[i] === "M" ? 38 : idList[i] === "N" ? 39 : idList[i] === "O" ? 40 : idList[i] === "P" ? 41 : idList[i] === "Q" ? 42 : idList[i] === "R" ? 43 : idList[i] === "S" ? 44 : idList[i] === "T" ? 45 : idList[i] === "U" ? 46 : idList[i] === "V" ? 47 : idList[i] === "W" ? 48 : idList[i] === "X" ? 49 : idList[i] === "Y" ? 50 : idList[i] === "Z" ? 51 : idList[i] === "1" ? 52 : idList[i] === "2" ? 53 : idList[i] === "3" ? 54 : idList[i] === "4" ? 55 : idList[i] === "5" ? 56 : idList[i] === "6" ? 57 : idList[i] === "7" ? 58 : idList[i] === "8" ? 59 : idList[i] === "9" ? 60 : idList[i] === "0" ? 61 : idList[i] === "-" ? 62 : idList[i] === "_" ? 63 : 0;
        if (i % 2 === 0) {
          gallinaValue += value;
        } else {
          gallinaValue -= value;
        }
      }
      return gallinaValue;
    },

    splitupdna(beak, belly, chest, comb, eyes, feet, head, legs, wattle, wings) {
      this.gallinaDNA = beak + belly + chest + comb + eyes + feet + head + legs + wattle + wings;
      return this.gallinaDNA;
    },

    showSplitDNA(beak, belly, chest, comb, eyes, feet, head, legs, wattle, wings) {
      this.dnasplitted = !this.dnasplitted;
      this.gallinaDNA = beak + " " + belly + " " + chest + " " + comb + " " + eyes + " " + feet + " " + head + " " + legs + " " + wattle + " " + wings;
    },

    splituph1dna(beak, belly, chest, comb, eyes, feet, head, legs, wattle, wings) {
      this.gallinah1DNA = beak + belly + chest + comb + eyes + feet + head + legs + wattle + wings;
      return beak + belly + chest + comb + eyes + feet + head + legs + wattle + wings;
    },

    showSplith1DNA(beak, belly, chest, comb, eyes, feet, head, legs, wattle, wings) {
      this.h1dnasplitted = !this.h1dnasplitted;
      this.gallinah1DNA = beak + " " + belly + " " + chest + " " + comb + " " + eyes + " " + feet + " " + head + " " + legs + " " + wattle + " " + wings;
    },

    splituph2dna(beak, belly, chest, comb, eyes, feet, head, legs, wattle, wings) {
      this.gallinah2DNA = beak + belly + chest + comb + eyes + feet + head + legs + wattle + wings;
      return beak + belly + chest + comb + eyes + feet + head + legs + wattle + wings;
    },

    showSplith2DNA(beak, belly, chest, comb, eyes, feet, head, legs, wattle, wings) {
      this.h2dnasplitted = !this.h2dnasplitted;
      this.gallinah2DNA = beak + " " + belly + " " + chest + " " + comb + " " + eyes + " " + feet + " " + head + " " + legs + " " + wattle + " " + wings;
    },

    splituph3dna(beak, belly, chest, comb, eyes, feet, head, legs, wattle, wings) {
      this.gallinah3DNA = beak + belly + chest + comb + eyes + feet + head + legs + wattle + wings;
      return beak + belly + chest + comb + eyes + feet + head + legs + wattle + wings;
    },

    showSplith3DNA(beak, belly, chest, comb, eyes, feet, head, legs, wattle, wings) {
      this.h3dnasplitted = !this.h3dnasplitted;
      this.gallinah3DNA = beak + " " + belly + " " + chest + " " + comb + " " + eyes + " " + feet + " " + head + " " + legs + " " + wattle + " " + wings;
    },

    randomIntFromInterval(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    randomizeGallina() {
      if (this.debug) {
        console.log("randomizinggallinadna");
      }
      let idlist = [];
      for (let i = 0; i < 11; i++) {
        let randomgene = this.randomIntFromInterval(1, 20).toString();
        if (i === 10) {
          randomgene = this.randomIntFromInterval(1, 53).toString();
        }
        idlist.push(randomgene);
      }
      this.randomDNAlist = idlist;
    },

    number_test(n) {
      let result = (n - Math.floor(n)) !== 0;

      if (result) {
        if (this.debug) {
          console.log("Contains Decimal Place");
        }
        return true;
      } else {
        if (this.debug) {
          console.log("No decimal place");
        }
        return false;
      }

    },

    async checkForTypes() {

      if (this.debug) {
        console.log(this.gallinaCostInput);
        console.log(typeof (this.gallinaCostInput));
      }


    },

    async clickmaintenance() {

      alert("Market currently under maintenance");
    },

    async showfloat(input) {

      if (this.debug === true) {
        console.log("input");
        console.log(input);
        console.log("parsed");
        console.log(parseFloat(input));
        console.log("input2");
        console.log(this.gallinaCostInput);
        console.log("try3");
        var floatValue = +(input.replace(/,/, "."));
        console.log(floatValue);
        console.log("try4");
        console.log(+(input) + 0.0);
        console.log("try5");
        console.log((Math.round(+(input) * 100) / 100));
        console.log("try6");
        console.log(+(Math.round(input + "e+2") + "e-2"));
      }

    },

    async autoVerify() { //Used to verify if an account exists on kadena
      let payload = this.transferToId;
      await this.$store.dispatch("accounts/getTransferVerification", payload);
    },

    async clickOkTransfer() {

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }
      await this.bindCheckPending(name).then(async (response) => {
        if (this.debug === true) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }
        if (response !== undefined && response[0] !== undefined && response[0].length === 0 || response === 0) {
          let theAccountName = localStorage.getItem("accountName");

          let payload = {
            accountName: theAccountName,
            receiverAccountName: this.transferToId,
            receiverAccountGuard: this.getReceiverAccountData(),
            gallinaId: this.id,
          };

          if (this.debug) {
            if (this.debug === true) {
              console.log("paylaod");
              console.log(payload);
            }
          }

          this.showTransferModal = false;
          this.showSignTransactionModal = true;
          await this.$store.dispatch("accounts/transferGallina", payload);
        } else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.");
        }
      }).catch((error) => {
        if (this.debug === true) {
          console.log("Gallinas.io communication error");
          console.log(error);
        }
      });
    },
    async clickCancelTransfer() {
      this.showTransferModal = false;
    },
    //copys gallina id
    async clickCopyId() {
      if (this.debug) {
        console.log(this.id);
      }
      let selectid = "#" + "A" + this.id; //html ids are prefixed with hash and then have to start with a capital letter, so we add #A as a prefix
      let idtocopy = document.querySelector(selectid);
      idtocopy.setAttribute("type", "text");    //hidden
      idtocopy.select();

      try {
        document.execCommand("copy");
      } catch (err) {
        console.log(err);
      }

      /* unselect the range */
      idtocopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    async clickOkAddToMarket() {

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      await this.bindCheckPending(name).then(async (response) => {

        if (this.debug === true) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }
        if (response !== undefined && response[0] !== undefined && response[0].length === 0 || response === 0) {
          let theAccountName = localStorage.getItem("accountName");

          let payload = {
            accountName: theAccountName,
            price: this.gallinaCostInput,
            gallinaId: this.id,
          };

          if (this.debug) {
            console.log(payload.price);
            console.log(typeof payload.price);
          }


          this.showSellGallinaModal = false;
          this.showSignTransactionModal = true;
          await this.$store.dispatch("accounts/sellGallina", payload);
        } else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.");
        }
      }).catch((error) => {
        if (this.debug === true) {
          console.log("Gallinas.io communication error");
          console.log(error);
        }

      });

    },
    async clickCreateAListing() {
      this.showMarketChoiceModal = false;
      this.showSellGallinaModal = true;
    },
    async clickCancelAListing() {


      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }
      await this.bindCheckPending(name).then(async (response) => {
        if (this.debug === true) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }
        if (response !== undefined && response[0] !== undefined && response[0].length === 0 || response === 0) {
          let theAccountName = localStorage.getItem("accountName");

          let payload = {
            accountName: theAccountName,
            price: 1234.56,
            gallinaId: this.id,
          };

          if (this.debug) {
            console.log(payload.price);
            console.log(typeof payload.price);
          }

          this.showMarketChoiceModal = false;
          this.showSignTransactionModal = true;
          await this.$store.dispatch("accounts/cancelGallinaSale", payload);
        } else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.");
        }
      }).catch((error) => {
        if (this.debug === true) {
          console.log("Gallinas.io communication error");
          console.log(error);
        }
      });


    },
    async clickBuy() {

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      await this.bindCheckPending(name).then(async (response) => {

        if (this.debug === true) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }

        if (response !== undefined && response[0] !== undefined && response[0].length === 0 || response !== undefined && response === "0" || response !== undefined && response === 0) {
          this.showSignTransactionModal = true;
          if (this.debug) {
            console.log(this.seller);
          }

          let theAccountName = localStorage.getItem("accountName");

          let theprice = this.price;

          if (this.number_test(this.price) === false) {

            theprice = parseFloat(this.price).toFixed(2);

          } else {
            theprice = parseFloat(this.price).toFixed(2);
          }

          if (this.debug) {
            console.log("Price:");
            console.log(theprice);
            console.log("this.price");
            console.log(this.price);
          }


          let payload = {
            accountName: theAccountName,
            price: theprice,
            gallinaId: this.id,
            seller: this.seller,
          };

          if (this.debug === true) {
            console.log("BUY PAYLOAD");
            console.log(payload);
          }


          await this.$store.dispatch("accounts/buyGallina", payload);
        } else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.");
        }
      });

    },
    async clickCancelBreed() {
      this.showBreedModal = false;
      await this.$store.dispatch("accounts/resetBreedChickenIds");
    },
    async clickOkSelectOneMore() {
      this.showBreedModal = false;
      await this.$store.dispatch("accounts/changeBreedChicken1Id", this.id);
    },
    async clickOkBreedBothGallinas() {
      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      await this.bindCheckPending(name).then(async (response) => {
        if (this.debug === true) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }
        if (response !== undefined && response[0] !== undefined && response[0].length === 0 || response === 0) {
          await this.$router.push({path: `/breed/${this.id}/${this.chickenMateIdInput}`});
        } else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.");
        }
      }).catch((error) => {
        if (this.debug === true) {
          console.log("Gallinas.io communication error");
          console.log(error);
        }

      });

    },
    async clickOkBurnGallina() {
      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }
      await this.bindCheckPending(name).then(async (response) => {
        if (this.debug === true) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }
        if (response !== undefined && response[0] !== undefined && response[0].length === 0 || response === 0) {
          this.showSellGallinaModal = !this.showSellGallinaModal;
          await this.$router.push({path: `/burn/${this.id}`});
        } else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.");
        }
      }).catch((error) => {
        console.log("Gallinas.io communication error");
        console.log(error);
      });
    },
    async clickInspectGallina() {
      //await this.$router.push({path: `/inspect/${this.id}`});
      let routeData = this.$router.resolve({path: "/inspect/" + this.id});
      window.open(routeData.href, "_blank");
    },
    async clickCancelChangeName() {
      this.showChangeNameModal = false;
    },
    async clickOkChangeName() {

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      await this.bindCheckPending(name).then(async (response) => {

        if (this.debug === true) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }
        if (response !== undefined && response[0] !== undefined && response[0].length === 0 || response === 0) {
          this.showChangeNameModal = false;
          let payload = {
            accountName: name,
            newGallinaName: this.gallinaNameInput,
            gallinaId: this.id,
          };
          if (payload.newGallinaName !== "") {
            this.showSignTransactionModal = true;
            await this.$store.dispatch("accounts/changeGallinaName", payload);
          }
        } else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.");
        }
      }).catch((error) => {
        if (this.debug === true) {
          console.log("Gallinas.io communication error");
          console.log(error);
        }
      });


    },

  },
};
</script>

<style scoped>

.cardlisbg {

}

.cardlisbg:hover {
  background-color: #27ffa6 !important;
  cursor: zoom-in;
}

.cardlisbg2 {
  background-color: #27ffa6 !important;
  cursor: zoom-in;
}

.cardlisbg2:hover {
  background-color: #27ffa6;
  cursor: zoom-in;
}

.connect-wallet-text {
  color: #f6f6f6;
  background-color: #2d89a7;
}

.options-bg {
  color: #f6f6f6;
  border-radius: 20px;
  background: repeating-linear-gradient(to top left,beige 0,beige 2em,#ffe4b5 0,#ffe4b5 4em,wheat 0,wheat 6em),repeating-linear-gradient(270deg,#fff 0,#fff 2em,wheat 0,wheat 4em,beige 0,beige 6em),#fff;
  background-blend-mode: multiply;
  top: 9px;
  position: relative;
  height: 459px;
  border: 4px outset #1f8a70;
}

.bubble-bg-found {
  background-color: #028050;
  color: white;
}

.bubble-bg-notfound {
  background-color: #e22b41;
  color: white;
}

.bubble-bg-sellgallina {
  background-color: #1f8a70;
  color: white;
}


.bubble-bg-null {
  background-color: #2d89a7;
  color: white;
}

.copybutton {
  outline: none;
  margin: 0;
  padding: 0;
}

.titleareagift {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #FFF;
  font-size: 1rem;
  margin: 0 auto;
  border-radius: 1rem;
  background-color: #3aafc2;
  border: none;
  width: 90%;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  position: relative;
  left: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;
}

.titlearea {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #bf9e33;
  font-size: 1rem;
  margin: 0 auto;
  border-radius: 1rem;
  background-color: #c2803a;
  border: none;
  width: 90%;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  position: relative;
  left: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;
}


.titlearea-sell {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #bf9e33;
  font-size: 1rem;
  margin: 0 auto;
  border-radius: 1rem;
  background-color: #1f8a70;
  border: none;
  width: 90%;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  position: relative;
  left: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;
  padding: 1rem;
}

.text3d {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.chicken-breed-first-modal {
}

.chicken-sell-modal {
  border-radius: 20px;
  background: repeating-linear-gradient(to top left,beige 0,beige 2em,#ffe4b5 0,#ffe4b5 4em,wheat 0,wheat 6em),repeating-linear-gradient(270deg,#fff 0,#fff 2em,wheat 0,wheat 4em,beige 0,beige 6em),#fff;
  background-blend-mode: multiply;
  position: relative;
  left: -10px;
  top: 10px;
  border: 4px outset #1f8a70;
}

.breed-modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.breed-modal-buttons-style {
  height: 60px;
  font-size: 1rem;
  color: #ffffff;
  border: none;
}

.eggOne, .eggTwo, .eggThree{
  font-family: 'Mochiy Pop P One', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  overflow: hidden;
  display: inline-block;
  width: 160px;
  height: 60px;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.1);
  top: -100px;
  border: 5px solid #c99a50;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;

}

.eggOne {
  left: -300px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
  #90be6d 10px, #90be6d 20px);
  transition: all .5s ease-out;
}

.eggOne:focus {
  color: #fff;
}

.eggOne:hover {
  top: 175px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
  #90be6d 10px, #90be6d 20px);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggOne:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggOne:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

.eggOne2, .eggTwo2, .eggThree2 {
  font-family: 'Mochiy Pop P One', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  overflow: hidden;
  display: inline-block;
  width: 160px;
  height: 60px;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.1);
  border: 5px solid #c99a50;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;

}

.eggOne2 {
  left: -300px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
  #90be6d 10px, #90be6d 20px);
  transition: all .5s ease-out;
}

.eggOne2:focus {
  color: #fff;
}

.eggOne2:hover {
  top: 175px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
  #90be6d 10px, #90be6d 20px);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggOne2:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggOne2:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

@media only screen and (max-width: 767px) {
  .eggOne2 {
    margin-bottom: 20px;
  }
}

.eggTwo2 {
  left: -300px;
  background: repeating-linear-gradient(#a8619c,#87488b 10px,#b56dbe 0,#be6db0 20px);
  transition: all .5s ease-out;
}

.eggTwo2:focus {
  color: #fff;
}

.eggTwo2:hover {
  top: 175px;
  background: repeating-linear-gradient(#a8619c,#87488b 10px,#b56dbe 0,#be6db0 20px);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggTwo2:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggTwo2:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

@media only screen and (max-width: 767px) {
  .eggTwo2 {
    margin-bottom: 20px;
  }
}

.eggTwo {
  left: -100px;
  background-color: #f94144;
  transition: all .5s ease-out;
}

.eggTwo:focus {
  color: #fff;
}

.eggTwo:hover {
  top: 175px;
  background-color: #f94144;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggTwo:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggTwo:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

@media only screen and (max-width: 767px) {
  .eggTwo {
    margin-bottom: 20px;
  }
}


.eggTwo:after {
  content: "";
  position: absolute;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.02);
  width: 126px;
  height: 180px;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;
}

.eggThree {
  left: 100px;
  background: repeating-linear-gradient(-45deg, #70c1b3, #70c1b3 20px,
  #247ba0 20px, #247ba0 40px);
}

.eggThree:focus {
  color: #fff;
}

.eggThree:hover {
  top: 175px;
  background-color: #f94144;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggThree:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggThree:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

@media only screen and (max-width: 767px) {
  .eggThree {
    margin-bottom: 20px;
  }
}


.eggThree:after {
  content: "";
  position: absolute;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.02);
  width: 126px;
  height: 180px;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;
}

.form__input {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #bf9e33;
  font-size: 1rem;
  margin: 0 auto;
  border-radius: 1rem;
  background-color: #ffffbb;
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;
}

.card-back-buttons {
  position: relative;
  bottom: 20px;
  justify-content: space-evenly;
  width: 103%;
  right: 7px;
}

.btnname {
  color: #fff;
  background-color: #e84393;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 12px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnname:active {
  margin-left: 10px;
  margin-top: 10px;
}

.btnname:focus,
.btnname:hover {
  color: #fff;
  background-color: #42c1a3;
}

.btnname:hover:before,
.btnname:hover:after {
  content: '';
  background-color: #1dad82;
  z-index: -1;
}

.btnname:before,
.btnname:after {
  content: '';
  background-color: #ad1d63;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -6px;
  z-index: -1;
  transition: all 0.3s ease 0s;
  outline: none;
}

.btnname:after {
  background-color: #821248;
  height: 100%;
  width: 8px;
  transform: skewY(45deg);
  right: -6px;
  bottom: -3.5px;
  outline: none;
}

.btnname:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnname:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}


.btnbreed {
  color: #fff;
  background-color: #e84393;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 15px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnbreed:active {
  margin-left: 10px;
  margin-top: 10px;
}


.btnbreed:focus,
.btnbreed:hover {
  color: #fff;
  background-color: #42c1a3;
}

.btnbreed:hover:before,
.btnbreed:hover:after {
  content: '';
  background-color: #1dad82;
  z-index: -1;
}

/*.btnbreed:focus,*/
/*.btnbreed:hover {*/
/*  color: #fff;*/
/*}*/

.btnbreed:before,
.btnbreed:after {
  content: '';
  background-color: #ad1d63;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -6px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btnbreed:after {
  background-color: #821248;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btnbreed:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnbreed:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}


.pricingTable {
  background: #fff;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 0 0 30px;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
}

.pricingTable .pricingTable-header {
  background: #c2803a;
  padding: 10px 0 10px;
  margin: 0 auto 40px;
  border-radius: 40px 40px 0 0;
  position: relative;
}

.pricingTable .pricingTable-header:hover {
  color: #fff;
  background: #7bc8fa;
}

.pricingTable .pricingTable-header:after {
  content: "";
  background: linear-gradient(-45deg, transparent 75%, #c2803a 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #c2803a 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 22px;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: -21px;
  left: 0;
  cursor: pointer;
}

.pricingTable .pricingTable-header:hover:after {
  content: "";
  background: linear-gradient(-45deg, transparent 75%, #7bc8fa 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #7bc8fa 75%) 0 50%;
  background-repeat: repeat-x;
  background-size: 22px;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: -21px;
  left: 0;
  cursor: pointer;
}


.pricingTable .title-back {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  cursor: pointer;
}


.pricingTable .price-value {
  color: #5b5b5b;
  margin: 0 0 20px;
}

.pricingTable .price-value .amount {
  font-size: 65px;
  font-weight: 400;
  line-height: 60px;
  display: block;
}

.pricingTable .price-value .duration {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}

.pricingTable .pricing-content {
  padding: 0 10px;
  margin: 0 0 10px;
  list-style: none;
}

.pricingTable .pricing-content li {
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  line-height: 1px;
  padding: 9px 15px 8px 10px;
  position: relative;
  overflow: auto;
}

.pricingTable .pricing-content li:nth-child(odd) {
  background: #e7e7e7;
}

.pricingTable .pricing-content li:nth-last-child(-n+4) {
  line-height: normal;
}

.pricingTable .pricingTable-signup a {
  color: #fff;
  background: #c2803a;
  font-size: 22px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 5px 25px;
  border-radius: 50px;
  transition: all 0.3s ease 0s;
}

.pricingTable .pricingTable-signup a:hover {
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.9);
}

.pricingTable.red .pricingTable-header {
  background: #d83b50;
}

.pricingTable.red .pricingTable-header:after {
  background: linear-gradient(-45deg, transparent 75%, #d83b50 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #d83b50 75%) 0 50%;
  background-size: 22px;
}

.pricingTable.red .pricingTable-signup a {
  background: #d83b50;
}

.pricingTable.orange .pricingTable-header {
  background: #e0681e;
}

.pricingTable.orange .pricingTable-header:after {
  background: linear-gradient(-45deg, transparent 75%, #e0681e 75%) 0 50%,
  linear-gradient(45deg, transparent 75%, #e0681e 75%) 0 50%;
  background-size: 22px;
}

.pricingTable.orange .pricingTable-signup a {
  background: #e0681e;
}

.btn {
  color: #fff;
  background-color: transparent;
  font-family: 'Cutive', serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 1px;
  border: none;
  border-radius: 0;
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease 0s;
  top: 0;
  right: 0px;
}

.btn:focus,
.btn:hover {
  color: #fff;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset, 0 0 10px rgba(0, 0, 0, 0.3);
}

.btn:before,
.btn:after,
.btn span:before,
.btn span:after {
  content: "";
  width: 75%;
  height: 50%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.btn:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

.btn span:before {
  left: auto;
  right: 7px;
  top: 7px;
}

.btn span:after {
  top: auto;
  bottom: 7px;
  left: 7px;
}

.btn:hover:before {
  top: 7px;
  left: 7px;
}

.btn:hover:after {
  bottom: 7px;
  right: 7px;
}

.btn:hover span:before {
  top: 0;
  right: 0;
}

.btn:hover span:after {
  bottom: 0;
  left: 0;
}

.btn span {
  background-color: rgba(255, 165, 79, 0.11);
  padding: 7px 20px;
  display: block;
  border-radius: 50px;
}

i.frontFlipBtn,
i.backFlipBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #FFFFFF;
}

i.backFlipBtn {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}

.flip-container {
  min-height: 120px;
}

.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}

.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}

.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container.flippedhm .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.flippedhm .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container.flipped {
  min-height: 520px;
}

.flip-container.flippedhm {
  min-height: 320px;
}

.front {
  z-index: 2;
}
</style>

<style scoped lang="scss">
.icon {
  display: flex;
}

.card {
  background-color: #a0b3c4;
  color: #8BACD9;
  border-radius: 16px;
  width: 300px;
  //height: 500px;
  padding-bottom: 0;
  box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.1);

  .card__product-img {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }

  .card__content {
    display: flex;
    flex-direction: column;

    .card__name {
      color: #285a79;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .card__description {
      font-size: 1rem;
      line-height: 2.6rem;
      font-weight: lighter;
      position: absolute;
      top: 0px;
    }

    .card__content-bottom {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 0;

      > * {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .card__price {
        color: #d50078;
      }
    }
  }

  .card__footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #2E405A;
    gap: 16px;
    padding-top: 16px;

    .card__avatar {
      display: flex;
      border-radius: 90px;
      border: 1px solid white;

      img {
        width: 33px;
      }
    }

    .card__autor--alt-color {
      color: white;
      cursor: pointer;

      &:hover {
        color: #00FFF8;
      }
    }
  }
}

//Transition
.fade-enter-active,
.fade-leave-active {
  transition: 0.25s ease-out;
}

.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}


.form-container {
  background: radial-gradient(
          circle at bottom left,
          transparent 0,
          transparent 2em,
          beige 2em,
          beige 4em,
          transparent 4em,
          transparent 6em,
          peachpuff 6em,
          peachpuff 8em,
          transparent 8em,
          transparent 10em
  ),
  radial-gradient(
          circle at top right,
          transparent 0,
          transparent 2em,
          beige 2em,
          beige 4em,
          transparent 4em,
          transparent 6em,
          peachpuff 6em,
          peachpuff 8em,
          transparent 8em,
          transparent 10em
  ),
  radial-gradient(
          circle at top left,
          transparent 0,
          transparent 2em,
          navajowhite 2em,
          navajowhite 4em,
          transparent 4em,
          transparent 6em,
          peachpuff 6em,
          peachpuff 8em,
          transparent 8em,
          transparent 10em
  ),
  radial-gradient(
          circle at bottom right,
          transparent 0,
          transparent 2em,
          palegoldenrod 2em,
          palegoldenrod 4em,
          transparent 4em,
          transparent 6em,
          peachpuff 6em,
          peachpuff 8em,
          transparent 8em,
          transparent 10em
  ), khaki;

  background-blend-mode: darken;
  background-size: 10em 10em;
  background-position: 0 0, 0 0, 5em 5em, 5em 5em;
  padding: 20px;
  padding-top: 40px;
  border-radius: 20px;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
}

.form-container .form-icon {
  color: #40ac98;
  font-size: 55px;
  text-align: center;
  line-height: 100px;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  border-radius: 50px;
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px #fff;
}

.form-container .title {
  color: #ac40ab;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px;
}

.form-container .form-horizontal .form-group {
  margin: 0 0 25px 0;
}

.form-container .form-horizontal .form-group label {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-container .form-horizontal .form-control {
  color: #333;
  background: #ecf0f3;
  font-size: 15px;
  height: 50px;
  padding: 20px;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.form-container .form-horizontal .form-control:focus {
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px #fff;
  outline: none;
}

.form-container .form-horizontal .form-control::placeholder {
  color: #808080;
  font-size: 14px;
}

.form-container .form-horizontal .btn {
  color: #000;
  background-color: #ac40ab;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  padding: 12px 15px 11px;
  border-radius: 20px;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px #fff;
  border: none;
  transition: all 0.5s ease 0s;
}

.form-container .form-horizontal .btn:hover,
.form-container .form-horizontal .btn:focus {
  color: #fff;
  letter-spacing: 3px;
  box-shadow: none;
  outline: none;
}

</style>
