import Vue from 'vue';
import Router from 'vue-router';
import Home from "./pages/Home";
import MyGallinas from "./pages/MyGallinas";
import BuyEggs from "./pages/BuyEggs";
import Breed from "./pages/Breed";
import Market from "./pages/Market";
import History from "./pages/History";
import Inspect from "./pages/Inspect";
import Help from "./pages/Help";
import Burn from "./pages/Burn";

Vue.use(Router);

export default new Router({
    // Make sure the server can handle the history mode
    // If not, set it to hash (or delete the mode)
    // More info here: https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            path: "/gallinas",
            name: "gallinas",
            component: MyGallinas
        },
        {
            path: "/buyeggs",
            name: "buyeggs",
            component: BuyEggs
        },
        {
            path: "/market",
            name: "market",
            component: Market
        },
        {
            path: "/history",
            name: "history",
            component: History
        },
        {
            path: "/breed/:x/:y",
            name: "breed",
            component: Breed,
            props: true
        },
        {
            path: "/inspect/:x",
            name: "inspect",
            component: Inspect,
            props: true
        },
        {
            path: "/burn/:x",
            name: "burn",
            component: Burn,
            props: true,
            required: false,
        },
        {
            path: "/help",
            name: "help",
            component: Help
        },
        {
            path: "*",
            redirect: { path: "/" }
        }
    ],
    linkActiveClass: "active"
});
