<template>
  <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar collapse">
    <div class="position-sticky pt-3">

      <div class="nav flex-column">

        <div class="gradient"></div>
        <Scroll-Div width="98%" height="90vh" view-class="yourclassname">

        <section v-if="$screen.width > 767.98">

          <div class="mt-2"><br/></div>

          <div class="panelbg">
          <div v-if="showTransactionButton === true || this.getRequestPending() === true" class="row mt-2">
            <div class="col-12">
              <div class="my-flex">
                <a aria-current="page" href="#">
                  <span data-feather="market"></span>
                  <div class="col-md-4 align-items-center">
                    <span v-tooltip="'Transaction in Progress'" class="gtransitionbutton"><div @click="clickTransactionsButton()" class="loaderlarge"></div></span>
                  </div>
                </a>
              </div>
            </div>
          </div>

            <div class="row mt-2">
              <div class="col-12">
                <div class="my-flex">
                  <a aria-current="page" href="/market">
                    <span data-feather="market"></span>
                    <div class="col-md-4 align-items-center">
                      <span v-tooltip="'Gallina Marketplace'" class="gcontrolbutton"> <img class="gcontrolimage" src="../../public/icons/market.svg" alt="market" /></span>
                    </div>
                  </a>
                </div>
              </div>
            </div>

          <div class="row mt-2">
            <div class="col-12">
              <div class="my-flex">
                <a aria-current="page" href="/buyeggs">
                  <span data-feather="buy-eggs"></span>
                  <div class="col-md-4 align-items-center">
                    <span v-tooltip="'Buy and Hatch Eggs'" class="gcontrolbutton low-ink-x-effect"> <img class="gcontrolimage" src="../../public/icons/buy-eggs.svg"/></span>
                  </div>
                </a>
              </div>
            </div>
          </div>


          <div class="row mt-2">
            <div class="col-12">
              <div class="my-flex">
                <a aria-current="page" href="/gallinas">
                  <span data-feather="gallinas"></span>
                  <div class="col-md-4 align-items-center">
                    <span v-tooltip="'Manage your Gallinas'" class="gcontrolbutton"> <img class="gcontrolimage" src="../../public/icons/home.svg"/></span>
                  </div>
                </a>
              </div>
            </div>
          </div>

            <div class="row mt-2">
              <div class="col-12">
                <div class="my-flex">
                  <a aria-current="page" href="/burn/0">
                    <span data-feather="store"></span>
                    <div class="col-md-4 align-items-center">
                      <span v-tooltip="'Pet Shop & Gift Store'" class="gcontrolbutton"> <img class="gcontrolimage" src="../../public/icons/gift-store-icon.svg"/></span>
                    </div>
                  </a>
                </div>
              </div>
            </div>

          <div class="row mt-2">
            <div class="col-12">
              <div class="my-flex">
                <a aria-current="page" href="/">
                  <span data-feather="home"></span>
                  <div class="col-md-4 align-items-center">
                    <span class="bgmarketbutton"></span>
                    <span v-tooltip="'Home'" class="gcontrolbutton">  <img class=" gcontrolimage" src="../../public/icons/faq-icon.svg"/></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          </div>



        </section>

        <section>

          <section v-if="$screen.width <= 767.8">
            <div class="row mt-2">
              <div class="col-6">
                <div class="col-equip-lg equipped-item-lg my-flex my-shadow">
                  <a class="nav-link" :class="{active:this.$route.name === 'market'}" aria-current="page" href="/market">
                    <span data-feather="market"></span>
                    <img class="my-flex" src="../../public/icons/market.svg" height="80">
                  </a>
                </div>
              </div>
              <div class="col-6">
                <div class="col-equip-lg equipped-item-lg my-flex my-shadow">
                  <a class="nav-link" :class="{active:this.$route.name === 'buyeggs'}" aria-current="page" href="/buyeggs">
                    <span data-feather="buyeggs"></span>
                    <img class="my-flex" src="../../public/icons/buy-eggs.svg" height="80">
                  </a>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <div class="col-equip-lg equipped-item-lg my-flex my-shadow">
                  <a class="nav-link" :class="{active:this.$route.name === 'gallinas'}" aria-current="page" href="/gallinas">
                    <span data-feather="mygallinas"></span>
                    <img class="my-flex" src="../../public/icons/home.svg" width="70">
                  </a>
                </div>
              </div>


              <div class="col-6">
                <div class="col-equip-lg equipped-item-lg my-flex my-shadow">
                  <a class="nav-link" :class="{active:this.$route.name === 'home'}" aria-current="page" href="/">
                    <span data-feather="home"></span>
                    <img class="my-flex" src="../../public/icons/faq-icon.svg" width="70">
                  </a>
                </div>
              </div>
            </div>
          </section>

          <div class="alert alertbg borderalert m-4 negmarginup" style="width:80%; left: 2%" role="alert">
            <div class="rivet jr--silver bolt1"></div><div class="rivet jr--silver bolt2"></div><div class="rivet jr--silver bolt3"></div><div class="rivet jr--silver bolt4"></div>
            <span>
            <span style="font-weight: bold;">Live on Kadena Chain 1</span>
              <br/>
              Gallinas.io plays best with <a href="https://wallet.ecko.finance/" target="_blank">Ecko</a> and Chrome.<br/>
          </span>
          </div>

        </section>
        </Scroll-Div>

      </div>

    </div>
    <vmodal v-if="showTransactionModal" :show-gold-header="false" content-class="darkMode"
            :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Confirm" cancel-name="Close"
            v-model="showTransactionModal" @confirm="clickConfirmTransaction"
            @cancel="closeTransactionModal" name="intro" modaltitle="Connect My Account">
      <template v-slot:title>
        <div>
          Processing Recent Transaction
        </div>
      </template>

      <div v-if="this.getTransactionFailed() === true" class="row align-items-center justify-content-center">
        <div class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-notfound mt-2">
          Transaction Failed
        </div>
      </div>
      <div v-if="this.getTransactionFailed() === false" class="row align-items-center justify-content-center">
        <div class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-found mt-2">
          Transaction Confirmed
        </div>
      </div>
      <div v-if="this.getTransactionFailed() === null" class="row align-items-center justify-content-center">
        <div class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-null mt-2">
          Checking Kadena Blockchain..
        </div>
      </div>


      <div class="displayGuard">
        <div class="row align-items-center justify-content-center mb-0">
          <div v-if="this.getAccountExists() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-5 bubble-bg-found mt-2">
            Transaction Hash:
          </div>
        </div>

        <div class="row align-items-center justify-content-center mb-0">
          <div v-if="this.getAccountExists() === true"
               class="p-0 border-gold text-center connect-wallet-text alert col-10 bubble-bg-found mt-2" style="line-height: 30px; overflow-wrap: anywhere;">
            <a :href="exploerLink" target="_blank" style="color: #FFF">{{this.getTransactionHash()}}</a>
          </div>
        </div>

      </div>

    </vmodal>
  </nav>
</template>

<script>


import {mapGetters, mapState} from "vuex";
import ScrollDiv from "vue-scroll-div";
import vmodal from "@/components/VModal";

export default {
  name: "Sidebar",
  components: {
    ScrollDiv,
    vmodal
  },
  data() {
    return {
      debug: false,
      showTransactionButton: false,
      showTransactionModal: false,
    };
  },
  methods: {
    ...mapGetters("accounts", ["getAccountExists", "getAccountName", "getAccountKeys", "getAccountPredicate", "getAccountData", "getAccountConfirmed", "getTransactionPolling", "getTransactionConfirmed", "getTransactionFailed", "getTransactionHash", "getTransactionConfirmedResult", "getRequestPending"]),

    clickTransactionsButton(){
      this.showTransactionModal = !this.showTransactionModal;
      if(this.debug) {
        console.log("click");
      }

    },
    async clickConfirmTransaction(){

      let transactionfailed = await this.getTransactionFailed();

      if(transactionfailed === null){
        this.closeTransactionModal();
      }else if (transactionfailed === true){
        this.closeTransactionModal();
        await this.$store.dispatch("accounts/clearTransactionPoll");
        if(this.getRequestPending() === false){
          this.showTransactionButton = false;
        }
      }else if (transactionfailed === false){
        this.closeTransactionModal();
        await this.$store.dispatch("accounts/clearTransactionPoll");
        this.showTransactionButton = false;
      }else {
        this.closeTransactionModal();
        await this.$store.dispatch("accounts/clearTransactionPoll");
        if(this.getRequestPending() === false){
          this.showTransactionButton = false;
        }
      }

    },

    closeTransactionModal(){
      this.showTransactionModal = !this.showTransactionModal;
    },
  },
  watch: {
    transactionPolling: function (newValue, oldValue) {
      if(this.debug) {
        console.log("getTransactionPolling newValue: " + newValue);
        console.log("getTransactionPolling oldValue: " + oldValue);
      }
      if (newValue === true) {
        this.showTransactionButton = true;
      } else if (newValue === false) {
        this.showTransactionButton = false;
      }

      if(this.getRequestPending === true){
        this.showTransactionButton = true;
      } else if(this.getRequestPending === false){
        this.showTransactionButton = false;
      }


    },
  },
  computed: {
    ...mapState("accounts", ["transactionPolling"]),

    exploerLink(){
      return 'https://explorer.chainweb.com/mainnet/tx/'+this.getTransactionHash().toString();
    }

  },
};
</script>

<style scoped>

.bolt {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  width: 10px;
  height: 10px;
  background: rgb(231,231,231);
  background: linear-gradient(90deg, rgba(231,231,231,1) 0%, rgba(99,99,99,1) 0%, rgba(177,177,177,1) 30%, rgba(128,126,129,1) 51%, rgba(93,93,93,1) 83%, rgba(56,56,56,1) 100%);
  position: absolute;

}

.jr--silver { /* Metal - Silver */
  background: radial-gradient(at 75% 28%, #fffc 3%,  #0000 11%),
  radial-gradient(at 50% 50%, #0000 44%, #0007 99%),
  radial-gradient(at 67% 33%, #0000 10%, #0006 30%),
  radial-gradient(at 50% 45%, #0000 60%, #0003 75%),
  linear-gradient(#0000 1%,   #fff5  0%, #0000 12%),
  linear-gradient(#0000 14%,  #fff2 18%, #0000 30%),
  linear-gradient(#0000 8%,   #fff5 15%, #0000 38%),
  #ccc;

}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(90deg, rgba(120, 68, 0, 0.5) 50%, transparent 50%),
  linear-gradient(rgba(255, 124, 29, 0.6) 50%, transparent 50%);
  background-size:50px 50px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.rivet {
  width: 0.8em;
  height: 0.8em;
  border-radius: 0.4em;
  box-shadow: -0.1em 0.1em 0.2em 0 #000d;
  position: absolute;
}

.bolt1 {

  left: 5px;
  top: 5px;
}

.bolt2 {

  right: 5px;
  top: 5px;
}

.bolt3 {

  left: 5px;
  bottom: 5px;
}

.bolt4 {

  right: 5px;
  bottom: 5px;
}

.borderalert{
  border: 5px inset rgba(172, 107, 40, 0.59);
  border-radius:20px;
}

.negmarginup{
  margin-top: 10px !important;
}

.panelbg{
  /*background: linear-gradient(#ffe1bf, transparent),*/
  /*linear-gradient(to top left, #fff4e3, #ffedc9),*/
  /*linear-gradient(to top right, #fff2c5, #ffefc5);*/
  background: rgb(231,231,231);
  background: linear-gradient(90deg, rgba(231,231,231,1) 0%, rgb(253, 228, 201) 0%, rgb(246, 227, 207) 30%, rgb(231, 228, 211) 51%, rgb(246, 227, 207) 83%, rgb(255, 225, 191) 100%);
  width: 65%;
  position: relative;
  left: 18%;
  padding-bottom: 40px;
  border: 8px outset #ffedc9;
  border-radius: 35px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset, rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}

.alertbg {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 18%, #0000 32%, #0000 60%),
  linear-gradient(90deg, #0000 40%, rgba(255, 255, 255, 0.05) 68%, #0000 82%),
  linear-gradient(170deg,#0000 36%, rgba(255, 255, 255, 0.01) 37%,  #0000 38%, rgba(95, 16, 16, 0.004) 45%, #0000 46%, #0000 60%),
  radial-gradient(at 50% 50%, #0000 60%, #0000 100%),
  linear-gradient(170deg, rgba(0, 0, 0, 0.05) 75%, rgba(0, 0, 0, 0.05) 78%, rgba(0, 0, 0, 0.05) 82%),
  linear-gradient(#0000 0%, rgba(118, 32, 32, 0.05) 50%, rgba(0, 0, 0, 0.05) 100%),
  linear-gradient(#0000 0%, rgba(116, 30, 30, 0.05) 15%, rgba(0, 0, 0, 0.05) 38%),
  rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.05) 0px -3px 0px inset;
}

.metal.linear {
  width: 100px;
  font-size: 4em;
  height: 80px;
  border-radius: .5em;
  background-image: -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0)   6%, hsla(0,0%,100%, .1) 7.5%),
  -webkit-repeating-linear-gradient(left, hsla(0,0%,  0%,0) 0%, hsla(0,0%,  0%,0)   4%, hsla(0,0%,  0%,.03) 4.5%),
  -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 1.2%, hsla(0,0%,100%,.15) 2.2%),

  linear-gradient(180deg, hsl(0,0%,78%)  0%,
      hsl(0,0%,90%) 47%,
      hsl(0,0%,78%) 53%,
      hsl(0,0%,70%)100%);
}

.tootipstyle {
  background-color: #fff;
}

.connect-wallet-text {
  color: #f6f6f6;
}

.gtransitionbutton {
  border-radius: 15px;
  background: linear-gradient(#f7ffe9, transparent),
  linear-gradient(to top left, #fff4e3, #ffedc9),
  linear-gradient(to top right, #fff2c5, #ffefc5);
  background-blend-mode: lighten,   /* sepia */
  lighten,  /* lavender */
  multiply;  /* dust-and-scratches */
  /*box-shadow: #b2bd97 0 15px 0 0;*/
  box-shadow: 0px 4px 0px #90997a, 0px 9px 25px rgba(0,0,0,.7);
  border: 2px;
  border-style: outset;
  border-color: #f7ffe9;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .1s ease;
  margin-top: 20px;
  width: calc(7vw);
  height: 80px;
  overflow: hidden;
}

.gtransitionbutton:active {
  background: linear-gradient(#f7ffe9, transparent),
  linear-gradient(to top left, #ffe9b0, #f8e3a3),
  linear-gradient(to top right, #ffeda0, #ffedb1);
  /*box-shadow: #6d745c 0 5px 0 0;*/
  box-shadow: 0px 3px 0px #6d745c, 0px 3px 6px rgba(0,0,0,.9);
  transform: translateY(10px);
}

.gcontrolbutton {
  border-radius: 15px;
  background: linear-gradient(#f7ffe9, transparent),
  linear-gradient(to top left, #fff4e3, #ffedc9),
  linear-gradient(to top right, #fff2c5, #ffefc5);
  background-blend-mode: lighten,   /* sepia */
  lighten,  /* lavender */
  multiply;  /* dust-and-scratches */
  /*box-shadow: #b2bd97 0 15px 0 0;*/
  box-shadow: 0px 7px 0px #7d8668, 0px 9px 25px rgba(0,0,0,.7);
  border: 3px;
  border-style: outset;
  border-color: #f7ffe9;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .1s ease;
  margin-top: 20px;
  width: calc(7vw);
  height: 10vh;
  padding: 3rem;
  overflow: hidden;
}

.gcontrolbutton:active {
  background: linear-gradient(#f7ffe9, transparent),
  linear-gradient(to top left, #ffe9b0, #f8e3a3),
  linear-gradient(to top right, #ffeda0, #ffedb1);
  /*box-shadow: #6d745c 0 5px 0 0;*/
  box-shadow: 0px 3px 0px #6d745c, 0px 3px 6px rgba(0,0,0,.9);
  transform: translateY(10px);
}

.gcontrolimage{
  position:relative;
  height: 120px;
  z-index: 0;
  mix-blend-mode: multiply;
}

/*MODAL STUFF*/
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.75);
}

::v-deep .confirm-style {
  height: 60px;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  position: absolute;
  top: 180px;
  left: 15px;
}

::v-deep .cancel-style {
  height: 60px;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  position: absolute;
  top: 180px;
  left: 160px;
}

::v-deep .modal__title {
  background-color: #fff;
  height: 10px;
}


::v-deep .main-element {
  background: repeating-linear-gradient(
      to top left,
      beige 0,
      beige 2em,
      moccasin 2em,
      moccasin 4em,
      wheat 4em,
      wheat 6em
  ),
  repeating-linear-gradient(
      to left,
      white 0,
      white 2em,
      wheat 2em,
      wheat 4em,
      beige 4em,
      beige 6em
  ), white;

  background-blend-mode: multiply;
  border-radius: 10%;

}

.border-20 {
  border-radius: 20%;
}

.border-gold {
  border: 1px solid #8a6f3e;
}

.bubble-bg-found {
  background-color: #028050;
}

.bubble-bg-notfound {
  background-color: #e22b41;
}

.bubble-bg-null {
  background-color: #2d89a7;
}

.loader{
  background: repeating-linear-gradient(to bottom,#fff 10px,#f5f5f5 20px);
  height: 60px;
  width: 60px;
  margin: 0 auto 0;
  border: 2px solid #3498db;
  box-shadow: 0 0 5px #3498db inset;
  border-radius: 50%;
  position: relative;
  top:0px;
}
.loader:before,
.loader:after{
  content: '';
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translate(-50%);
  transform-origin: bottom;
  position: absolute;
  left: 50%;
  bottom: 50%;
}
.loader:before{
  background-color: #eb3b5a;
  width: 1px;
  height: 31%;
  animation: rotate 5s linear infinite;
}
.loader:after{
  background-color: #2c3e50;
  width: 1px;
  height: 40%;
  animation: rotate 2s linear infinite;
}
@keyframes rotate{
  from{ transform: rotate(0); }
  to{ transform: rotate(360deg); }
}

.loaderlarge{
  background: repeating-linear-gradient(to bottom,#fff 10px,#f5f5f5 20px);
  background-blend-mode: multiply;
  height: 110px;
  width: 110px;
  margin: 0 auto 0;
  border: 5px solid #3498db;
  box-shadow: 0 0 5px #3498db inset;
  border-radius: 50%;
  position: relative;
  top:0px;
}
.loaderlarge:before,
.loaderlarge:after{
  content: '';
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translate(-50%);
  transform-origin: bottom;
  position: absolute;
  left: 50%;
  bottom: 50%;
}
.loaderlarge:before{
  background-color: #eb3b5a;
  width: 3px;
  height: 31%;
  animation: rotate 5s linear infinite;
}
.loaderlarge:after{
  background-color: #2c3e50;
  width: 3px;
  height: 40%;
  animation: rotate 2s linear infinite;
}

.alertbg {
  background-color: #fff8ea;
}

.gradient {
  background: #999955;
  background-image: linear-gradient(#DAB046 20%,
  #be9736 20%,
  #a07e28 40%,
  #a1875d 40%,
  #ecb742 60%,
  #f3bc45 60%,
  #f39e45 80%,
  #ec9a44 80%);
  margin: 0 auto;
  margin-top: -14px;
  width: 100%;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}


.equipped-item-lg {
  box-shadow: 0px 5px 1px 0px rgba(0, 0, 0, 0.75);
  border: 3px outset #ffda86;
  border-radius: 50px;
  margin: .5rem auto;
  background: linear-gradient(#e1f0be, transparent),
  linear-gradient(to top left, #ffd963, transparent),
  linear-gradient(to top right, #fdc400, transparent);
  background-blend-mode: screen;
  /*-webkit-background-clip: text;*/
  height: 90px;
  padding: 0px;
}


.my-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.col-equip-lg {
  width: 90px;
  height: 90px;
}


.my-shadow {
  box-shadow: -7px -7px 20px 0px rgba(45, 43, 43, 0.6),
  -4px -4px 5px 0px rgba(45, 43, 43, 0.6),
  7px 7px 20px 0px #0002,
  4px 4px 5px 0px #0001,
  inset 0px 0px 0px 0px rgba(45, 43, 43, 0.6),
  inset 0px 0px 0px 0px #0001,
  inset 0px 0px 0px 0px rgba(45, 43, 43, 0.6), inset 0px 0px 0px 0px #0001;
  transition: box-shadow 0.6s cubic-bezier(.79, .21, .06, .81);
}

.my-shadow:active {

  box-shadow: 4px 4px 6px 0 rgba(45, 43, 43, 0.6),
  -4px -4px 6px 0 rgba(116, 125, 136, .2),
  inset -4px -4px 6px 0 rgba(45, 43, 43, 0.6),
  inset 4px 4px 6px 0 rgba(116, 125, 136, .3);

}

.pagination li a {
  color: #999;
  background-color: #e7e7e7;
  font-size: 15px;
  font-weight: 600;
  margin: 0 2px;
  border: none;
  transition: all 0.3s ease 0s;
}

.pagination li a:hover,
.pagination li a:focus,
.pagination li.active a {
  color: #90782a;
  background: linear-gradient(#6d5b34, rgba(31, 25, 24, 1));
  font-family: 'pixel_font7regular', serif;
}


</style>
