<template>
  <div class="main-wrap">
    <div class="loader" v-if="this.showLoadingModal === true">
      <div class="loader__bar"></div>
      <div class="loader__bar loader__bar--delay-1"></div>
      <div class="loader__bar loader__bar--delay-2"></div>
      <div class="loader__bar loader__bar--delay-3"></div>
      <div class="loader__bar loader__bar--delay-4"></div>
      <div class="loader__bar loader__bar--delay-5"></div>
    </div>

    <Scroll-Div height="85vh" width="97%" view-class="sd" v-if="this.showLoadingModal === false">
      <div class="container">

        <div v-if="$screen.width > 576" class="row align-items-center justify-content-center mt-4">
          <div class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalGallinas()+' Gallina(s).'">
            {{ this.getUserTotalGallinas() }} &nbsp; <img src="../../public/icons/chicken-mygallinas-icon.svg"
                                                          alt="Chickens" style="position:relative; top: 3px;"
                                                          width="40px"/>
            </span>
          </div>
          <div v-if="this.getUserTotalEggs() > 0"
               class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalEggs()+' Egg(s).'">
            {{ this.getUserTotalEggs() }} &nbsp; <img src="../../public/icons/egg-mygallinas-icon.svg"
                                                      style="position:relative; top: 5px;" alt="Eggs"
                                                      width="25px"/>
            </span>
          </div>
          <div v-if="this.getUserTotalGiftVouchers() > 0"
               class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalGiftVouchers()+' Voucher(s).'">
            {{ this.getUserTotalGiftVouchers() }} &nbsp; <img src="../../public/icons/gift-voucher-icon.svg"
                                                              style="position:relative; top: 5px;" alt="Gift Vouchers"
                                                              width="40px"/>
            </span>
          </div>
          <div v-if="this.getUserTotalEggs() <= 0"
               class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You dont have any Eggs right now'">
            0 &nbsp; <img src="../../public/icons/egg-mygallinas-icon.svg" style="position:relative; top: 5px;"
                          alt="Eggs"
                          width="25px"/>
            </span>
          </div>
          <div v-if="this.getUserTotalGiftVouchers() <= 0"
               class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You dont have any Vouchers right now'">
            0 &nbsp; <img src="../../public/icons/gift-voucher-icon.svg" style="position:relative; top: 5px;"
                          alt="Gift Vouchers"
                          width="40px"/>
            </span>
          </div>
        </div>


        <div v-if="hasLostGallinas === true" class="modal-box" :class="hasMissedGallinas ? 'mb-2' : ''">
          <div class="mdialog" role="document">
            <div class="modal-content clearfix">
              <div class="modal-body">
                <div class="row align-items-center justify-content-center mt-1 mb-1">
                  <div @click="showWhatIsGallinas = !showWhatIsGallinas"
                       :class="showWhatIsGallinas ? 'helpselected' : 'dark-text-section' "
                       class="col-11 d-flex align-items-center justify-content-evenly">
                    <img
                        src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas"
                        width="40px">
                    <h3 class="title">Upgradable Gallinas</h3><img
                      src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                  </div>
                </div>
                <div v-show="showWhatIsGallinas" class="row align-items-center justify-content-center mt-2 mb-2">
                  <div class="col-8 align-items-center justify-content-evenly hidden-section p-3 rcolumn2 mt-4">
                    <p><span style="font-weight: bold;"> A recent upgrade to Gallinas.io on 03/30/2022 requires the following Gallinas to each be updated!</span></p>
                    <br/>
                    <p>We realize this is a inconvenience for our earliest players, as such, upgrading a Gallina automatically performs a one time upgrade of the Gallina's stats, converting it's DNA into a unique strand no other Gallina has.</p>
                    <br/>
                    <p>Please feel free to upgrade your existing Gallinas at any time by clicking the "Upgrade" button next to each one to take advantage of this opportunity to turn your Gallinas into something unique and begin rarity farming at the Pet Shop.</p>
                    <br/>
                    <p>Upgrading a Gallina will automatically convert it's current DNA into a unique strand not owned by any other player, set it's generation to 1, and begin displaying it again on Gallinas.io.</p>
                    </div>
                  <div class="col-8 d-flex align-items-center justify-content-evenly hidden-section p-3 rcolumn mt-4">
                    <div class="panel">
                      <div class="panel-heading">
                        <div class="row">
                          <div class="col-sm-12">
                            <h4 class="title2 pull-left">Please upgrade the Gallinas below:</h4>
                          </div>
                        </div>
                      </div>
                      <div class="panel-body table-responsive">
                        <table class="table">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Update</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(g, index) in lostUserGallinasList" :key="g.id">

                            <td>{{ index+1 }}</td>
                            <td>{{ g.id }}</td>
                            <td>
                              <ul class="action-list">
                                <li> <button @click="clickRemint(g.id)" type="button" class="btnbreed"> Upgrade </button></li>
                              </ul>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="hasMissedGallinas === true" class="modal-box">
          <div class="mdialog" role="document">
            <div class="modal-content clearfix">
              <div class="modal-body">
                <div class="row align-items-center justify-content-center mt-1 mb-1">
                  <div @click="showWhatIsGallinas2 = !showWhatIsGallinas2"
                       :class="showWhatIsGallinas2 ? 'helpselected' : 'dark-text-section' "
                       class="col-11 d-flex align-items-center justify-content-evenly">
                    <img
                        src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas"
                        width="40px">
                    <h3 class="title">New Gallinas Detected!</h3><img
                      src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                  </div>
                </div>
                <div v-show="showWhatIsGallinas2" class="row align-items-center justify-content-center mt-2 mb-2">
                  <div class="col-8 align-items-center justify-content-evenly hidden-section p-3 rcolumn2 mt-4">
                    <p><span style="font-weight: bold;">We have detected new Gallinas in your wallet that need to be added to Gallinas.io!</span></p>
                    <br/>
                    <p>This message pops up when Gallinas.io detects new Gallinas in your Wallet that it hasn't met on the blockchain before.</p>
                    <br/>
                    <p>Please Resolve the new Gallina(s) we have detected in your wallet to begin displaying them on Gallinas.io:</p>
                  </div>
                  <div class="col-8 d-flex align-items-center justify-content-evenly hidden-section p-3 rcolumn mt-4">
                    <div class="panel">
                      <div class="panel-heading">
                        <div class="row">
                          <div class="col-sm-12">
                            <h4 class="title2 pull-left">Please resolve the Gallinas below:</h4>
                          </div>
                        </div>
                      </div>
                      <div class="panel-body table-responsive">
                        <table class="table">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Update</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(g, index) in lostUserGallinasList2" :key="g.id">

                            <td>{{ index+1 }}</td>
                            <td>{{ g.id }}</td>
                            <td>
                              <ul class="action-list">
                                <li> <button @click="clickResolve(g.id)" type="button" class="btnbreed"> Resolve </button></li>
                              </ul>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div v-if="$screen.width <= 576" class="row align-items-center justify-content-center mt-2">
          <div class="col-md-10 d-flex align-items-center justify-content-center stat-column"><span class="hugleft">
            {{ this.getUserTotalGallinas() }} &nbsp; <img src="../../public/icons/chicken-mygallinas-icon.svg"
                                                          alt="Chickens" width="40px"/>  </span> &nbsp; <span
              class="hugright">  {{ this.getUserTotalEggs() }} &nbsp; <img
              src="../../public/icons/egg-mygallinas-icon.svg" alt="Chickens" width="25px"/></span>
          </div>
        </div>

        <div v-if="!this.showLoadingModal && $screen.width > 576 && this.getUserTotalGallinas() > 0"
             class="row align-items-center justify-content-center mt-4">
          <div class="col-md-12 d-flex align-items-center justify-content-evenly search-column">
              <span class="font3dsmall3">
                <br/>
                Type:
                <v-suggest class="gallinacansearch"
                           :data="gallinacanlist"
                           :full-list="true" placeholder="All Gallinas" @values="valuesgallinacanvalue"
                ></v-suggest>

                 Gender:
                <v-suggest class="genesearch"
                           :data="genderlist"
                           :full-list="true" placeholder="Any" @values="valuesgallinagendervalue"
                ></v-suggest>
                &nbsp;
                 Generation:
                <v-suggest class="genesearch"
                           :data="generationlist"
                           :full-list="true" placeholder="Any" @values="valuesgallinagenerationvalue"
                ></v-suggest>
                &nbsp;
                DNA Position:
              <v-suggest class="genesearch"
                         :data="genelist"
                         :full-list="true" placeholder="Any" @values="valuesgeneselected"
              ></v-suggest>
                &nbsp;
                DNA Value:
              <v-suggest class="genesearch"
                         :data="genevallist"
                         :full-list="true"
                         placeholder="Any" @values="valuesgenevalue"
              ></v-suggest></span>
            <button type="button" class="mx-3 btnsearch" style="position:relative; top: 4px; outline:none;"
                    @click="clickSearch">
              <div v-if="searching === true" class="loadereye">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <span v-if="searching === false">Search</span>
            </button>
          </div>
        </div>


        <div class="row paginate-row justify-content-center align-items-center mt-4">
          <div class="col-sm-12 d-flex justify-content-center align-items-center">
            <ul class="pagination link-menu mt-4">
              <li class="page-item">
                <button type="button" class="btnbreed" v-if="page != 1" @click="page--; clickShowPage(page, page);">
                  Previous
                </button>
              </li>
              <li class="page-item">
                <button type="button" class="btnbreed" v-for="pageNumber in pages.slice(page-1, page+5)"
                        :key="pageNumber" @click="page = pageNumber;  clickShowPage(page, pageNumber); ">
                  {{ pageNumber }}
                </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++; clickShowPage(page, page);" v-if="page < pages.length"
                        class="btnbreed"> Next
                </button>
              </li>
            </ul>
          </div>
        </div>


        <div v-if="gallinasToShowNowList.length > 0 && $screen.width > 1280"
             class="row align-items-center justify-content-center mt-4">
          <div v-for="g in gallinasToShowNowList" :key="g.id"
               class="col-md-4 d-flex align-items-center justify-content-center special-margin">
            <FlipCard style="margin-bottom: 101px;"
                      shadow="1"
                      :name="g.name" :id="g.id"
                      :gender="g.gender" :birthday="g.birthday" :nbd="g.nbd"
                      :motherid="g.motherid" :fatherid="g.fatherid"
                      :price="g.price"
                      :generation="g.generation.toString()"
                      :special="g.special.toString()"
                      :setcount="g.setcount.toString()"
                      :rarity="g.rarity.toString()"
                      :gene1h1="g.gene1h1.toString()" :gene2h1="g.gene2h1.toString()" :gene3h1="g.gene3h1.toString()"
                      :gene4h1="g.gene4h1.toString()" :gene5h1="g.gene5h1.toString()" :gene6h1="g.gene6h1.toString()"
                      :gene7h1="g.gene7h1.toString()" :gene8h1="g.gene8h1.toString()" :gene9h1="g.gene9h1.toString()"
                      :gene10h1="g.gene10h1.toString()"
                      :gene1h2="g.gene1h2.toString()" :gene2h2="g.gene2h2.toString()" :gene3h2="g.gene3h2.toString()"
                      :gene4h2="g.gene4h2.toString()" :gene5h2="g.gene5h2.toString()" :gene6h2="g.gene6h2.toString()"
                      :gene7h2="g.gene7h2.toString()" :gene8h2="g.gene8h2.toString()" :gene9h2="g.gene9h2.toString()"
                      :gene10h2="g.gene10h2.toString()"
                      :gene1h3="g.gene1h3.toString()" :gene2h3="g.gene2h3.toString()" :gene3h3="g.gene3h3.toString()"
                      :gene4h3="g.gene4h3.toString()" :gene5h3="g.gene5h3.toString()" :gene6h3="g.gene6h3.toString()"
                      :gene7h3="g.gene7h3.toString()" :gene8h3="g.gene8h3.toString()" :gene9h3="g.gene9h3.toString()"
                      :gene10h3="g.gene10h3.toString()"
                      :beak="g.gene1p.toString()" :belly="g.gene2p.toString()" :chest="g.gene3p.toString()"
                      :comb="g.gene4p.toString()" :eyes="g.gene5p.toString()" :feet="g.gene6p.toString()"
                      :head="g.gene7p.toString()" :legs="g.gene8p.toString()" :wattle="g.gene9p.toString()"
                      :wings="g.gene10p.toString()">
            </FlipCard>
          </div>
        </div>

        <div v-if="gallinasToShowNowList.length > 0 && $screen.width <= 1280"
             class="row align-items-center justify-content-center neg-margin">
          <div v-for="g in gallinasToShowNowList" :key="g.id"
               class="col-md-12 d-flex align-items-center justify-content-center special-margin">
            <FlipCard style="margin-bottom: 50px;"
                      :name="g.name" :id="g.id"
                      :gender="g.gender" :birthday="g.birthday" :nbd="g.nbd"
                      :motherid="g.motherid" :fatherid="g.fatherid"
                      :price="g.price"
                      :generation="g.generation.toString()"
                      :special="g.special.toString()"
                      :setcount="g.setcount.toString()"
                      :rarity="g.rarity.toString()"
                      :gene1h1="g.gene1h1.toString()" :gene2h1="g.gene2h1.toString()" :gene3h1="g.gene3h1.toString()"
                      :gene4h1="g.gene4h1.toString()" :gene5h1="g.gene5h1.toString()" :gene6h1="g.gene6h1.toString()"
                      :gene7h1="g.gene7h1.toString()" :gene8h1="g.gene8h1.toString()" :gene9h1="g.gene9h1.toString()"
                      :gene10h1="g.gene10h1.toString()"
                      :gene1h2="g.gene1h2.toString()" :gene2h2="g.gene2h2.toString()" :gene3h2="g.gene3h2.toString()"
                      :gene4h2="g.gene4h2.toString()" :gene5h2="g.gene5h2.toString()" :gene6h2="g.gene6h2.toString()"
                      :gene7h2="g.gene7h2.toString()" :gene8h2="g.gene8h2.toString()" :gene9h2="g.gene9h2.toString()"
                      :gene10h2="g.gene10h2.toString()"
                      :gene1h3="g.gene1h3.toString()" :gene2h3="g.gene2h3.toString()" :gene3h3="g.gene3h3.toString()"
                      :gene4h3="g.gene4h3.toString()" :gene5h3="g.gene5h3.toString()" :gene6h3="g.gene6h3.toString()"
                      :gene7h3="g.gene7h3.toString()" :gene8h3="g.gene8h3.toString()" :gene9h3="g.gene9h3.toString()"
                      :gene10h3="g.gene10h3.toString()"
                      :beak="g.gene1p.toString()" :belly="g.gene2p.toString()" :chest="g.gene3p.toString()"
                      :comb="g.gene4p.toString()" :eyes="g.gene5p.toString()" :feet="g.gene6p.toString()"
                      :head="g.gene7p.toString()" :legs="g.gene8p.toString()" :wattle="g.gene9p.toString()"
                      :wings="g.gene10p.toString()">
            </FlipCard>
          </div>
        </div>

        <section v-if="gallinasToShowNowList.length === 0">
          <div class="row align-items-center justify-content-center mt-4">
            <div class="col-md-4 d-flex align-items-center justify-content-center mt-4">
              <img src="../../public/icons/electronic-farm-empty.svg"
                   alt="Oh no, it looks like you dont own any Gallinas!" width="700px"/>
            </div>
          </div>

          <div class="row align-items-center justify-content-center mt-4" style="margin-bottom: 50px;">
            <div class="col-md-10 d-flex align-items-center justify-content-center mt-4 stat-column">
              <span class="font3d">Oh no! It looks like you don't have any Gallinas yet..</span>
            </div>
          </div>

        </section>

        <div v-if="this.getUserTotalGallinas() > 3"
             class="row paginate-row justify-content-center align-items-center mt-4">
          <div class="col-sm-12 d-flex justify-content-center align-items-center">
            <ul class="pagination link-menu">
              <li class="page-item">
                <button type="button" class="btnbreed" v-if="page != 1" @click="page--; clickShowPage(page);">
                  Previous
                </button>
              </li>
              <li class="page-item">
                <button type="button" class="btnbreed" v-for="pageNumber in pages.slice(page-1, page+5)"
                        :key="pageNumber" @click="page = pageNumber;  clickShowPage(page); "> {{ pageNumber }}
                </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++; clickShowPage(page);" v-if="page < pages.length" class="btnbreed">
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div class="mt-4"><br/></div>

        <div v-if="$screen.width >= 992 && this.userSearched === false"
             class="row mt-4 frontfooter justify-content-lg-evenly">
          <div class="col-12">
            <div class="modal-box">
              <div class="mdialog" role="document">
                <div class="modal-content clearfix">
                  <div class="modal-body" style="padding:20px;">
                    <h3 class="title" style="position:relative; right:0px; font-size: 20px;"><img
                        class="flip-horizontally mx-3"
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px"><span
                        class="discordcolor">Come chat with fellow Gallina Breeders on</span> <a
                        href="https://discord.gg/RXYxy2mRM5" target="_blank">
                      <button type="button" class="mx-3 btnsearch" style="position:relative; top: -2px; outline:none;">
                        <svg width="124" height="34" viewBox="0 0 124 34" class="logo-TA52YN bgdiscord">
                          <g fill="currentColor">
                            <path
                                d="M26.0015 6.9529C24.0021 6.03845 21.8787 5.37198 19.6623 5C19.3833 5.48048 19.0733 6.13144 18.8563 6.64292C16.4989 6.30193 14.1585 6.30193 11.8336 6.64292C11.6166 6.13144 11.2911 5.48048 11.0276 5C8.79575 5.37198 6.67235 6.03845 4.6869 6.9529C0.672601 12.8736 -0.41235 18.6548 0.130124 24.3585C2.79599 26.2959 5.36889 27.4739 7.89682 28.2489C8.51679 27.4119 9.07477 26.5129 9.55525 25.5675C8.64079 25.2265 7.77283 24.808 6.93587 24.312C7.15286 24.1571 7.36986 23.9866 7.57135 23.8161C12.6241 26.1255 18.0969 26.1255 23.0876 23.8161C23.3046 23.9866 23.5061 24.1571 23.7231 24.312C22.8861 24.808 22.0182 25.2265 21.1037 25.5675C21.5842 26.5129 22.1422 27.4119 22.7621 28.2489C25.2885 27.4739 27.8769 26.2959 30.5288 24.3585C31.1952 17.7559 29.4733 12.0212 26.0015 6.9529ZM10.2527 20.8402C8.73376 20.8402 7.49382 19.4608 7.49382 17.7714C7.49382 16.082 8.70276 14.7025 10.2527 14.7025C11.7871 14.7025 13.0425 16.082 13.0115 17.7714C13.0115 19.4608 11.7871 20.8402 10.2527 20.8402ZM20.4373 20.8402C18.9183 20.8402 17.6768 19.4608 17.6768 17.7714C17.6768 16.082 18.8873 14.7025 20.4373 14.7025C21.9717 14.7025 23.2271 16.082 23.1961 17.7714C23.1961 19.4608 21.9872 20.8402 20.4373 20.8402Z"></path>
                            <path
                                d="M41.2697 9.86615H47.8585C49.4394 9.86615 50.7878 10.1141 51.8883 10.6101C52.9887 11.1061 53.8102 11.7881 54.3527 12.6715C54.8951 13.555 55.1741 14.5624 55.1741 15.7094C55.1741 16.8253 54.8952 17.8328 54.3217 18.7472C53.7482 19.6462 52.8803 20.3746 51.7178 20.9016C50.5554 21.4286 49.1139 21.6921 47.3935 21.6921H41.2697V9.86615ZM47.316 18.6852C48.3854 18.6852 49.2069 18.4217 49.7804 17.8793C50.3539 17.3523 50.6484 16.6083 50.6484 15.6939C50.6484 14.8414 50.3849 14.1594 49.8734 13.648C49.3619 13.1365 48.587 12.873 47.5485 12.873H45.4871V18.6852H47.316Z"></path>
                            <path
                                d="M65.4362 21.6774C64.5217 21.4449 63.7003 21.1039 62.9718 20.6389V17.8335C63.5298 18.2675 64.2582 18.6085 65.1882 18.8875C66.1181 19.1665 67.0171 19.306 67.8851 19.306C68.288 19.306 68.598 19.2595 68.7995 19.151C69.001 19.0425 69.1095 18.9185 69.1095 18.7635C69.1095 18.593 69.0475 18.4535 68.939 18.345C68.8305 18.2365 68.6135 18.1435 68.288 18.0505L66.2576 17.6011C65.0952 17.3376 64.2737 16.9501 63.7777 16.4851C63.2818 16.0201 63.0493 15.3847 63.0493 14.6097C63.0493 13.9587 63.2663 13.3853 63.6847 12.9048C64.1187 12.4243 64.7232 12.0523 65.5137 11.7888C66.3041 11.5254 67.2186 11.3859 68.288 11.3859C69.2335 11.3859 70.1014 11.4789 70.8919 11.6959C71.6823 11.8973 72.3333 12.1608 72.8448 12.4708V15.1212C72.3178 14.8112 71.6979 14.5632 71.0159 14.3772C70.3184 14.1912 69.6055 14.0982 68.877 14.0982C67.823 14.0982 67.2961 14.2842 67.2961 14.6407C67.2961 14.8112 67.3736 14.9352 67.5441 15.0282C67.7146 15.1212 68.009 15.1987 68.443 15.2917L70.1324 15.6017C71.2329 15.7876 72.0543 16.1286 72.5968 16.6091C73.1393 17.0896 73.4028 17.787 73.4028 18.7325C73.4028 19.7555 72.9533 20.5769 72.0543 21.1659C71.1554 21.7704 69.8844 22.0648 68.2415 22.0648C67.2806 22.0338 66.3506 21.9098 65.4362 21.6774Z"></path>
                            <path
                                d="M77.5891 21.3213C76.6281 20.8408 75.8842 20.2054 75.4037 19.3994C74.9077 18.5934 74.6752 17.679 74.6752 16.656C74.6752 15.6486 74.9232 14.7341 75.4347 13.9437C75.9462 13.1377 76.6901 12.5177 77.6666 12.0528C78.643 11.6033 79.821 11.3708 81.1849 11.3708C82.8743 11.3708 84.2693 11.7273 85.3852 12.4402V15.5246C84.9977 15.2611 84.5328 15.0286 84.0058 14.8736C83.4788 14.7031 82.9208 14.6256 82.3319 14.6256C81.2779 14.6256 80.472 14.8116 79.8675 15.1991C79.2785 15.5866 78.984 16.0826 78.984 16.7025C78.984 17.307 79.263 17.803 79.852 18.1905C80.4254 18.5779 81.2624 18.7794 82.3474 18.7794C82.9053 18.7794 83.4633 18.7019 84.0058 18.5314C84.5483 18.3609 85.0287 18.175 85.4162 17.927V20.9183C84.1762 21.6623 82.7348 22.0343 81.1074 22.0343C79.728 22.0343 78.5655 21.7863 77.5891 21.3213Z"></path>
                            <path
                                d="M89.8041 21.3213C88.8276 20.8408 88.0837 20.2054 87.5722 19.3839C87.0607 18.5624 86.7972 17.648 86.7972 16.625C86.7972 15.6176 87.0607 14.7031 87.5722 13.9127C88.0837 13.1222 88.8276 12.5022 89.7886 12.0528C90.7495 11.6033 91.9119 11.3708 93.2464 11.3708C94.5794 11.3708 95.7418 11.5878 96.7028 12.0528C97.6637 12.5022 98.4077 13.1222 98.9192 13.9127C99.4306 14.7031 99.6786 15.6021 99.6786 16.625C99.6786 17.6325 99.4306 18.5624 98.9192 19.3839C98.4077 20.2054 97.6792 20.8563 96.7028 21.3213C95.7263 21.7863 94.5794 22.0343 93.2464 22.0343C91.9274 22.0343 90.7805 21.7863 89.8041 21.3213ZM94.9358 18.3299C95.3388 17.927 95.5558 17.369 95.5558 16.7025C95.5558 16.0206 95.3543 15.4936 94.9358 15.0906C94.5174 14.6876 93.9594 14.4861 93.2619 14.4861C92.5335 14.4861 91.9739 14.6876 91.5555 15.0906C91.1525 15.4936 90.9355 16.0206 90.9355 16.7025C90.9355 17.3845 91.137 17.927 91.5555 18.3299C91.9739 18.7484 92.5335 18.9499 93.2619 18.9499C93.9594 18.9344 94.5329 18.7329 94.9358 18.3299Z"></path>
                            <path
                                d="M110.048 11.9901V15.6325C109.614 15.3535 109.056 15.214 108.374 15.214C107.475 15.214 106.777 15.493 106.297 16.0354C105.816 16.5779 105.568 17.4304 105.568 18.5773V21.6772H101.43V11.8196H105.491V14.966C105.708 13.819 106.08 12.9666 106.576 12.4241C107.072 11.8816 107.723 11.5872 108.513 11.5872C109.102 11.5872 109.614 11.7267 110.048 11.9901Z"></path>
                            <path
                                d="M124 9.52563V21.6925H119.862V19.4761C119.505 20.3131 118.978 20.9486 118.265 21.3825C117.551 21.8165 116.667 22.0335 115.613 22.0335C114.683 22.0335 113.862 21.801 113.164 21.3515C112.467 20.9021 111.925 20.2666 111.553 19.4761C111.181 18.6702 110.995 17.7712 110.995 16.7793C110.979 15.7408 111.181 14.8109 111.599 13.9894C112.002 13.168 112.591 12.5325 113.335 12.0675C114.079 11.6025 114.931 11.37 115.892 11.37C117.861 11.37 119.18 12.2225 119.862 13.9429V9.52563H124ZM119.242 18.2517C119.66 17.8487 119.877 17.3062 119.877 16.6553C119.877 16.0198 119.676 15.5083 119.257 15.1209C118.839 14.7334 118.281 14.5319 117.582 14.5319C116.884 14.5319 116.326 14.7334 115.908 15.1364C115.489 15.5393 115.288 16.0508 115.288 16.7018C115.288 17.3527 115.489 17.8642 115.908 18.2672C116.326 18.6702 116.869 18.8717 117.566 18.8717C118.265 18.8717 118.823 18.6702 119.242 18.2517Z"></path>
                            <path
                                d="M58.9885 12.4091C60.1772 12.4091 61.1429 11.5416 61.1429 10.4717C61.1429 9.40164 60.1772 8.5343 58.9885 8.5343C57.7981 8.5343 56.8341 9.40164 56.8341 10.4717C56.8341 11.5416 57.7981 12.4091 58.9885 12.4091Z"></path>
                            <path
                                d="M61.1429 13.741C59.8254 14.3144 58.1825 14.3299 56.8341 13.741V21.6921H61.1429V13.741Z"></path>
                          </g>
                        </svg>
                      </button>
                    </a><img
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px"></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4"><br/></div>


      </div>
    </Scroll-Div>

    <vmodalwaitsignature v-if="showSignTransactionModal" :show-gold-header="false" content-class="darkMode"
                         :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Ok"
                         v-model="showSignTransactionModal" @confirm="showSignTransactionModal = false"
                         @cancel="showSignTransactionModal = false" name="intro" cancel-name="Cancel">

      <template v-slot:title>
        <div class="pt-2">
          Awaiting wallet response
        </div>
      </template>

      <div class="displayGuard">
        <div class="row align-items-center justify-content-center mt-4 mb-0">
          <div class="p-0 border-gold text-center connect-wallet-text alert col-10 mt-4">
            We just sent a request to your wallet.<br/>
            <b>Always review your wallet's transactions.</b> When you're ready, sign, come back and click OK!
          </div>
        </div>
      </div>

    </vmodalwaitsignature>

  </div>
</template>
<script>
import FlipCard from "@/components/FlipCard";
import ScrollDiv from "vue-scroll-div";
import {mapActions, mapGetters, mapState} from "vuex";
import vmodalwaitsignature from "@/components/VModalWaitSignature";


export default {
  name: "MyGallinasPage",
  components: {
    ScrollDiv,
    FlipCard,
    vmodalwaitsignature,
  },
  data() {
    return {
      debug: false,
      showLoadingModal: true,
      posts: [""],
      page: 1,
      perPage: 9,
      pages: [],
      userSearched: false,
      searching: false,
      showWhatIsGallinas: false,
      showWhatIsGallinas2: false,
      userGallinaIdsList: [],
      showSignTransactionModal: false,
      allUserGallinasList: [],
      confirmedUserGallinasList: [],
      confirmedUserGallinasListAll: [],
      lostUserGallinasList: [],
      lostUserGallinasList2: [],
      userGallinasSearchedList: [],
      hasLostGallinas: false,
      hasMissedGallinas: false,
      gallinasToShowNowList: [],
      marketGallinasForSaleList: [],
      specialTableKeysList: [],
      marketGallinasForSaleListgio:[],
      // network: "https://api.chainweb.com/chainweb/0.0/mainnet01/chain/1/pact",
      network: "https://api.chainweb.com/chainweb/0.0/mainnet01/chain/1/pact",
      //network: "https://api.testnet.chainweb.com/chainweb/0.0/testnet04/chain/1/pact",
      gasPrice: 0.000001,
      gallinacanvalue: "Gallinas",
      geneselected: "All",
      genesearchvalue: -1,
      generationvalue: -1,
      gendervalue: 0,
      generationlist: [
        {id: -1, name: "Any", value: "Any"},
        {id: 0, name: "0", value: "0"},
        {id: 1, name: "1", value: "1"},
        {id: 2, name: "2", value: "2"},
        {id: 3, name: "3", value: "3"},
        {id: 4, name: "4", value: "4"},
        {id: 5, name: "5", value: "5"},
        {id: 6, name: "6", value: "6"},
        {id: 7, name: "7", value: "7"},
        {id: 8, name: "8", value: "8"},
        {id: 9, name: "9", value: "9"},
        {id: 10, name: "10", value: "10"},
        {id: 11, name: "11", value: "11"},
        {id: 12, name: "12", value: "12"},
        {id: 13, name: "13", value: "13"},
        {id: 14, name: "14", value: "14"},
        {id: 15, name: "15", value: "15"},
        {id: 16, name: "16", value: "16"},
        {id: 17, name: "17", value: "17"},
        {id: 18, name: "18", value: "18"},
        {id: 19, name: "19", value: "19"},
        {id: 20, name: "20", value: "20"},
        {id: 21, name: "21", value: "21"},
        {id: 22, name: "22", value: "22"},
        {id: 23, name: "23", value: "23"},
        {id: 24, name: "24", value: "24"},
        {id: 25, name: "25", value: "25"},
        {id: 26, name: "26", value: "26"},
        {id: 27, name: "27", value: "27"},
        {id: 28, name: "28", value: "28"},
        {id: 29, name: "29", value: "29"},
        {id: 30, name: "30", value: "30"},
      ],
      genderlist: [
        {id: 0, name: "Any", value: "All"},
        {id: 1, name: "Male", value: "male"},
        {id: 2, name: "Female", value: "female"},
      ],
      gallinacanlist: [
        {id: 0, name: "Gallinas", value: "All"},
        {id: 1, name: "Breedable Gallinas", value: "breedable"},
      ],
      genelist: [
        {id: 0, name: "Any", value: "All"},
        {id: 1, name: "1) Beak", value: "gene_1_p"},
        {id: 2, name: "2) Belly", value: "gene_2_p"},
        {id: 3, name: "3) Chest", value: "gene_3_p"},
        {id: 4, name: "4) Comb", value: "gene_4_p"},
        {id: 5, name: "5) Eyes", value: "gene_5_p"},
        {id: 6, name: "6) Feet", value: "gene_6_p"},
        {id: 7, name: "7) Head", value: "gene_7_p"},
        {id: 8, name: "8) Legs", value: "gene_8_p"},
        {id: 9, name: "9) Wattle", value: "gene_9_p"},
        {id: 10, name: "10) Wings", value: "gene_10_p"},
      ],
      genevallist: [
        {id: -1, name: "Any"},
        {id: 0, name: "0"},
        {id: 1, name: "1"},
        {id: 2, name: "2"},
        {id: 3, name: "3"},
        {id: 4, name: "4"},
        {id: 5, name: "5"},
        {id: 6, name: "6"},
        {id: 7, name: "7"},
        {id: 8, name: "8"},
        {id: 9, name: "9"},
        {id: 10, name: "10"},
        {id: 11, name: "11"},
        {id: 12, name: "12"},
        {id: 13, name: "13"},
        {id: 14, name: "14"},
        {id: 15, name: "15"},
        {id: 16, name: "16"},
        {id: 17, name: "17"},
        {id: 18, name: "18"},
        {id: 19, name: "19"},
        {id: 20, name: "20"},
      ],
    };
  },
  watch: {
    gallinasToShowNowList() {
      this.setPages();
    },
    transactionPolling: function (newValue, oldValue) { //transactionpending indicates we should start a timer for 2 minutes
      if(this.debug){
        console.log("MyGallinasPage getTransactionPending change detected");
        console.log("MyGallinasPage getTransactionPending newValue: " + newValue);
        console.log("MyGallinasPage getTransactionPending oldValue: " + oldValue);
      }
      if(newValue === false){
        this.rescanAll();
      }
    },
  },
  computed: {
    ...mapState("accounts", ["transactionPolling"]),


  },
  methods: {
    ...mapActions("accounts", ["getAllUserGallinas", "getUserAllGallinasDetails", "getUserGallinaCount", "bindGallinaRarity", "bindGallinaSetCount", "bindDoGallinaSearch", "bindMarketGallinas","bindCheckPending"]),
    ...mapGetters("accounts", ["getAccountExists", "getAccountName", "getAccountConfirmed", "getUserTotalGallinas", "getUserTotalEggs", "getUserTotalGiftVouchers", "getUserGallinasList", "getUserGallinaIds", "getMarketGallinasForSale", "getKeysSpecialTable"]),


    async rescanAll(){

      this.showWhatIsGallinas = false;
      this.showWhatIsGallinas2 = false;
      //Confirm account
      let name = localStorage.getItem("accountName");

      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      this.confirmedUserGallinasList= [];
      this.confirmedUserGallinasListAll= [];
      this.lostUserGallinasList= [];
      this.lostUserGallinasList2= [];


      if(this.debug) {
        console.log("Getting user information..");
      }
      //Get number of Eggs from blockchain this user owns, if any
      await this.$store.dispatch("accounts/getAllUserEggs", name);

      //Now we get the complete list of IDs of gallinas this user owns from blockchain, and the total count of gallinas they own
      await this.$store.dispatch("accounts/getUserGallinaCount", name);

      await this.$store.dispatch("accounts/getAllUserGiftVouchers", name);

      //Get the list of keys in the special table to check for remints
      await this.$store.dispatch("accounts/getKeysSpecialTable");

      this.specialTableKeysList = this.getKeysSpecialTable();

      if(this.debug === true) {
        console.log("KEYS SPECIAL TABLE");
        console.log(this.specialTableKeysList);
      }

      //Next we get the complete list of Gallinas that are for sale from blockchain for confirmation purposes
      await this.$store.dispatch("accounts/getGallinasForSale");
      this.marketGallinasForSaleList = this.getMarketGallinasForSale();

      //Next we get complete list of Gallinas that are for sale from gallinasio to list prices
      this.marketGallinasForSaleListgio = await this.bindMarketGallinas();

      let gallinaIdsList = await this.getUserGallinaIds();
      this.userGallinaIdsList = gallinaIdsList;

      if(this.debug === true) {
        console.log("userGallinaIds list:");
        console.log(this.userGallinaIdsList);

        console.log("marketGallinasForSaleList list:");
        console.log(this.marketGallinasForSaleList);
      }

      //Now that we have the users gallinas from the blockchain, lets get the users gallinas from the DB, and make sure they match
      //Lets start by getting all this users gallinas from the DB
      await this.$store.dispatch("accounts/bindUserInfoNew", name);

      let allUserGallinas = this.getUserGallinasList();
      this.allUserGallinasList = allUserGallinas;

      if(this.debug === true) {
        console.log("allUserGallinas list:");
        console.log(this.allUserGallinasList);

        console.log("userGallinaIdsList length");
        console.log(this.userGallinaIdsList.length);

        console.log("allUserGallinasList length");
        console.log(this.allUserGallinasList.length);
      }

      if (this.userGallinaIdsList.length > 0) {

        for (let k = 0; k < this.userGallinaIdsList.length; k++) {

          if (this.allUserGallinasList.length > 0) {

            //For each id in the list of ids from the blockchain, we want to compare it to the ids in the list from the db
            for (let j = 0; j < this.allUserGallinasList.length; j++) {

              if(this.debug === true) {
                console.log("GALLINA ID 1");
                console.log(this.userGallinaIdsList[k].id);

                console.log("GALLINA ID 2");
                console.log(this.allUserGallinasList[j].gid);
              }

              if (this.userGallinaIdsList[k].id === this.allUserGallinasList[j].gid && this.userGallinaIdsList[k].id !== "Egg") {
                //The gallina ID from the blockchain matches the ID in the users gallinas list from the DB

                //Now we should add each match to a separate list, that will be shown on the my gallinas page.
                if (this.confirmedUserGallinasList.includes(this.userGallinaIdsList[k].id) === false) {
                  this.confirmedUserGallinasList.push(this.userGallinaIdsList[k].id);
                  this.confirmedUserGallinasListAll.push(this.allUserGallinasList[j]);
                }

              }

            }

          }

        }

        //We now have a list of gallinas that can be shown to the user
        if(this.debug === true) {
          console.log("CONFIRMED GALLINAS LIST");
          console.log(this.confirmedUserGallinasList);
        }

        if (this.userGallinaIdsList.length > 0) {
          //Lets now handle the Gallinas that werent in the database, these gallinas are added to a separate list so users can remint these gallinas
          for (let n = 0; n < this.userGallinaIdsList.length; n++) {


            if (this.userGallinaIdsList[n].id !== "Egg" && this.confirmedUserGallinasList.includes(this.userGallinaIdsList[n].id) === false && this.lostUserGallinasList.includes(this.userGallinaIdsList[n]) === false && this.lostUserGallinasList2.includes(this.userGallinaIdsList[n]) === false && this.specialTableKeysList.includes(this.userGallinaIdsList[n].id) === false) {
              this.hasLostGallinas = true;
              this.lostUserGallinasList.push(this.userGallinaIdsList[n]);
            }

            if (this.userGallinaIdsList[n].id !== "Egg" && this.confirmedUserGallinasList.includes(this.userGallinaIdsList[n].id) === false  && this.lostUserGallinasList.includes(this.userGallinaIdsList[n]) === false && this.lostUserGallinasList2.includes(this.userGallinaIdsList[n]) === false && this.specialTableKeysList.includes(this.userGallinaIdsList[n].id) === true) {
              this.hasMissedGallinas = true;
              this.lostUserGallinasList2.push(this.userGallinaIdsList[n]);
            }


          }
        }

        //We now have a list of gallinas that are not shown and need to be reminted
        if(this.debug === true) {
          console.log("LOST GALLINAS LIST");
          console.log(this.lostUserGallinasList);
        }

      }


      await this.getGallinasToShow(0, this.perPage);

    },

    async clickResolve(gallinaid) {

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }


      await this.bindCheckPending(name).then(async(response)=>{

        if(this.debug) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }

        if(response !== undefined && response[0] !== undefined && response[0].length === 0 || response !== undefined && response === "0" || response !== undefined && response === 0) {
          this.showSignTransactionModal = true;
          if (this.debug) {
            console.log(this.seller);
          }

          let theAccountName = localStorage.getItem("accountName");


          let payload = {
            accountName: theAccountName,
            gallinaId: gallinaid,
          };

          if(this.debug) {
            console.log("RESOLVE PAYLOAD");
            console.log(payload);
          }


          this.showTransactionModal = true;
          await this.$store.dispatch("accounts/resolveGallina", payload);
        }else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.")
        }
      });

    },

    async clickRemint(gallinaid) {

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }


      await this.bindCheckPending(name).then(async(response)=>{

            if(this.debug) {
              console.log("PENDING TX CHECK RESPONSE");
              console.log(response);
            }

        if(response !== undefined && response[0] !== undefined && response[0].length === 0 || response !== undefined && response === "0" || response !== undefined && response === 0) {
          this.showSignTransactionModal = true;
          if (this.debug) {
            console.log(this.seller);
          }

          let theAccountName = localStorage.getItem("accountName");


          let payload = {
            accountName: theAccountName,
            gallinaId: gallinaid,
          };

          if(this.debug) {
            console.log("REMINT PAYLOAD");
            console.log(payload);
          }


          this.showTransactionModal = true;
          await this.$store.dispatch("accounts/remintGallina", payload);
        }else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.")
        }
      });

    },

    async sortGallinas(breedable, gene, geneval, generationval, gender) {

      let name = localStorage.getItem("accountName");

      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      if (this.debug) {
        console.log("inside sorting gallinas");
        console.log("gallinacan:");
        console.log(breedable);
        console.log("generationval:");
        console.log(generationval);
        console.log("gene:");
        console.log(gene);
        console.log("geneval:");
        console.log(geneval);
        console.log("gender");
        console.log(gender);

      }

      this.userGallinasSearchedList = [];

      let searchPayload = {
        gallinacan: breedable,
        generation: generationval,
        gene: gene,
        geneval: geneval,
        address: name
      };

      let doSearch = await this.bindDoGallinaSearch(searchPayload);

      if(this.debug) {
        console.log("DO SEARCH RESULTS");
        console.log(doSearch);
      }

      if (doSearch !== undefined) {

        if (doSearch.length !== undefined) {


          if (breedable === 1) {

            for (let i = 0; i < doSearch.length; i++) {

              if (doSearch[i]["next_breed_time"]) {

                let nextbreeddate = null;

                nextbreeddate = new Date(doSearch[i]["next_breed_time"]).getTime();

                if(this.debug) {
                  console.log("ACTUAL TIME");
                  console.log(doSearch[i]["next_breed_time"]);
                }

                if (this.debug) {
                  console.log("nextbreeddate:");
                  console.log(nextbreeddate);
                }

                let currentdate = Date.now() - 7200000;

                if (this.debug) {
                  console.log("currentdate:");
                  console.log(currentdate);
                  console.log(currentdate > nextbreeddate);
                }

                if (currentdate > nextbreeddate) {

                  if (this.debug) {
                    console.log("This gallina can breed, adding gallina to list");
                  }


                  //Lets quickly comb through for gender matches if the user is searching for those
                  if(gender === 0 || gender === 1 && doSearch[i]["gender"] === "Male" || gender === 2 && doSearch[i]["gender"] === "Female" ){
                    //we found the gallina that is breedable and fits gender search criterea:
                    if (!this.userGallinasSearchedList.includes(doSearch[i]) && this.confirmedUserGallinasList.includes(doSearch[i].gid)) {
                      this.userGallinasSearchedList.push(doSearch[i]);
                    }
                  }




                } else {
                  if (this.debug) {
                    console.log("This gallina cannot breed yet.. not needed..");
                  }
                }


              }
            }

          } else {

            for (let i = 0; i < doSearch.length; i++) {
              if (!this.userGallinasSearchedList.includes(doSearch[i]) && this.confirmedUserGallinasList.includes(doSearch[i].gid)) {

                //Lets comb through for gender criteria and add the gallina
                if(gender === 0 || gender === 1 && doSearch[i]["gender"] === "Male" || gender === 2 && doSearch[i]["gender"] === "Female" ){
                  this.userGallinasSearchedList.push(doSearch[i]);
                }


              }
            }
          }
        }
      }


      await this.getGallinasToShow(0, this.perPage);


    },

    async sortAllBreedableGallinas() {


      if (this.debug) {
        console.log("sorting gallinas that are all breedable");
      }

      this.userGallinasSearchedList = [];

      for (let i = 0; i < this.userGallinaIdsList.length; i++) {


        if (this.userGallinaIdsList[i]["next-breed-time"]) {

          let nextbreeddate = null;

          if (this.userGallinaIdsList[i]["next-breed-time"].timep !== undefined) {
            if (this.debug) {
              console.log("TIMEP next-breed-time:");
              console.log(this.userGallinaIdsList[i]["next-breed-time"].timep);
            }
            nextbreeddate = new Date(this.userGallinaIdsList[i]["next-breed-time"].timep).getTime();
          }

          if (this.userGallinaIdsList[i]["next-breed-time"].time !== undefined) {
            if (this.debug) {
              console.log("TIME next-breed-time:");
              console.log(this.userGallinaIdsList[i]["next-breed-time"].time);
            }
            nextbreeddate = new Date(this.userGallinaIdsList[i]["next-breed-time"].time).getTime();
          }

          //let nextbreeddate = new Date(this.userGallinaIdsList[i]["next-breed-time"].timep).getTime();

          if (this.debug) {
            console.log("nextbreeddate:");
            console.log(nextbreeddate);
          }

          let currentdate = new Date().getTime();

          if (this.debug) {
            console.log("currentdate:");
            console.log(currentdate);
            console.log(currentdate > nextbreeddate);
          }

          if (currentdate > nextbreeddate) {

            if (this.debug) {
              console.log("This gallina can breed");
            }

            this.userGallinasSearchedList.push(this.userGallinaIdsList[i]);


          } else {
            if (this.debug) {
              console.log("This gallina cannot breed yet");
            }
          }


        } else {
          if (this.debug) {
            console.log("not same");
          }
        }


      }
      await this.getGallinasToShow(0, this.perPage);


    },

    async clickSearch() {
      //getGallinasForSaleByGene
      let payload = {
        gene: this.geneselected,
        geneval: this.genesearchvalue,
        gallinacan: this.gallinacanvalue,
        generationval: this.generationvalue,
        gender: this.gendervalue
      };

      if (this.debug) {
        console.log("payload");
        console.log(payload);
        console.log(payload.gene);
        console.log(payload.geneval);
        console.log(payload.gallinacan);
        console.log(payload.gender)
      }

      if (payload.gene === "All" && payload.geneval === -1 && payload.gallinacan === "Gallinas" && payload.generationval === -1 && payload.gender === 0|| payload.gene === "All" && payload.geneval === -1 && payload.gallinacan === 0 && payload.generationval === -1 && payload.gender === 0 || payload.gene !== "All" && payload.geneval === -1 && payload.gallinacan === 0 && payload.generationval === -1 && payload.gender === 0 || payload.gene !== "All" && payload.geneval === -1 && payload.gallinacan === "Gallinas" && payload.generationval === -1 && payload.gender === 0) {

        this.searching = true;
        if (this.debug) {
          console.log("SEARCHING FOR ALL GALLINAS NO FILTERS");
        }


        this.userSearched = false;
        await this.getAllUserGallinaIds();

        await this.getGallinasToShow(0, this.perPage);

        this.searching = false;
      } else {

        this.searching = true;

        if (this.debug) {
          console.log("SEARCHING FOR GALLINAS WITH FILTERS");
        }


        this.userSearched = true;

        await this.sortGallinas(payload.gallinacan, payload.gene, payload.geneval, payload.generationval, payload.gender);

        this.searching = false;

      }


    },

    valuesgeneselected(row) {

      if (this.debug) {
        console.log(JSON.stringify(row));
        console.log(row);
        console.log(row.id);
        console.log(row.value);
      }

      this.geneselected = row.value;


    },

    valuesgenevalue(row) {

      if (this.debug) {
        console.log(JSON.stringify(row));
        console.log(row);
        console.log(row.id);
        console.log(row.value);
      }

      this.genesearchvalue = row.id;
    },

    valuesgallinacanvalue(row) {

      if (this.debug) {
        console.log(JSON.stringify(row));
        console.log(row);
        console.log(row.id);
        console.log(row.value);
      }

      this.gallinacanvalue = row.id;
    },

    valuesgallinagenerationvalue(row) {

      if (this.debug) {
        console.log(JSON.stringify(row));
        console.log(row);
        console.log(row.id);
        console.log(row.value);
      }

      this.generationvalue = row.id;
    },

    valuesgallinagendervalue(row) {

      if (this.debug) {
        console.log(JSON.stringify(row));
        console.log(row);
        console.log(row.id);
        console.log(row.value);
      }

      this.gendervalue = row.id;
    },

    async clickShowPage(pagenumber) {

      let newfrom = (pagenumber * this.perPage) - this.perPage; //0 9
      let newto = (this.page * this.perPage); //9 18


      if (newfrom < 0) {
        newfrom = 0;
      }

      if (this.debug) {
        console.log("newfrom: " + newfrom);
        console.log("newTo: " + newto);
      }
      this.showLoadingModal = true;
      await this.getGallinasToShow(newfrom, newto);
      this.showLoadingModal = false;

    },
    setPages() {


      let gallinaIdsList = this.confirmedUserGallinasListAll;
      if (this.userSearched === true) {
        gallinaIdsList = this.userGallinasSearchedList;
      } else {
        gallinaIdsList = this.confirmedUserGallinasListAll;
      }

      let numberOfPages = Math.ceil(gallinaIdsList.length / this.perPage);

      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
      if (this.debug) {
        console.log("pages");
        console.log(this.pages);
      }
    },

    async getGallinasToShow(from, to) {


      if(this.debug) {
        console.log("Getting Gallinas to show for this page");
        console.log("From: " + from + " and To: " + to);
      }


      let gallinaIdsList = this.confirmedUserGallinasListAll;
      if (this.userSearched === true) {
        gallinaIdsList = this.userGallinasSearchedList;
      } else {
        gallinaIdsList = this.confirmedUserGallinasListAll;
      }

      let gallinaDetailsList = [];

      this.marketGallinasForSaleListgio = await this.bindMarketGallinas();

      if(this.debug) {
        console.log("MARKETLISTFORSALE:");
        console.log(this.marketGallinasForSaleList);

        console.log("MARKETLISTFORSALE GIO:");
        console.log(this.marketGallinasForSaleListgio);
      }


      for (let i = from; i < to; i++) {

        let forsale = false;
        let price = 0;
        let seller = null;


        if(this.debug) {
          console.log("Loop");
          console.log(i);
          console.log("Checking to see if " + i + " is less than or equal to " + gallinaIdsList.length);
        }


        if (i <= gallinaIdsList.length - 1) {


          if(this.debug) {
            console.log("i:");
            console.log(i);
            console.log("gallinaIdsListi.gid");
            console.log(gallinaIdsList[i].gid);
          }


          if (i <= gallinaIdsList.length - 1) {


            if(this.debug) {
              console.log("current gallina details:");
              console.log(gallinaIdsList[i]);
            }


            for (let k = 0; k < this.marketGallinasForSaleListgio.length; k++) {

              if(this.debug){
                console.log("COMPARING DETAILS TO MARKET DETAILS:");
                console.log(this.marketGallinasForSaleListgio[k].gid);
                console.log(gallinaIdsList[i].gid);
              }


              if (this.marketGallinasForSaleListgio[k].gid === gallinaIdsList[i].gid) {
                forsale = true;
                price = this.marketGallinasForSaleListgio[k].price;
                seller = this.marketGallinasForSaleListgio[k].owner;
              }
            }

            let rarity = [];

            let rarityPayload = {
              dna: gallinaIdsList[i]["dna"],
              special: gallinaIdsList[i]["special"]
            };

            await this.bindGallinaRarity(rarityPayload).then((response) => {
              if(this.debug) {
                console.log("GALLINA 1 RARITY RESPONSE");
                console.log(response);
              }
              rarity = response;
            });

            await this.bindGallinaSetCount().then((response) => {

              let set = "col" + gallinaIdsList[i]["special"].toString();

              if(this.debug) {
                console.log("SET");
                console.log(set);
                console.log("SETCOUNT");
                console.log(response);

                console.log("RARITY");
                console.log(rarity);

                console.log("RARITY LENGTH");
                console.log(rarity.length);
              }


              let gallinaDetailsObject = {
                name: gallinaIdsList[i].name,
                id: gallinaIdsList[i].gid,
                account: gallinaIdsList[i].owner,
                gender: gallinaIdsList[i].gender,
                motherid: gallinaIdsList[i]["mother_id"],
                fatherid: gallinaIdsList[i]["father_id"],
                birthday: gallinaIdsList[i]["birthday"],
                nbd: gallinaIdsList[i]["next_breed_time"],
                generation: gallinaIdsList[i]["generation"],
                special: gallinaIdsList[i]["special"],
                setcount: response[0][set],
                rarity: rarity.length,
                gene1p: gallinaIdsList[i]["gene_1_p"],
                gene2p: gallinaIdsList[i]["gene_2_p"],
                gene3p: gallinaIdsList[i]["gene_3_p"],
                gene4p: gallinaIdsList[i]["gene_4_p"],
                gene5p: gallinaIdsList[i]["gene_5_p"],
                gene6p: gallinaIdsList[i]["gene_6_p"],
                gene7p: gallinaIdsList[i]["gene_7_p"],
                gene8p: gallinaIdsList[i]["gene_8_p"],
                gene9p: gallinaIdsList[i]["gene_9_p"],
                gene10p: gallinaIdsList[i]["gene_10_p"],
                gene1h1: gallinaIdsList[i]["gene_1_h1"],
                gene2h1: gallinaIdsList[i]["gene_2_h1"],
                gene3h1: gallinaIdsList[i]["gene_3_h1"],
                gene4h1: gallinaIdsList[i]["gene_4_h1"],
                gene5h1: gallinaIdsList[i]["gene_5_h1"],
                gene6h1: gallinaIdsList[i]["gene_6_h1"],
                gene7h1: gallinaIdsList[i]["gene_7_h1"],
                gene8h1: gallinaIdsList[i]["gene_8_h1"],
                gene9h1: gallinaIdsList[i]["gene_9_h1"],
                gene10h1: gallinaIdsList[i]["gene_10_h1"],
                gene1h2: gallinaIdsList[i]["gene_1_h2"],
                gene2h2: gallinaIdsList[i]["gene_2_h2"],
                gene3h2: gallinaIdsList[i]["gene_3_h2"],
                gene4h2: gallinaIdsList[i]["gene_4_h2"],
                gene5h2: gallinaIdsList[i]["gene_5_h2"],
                gene6h2: gallinaIdsList[i]["gene_6_h2"],
                gene7h2: gallinaIdsList[i]["gene_7_h2"],
                gene8h2: gallinaIdsList[i]["gene_8_h2"],
                gene9h2: gallinaIdsList[i]["gene_9_h2"],
                gene10h2: gallinaIdsList[i]["gene_10_h2"],
                gene1h3: gallinaIdsList[i]["gene_1_h3"],
                gene2h3: gallinaIdsList[i]["gene_2_h3"],
                gene3h3: gallinaIdsList[i]["gene_3_h3"],
                gene4h3: gallinaIdsList[i]["gene_4_h3"],
                gene5h3: gallinaIdsList[i]["gene_5_h3"],
                gene6h3: gallinaIdsList[i]["gene_6_h3"],
                gene7h3: gallinaIdsList[i]["gene_7_h3"],
                gene8h3: gallinaIdsList[i]["gene_8_h3"],
                gene9h3: gallinaIdsList[i]["gene_9_h3"],
                gene10h3: gallinaIdsList[i]["gene_10_h3"],
                isforsale: forsale,
                price: price,
                seller: seller,
              };

              if(this.debug) {
                console.log("SPECIAL");
                console.log(gallinaIdsList[i]["special"]);
                console.log("SPECIAL TYPOF");
                console.log(typeof (gallinaIdsList[i]["special"]));
                console.log("ISFORSALE");
                console.log(forsale);
                console.log("PRICE");
                console.log(price);
                console.log("SELLER");
                console.log(seller);
                console.log("gallinaDetailsObject");
                console.log(gallinaDetailsObject);
              }


              gallinaDetailsList.push(gallinaDetailsObject);
            }).catch((err) => {

              if(this.debug) {
                console.log("Get gallina info error");
                console.log(err);
              }

            });

          } else {
            if (this.debug) {
              if(this.debug) {
                console.log("Error 22: Could not get a Gallinas Details.");
              }
            }

          }


        }

      }


      if(this.debug) {
        console.log("GallinaListToShowThisPage:");
        console.log(gallinaDetailsList);
      }

      this.gallinasToShowNowList = gallinaDetailsList;


    },

    async getAllUserGallinaIds() {
      //Confirm account
      let name = localStorage.getItem("accountName");

      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }
      //Here we run the same sequence as when the user comes ot the page for the first time, and get ALL Gallinas the user owns

      //Now we get the complete list of IDs of gallinas this user owns from blockchain, and the total count of gallinas they own
      await this.$store.dispatch("accounts/getUserGallinaCount", name);

      //Next we get the complete list of Gallinas that are for sale from the blockchain and gio, so we can show their price if needed
      //We may change this to go with a database instead! But for now, lets do it this way..
      await this.$store.dispatch("accounts/getGallinasForSale");
      this.marketGallinasForSaleList = this.getMarketGallinasForSale();
      this.marketGallinasForSaleListgio = await this.bindMarketGallinas();

      //Get the list of keys in the special table to check for remints
      await this.$store.dispatch("accounts/getKeysSpecialTable");

      this.specialTableKeysList = this.getKeysSpecialTable();

      let gallinaIdsList = await this.getUserGallinaIds();
      this.userGallinaIdsList = gallinaIdsList;

      if(this.debug === true) {
        console.log("userGallinaIds list:");
        console.log(this.userGallinaIdsList);

        console.log("marketGallinasForSaleList list:");
        console.log(this.marketGallinasForSaleList);

        console.log("marketGallinasForSaleListgio list");
        console.log(this.marketGallinasForSaleListgio);
      }

      //Now that we have the users gallinas from the blockchain, lets get the users gallinas from the DB, and make sure they match
      //Lets start by getting all this users gallinas from the DB
      await this.$store.dispatch("accounts/bindUserInfoNew", name);

      let allUserGallinas = this.getUserGallinasList();
      this.allUserGallinasList = allUserGallinas;

      if(this.debug === true) {
        console.log("allUserGallinas list:");
        console.log(this.allUserGallinasList);

        console.log("userGallinaIdsList length");
        console.log(this.userGallinaIdsList.length);

        console.log("allUserGallinasList length");
        console.log(this.allUserGallinasList.length);
      }

      if (this.userGallinaIdsList.length > 0) {

        for (let k = 0; k < this.userGallinaIdsList.length; k++) {

          if (this.allUserGallinasList.length > 0) {

            //For each id in the list of ids from the blockchain, we want to compare it to the ids in the list from the db
            for (let j = 0; j < this.allUserGallinasList.length; j++) {

              if(this.debug === true) {
                console.log("GALLINA ID 1");
                console.log(this.userGallinaIdsList[k].id);

                console.log("GALLINA ID 2");
                console.log(this.allUserGallinasList[j].gid);
              }

              if (this.userGallinaIdsList[k].id === this.allUserGallinasList[j].gid) {
                //The gallina ID from the blockchain matches the ID in the users gallinas list from the DB

                //Now we should add each match to a separate list, that will be shown on the my gallinas page.
                if (this.confirmedUserGallinasList.includes(this.userGallinaIdsList[k].id) === false) {
                  this.confirmedUserGallinasList.push(this.userGallinaIdsList[k].id);
                  this.confirmedUserGallinasListAll.push(this.allUserGallinasList[j]);
                }

              }

            }

          }

        }

        //We now have a list of gallinas that can be shown to the user
        if(this.debug === true) {
          console.log("CONFIRMED GALLINAS LIST");
          console.log(this.confirmedUserGallinasList);
        }

      }
    },


  },

  async created() {

    //Confirm account
    let name = localStorage.getItem("accountName");

    if (name === null || name === "") {
      await this.$router.push({path: `/`});
    }

    if(this.debug) {
      console.log("Getting user information..");
    }
    //Get number of Eggs from blockchain this user owns, if any
    await this.$store.dispatch("accounts/getAllUserEggs", name);

    //Now we get the complete list of IDs of gallinas this user owns from blockchain, and the total count of gallinas they own
    await this.$store.dispatch("accounts/getUserGallinaCount", name);

    await this.$store.dispatch("accounts/getAllUserGiftVouchers", name);

    //Get the list of keys in the special table to check for remints
    await this.$store.dispatch("accounts/getKeysSpecialTable");

    this.specialTableKeysList = this.getKeysSpecialTable();

    if(this.debug === true) {
      console.log("KEYS SPECIAL TABLE");
      console.log(this.specialTableKeysList);
    }

    //Next we get the complete list of Gallinas that are for sale from blockchain for confirmation purposes
    await this.$store.dispatch("accounts/getGallinasForSale");
    this.marketGallinasForSaleList = this.getMarketGallinasForSale();

    //Next we get complete list of Gallinas that are for sale from gallinasio to list prices
    this.marketGallinasForSaleListgio = await this.bindMarketGallinas();

    let gallinaIdsList = await this.getUserGallinaIds();
    this.userGallinaIdsList = gallinaIdsList;

    if(this.debug === true) {
      console.log("userGallinaIds list:");
      console.log(this.userGallinaIdsList);

      console.log("marketGallinasForSaleList list:");
      console.log(this.marketGallinasForSaleList);
    }

    //Now that we have the users gallinas from the blockchain, lets get the users gallinas from the DB, and make sure they match
    //Lets start by getting all this users gallinas from the DB
    await this.$store.dispatch("accounts/bindUserInfoNew", name);

    let allUserGallinas = this.getUserGallinasList();
    this.allUserGallinasList = allUserGallinas;

    if(this.debug === true) {
      //db ids
      console.log("allUserGallinas list:");
      console.log(this.allUserGallinasList);

      //blockchain ids
      console.log("userGallinaIdsList length");
      console.log(this.userGallinaIdsList.length);

      console.log("allUserGallinasList length");
      console.log(this.allUserGallinasList.length);
    }

    if (this.userGallinaIdsList.length > 0) {

      for (let k = 0; k < this.userGallinaIdsList.length; k++) {

        if (this.allUserGallinasList.length > 0) {

          //For each id in the list of ids from the blockchain, we want to compare it to the ids in the list from the db
          for (let j = 0; j < this.allUserGallinasList.length; j++) {

            if(this.debug === true) {
              console.log("GALLINA ID 1");
              console.log(this.userGallinaIdsList[k].id);

              console.log("GALLINA ID 2");
              console.log(this.allUserGallinasList[j].gid);
            }

            if (this.userGallinaIdsList[k].id === this.allUserGallinasList[j].gid && this.userGallinaIdsList[k].id !== "Egg") {
              //The gallina ID from the blockchain matches the ID in the users gallinas list from the DB

              //Now we should add each match to a separate list, that will be shown on the my gallinas page.
              if (this.confirmedUserGallinasList.includes(this.userGallinaIdsList[k].id) === false) {
                this.confirmedUserGallinasList.push(this.userGallinaIdsList[k].id);
                this.confirmedUserGallinasListAll.push(this.allUserGallinasList[j]);
              }

            }

          }

        }

      }

      //We now have a list of gallinas that can be shown to the user
      if(this.debug === true) {
        console.log("CONFIRMED GALLINAS LIST");
        console.log(this.confirmedUserGallinasList);
      }

      if (this.userGallinaIdsList.length > 0) {
        //Lets now handle the Gallinas that werent in the database, these gallinas are added to a separate list so users can remint these gallinas
        for (let n = 0; n < this.userGallinaIdsList.length; n++) {

          //Check comparing to confirmed list first
          for (let b = 0; b < this.confirmedUserGallinasList.length; b++) {

            if (this.userGallinaIdsList[n].id !== "Egg" && this.userGallinaIdsList[n].id !== this.confirmedUserGallinasList[b]) {

              if (this.debug === true) {
                console.log("CHECKING TO SEE IF ID IS IN SPECIAL LIST " + this.userGallinaIdsList[n].id);
                console.log("AND LIST:");
                console.log(this.specialTableKeysList);
                console.log(this.specialTableKeysList.includes(this.userGallinaIdsList[n].id));
              }

              if (this.userGallinaIdsList[n].id !== "Egg" && this.lostUserGallinasList.includes(this.userGallinaIdsList[n]) === false && this.lostUserGallinasList2.includes(this.userGallinaIdsList[n]) === false && this.specialTableKeysList.includes(this.userGallinaIdsList[n].id) === false) {
                this.hasLostGallinas = true;
                this.lostUserGallinasList.push(this.userGallinaIdsList[n]);
              }

              /*            if (this.userGallinaIdsList[n].id !== "Egg" && this.lostUserGallinasList.includes(this.userGallinaIdsList[n]) === false && this.lostUserGallinasList2.includes(this.userGallinaIdsList[n]) === false && this.confirmedUserGallinasList.includes(this.userGallinaIdsList[n].id) === false && this.specialTableKeysList.includes(this.userGallinaIdsList[n].id) === true) {
              this.hasMissedGallinas = true;
              this.lostUserGallinasList2.push(this.userGallinaIdsList[n]);
            }*/

            }


          }

          if (this.userGallinaIdsList[n].id !== "Egg" && this.confirmedUserGallinasList.includes(this.userGallinaIdsList[n].id) === false && this.lostUserGallinasList.includes(this.userGallinaIdsList[n]) === false && this.lostUserGallinasList2.includes(this.userGallinaIdsList[n]) === false && this.specialTableKeysList.includes(this.userGallinaIdsList[n].id) === false) {
            this.hasLostGallinas = true;
            this.lostUserGallinasList.push(this.userGallinaIdsList[n]);
          }

          if (this.userGallinaIdsList[n].id !== "Egg" && this.lostUserGallinasList.includes(this.userGallinaIdsList[n]) === false && this.lostUserGallinasList2.includes(this.userGallinaIdsList[n]) === false && this.confirmedUserGallinasList.includes(this.userGallinaIdsList[n].id) === false && this.specialTableKeysList.includes(this.userGallinaIdsList[n].id) === true) {
            this.hasMissedGallinas = true;
            this.lostUserGallinasList2.push(this.userGallinaIdsList[n]);
          }


        }
      }

      //We now have a list of gallinas that are not shown and need to be reminted
      if(this.debug === true) {
        console.log("LOST GALLINAS LIST");
        console.log(this.lostUserGallinasList);
      }

    }


    await this.getGallinasToShow(0, this.perPage);


    //close loader
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 100);
  },
};
</script>
<style scoped>


.helpselected {
  border: 5px #ffda86;
  border-radius: 22px;
  border-style: inset;
  background-color: #e1b46f;
  margin-left: 2rem;
  margin-right: 2rem;
  cursor: pointer;
}

.dark-text-section {
  border: 5px #ffda86;
  border-radius: 22px;
  border-style: outset;
  background-color: #ebb667;
  margin-left: 2rem;
  margin-right: 2rem;
  cursor: pointer;
}

.hidden-section {
  border: 5px #ffda86;
  border-radius: 22px;
  border-style: inset;
  background-color: #6aa5d7d4;
  margin-left: 2rem;
  margin-right: 2rem;
}
.rcolumn2 {
  background-color: #edc180;
  border: 5px groove #ffda86;
  border-radius: 40px;
  padding: 0.3rem;
  padding-bottom: 0.6rem;
  font-family: 'Comic Neue', sans-serif;
  color: #b52e2e;
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 1rem;
}

.rcolumn {
  background-color: #efc78a;
  border: 5px solid #ffda86;
  border-radius: 40px;
  padding: 0.3rem;
  padding-bottom: 0.6rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.stat-column2 {
  background-color: #e8c898;
  border: 5px solid #ffda86;
  border-radius: 40px;
  padding: 0.3rem;
  padding-bottom: 0.6rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 1rem;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
}

.search-column {
  border: 5px #ffda86;
  border-radius: 22px;
  border-style: inset;
  background-color: #86bfb0d4;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-bottom: 20px;
  margin-top: 1rem;
}

.genesearch {
  width: 105px;
}

.gallinacansearch {
  width: 205px;
}


.awesome {

  width: 100%;

  margin: 0 auto;
  text-align: center;

  color: #313131;
  letter-spacing: -2px;
  font-weight: bold;
  -webkit-animation: colorchange 20s infinite alternate;


}

@-webkit-keyframes colorchange {
  0% {

    color: #FFCE3F;
  }

  10% {

    color: #4861AD;
  }

  20% {

    color: #6A45A2;
  }

  30% {

    color: #88CEB8;
  }

  40% {

    color: #0f0f0f;
  }

  50% {

    color: #FFCE3F;
  }

  60% {

    color: #4861AD;
  }

  70% {

    color: #6A45A2;
  }
  80% {

    color: #88CEB8;
  }

  90% {

    color: #0f0f0f;
  }

  100% {

    color: #6A45A2;
  }
}

.discordcolor {
  color: #ffffff !important;
}

.btnsearch {
  color: #fff;
  background-color: #5631c7;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 12px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnsearch:active {
  margin-left: 10px;
  margin-top: 10px;
  background-color: #310d9e;
}

.btnsearch:focus,
.btnsearch:hover {
  font-family: 'Mochiy Pop P One', sans-serif;
}

.btnsearch:hover:before,
.btnsearch:hover:after {
  content: '';
  z-index: -1;
}

.btnsearch:before,
.btnsearch:after {
  content: '';
  background-color: #230972;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btnsearch:after {
  background-color: #22096c;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btnsearch:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnsearch:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}

.bgdiscord {
  background-color: #5631c7;
}


.neg-margin {
  margin-top: -30px;
}

.special-margin {
  margin-top: 70px;
}


.btnbreed {
  color: #fff;
  background-color: #e84393;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 13px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnbreed:active {
  margin-left: 10px;
  margin-top: 10px;
}

/*.btnbreed:focus,*/
/*.btnbreed:hover {*/
/*  color: #fff;*/
/*}*/

.btnbreed:focus,
.btnbreed:hover {
  color: #b43171;
  background-color: #42c1a3;
}

.btnbreed:hover:before,
.btnbreed:hover:after {
  content: '';
  background-color: #1dad82;
  z-index: -1;
}

.btnbreed:before,
.btnbreed:after {
  content: '';
  background-color: #ad1d63;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btnbreed:after {
  background-color: #821248;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btnbreed:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnbreed:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}

.stat-column {
  border: 5px solid #ffda86;
  background-color: #e8c898;
  border-radius: 40px;
  padding: .5rem;
  padding-bottom: 1rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 10px;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
}

.font3d {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
}

.font3dsmall {
  font-family: 'Comic Neue', cursive;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.2);
  font-size: 20px;
}

.font3dsmall2 {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 4px 4px rgba(0, 0, 0, 0.4);
  font-size: 20px;
}

.font3dsmall3 {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 4px 4px rgba(0, 0, 0, 0.4);
  font-size: 12px;
}

.hugleft {
  position: relative;
  right: 15%;
}

.hugright {
  position: relative;
  left: 15%;
}

.frontfooter {
  display: flex;
  position: relative;
  justify-content: center;
}

.modal-box .mdialog {
  width: 80%;
  margin: 70px auto 0;
  margin-top: 0px;

}

@media (max-width: 576px) {
  .modal-box .mdialog {
    width: 95%;
  }
}

.modal.fade .mdialog {
  opacity: 0;
  transform: translate(30%, 30%) scale(0.5);
}

.modal.fade.in .mdialog {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.modal-box .mdialog .modal-content {
  border: 5px outset #ffda86;
  border-radius: 40px;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
  background-color: #e8c898;
}

.modal-box .mdialog .modal-content .modal-body {
  padding: 0px;
}

.modal-box .mdialog .modal-content .modal-body .icon {
  color: #827f3b;
  font-size: 80px;
  line-height: 145px;
  width: 100px;
  height: 100px;
  padding-left: 0px;
  padding-right: 10px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: 25px;
}

.modal-box .mdialog .modal-content .modal-body .inner-content {
  padding: 0 0 0 170px;
  margin: 0 0 30px;
  margin-top: 0px;
}

.modal-box .mdialog .modal-content .modal-body .title {
  font-family: Mochiy Pop P One,sans-serif;
  color: #fff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 transparent, 0 4px 0 transparent, 0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent, 0 9px 0 transparent, 0 10px 10px rgb(0 0 0 / 40%);
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.title2 {
  font-family: 'Comic Neue', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.titlecolor {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #83b88e;
  text-shadow: 0 1px 0 #007f5c, 0 2px 0 #00825f, 0 3px 0 #007f5c, 0 4px 0 #00825f,
  0 5px 0 #007f5c, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  margin: 0 0 10px 0;
}

.modal-box .mdialog .modal-content .modal-body .description {
  color: #534f47;
  font-size: 15px;
  margin: 0;
  padding-top: 20px;
  font-family: 'Comic Neue', cursive;
}

.modal-box .mdialog .modal-content .modal-body .form-control {
  color: #6d5a36;
  background: #eee8dd;
  font-size: 15px;
  letter-spacing: 1px;
  height: 45px;
  padding: 2px 15px;
  border-radius: 50px 0 0 50px;
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control:focus {
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.modal-box .mdialog .modal-content .modal-body .subscribe {
  color: #fff;
  background: #ebbf53;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px 20px 11px;
  border: none;
  border-radius: 0 50px 50px 0;
  display: block;
  transition: all 0.3s;
}

.modal-box .mdialog .modal-content .modal-body .subscribe:hover,
.modal-box .mdialog .modal-content .modal-body .subscribe:focus {
  background: #827f3b;
}

.loadereye {
  width: 100px;
  height: 25px;
  position: relative;
  margin: 0px auto 0;
}

.loadereye span {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.loadereye span:nth-child(1) {
  background: #005395;
}

.loadereye span:nth-child(2) {
  background: #1F4284;
}

.loadereye span:nth-child(3) {
  background: #604F93;
}

.loadereye span:nth-child(4) {
  background: #512F6C;
}

.loadereye span:nth-child(5) {
  background: #C81C70;
}

.loadereye span:before {
  content: "";
  background: inherit;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  animation: wave 2s ease-out infinite;
}

.loadereye span:nth-child(1):before {
  animation-delay: 0.2s;
}

.loadereye span:nth-child(2):before {
  animation-delay: 0.4s;
}

.loadereye span:nth-child(3):before {
  animation-delay: 0.6s;
}

.loadereye span:nth-child(4):before {
  animation-delay: 0.8s;
}

.loadereye span:nth-child(5):before {
  animation-delay: 1s;
}

@keyframes wave {
  50%, 75% {
    transform: scale(2.5);
  }
  80%, 100% {
    opacity: 0;
  }
}

.panel {
  font-family: 'Comic Neue', sans-serif;
  padding: 0;
  border: none;
}

.panel .panel-heading {
  border-radius: 20px;
  padding: 10px;
  background-color: rgba(176,177,142,.57);
  border-style: inset;
}

.panel .panel-heading .title {
  color: #333;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 33px;
  margin: 0;
}

.panel .panel-heading .btn {
  color: #fff;
  background-color: #6c5ce7;
  font-size: 14px;
  font-weight: 600;
  padding: 7px 15px;
  border: none;
  border-radius: 0;
  transition: all 0.3s ease 0s;
}

.panel .panel-heading .btn:hover {
  box-shadow: 4px 4px 0 #6c5ce7;
}

.panel .panel-body {
  padding: 0;
  border-radius: 0;
}

.panel .panel-body .table thead tr th {
  color: #333;
  font-size: 19px;
  font-weight: 400;
  padding: 12px;
  border-bottom: none;
}

.panel .panel-body .table tbody tr td {
  color: #555;
  font-size: 15px;
  font-weight: 500;
  padding: 13px;
  vertical-align: middle;
  background: rgba(233, 229, 184, 0.36);
}

.panel .panel-body .table tbody tr:nth-child(even) td {
  background: rgba(245, 245, 245, 0.36);
}

.panel .panel-body .table tbody .percentage {
  color: #2ecc71;
  font-size: 17px;
  font-weight: 600;
  padding: 3px 10px 5px;
  border: 2px solid #2ecc71;
  border-radius: 5px;
  display: inline-block;
}

.panel .panel-body .table tbody .percentage.disable {
  color: #fc9f0a;
  border: 2px solid #fc9f0a;
}

.panel .panel-body .table tbody .percentage.failed {
  color: #e74c3c;
  border: 2px solid #e74c3c;
}

.panel .panel-body .table tbody .action-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.panel .panel-body .table tbody .action-list li {
  display: inline-block;
}

.panel .panel-footer {
  color: #333;
  border-radius: 40px;
  font-size: 16px;
  line-height: 33px;
  padding: 25px 15px;
}

.pagination1 {
  margin: 0;
}

.pagination1 li a {
  color: #333;
  font-size: 15px;
  font-weight: 700;
  margin: 0 2px;
  border: 2px solid #ddd;
  border-radius: 0;
  transition: all 0.3s ease 0s;
}

.pagination1 li a:hover,
.pagination1 li a:focus,
.pagination1 li.active a {
  color: #6c5ce7;
  background: none;
  border-color: transparent;
  border-bottom: 2px solid #6c5ce7;
}

@media only screen and (max-width: 479px) {
  .panel .panel-heading {
    text-align: center;
  }

  .panel .panel-heading .title {
    float: none !important;
    margin: 0 0 10px;
  }

  .panel .panel-heading .btn {
    float: none !important;
  }
}

.connect-wallet-text {
  color: #f6f6f6;
  background-color: #2d89a7;
}

.titleareagift {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #FFF;
  font-size: 1rem;
  margin: 0 auto;
  border-radius: 1rem;
  background-color: #3aafc2;
  border: none;
  width: 90%;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  position: relative;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;
}

.eggOne, .eggTwo, .eggThree {
  font-family: 'Mochiy Pop P One', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  overflow: hidden;
  display: inline-block;
  width: 160px;
  height: 60px;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.1);
  top: -100px;
  border: 5px solid #c99a50;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;

}

.eggOne {
  left: -300px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
  #90be6d 10px, #90be6d 20px);
  transition: all .5s ease-out;
}

.eggOne:focus {
  color: #fff;
}

.eggOne:hover {
  top: 175px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
  #90be6d 10px, #90be6d 20px);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggOne:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggOne:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

.eggOne2, .eggTwo2, .eggThree2 {
  font-family: 'Mochiy Pop P One', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  overflow: hidden;
  display: inline-block;
  width: 160px;
  height: 60px;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.1);
  border: 5px solid #c99a50;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;

}

.eggOne2 {
  left: -300px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
  #90be6d 10px, #90be6d 20px);
  transition: all .5s ease-out;
}

.eggOne2:focus {
  color: #fff;
}

.eggOne2:hover {
  top: 175px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
  #90be6d 10px, #90be6d 20px);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggOne2:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggOne2:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

@media only screen and (max-width: 767px) {
  .eggOne {
    margin-bottom: 20px;
  }
}

.eggTwo {
  left: -100px;
  background-color: #f94144;
  transition: all .5s ease-out;
}

.eggTwo:focus {
  color: #fff;
}

.eggTwo:hover {
  top: 175px;
  background-color: #f94144;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggTwo:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggTwo:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

@media only screen and (max-width: 767px) {
  .eggTwo {
    margin-bottom: 20px;
  }
}


.eggTwo:after {
  content: "";
  position: absolute;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.02);
  width: 126px;
  height: 180px;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;
}

.eggThree {
  left: 100px;
  background: repeating-linear-gradient(-45deg, #70c1b3, #70c1b3 20px,
  #247ba0 20px, #247ba0 40px);
}

.eggThree:focus {
  color: #fff;
}

.eggThree:hover {
  top: 175px;
  background-color: #f94144;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggThree:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggThree:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

@media only screen and (max-width: 767px) {
  .eggThree {
    margin-bottom: 20px;
  }
}


.eggThree:after {
  content: "";
  position: absolute;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.02);
  width: 126px;
  height: 180px;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;
}

.white{
  color: #FFF;
}


</style>
