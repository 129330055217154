<template>
  <div class="main-wrap">
    <div class="loader" v-if="this.showLoadingModal === true">
      <div class="loader__bar"></div>
      <div class="loader__bar loader__bar--delay-1"></div>
      <div class="loader__bar loader__bar--delay-2"></div>
      <div class="loader__bar loader__bar--delay-3"></div>
      <div class="loader__bar loader__bar--delay-4"></div>
      <div class="loader__bar loader__bar--delay-5"></div>
    </div>

    <section class="moveup" v-if="this.showLoadingModal === false">
      <Scroll-Div width="98%" height="85vh" view-class="yourclassname" v-if="this.showLoadingModal === false">
        <div class="container">
          <div v-if="$screen.width > 576" class="row align-items-center justify-content-center mt-4">
            <div class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalGallinas()+' Gallina(s).'">
            {{ this.getUserTotalGallinas() }} &nbsp; <img src="../../public/icons/chicken-mygallinas-icon.svg"
                                                          alt="Chickens" style="position:relative; top: 3px;"
                                                          width="40px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalEggs() > 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalEggs()+' Egg(s).'">
            {{ this.getUserTotalEggs() }} &nbsp; <img src="../../public/icons/egg-mygallinas-icon.svg"
                                                      style="position:relative; top: 5px;" alt="Eggs"
                                                      width="25px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalGiftVouchers() > 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalGiftVouchers()+' Voucher(s).'">
            {{ this.getUserTotalGiftVouchers() }} &nbsp; <img src="../../public/icons/gift-voucher-icon.svg"
                                                              style="position:relative; top: 5px;" alt="Gift Vouchers"
                                                              width="40px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalEggs() <= 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You dont have any Eggs right now'">
            0 &nbsp; <img src="../../public/icons/egg-mygallinas-icon.svg" style="position:relative; top: 5px;"
                          alt="Eggs"
                          width="25px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalGiftVouchers() <= 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You dont have any Vouchers right now'">
            0 &nbsp; <img src="../../public/icons/gift-voucher-icon.svg" style="position:relative; top: 5px;"
                          alt="Gift Vouchers"
                          width="40px"/>
            </span>
            </div>
          </div>

          <div v-if="$screen.width <= 576" class="row align-items-center justify-content-center mt-2">
            <div class="col-md-10 d-flex align-items-center justify-content-center stat-column2"><span class="hugleft">
            {{ this.getUserTotalGallinas() }} &nbsp; <img src="../../public/icons/chicken-mygallinas-icon.svg" alt="Chickens" width="40px"/>  </span> &nbsp; <span class="hugright">  {{ this.getUserTotalEggs() }} &nbsp; <img src="../../public/icons/egg-mygallinas-icon.svg" alt="Chickens" width="25px"/></span>
            </div>
          </div>
          <div class="modal-box">
            <div class="mdialog" role="document">
              <div class="modal-content clearfix">
                <div class="modal-body">
                  <img src="../../public/icons/egg-bg-main1.svg" class="mt-3" width="80%"
                       alt="Buy Trade And Hatch Eggs"/>
                  <p style="margin-top: 20px;">{{quoteToShowNow}}</p>
                  <div class="inner-content">
                    <h3 class="title eggsTitle">Buy + Hatch Eggs</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row align-items-center justify-content-evenly mt-2">
              <div class="col-md-3 align-items-center justify-content-center stat-column" style="height: 420px;">

                <div class="timeline-year">1 KDA</div>

                <svg version="1.1" id="egg_buy" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     width="80%" height="90%" viewBox="0 0 300 400" enable-background="new 0 0 300 400"
                     xml:space="preserve" style="position: relative; top: -70px; z-index: 0;">
                  <g id="egg-all_buy" ref="eggref_buy">
                        <g id="chicken_buy" ref="chicken_buy">
                          <path fill="#F5796C" d="M167.342,150.411c2.143,9.231-0.18,17.659-5.192,18.823c-5.011,1.164-10.812-5.375-12.957-14.607
                            c-2.144-9.231,0.18-17.658,5.192-18.823C159.394,134.639,165.195,141.179,167.342,150.411z"/>

                          <ellipse transform="matrix(0.8488 0.5288 -0.5288 0.8488 106.1718 -65.5443)" fill="#F5796C"
                                   cx="167.672" cy="152.841"
                                   rx="5.97" ry="10.996"/>
                          <path fill="#FEEEB4" d="M220.46,218c0.001-1,0.012-0.627,0.012-0.81c0-32.438-26.295-58.868-58.735-58.868
                            c-32.439,0-58.736,26.495-58.736,58.934c0,0.184,0.012-0.256,0.014,0.744H103v39l117.232,1.01L220.46,218L220.46,218z"/>
                          <circle fill="#72747C" cx="125.538" cy="204.594" r="5.633"/>
                          <circle fill="#72747C" cx="196.378" cy="204.594" r="5.634"/>
                          <polygon fill="#F89849"
                                   points="161.119,219 173.839,219 167.479,229.989 161.119,240.993 154.759,229.996 148.4,219 	"/>
                        </g>
                    <g id="bottom_part_buy" ref="bottom_part_buy">
                          <path id="bottom" fill="#E9E6DB" d="M89.467,194c-1.531,7.035-2.359,14.405-2.36,22h0.075c0.009,52,32.391,86.761,72.409,86.761
                            c40.018,0,72.409-39.13,72.409-87.12v-0.119c0-0.003,0-0.005,0-0.008c0-0.004,0-0.009,0-0.014c0-7.551-0.694-14.729-1.981-21.5
                            l-26.185,6l-44-6l-42.334,6L89.467,194z"/>
                      <path id="shbottom_buy" fill="#DEDAC9" d="M99.182,215h-0.038c0.001-6.301,0.583-12.438,1.65-18.362l-11.539-2.469
                            c-1.375,6.676-2.11,13.651-2.111,20.831h0.038c-0.038,52.75,29.318,86.875,69.409,87.821c1.998,0.047,3.979-0.102,5.938-0.293
                            C125.355,299.196,99.19,264.344,99.182,215z"/>
                        </g>
                    <g id="top_part_buy" ref="top_part_buy">
                          <path id="top_buy" fill="#E9E6DB" d="M230.545,197c-6.561-41.887-35.882-68.88-71.082-68.88c-31.932,0-63.045,26.565-69.996,65.88
                            l28.7,6l40.667-6l45,6L230.545,197z"/>
                      <path id="shtop_1_" fill="#DEDAC9" d="M100.794,196.638c7.403-35.642,33.679-65.285,65.013-68.344
                            c-1.996-0.18-4.011-0.294-6.051-0.294c-33.982,0-62.634,28.294-70.5,66.169L100.794,196.638z"/>
                      <circle id="highlight3" fill="#FFFFFD" cx="190.814" cy="151.488" r="4.146"/>
                      <circle id="highlight2" fill="#FFFFFD" cx="199.877" cy="159.502" r="4.146"/>
                      <circle id="highlight1" fill="#FFFFFD" cx="190.629" cy="160.687" r="2.612"/>
                        </g>
                  </g>
                </svg>

                <div class="buyeggbutton" style="position: relative; top: -120px;">
                  <a href="#" @click="this.buyNewEgg" class="btn btn-lg btnsearch">
                    <span>Buy 1 Egg</span>
                  </a>
                </div>

              </div>

              <div v-if="this.getUserTotalEggs() > 0" class="col-md-3 align-items-center justify-content-center stat-column" style="height: 420px;">

                <div class="newGallinaNameInputArea">
                  <input :v-model="newGallinaName" @input="newGallinaName = $event.target.value" type="text" class="form__input" id="newgallinanameinput"
                         placeholder="Name My Gallina" required="" style="position:relative; z-index: 2;"/>
                </div>

                <svg version="1.1" id="egg" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     width="80%" height="90%" viewBox="0 0 300 400" enable-background="new 0 0 300 400"
                     xml:space="preserve" style="position: relative; top: -50px; z-index: 0;">
                  <g id="egg-all" ref="eggref">
                        <g id="chicken" ref="chicken">
                          <path fill="#F5796C" d="M167.342,150.411c2.143,9.231-0.18,17.659-5.192,18.823c-5.011,1.164-10.812-5.375-12.957-14.607
                            c-2.144-9.231,0.18-17.658,5.192-18.823C159.394,134.639,165.195,141.179,167.342,150.411z"/>

                          <ellipse transform="matrix(0.8488 0.5288 -0.5288 0.8488 106.1718 -65.5443)" fill="#F5796C"
                                   cx="167.672" cy="152.841"
                                   rx="5.97" ry="10.996"/>
                          <path fill="#FEEEB4" d="M220.46,218c0.001-1,0.012-0.627,0.012-0.81c0-32.438-26.295-58.868-58.735-58.868
                            c-32.439,0-58.736,26.495-58.736,58.934c0,0.184,0.012-0.256,0.014,0.744H103v39l117.232,1.01L220.46,218L220.46,218z"/>
                          <circle fill="#72747C" cx="125.538" cy="204.594" r="5.633"/>
                          <circle fill="#72747C" cx="196.378" cy="204.594" r="5.634"/>
                          <polygon fill="#F89849"
                                   points="161.119,219 173.839,219 167.479,229.989 161.119,240.993 154.759,229.996 148.4,219 	"/>
                        </g>
                    <g id="bottom_part" ref="bottom_part">
                          <path id="bottom" fill="#E9E6DB" d="M89.467,194c-1.531,7.035-2.359,14.405-2.36,22h0.075c0.009,52,32.391,86.761,72.409,86.761
                            c40.018,0,72.409-39.13,72.409-87.12v-0.119c0-0.003,0-0.005,0-0.008c0-0.004,0-0.009,0-0.014c0-7.551-0.694-14.729-1.981-21.5
                            l-26.185,6l-44-6l-42.334,6L89.467,194z"/>
                      <path id="shbottom" fill="#DEDAC9" d="M99.182,215h-0.038c0.001-6.301,0.583-12.438,1.65-18.362l-11.539-2.469
                            c-1.375,6.676-2.11,13.651-2.111,20.831h0.038c-0.038,52.75,29.318,86.875,69.409,87.821c1.998,0.047,3.979-0.102,5.938-0.293
                            C125.355,299.196,99.19,264.344,99.182,215z"/>
                        </g>
                    <g id="top_part" ref="top_part">
                          <path id="top" fill="#E9E6DB" d="M230.545,197c-6.561-41.887-35.882-68.88-71.082-68.88c-31.932,0-63.045,26.565-69.996,65.88
                            l28.7,6l40.667-6l45,6L230.545,197z"/>
                      <path id="shtop_1_" fill="#DEDAC9" d="M100.794,196.638c7.403-35.642,33.679-65.285,65.013-68.344
                            c-1.996-0.18-4.011-0.294-6.051-0.294c-33.982,0-62.634,28.294-70.5,66.169L100.794,196.638z"/>
                      <circle id="highlight3" fill="#FFFFFD" cx="190.814" cy="151.488" r="4.146"/>
                      <circle id="highlight2" fill="#FFFFFD" cx="199.877" cy="159.502" r="4.146"/>
                      <circle id="highlight1" fill="#FFFFFD" cx="190.629" cy="160.687" r="2.612"/>
                        </g>
                    <path id="break" ref="breakme" fill="none" stroke="#72747C" stroke-miterlimit="10" d="M89.467,194 117.5,200 159.834,194 203.834,200
                          230.019,194 "/>
                  </g>
                </svg>

                <div class="buyeggbutton">
                  <a href="#" @click="this.hatchNewEgg" class="btn btn-lg btnsearch">
                    <span>Hatch 1 Egg</span>
                  </a>
                </div>
                <div v-if="newGallinaName.length === 0" class="buyeggwarning">
                   (Enter a name first!)
                </div>
                <div v-if="newGallinaName.length >= 1 && newGallinaName.length < 3" class="buyeggwarning">
                  (Name's are 3+ characters)
                </div>

              </div>
            </div>
          </div>

        </div>
      </Scroll-Div>
    </section>

    <vmodalwaitsignature v-if="showTransactionModal" :show-gold-header="false" content-class="darkMode"
                         :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Ok"
                         v-model="showTransactionModal" @confirm="showTransactionModal = false"
                         @cancel="showTransactionModal = false" name="intro" cancel-name="Cancel">

      <template v-slot:title>
        <div class="pt-2">
          Awaiting wallet response
        </div>
      </template>

      <div class="displayGuard">
        <div class="row align-items-center justify-content-center mt-4 mb-0">
          <div class="p-0 border-gold text-center connect-wallet-text alert col-10 mt-4">
            We just sent a request to your wallet.<br/>
            <b>Always review your wallet's transactions.</b> When you're ready, sign, come back and click OK!
          </div>
        </div>
      </div>

    </vmodalwaitsignature>

  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ScrollDiv from "vue-scroll-div";
import vmodalwaitsignature from "@/components/VModalWaitSignature";
import {gsap} from "gsap";
import axios from "axios";



export default {
  name: "BuyEggsPage",
  components: {
    ScrollDiv,
    vmodalwaitsignature,
  },
  data() {
    return {
      showLoadingModal: true,
      showTransactionModal: false,
      newGallinaName: "",
      debug: false,
      quotesList: ["Each of our Eggs is ready to hatch into a healthy Gallina whenever you choose!",
      "Do you ever wonder which came first?",
      "How do they fit all those Eggs in there?",
        "Did you know that a Gallina's Generation determines how often it's able to breed?",
      "Gallinas hatched from our Eggs all have a similar DNA structure filled with the Genes 0-2.",
      "Gallinas hatched from our Eggs grow into full breeding adults after 7 days!",
      "Our Eggs are guaranteed to hatch into a healthy Generation 0 Gallina!",
      "The genetic structure of these Gallinas is eggscellent!",
        "Gallinas with lower Generations can breed more frequently than those with higher Generations.",
      "Don't worry, we make sure our Gallinas get lots of eggsercise!",
        "One day we will run out of Eggs to sell..",
      "I have lots of great eggspecations from our Gallinas today!",
        "Generation 0 Gallinas love to breed, and can breed much more often than other Gallinas.",
      "Eggs purchased from Gallinas.io typically hatch into a Gallina with the genes 0-2.",
      "One day these Gallinas will stop laying Eggs..",
      "When Gallinas breed, the H1 series of genes seems to be the most common set of traits that offspring inherit.",
      "Don't worry! These Gallinas get time off too! We only work them around the cluck.",
      "Don't worry! These Gallinas are free to eggsit the hatchery at any time.",
      "Our teggnology at the Gallinas.io ensures every one of our Eggs hatches into a Generation 0 Gallina!",
      "Huevo! Let's go!",
      "Why did the Gallina cross the road? To get to the Kadena side, of course.",
      "We'll make great pets, we'll make great pets, we will make great pets!",
        "Some Gallinas have special trinkets- These are awarded from playing Gallinas.io and can be inherited while breeding.",
      "Everybody needs a Gallina for a pillow, everybody needs a Gallina!",
      "The Gallinas you hatch from our Eggs will always have a similar DNA structure, typically consisting of Genes 0-2.",],
      quoteToShowNow: "Each of our Eggs is ready to hatch into a healthy Gallina whenever you choose!",
    };
  },
  methods: {
    ...mapActions("accounts", ["getAllGameGallinas", "getAllGameEggs", "bindCheckPending"]),
    ...mapGetters("accounts", ["getAccountExists", "getGameTotalGallinas", "getGameTotalEggs", "getAccountConfirmed", "getTransactionHash", "getUserTotalGallinas", "getUserTotalEggs", "getUserGallinasList", "getUserGallinaCount","getUserTotalGiftVouchers"]),

    async randomIntFromInterval(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    async makeGetRequest() {

      const newTransaction = {
        request: 66,
      };

      if(this.debug === true) {
        console.log("sending new payload");
        console.log(newTransaction);
      }

      let res = await axios.post("/phpstuff/ajaxfile.php", newTransaction);

      let data = res.data;
      console.log(data);


    },

    async hatchNewEgg() {

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      await this.bindCheckPending(name).then(async(response)=>{

        if(this.debug) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }

        if(response !== undefined && response[0] !== undefined && response[0].length === 0 || response !== undefined && response === "0" || response !== undefined && response === 0) {
          this.showSignTransactionModal = true;

          let theAccountName = localStorage.getItem("accountName");

          let payload = {
            accountName: theAccountName,
            newGallinaName: this.newGallinaName,
          };

          if(this.newGallinaName !== null && this.newGallinaName !== "" && this.newGallinaName.length >= 3)
          {
            this.showTransactionModal = true;
            await this.$store.dispatch("accounts/hatchnewegg", payload);
            if(this.debug === true){
              console.log("Hatching an Egg with payload:");
              console.log(payload);
            }

          }else {
            console.log("Give your new Gallina a name first, then click Hatch.");
            alert("Gallina names must be at least 3 characters.")
          }

        }else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.")
        }
      });


    },

    async buyNewEgg() {

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      await this.bindCheckPending(name).then(async(response)=>{

        if(this.debug) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }

        if(response !== undefined && response[0] !== undefined && response[0].length === 0 || response !== undefined && response === "0" || response !== undefined && response === 0) {
          this.showSignTransactionModal = true;

          let theAccountName = localStorage.getItem("accountName");
          this.showTransactionModal = true;
          await this.$store.dispatch("accounts/buynewegg", theAccountName);

          console.log("Buying new Egg with account: " + theAccountName);


        }else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.")
        }
      });



    },

    animateChickenIntro() {

      // gsap.to(this.$refs.eggref, {duration: 1, rotation: 10});

      /* gsap.fromTo(this.$refs.breakme, {
         autoAlpha: 1
       }, {
         autoAlpha: 0,
         duration: 0.35
       });

       gsap.to(this.$refs.top_part,  {duration: 3, y: -60, rotationX: 15});

       gsap.to(this.$refs.chicken, {duration: 4, y: -50, ease: "yoyo"});*/

      const t1 = gsap.timeline({repeat: -1});
      t1.to(this.$refs.eggref, {duration: 0.5, rotation: 10});
      t1.to(this.$refs.eggref, {duration: 0.5, rotation: -10});
      t1.to(this.$refs.eggref, {duration: 0.5, rotation: 10});
      t1.to(this.$refs.eggref, {duration: 0.5, rotation: 0});
      t1.to(this.$refs.eggref, {duration: 0.5, rotation: -2});
      t1.to(this.$refs.eggref, {duration: 0.5, rotation: 0});
      t1.to(this.$refs.eggref, {duration: 0.5, rotation: 3});
      t1.to(this.$refs.eggref, {duration: 0.5, rotation: 0});
      t1.to(this.$refs.eggref, {duration: 0.5, x: -8});
      t1.to(this.$refs.eggref, {duration: 0.5, x: 0});
      t1.to(this.$refs.eggref, {duration: 0.5, x: 8});
      t1.to(this.$refs.eggref, {duration: 0.5, x: 0});


    },

  },

  async created() {

    let name = localStorage.getItem("accountName");
    if(name === null || name === "")
    {
      await this.$router.push({path: `/`});
    }
    await this.$store.dispatch("accounts/getUserGallinaCount", name);
    await this.$store.dispatch("accounts/getAllUserEggs", name);
    await this.$store.dispatch("accounts/getAllUserGiftVouchers", name);
    let totalgallinas = this.getUserTotalGallinas();

    if(this.debug){
      console.log(totalgallinas);
    }

    let quoteToShow = await this.randomIntFromInterval(0, 24);

    this.quoteToShowNow = this.quotesList[quoteToShow];

    if(this.debug){
      console.log(quoteToShow);
      console.log(this.quoteToShowNow);
    }



    //Close loading screen
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 10);
  },
  mounted() {
    this.$nextTick(() => {

      setTimeout(async () => {
        if(this.getUserTotalEggs() > 0){
          this.animateChickenIntro();
        }
      }, 20000);

    });
  },
};
</script>
<style lang="scss" scoped>

$background: #A69A8E;
$accent: #E2D3C2;
$text: #55565D;


.connect-wallet-text {
  color: #f6f6f6;
  background-color: #2d89a7;
}


.hugleft {
  position:relative;
  right: 15%;
}

.hugright {
  position:relative;
  left: 15%;
}

.stat-column2 {
  background-color: #e8c898;
  border: 5px solid #ffda86;
  border-radius: 40px;
  padding: 1rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 1rem;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
}

.timeline-year {
  color: #fff;
  background-color: #d13847;
  font-size: 2vh;
  line-height: 70px;
  text-wrap: none;
  height: 55px;
  width: 30%;
  transform: rotate(7deg);
  position: relative;
  left: -15px;
  top: 10px;
}

.timeline-year:before {
  content: '';
  background-color: #fcfcfc;
  height: 12px;
  width: 12px;
  border: 3px solid #aaa;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  position: absolute;
  left: 8px;
  top: 8px;
}


.timeline-year {
  transform: rotate(-7deg);
  left: auto;
  right: -15px;
  z-index: 0;
}

.timeline-year:before {
  left: auto;
  right: 8px;
}

.timeline-year {
  background-color: #20b583;
}

.btn {
  color: #fff;
  background-color: #e84393;
  font-family: 'Comfortaa', cursive;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 12px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btn:active {
  margin-left: 10px;
  margin-top: 10px;
}

.btn:focus,
.btn:hover {
  color: #fff;
}

.btn:before,
.btn:after {
  content: '';
  background-color: #ad1d63;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btn:after {
  background-color: #821248;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btn:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btn:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}

.newGallinaNameInputArea {
  position: relative;
  top: 45px;
  width: 100%;
  left: 0;
}

.buyeggbutton {
  position: relative;
  bottom: 100px;
}

.btnsearch {
  color: #fff;
  background-color: #e84393;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 10px 12px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnsearch:active {
  margin-left: 10px;
  margin-top: 10px;
  background-color: #ad1d63;
}

.btnsearch:focus,
.btnsearch:hover {
  color: #ffffff;
  font-family: 'Mochiy Pop P One', sans-serif;
}

.btnsearch:hover:before,
.btnsearch:hover:after {
  content: '';
  z-index: -1;
}

.btnsearch:before,
.btnsearch:after {
  content: '';
  background-color: #ad1d63;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btnsearch:after {
  background-color: #821248;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btnsearch:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnsearch:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}

.buyeggwarning {
  position: relative;
  bottom: 80px;
  color: #6d5a36;
  font-size: 15px;
  letter-spacing: 1px;
  font-family: 'Comic Neue', cursive;
}


.form__label {
  margin-top: 0.7rem;
  display: block;
  color: #000000;
  background-color: rgba(206, 173, 232, 0.56);
  height: 100px;
  z-index: 1;
}

.form__input {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #bf9e33;
  font-size: 1rem;
  margin: 0 auto;
  border-radius: 1rem;
  background-color: #ffffbb;
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;

}

.box {
  background: green;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.circle {
  background-color: red;
  height: 5em;
  width: 5em;
  border-radius: 100%
}

.stat-column {
  border: 5px solid #ffda86;
  background-color: #e8c898;
  border-radius: 40px;
  padding: 1rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.frontfooter {
  display: flex;
  position: relative;
  justify-content: center;
}

.modal-box .mdialog {
  width: 80%;
  margin: 70px auto 0;
  margin-top: 0px;

}

@media (max-width: 576px) {
  .modal-box .mdialog {
    width: 95%;
  }
}

.modal.fade .mdialog {
  opacity: 0;
  transform: translate(30%, 30%) scale(0.5);
}

.modal.fade.in .mdialog {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.modal-box .mdialog .modal-content {
  border: 5px solid #ffda86;
  border-radius: 35px;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
  background-color: #e8c898;
}

.modal-box .mdialog .modal-content .modal-body {
  padding: 65px 45px;
}

.modal-body p {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #653800;
}

.modal-box .mdialog .modal-content .modal-body .icon {
  color: #827f3b;
  font-size: 80px;
  line-height: 145px;
  width: 100px;
  height: 100px;
  padding-left: 0px;
  padding-right: 10px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: 25px;
}

.modal-box .mdialog .modal-content .modal-body .inner-content {
  padding: 0 0 0 0px;
  margin: 0 0 30px;
  margin-top: 0px;
  position: absolute;
  top: 20px;
  left: 15%;
}

.eggsTitle{
  left: 190px;
  position: relative;
  margin-bottom: 2rem;
}

.modal-box .mdialog .modal-content .modal-body .title {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.modal-box .mdialog .modal-content .modal-body .description {
  color: #534f47;
  font-size: 15px;
  margin: 0;
  padding-top: 20px;
  font-family: 'Comic Neue', cursive;
}

.modal-box .mdialog .modal-content .modal-body .form-control {
  color: #6d5a36;
  background: #eee8dd;
  font-size: 15px;
  letter-spacing: 1px;
  height: 45px;
  padding: 2px 15px;
  border-radius: 50px 0 0 50px;
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control:focus {
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.modal-box .mdialog .modal-content .modal-body .subscribe {
  color: #fff;
  background: #ebbf53;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px 20px 11px;
  border: none;
  border-radius: 0 50px 50px 0;
  display: block;
  transition: all 0.3s;
}

.modal-box .mdialog .modal-content .modal-body .subscribe:hover,
.modal-box .mdialog .modal-content .modal-body .subscribe:focus {
  background: #827f3b;
}


</style>
