<template>
  <div id="app">
    <HelpPage></HelpPage>
  </div>
</template>

<script>
import HelpPage from '../components/HelpPage.vue'

export default {
  name: 'Help',
  components: {
    HelpPage
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
