<template>
  <div class="main-wrap">
    <div class="loader" v-if="this.showLoadingModal === true">
      <div class="loader__bar"></div>
      <div class="loader__bar loader__bar--delay-1"></div>
      <div class="loader__bar loader__bar--delay-2"></div>
      <div class="loader__bar loader__bar--delay-3"></div>
      <div class="loader__bar loader__bar--delay-4"></div>
      <div class="loader__bar loader__bar--delay-5"></div>
    </div>

    <section class="moveup" v-if="this.showLoadingModal === false && $screen.width > 576">
      <Scroll-Div width="98%" height="85vh" view-class="yourclassname" v-if="this.showLoadingModal === false">
        <div class="container mt-4">


          <div class="modal-box">
            <div class="mdialog" role="document">
              <div class="modal-content clearfix">
                <div class="modal-body">
                  <div class="row align-items-center justify-content-center mt-4">
                    <div @click="showWhatIsGallinas = !showWhatIsGallinas" :class="showWhatIsGallinas ? 'helpselected' : 'dark-text-section' " class="col-11 d-flex align-items-center justify-content-evenly">
                      <img
                          src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas" width="40px"><h3 class="title">What is Gallinas.io?</h3><img
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                    </div>
                  </div>
                  <div v-show="showWhatIsGallinas" class="row align-items-center justify-content-center mt-2">
                    <div class="col-7 d-flex align-items-center justify-content-evenly hidden-section p-3 small-font-3d">
                      <img src="../../public/icons/whatisgallinasio.svg" width="600px" alt="What is Kadena Gallinas?">
                    </div>
                  </div>

                  <div class="row align-items-center justify-content-center mt-4">
                    <div @click="showWhatIsKadena = !showWhatIsKadena" :class="showWhatIsKadena ? 'helpselected' : 'dark-text-section' " class="col-11 d-flex align-items-center justify-content-evenly">
                      <img
                          src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas" width="40px"><h3 class="title">What is Kadena?</h3><img
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                    </div>
                  </div>
                  <div v-show="showWhatIsKadena" class="row align-items-center justify-content-center mt-2">
                    <div class="col-7 align-items-center justify-content-center hidden-section p-3 small-font-3d">
                      <img src="../../public/icons/whatiskadena.svg" width="600px" alt="What is Kadena?">
                    </div>
                  </div>

                  <div class="row align-items-center justify-content-center mt-4">
                    <div @click="showWhatIsAGallina = !showWhatIsAGallina" :class="showWhatIsAGallina ? 'helpselected' : 'dark-text-section' " class="col-11 d-flex align-items-center justify-content-evenly">
                      <img
                          src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas" width="40px"><h3 class="title">What is a Gallina?</h3><img
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                    </div>
                  </div>
                  <div v-show="showWhatIsAGallina" class="row align-items-center justify-content-center mt-2">
                    <div class="col-7 align-items-center justify-content-center hidden-section p-3 small-font-3d">
                      <img src="../../public/icons/whatisagallina.svg" width="600px" alt="What is a Gallina?">
                      <br/>
                      <br/>
                      Click the Egg on the Gallina Card below to spin the Gallina around and watch it's DNA change!
                      <br/>
                      <br/>
                      <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
                        <FlipCard name="Rosey" id="03" :ishomepage="true" @flipped="changemargin = !changemargin"
                                  gender="Female" birthday="01-01-2022" nbd="01-02-2022"
                                  motherid="01" fatherid="02" :showbuttons="false" :showinspectbutton="false"
                                  extra11="0" extra12="0"
                                  gene1h1="1" gene2h1="0" gene3h1="0" gene4h1="1" gene5h1="1" gene6h1="1" gene7h1="1"
                                  gene8h1="1" gene9h1="1" gene10h1="1" gene11h1="1" gene12h1="1"
                                  gene1h2="1" gene2h2="0" gene3h2="1" gene4h2="1" gene5h2="1" gene6h2="1" gene7h2="1"
                                  gene8h2="1" gene9h2="1" gene10h2="1" gene11h2="1" gene12h2="1"
                                  gene1h3="1" gene2h3="0" gene3h3="1" gene4h3="0" gene5h3="1" gene6h3="1" gene7h3="1"
                                  gene8h3="1" gene9h3="1" gene10h3="1" gene11h3="1" gene12h3="2"
                                  beak="1" belly="1" chest="1" comb="1" eyes="1" feet="1" head="1" legs="1" wattle="1"
                                  wings="1">
                        </FlipCard>
                      </div>
                      <br/>
                      <br/>
                    </div>
                  </div>

                  <div class="row align-items-center justify-content-center mt-4">
                    <div @click="showWhatFees = !showWhatFees" :class="showWhatFees ? 'helpselected' : 'dark-text-section' " class="col-11 d-flex align-items-center justify-content-evenly">
                      <img
                          src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas" width="40px"><h3 class="title">Fees and Costs</h3><img
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                    </div>
                  </div>
                  <div v-show="showWhatFees" class="row align-items-center justify-content-center mt-2">
                    <div class="col-7 align-items-center justify-content-evenly hidden-section p-3 small-font-3d">
                      <img src="../../public/icons/helpfees.svg" width="600px" alt="Fees and Costs">
                    </div>
                  </div>

                  <div class="row align-items-center justify-content-center mt-4">
                    <div @click="showHowToBuyEggs = !showHowToBuyEggs" :class="showHowToBuyEggs ? 'helpselected' : 'dark-text-section' " class="col-11 d-flex align-items-center justify-content-evenly">
                      <img
                          src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas" width="40px"><h3 class="title">How to Buy Eggs</h3><img
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                    </div>
                  </div>
                  <div v-show="showHowToBuyEggs" class="row align-items-center justify-content-center mt-2">
                    <div class="col-10 align-items-center justify-content-evenly hidden-section p-3 small-font-3d">
                      <img src="../../public/icons/howtobuyeggs.gif" width="100%" alt="How To Buy Eggs">
                    </div>
                  </div>

                  <div class="row align-items-center justify-content-center mt-4">
                    <div @click="showHowToBreedGallinas = !showHowToBreedGallinas" :class="showHowToBreedGallinas ? 'helpselected' : 'dark-text-section' " class="col-11 d-flex align-items-center justify-content-evenly">
                      <img
                          src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas" width="40px"><h3 class="title">How to Breed Gallinas</h3><img
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                    </div>
                  </div>
                  <div v-show="showHowToBreedGallinas" class="row align-items-center justify-content-center mt-2">
                    <div class="col-10 align-items-center justify-content-evenly hidden-section p-3 small-font-3d">
                      <img src="../../public/icons/howtobreed.gif" width="100%" alt="How To Breed Gallinas">
                    </div>
                  </div>

                  <div class="row align-items-center justify-content-center mt-4">
                    <div @click="showWhatIsDNA = !showWhatIsDNA" :class="showWhatIsDNA ? 'helpselected' : 'dark-text-section' " class="col-11 d-flex align-items-center justify-content-evenly">
                      <img
                          src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas" width="40px"><h3 class="title">Breeding Guide</h3><img
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                    </div>
                  </div>
                  <div v-show="showWhatIsDNA" class="row align-items-center justify-content-center mt-2">
                    <div class="col-7 align-items-center justify-content-evenly hidden-section p-3 small-font-3d">

                      <img src="../../public/icons/help.svg" width="600px" alt="Breeding Guide">
                    </div>
                  </div>

                  <div class="row align-items-center justify-content-center mt-4">
                    <div @click="showHowAdoption = !showHowAdoption" :class="showHowAdoption ? 'helpselected' : 'dark-text-section' " class="col-11 d-flex align-items-center justify-content-evenly">
                      <img
                          src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas" width="40px"><h3 class="title">Pet Adoption + Gift Vouchers</h3><img
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                    </div>
                  </div>
                  <div v-show="showHowAdoption" class="row align-items-center justify-content-center mt-2">
                    <div class="col-7 align-items-center justify-content-evenly hidden-section p-3 small-font-3d">

                      <img src="../../public/icons/helpvouchers.svg" width="600px" alt="Help Vouchers">
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>




          <div class="mt-4">
            <br/>
          </div>
        </div>
      </Scroll-Div>
    </section>



  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ScrollDiv from "vue-scroll-div";
import FlipCard from "@/components/FlipCard";


export default {
  name: "HomePage",
  components: {
    ScrollDiv,
    FlipCard,
  },
  data() {
    return {
      showLoadingModal: true,
      showWhatIsGallinas: false,
      showWhatIsKadena: false,
      showWhatIsAGallina: false,
      showWhatIsGenerations: false,
      showWhatIsDNA: false,
      showWhatFees: false,
      showHowAdoption: false,
      showHowToBuyEggs: false,
      showHowToBreedGallinas: false,
      showWhatIsDominance: false,
      changemargin: false,
    };
  },
  methods: {
    ...mapActions("accounts", ["getAllGameGallinas", "getAllGameEggs"]),
    ...mapGetters("accounts", ["getAccountExists", "getGameTotalGallinas", "getGameTotalEggs", "getAccountConfirmed"]),
  },
  async created() {

    //Get homepage stats
    // await this.$store.dispatch("accounts/getAllGameGallinas");
    // await this.$store.dispatch("accounts/getAllGameEggs");

    //Close loading screen
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 10);
  },
};
</script>
<style scoped>






table.comicGreen {
  font-family: "Comic Sans MS", cursive, sans-serif;
  border: 6px solid #ab925a;
  background-color: #EEEEEE;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
table.comicGreen td, table.comicGreen th {
  border: 1px solid #ac7036;
  padding: 3px 2px;
}
table.comicGreen tbody td {
  font-size: 16px;
  font-weight: bold;
  color: #2a4ea1;
}
table.comicGreen tr:nth-child(even) {
  background: #CEE0CC;
}
table.comicGreen thead {
  background: #ac7036;
  background: -moz-linear-gradient(top, #ffb56e 0%,#ac7036  66%,#ffad61 100%);
  background: -webkit-linear-gradient(top, #ffb56e 0%, #ac7036 66%, #ffad61 100%);
  background: linear-gradient(to bottom, #ffb56e 0%, #ac7036 66%, #ffad61 100%);
  border-bottom: 1px solid #ac7036;
}
table.comicGreen thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  border-left: 2px solid #D0E4F5;
}
table.comicGreen thead th:first-child {
  border-left: none;
}

table.comicGreen tfoot td {
  font-size: 16px;
}table.comicGreen {
   font-family: "Comic Sans MS", cursive, sans-serif;
   border: 6px solid #4F7849;
   background-color: #EEEEEE;
   width: 100%;
   text-align: center;
   border-collapse: collapse;
 }
table.comicGreen td, table.comicGreen th {
  border: 1px solid #4F7849;
  padding: 3px 2px;
}
table.comicGreen tbody td {
  font-size: 16px;
  font-weight: bold;
  color: #4F7849;
}
table.comicGreen tr:nth-child(even) {
  background: #CEE0CC;
}
table.comicGreen thead {
  background: #4F7849;
  background: -moz-linear-gradient(top, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  background: -webkit-linear-gradient(top, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  background: linear-gradient(to bottom, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  border-bottom: 1px solid #444444;
}
table.comicGreen thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  border-left: 2px solid #D0E4F5;
}
table.comicGreen thead th:first-child {
  border-left: none;
}

table.comicGreen tfoot td {
  font-size: 16px;
}




.kadenalogo {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 20%;
  box-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 #ccc, 0 7px 0 #ccc, 0 8px 0 #ccc,
  0 9px 0 #ccc, 0 5px 5px rgba(0, 0, 0, 0.2);
}


.helpselected {
  border: 5px #ffda86;
  border-radius: 22px;
  border-style: inset;
  background-color: #699D8FD3;
  margin-left: 2rem;
  margin-right: 2rem;
  cursor: pointer;
}

.dark-text-section {
  border: 5px #ffda86;
  border-radius: 22px;
  border-style: outset;
  background-color: #86bfb0d4;
  margin-left: 2rem;
  margin-right: 2rem;
}


.discordcolor{
  color: #ffffff !important;
}

.btnsearch {
  color: #fff;
  background-color: #5631c7;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 12px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnsearch:active {
  margin-left: 10px;
  margin-top: 10px;
  background-color: #310d9e;
}

.btnsearch:focus,
.btnsearch:hover {
  font-family: 'Mochiy Pop P One', sans-serif;
}

.btnsearch:hover:before,
.btnsearch:hover:after {
  content: '';
  z-index: -1;
}

.btnsearch:before,
.btnsearch:after {
  content: '';
  background-color: #230972;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btnsearch:after {
  background-color: #22096c;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btnsearch:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnsearch:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}

.bgdiscord{
  background-color: #5631c7;
}

.hidden-section {
  border: 5px #ffda86;
  border-radius: 22px;
  border-style: inset;
  background-color: #4B7268D3;
  margin-left: 2rem;
  margin-right: 2rem;
}

.small-font-3d {
  font-family: 'Comic Neue', cursive;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 5px 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.col-equip-lg {
  width: 60px;
  height: 60px;
}

.equipped-item-lg {
  box-shadow: 0px 5px 1px 0px rgba(0, 0, 0, 0.75);
  border: 3px outset #ffda86;
  border-radius: 20px;
  margin: .5rem auto;
  background: linear-gradient(#fbb03b, transparent),
  linear-gradient(to top left, #fbb03b, transparent),
  linear-gradient(to top right, #fdc400, transparent);
  background-blend-mode: screen;
  /*-webkit-background-clip: text;*/
  height: 60px;
  padding: 0px;
}

.paper {
  position: relative;
  height: 450px;
  width: 350px;
  background: rgba(255, 255, 255, 0.9);
  margin: -275px -225px;
  box-shadow: 0px 0px 5px 0px #888;
}

.paper::before {
  content: '';
  position: absolute;
  left: 45px;
  height: 100%;
  width: 2px;
  background: rgba(255, 0, 0, 0.4);
}

.lines {
  margin-top: 40px;
  height: calc(100% - 40px);
  width: 100%;
  background-image: repeating-linear-gradient(white 0px, white 24px, steelblue 25px);
}

.text {
  position: absolute;
  top: 65px;
  left: 55px;
  bottom: 10px;
  right: 10px;
  line-height: 25px;
  font-family: 'Indie Flower';
  overflow: hidden;
  outline: none;
}

.holes {
  position: absolute;
  left: 10px;
  height: 25px;
  width: 25px;
  background: #e8c898;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 0px #888;
}

.hole-top {
  top: 10%;
}

.hole-middle {
  top: 50%;
}

.hole-bottom {
  bottom: 10%;
}

.frontfooter {
  display: flex;
  position: relative;
  justify-content: center;
}

.frontfooter-mobile {
  display: flex;
  position: relative;
  justify-content: center;
}


.modal-box .mdialog {
  width: 100%;
  margin: 70px auto 0;
  margin-top: 0px;

}

@media (max-width: 576px) {
  .modal-box .mdialog {
    width: 95%;
  }
}

.modal.fade .mdialog {
  opacity: 0;
  transform: translate(30%, 30%) scale(0.5);
}

.modal.fade.in .mdialog {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.modal-box .mdialog .modal-content {
  border: 5px outset #ffda86;
  border-radius: 35px;
  /*box-shadow: 0px 0px 20px #c2803a, 0px 0px 20px #f6a960;*/
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  background-color: #e8c898;
}

.modal-content-no-shadow {
  border: 5px outset #ffda86;
  border-radius: 35px;
  background-color: #e8c898;
}

.modal-box .mdialog .modal-content .modal-body {
  padding: 65px 45px;
}

.modal-box .mdialog .modal-content .modal-body .icon {
  color: #827f3b;
  font-size: 80px;
  line-height: 145px;
  width: 100px;
  height: 100px;
  padding-left: 0px;
  padding-right: 10px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: 25px;
}

.modal-box .mdialog .modal-content .modal-body .inner-content {
  padding: 0 0 0 170px;
  margin: 0 0 30px;
  margin-top: 0px;
}

.modal-box .mdialog .modal-content .modal-body .title {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 5px 5px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.title-mobile {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 5px 5px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
  line-height: 30px;
}

.modal-box .mdialog .modal-content .modal-body .description {
  color: #ffffff;
  font-size: 18px;
  margin: 0;
  padding-top: 20px;
  font-family: 'Comic Neue', cursive;
}

.modal-box .mdialog .modal-content .modal-body .form-control {
  color: #6d5a36;
  background: #eee8dd;
  font-size: 15px;
  letter-spacing: 1px;
  height: 45px;
  padding: 2px 15px;
  border-radius: 50px 0 0 50px;
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control:focus {
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.modal-box .mdialog .modal-content .modal-body .subscribe {
  color: #fff;
  background: #ebbf53;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px 20px 11px;
  border: none;
  border-radius: 0 50px 50px 0;
  display: block;
  transition: all 0.3s;
}

.modal-box .mdialog .modal-content .modal-body .subscribe:hover,
.modal-box .mdialog .modal-content .modal-body .subscribe:focus {
  background: #827f3b;
}


</style>
