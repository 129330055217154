import Pact from "pact-lang-api";
import axios from "axios";

const state = {
    debug: false,
    loaded: false,
    activeAccount: null,
    accountExists: null,
    senderAccountExists: null,
    accountData: null,
    receiverAccountData: null,
    accountName: null,
    accountConfirmed: false,
    accountKeys: [],
    accountPredicate: null,
    gameTotalGallinas: null,
    gameTotalEggs: null,
    gameCurrentGift: null,
    gameCurrentGiftEndTime: null,
    userTotalGiftVouchers: null,
    userTotalGallinas: null,
    userTotalEggs: null,
    keysSpecialTable: null,
    userGallinaIds: null,
    userGallinasList: [],
    gallinaburnlist: [],
    gallinaownerlist: [],
    marketForSaleList: [],
    myGallinasCanBreed: false,
    mate1List: [],
    mate2List: [],
    inspectGallinaList: [],
    userRequests: [],
    transactionPolling: false,
    requestPending: false,
    transactionConfirmed: false,
    transactionFailed: null,
    transactionHash: null,
    transactionConfirmedResult: null,
    breedChicken1Id: null,
    breedChicken2Id: null,
    chainId: "1",
    network: "https://api.chainweb.com/chainweb/0.0/mainnet01/chain/1/pact",
    //network: "https://api.testnet.chainweb.com/chainweb/0.0/testnet04/chain/1/pact",
    //network: "https://api.chainweb.com/chainweb123/0.0/mainnet01/chain/1/pact",
    networkclover: "https://api.chainweb.com/chainweb/0.0/mainnet01/chain/1/pact/api/v1/send",
    networkid: "mainnet01",
    gasPrice: 0.000001,
    xwalletconnected: false,
    cloverwalletconnected: false,

};

const getters = {
    getActiveAccount(state) {
        return state.activeAccount;
    },
    getAccountExists(state) {
        return state.accountExists;
    },
    getSenderAccountExists(state) {
        return state.senderAccountExists;
    },
    getAccountKeys(state) {
        return state.accountKeys;
    },
    getAccountPredicate(state) {
        return state.accountPredicate;
    },
    getMyGallinasCanBreed(state) {
        return state.myGallinasCanBreed;
    },
    getAccountData(state) {
        return state.accountData;
    },
    getReceiverAccountData(state) {
        return state.receiverAccountData;
    },
    getAccountName(state) {
        if (state.debug) {
            console.log("Inside getAccountName");
        }
        return state.accountName;
    },
    getAccountConfirmed(state) {
        if (state.debug) {
            console.log("Inside store getAccountConfirmed");
            console.log("accountConfirmed:");
            console.log(state.accountConfirmed);
        }
        return state.accountConfirmed;
    },
    getGameTotalGallinas(state) {
        return state.gameTotalGallinas;
    },
    getGameTotalEggs(state) {
        return state.gameTotalEggs;
    },
    getGameCurrentGift(state) {
        return state.gameCurrentGift;
    },
    getGameCurrentGiftEndTime(state) {
        return state.gameCurrentGiftEndTime;
    },
    getUserTotalGallinas(state) {
        return state.userTotalGallinas;
    },
    getUserTotalEggs(state) {
        return state.userTotalEggs;
    },
    getUserTotalGiftVouchers(state) {
        return state.userTotalGiftVouchers;
    },
    getUserGallinaIds(state) {
        return state.userGallinaIds;
    },
    getStoreLoaded(state) {
        return state.loaded;
    },
    getUserGallinasList(state) {
        return state.userGallinasList;
    },
    getTransactionPolling(state) {
        return state.transactionPolling;
    },
    getTransactionConfirmed(state) {
        return state.transactionConfirmed;
    },
    getTransactionFailed(state) {
        return state.transactionFailed;
    },
    getTransactionHash(state) {
        return state.transactionHash;
    },
    getTransactionConfirmedResult(state) {
        return state.transactionConfirmedResult;
    },
    getBreedChicken1Id(state) {
        return state.breedChicken1Id;
    },
    getBreedChicken2Id(state) {
        return state.breedChicken2Id;
    },
    getMate1List(state) {
        return state.mate1List;
    },
    getMate2List(state) {
        return state.mate2List;
    },
    getGallinaBurnList(state) {
        return state.gallinaburnlist;
    },
    getInspectGallinaList(state) {
        return state.inspectGallinaList;
    },
    getMarketGallinasForSale(state) {
        return state.marketForSaleList;
    },
    getXWalletConnected(state) {
        return state.xwalletconnected;
    },
    getCloverWalletConnected(state) {
        return state.cloverwalletconnected;
    },
    getUserRequests(state) {
        return state.userRequests;
    },
    getRequestPending(state) {
        return state.requestPending;
    },
    getKeysSpecialTable(state){
        return state.keysSpecialTable;
    },
    getGallinaOwnerInfoList(state) {
        return  state.gallinaownerlist;
    },

};

const actions = {


    //Gets user gallina info
    async bindUserInfoNew({commit}, address) {
        try {

            const getUser = {
                request: 3,
                address: address,
            };

            const response = axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {


                    if(state.debug === true) {
                        console.log("bindUserInfoNew response data: ");
                        console.log(response.data);
                    }


                    let responseData = response.data;
                    try {
                        commit("setUserGallinasList", responseData);
                    } catch (error) {

                        if(state.debug === true) {
                            console.log("setUserGallinasList error from bindUserInfo");
                            console.log(error);
                        }


                    }


                })
                .catch(async (error) => {
                    if(state.debug === true) {
                        console.log("bindUserInfo error 1");
                        console.log(error);
                    }
                });

            return response;

        } catch (error) {
            console.log(error);
        }

    },

    async bindGallinaSetCount() {
        const getUser = {
            request: 1,
        };
        return new Promise((resolve, reject) => {
            axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {
                    resolve(response.data);
                }).catch(function (error) {
                console.log(error);
                reject();
            });
        });
    },

    async bindConfirmedTransactions(context, address) {
        const getUser = {
            request: 18,
            address: address,
        };
        return new Promise((resolve, reject) => {
            axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {
                    resolve(response.data);
                }).catch(function (error) {
                console.log(error);
                reject();
            });
        });
    },

    async bindFailedTransactions(context, address) {
        const getUser = {
            request: 19,
            address: address,
        };
        return new Promise((resolve, reject) => {
            axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {
                    resolve(response.data);
                }).catch(function (error) {
                console.log(error);
                reject();
            });
        });
    },

    async bindMarketGallinas() {
        const getUser = {
            request: 12,
        };
        return new Promise((resolve, reject) => {
            axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {
                    resolve(response.data);
                }).catch(function (error) {
                console.log(error);
                reject();
            });
        });
    },

    async bindInspectGallina(context, address) {
        const getUser = {
            request: 16,
            address: address,
        };
        return new Promise((resolve, reject) => {
            axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {
                    resolve(response.data);
                }).catch(function (error) {
                console.log(error);
                reject();
            });
        });
    },

    async bindGiftGallinaVerification(context, address) {
        const getUser = {
            request: 16,
            address: address,
        };
        return new Promise((resolve, reject) => {
            axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {
                    resolve(response.data);
                }).catch(function (error) {
                console.log(error);
                reject();
            });
        });
    },

    async bindDNARecords(context, payload) {
        const getUser = {
            request: 17,
            gene: payload.gene,
            geneval: payload.geneval,
            special: payload.special
        };
        if(state.debug === true) {
            console.log("DO DNA RECORD SEARCH");
            console.log(getUser);
        }
        return new Promise((resolve, reject) => {
            axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {
                    resolve(response.data);
                }).catch(function (error) {
                console.log(error);
                reject();
            });
        });
    },

    async bindMarketGallina(context, address) {
        const getUser = {
            request: 11,
            address: address
        };
        return new Promise((resolve, reject) => {
            axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {
                    resolve(response.data);
                }).catch(function (error) {
                console.log(error);
                reject();
            });
        });
    },

    async bindCheckPending(context, address) {
        const getUser = {
            request: 5,
            address: address,
        };
        return new Promise((resolve, reject) => {
            axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {
                    resolve(response.data);
                }).catch(function (error) {
                console.log(error);
                reject();
            });
        });
    },

    async bindGallinaRarity(context, payload) {
        const getUser = {
            request: 4,
            dna: payload.dna,
            special: payload.special
        };
        if(state.debug === true){
            console.log("GETUSER");
            console.log(getUser);
        }
        return new Promise((resolve, reject) => {
            axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {
                    resolve(response.data);
                }).catch(function (error) {
                console.log(error);
                reject();
            });
        });
    },

    async bindDoGallinaSearch(context, payload) {

        //If the user is searching for gene + gene val + generation specifically
        if (payload.gene !== "All" && payload.geneval >= 0 && payload.generation >= 0) {
            const getUser = {
                request: 7,
                breedable: payload.gallinacan,
                generation: payload.generation,
                gene: payload.gene,
                geneval: payload.geneval,
                address: payload.address
            };
            if(state.debug === true){
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(function (error) {
                    console.log(error);
                    reject();
                });
            });
        } else if (payload.gene === "All" && payload.geneval < 0 && payload.generation < 0) {
            //user is searching for any gallina, any generation, where any gene is any value.. just find all gallinas the user owns
            const getUser = {
                request: 8,
                breedable: payload.gallinacan,
                generation: payload.generation,
                gene: payload.gene,
                geneval: payload.geneval,
                address: payload.address
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(function (error) {
                    console.log(error);
                    reject();
                });
            });
        } else if (payload.gene !== "All" && payload.geneval >= 0 && payload.generation < 0) {
            //user is searching for a gallina with a specific gene and gene value, any generation
            const getUser = {
                request: 9,
                breedable: payload.gallinacan,
                generation: payload.generation,
                gene: payload.gene,
                geneval: payload.geneval,
                address: payload.address
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(function (error) {
                    console.log(error);
                    reject();
                });
            });
        } else if (payload.gene === "All" && payload.geneval <= 0 && payload.generation >= 0) {
            //user is searching for any gene any value, with a specific generation
            const getUser = {
                request: 10,
                breedable: payload.gallinacan,
                generation: payload.generation,
                gene: payload.gene,
                geneval: payload.geneval,
                address: payload.address
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(function (error) {
                    console.log(error);
                    reject();
                });
            });
        } else if (payload.gene === "All" && payload.geneval >= 0 && payload.generation >= 0) {
            //user made a mistake, find any gene any value, specific generation
            const getUser = {
                request: 10,
                breedable: payload.gallinacan,
                generation: payload.generation,
                gene: payload.gene,
                geneval: payload.geneval,
                address: payload.address
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(function (error) {
                    console.log(error);
                    reject();
                });
            });
        } else if (payload.gene === "All" && payload.geneval >= 0 && payload.generation < 0) {
            //user is searching through all genes for a specific value, any generation.. find all gallinas
            const getUser = {
                request: 8,
                breedable: payload.gallinacan,
                generation: payload.generation,
                gene: payload.gene,
                geneval: payload.geneval,
                address: payload.address
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {

                        let genetitlelist = ["gene_1_p", "gene_2_p", "gene_3_p", "gene_4_p", "gene_5_p", "gene_6_p", "gene_7_p", "gene_8_p", "gene_9_p", "gene_10_p", "special"];
                        let returnmelist = [];

                        console.log("INSIDE");
                        console.log("RES.data");
                        console.log(response.data);

                        if (response.data !== undefined) {

                            console.log("RESPONSE DATA LENGTH");
                            console.log(response.data.length);
                            console.log("RESPONSE DATA 0");
                            console.log(response.data[0]);
                            if (response.data.length !== undefined) {

                                for (let i = 0; i < response.data.length; i++) {//go through all gallinas in list

                                    for (let k = 0; k < genetitlelist.length; k++) {//for each gallina in list we go through genetitlelist and check for a match

                                        console.log("COMPARING " + response.data[i][genetitlelist[k]] + " to " + payload.geneval);

                                        if (response.data[i][genetitlelist[k]].toString() === payload.geneval.toString()) {

                                            console.log("MATCH");

                                            if (returnmelist.includes(response.data[i]) === false) {
                                                console.log("ADDING TO LIST");
                                                returnmelist.push(response.data[i]);
                                            }


                                        }


                                    }


                                }


                            }


                        }

                        console.log("RETURNME LIST");
                        console.log(returnmelist);

                        resolve(returnmelist);


                    }).catch(function (error) {
                    console.log("Error searching");
                    console.log(error);
                    reject(error);


                });

            });
        }

    },

    async bindDoGallinaMarketSearch(context, payload) {

        //If the user is searching for gene + gene val + generation specifically
        if (payload.gene !== "All" && payload.geneval >= 0 && payload.generation >= 0) {
            const getUser = {
                request: 13,
                breedable: payload.gallinacan,
                generation: payload.generation,
                gene: payload.gene,
                geneval: payload.geneval,
                address: payload.address
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(function (error) {
                    console.log(error);
                    reject();
                });
            });
        } else if (payload.gene === "All" && payload.geneval < 0 && payload.generation < 0) {
            //user is searching for any gallina, any generation, where any gene is any value.. just find all gallinas
            const getUser = {
                request: 12,
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(function (error) {
                    console.log(error);
                    reject();
                });
            });
        } else if (payload.gene !== "All" && payload.geneval >= 0 && payload.generation < 0) {
            //user is searching for a gallina with a specific gene and gene value, any generation
            const getUser = {
                request: 14,
                gene: payload.gene,
                geneval: payload.geneval,
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(function (error) {
                    console.log(error);
                    reject();
                });
            });
        } else if (payload.gene === "All" && payload.geneval <= 0 && payload.generation >= 0) {
            //user is searching for any gene any value, with a specific generation
            const getUser = {
                request: 15,
                generation: payload.generation,
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(function (error) {
                    console.log(error);
                    reject();
                });
            });
        } else if (payload.gene === "All" && payload.geneval >= 0 && payload.generation >= 0) {
            //user made a mistake, find any gene any value, specific generation
            const getUser = {
                request: 15,
                generation: payload.generation,
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(function (error) {
                    console.log(error);
                    reject();
                });
            });
        } else if (payload.gene === "All" && payload.geneval >= 0 && payload.generation < 0) {
            //user is searching through all genes for a specific value, any generation.. find all gallinas
            const getUser = {
                request: 12,
            };
            if(state.debug === true) {
                console.log("DO GALLINA SEARCH EXEC");
                console.log(getUser);
            }
            return new Promise((resolve, reject) => {
                axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then((response) => {

                        let genetitlelist = ["gene_1_p", "gene_2_p", "gene_3_p", "gene_4_p", "gene_5_p", "gene_6_p", "gene_7_p", "gene_8_p", "gene_9_p", "gene_10_p", "special"];
                        let returnmelist = [];

                        if(state.debug === true) {
                            console.log("INSIDE");
                            console.log("RES.data");
                            console.log(response.data);
                        }

                        if (response.data !== undefined) {

                            if(state.debug === true) {
                                console.log("RESPONSE DATA LENGTH");
                                console.log(response.data.length);
                                console.log("RESPONSE DATA 0");
                                console.log(response.data[0]);
                            }
                            if (response.data.length !== undefined) {

                                for (let i = 0; i < response.data.length; i++) {//go through all gallinas in list

                                    for (let k = 0; k < genetitlelist.length; k++) {//for each gallina in list we go through genetitlelist and check for a match

                                        if(state.debug === true) {
                                            console.log("COMPARING " + response.data[i][genetitlelist[k]] + " to " + payload.geneval);
                                        }

                                        if (response.data[i][genetitlelist[k]].toString() === payload.geneval.toString()) {

                                            console.log("MATCH");

                                            if (returnmelist.includes(response.data[i]) === false) {
                                                if(state.debug === true) {
                                                    console.log("ADDING TO LIST");
                                                }
                                                returnmelist.push(response.data[i]);
                                            }


                                        }


                                    }


                                }


                            }


                        }

                        if(state.debug === true) {
                            console.log("RETURNME LIST");
                            console.log(returnmelist);
                        }

                        resolve(returnmelist);


                    }).catch(function (error) {
                    if(state.debug === true) {
                        console.log("Error searching");
                        console.log(error);
                    }
                    reject(error);


                });

            });
        }

    },

    async bindGallinaSetCountOLD() {
        try {

            const getUser = {
                request: 1,
            };

            await axios.post("/phpstuff/ajaxfile.php", getUser)
                .then((response) => {



                    console.log("bindGallinaSetCount response data: ");
                    console.log(response.data);

                    return response.data;


                })
                .catch(async (error) => {
                    console.log("bindGallinaSetCount error");
                    console.log(error);
                });


        } catch (error) {
            console.log(error);
        }

    },

    //connects clover wallet
    async connectCloverwallet() {

        if (state.debug) {
            console.log("connectCloverwallet");
        }

        //let kadena = window.clover_kadena;
        //let networkId = state.networkid;
       // let icon = "https://gallinas.io/icons/favicon.png";

        if(window.clover_kadena){

            console.log(window.clover_kadena);

            if (window.clover_kadena.isCloverWallet === true) {

                await window.clover_kadena.getAccount().then(async(response) =>{


                    if(response){
                        console.log("RESPONSE");
                        console.log(response);
                        await this.dispatch("accounts/getAccountVerification", response).then(async()=>{

                                let t_keys = state.accountKeys;
                                let t_pred = state.accountPredicate;
                                let t_accountdata = state.accountData;

                                if (state.debug) {
                                    console.log("keys got from store:");
                                    console.log(t_keys);
                                    console.log("predicate got from store:");
                                    console.log(t_pred);
                                    console.log("data got from store:");
                                    console.log(t_accountdata);
                                }


                                if (state.debug) {
                                    console.log("dispatching confirm to store with account name:");
                                    console.log(response);
                                }
                                await this.dispatch("accounts/confirmAccountExists", response);

                            if (state.debug) {
                                        console.log("setting local storage up.");
                                    }
                                    localStorage.setItem("accountName", response);
                                    localStorage.setItem("isConnected", true);
                                    localStorage.setItem("isUsingXwallet", false);
                                    localStorage.setItem("isUsingCloverwallet", true);
                                    state.cloverwalletconnected = true;
                                    if (state.debug) {
                                        console.log("isConnected:");
                                        let connected = localStorage.getItem("isConnected");
                                        console.log(connected);
                                        console.log("accountname:");
                                        let aname = localStorage.getItem("accountName");
                                        console.log(aname);
                                        console.log("isUsingCloverwallet:");
                                        let clvconnect = localStorage.getItem("isUsingCloverwallet");
                                        console.log(clvconnect);
                                    }

                        });
                    }




                } );

                // if (accountResult.status === "success") {
                //     if (state.debug) {
                //         console.log(accountResult.message);
                //         console.log(accountResult.wallet.account);
                //     }
                //
                //
                //     await this.dispatch("accounts/getAccountVerification", accountResult.wallet.account);
                //
                //     let t_keys = state.accountKeys;
                //     let t_pred = state.accountPredicate;
                //     let t_accountdata = state.accountData;
                //
                //     if (state.debug) {
                //         console.log("keys got from store:");
                //         console.log(t_keys);
                //         console.log("predicate got from store:");
                //         console.log(t_pred);
                //         console.log("data got from store:");
                //         console.log(t_accountdata);
                //     }
                //
                //
                //     if (state.debug) {
                //         console.log("dispatching confirm to store with account name:");
                //         console.log(accountResult.wallet.account);
                //     }
                //     await this.dispatch("accounts/confirmAccountExists", accountResult.wallet.account);
                //
                //     if (state.debug) {
                //         console.log("setting local storage up.");
                //     }
                //     localStorage.setItem("accountName", accountResult.wallet.account);
                //     localStorage.setItem("isConnected", true);
                //     localStorage.setItem("isUsingXwallet", true);
                //     state.xwalletconnected = true;
                //     if (state.debug) {
                //         console.log("isConnected:");
                //         let connected = localStorage.getItem("isConnected");
                //         console.log(connected);
                //         console.log("accountname:");
                //         let aname = localStorage.getItem("accountName");
                //         console.log(aname);
                //         console.log("isUsingXwallet:");
                //         let xwalconnect = localStorage.getItem("isUsingXwallet");
                //         console.log(xwalconnect);
                //     }
                //
                // } else {
                //     console.log("Please connect X Wallet");
                //     localStorage.setItem("accountName", null);
                //     localStorage.setItem("isConnected", false);
                //     localStorage.setItem("isUsingXwallet", false);
                //     state.xwalletconnected = false;
                // }
            }


            //await kadena.request({method: "kda_connect", networkId: networkId, icon: icon});

        }




    },

    //connects xwallet
    async connectXwallet() {

        if (state.debug) {
            console.log("connectXwallet");
        }

        let kadena = window.kadena;
        let networkId = state.networkid;
        let icon = "https://gallinas.io/icons/favicon.png";

        if (window.kadena.isKadena === true) {
            let accountResult = await kadena.request({
                method: "kda_requestAccount",
                networkId: networkId,
                domain: window.location.hostname
            });

            if (state.debug) {
                console.log("accountResult:");
                console.log(accountResult);
            }


            if (accountResult.status === "success") {
                if (state.debug) {
                    console.log(accountResult.message);
                    console.log(accountResult.wallet.account);
                }


                await this.dispatch("accounts/getAccountVerification", accountResult.wallet.account);

                let t_keys = state.accountKeys;
                let t_pred = state.accountPredicate;
                let t_accountdata = state.accountData;

                if (state.debug) {
                    console.log("keys got from store:");
                    console.log(t_keys);
                    console.log("predicate got from store:");
                    console.log(t_pred);
                    console.log("data got from store:");
                    console.log(t_accountdata);
                }


                if (state.debug) {
                    console.log("dispatching confirm to store with account name:");
                    console.log(accountResult.wallet.account);
                }
                await this.dispatch("accounts/confirmAccountExists", accountResult.wallet.account);

                if (state.debug) {
                    console.log("setting local storage up.");
                }
                localStorage.setItem("accountName", accountResult.wallet.account);
                localStorage.setItem("isConnected", true);
                localStorage.setItem("isUsingXwallet", true);
                state.xwalletconnected = true;
                if (state.debug) {
                    console.log("isConnected:");
                    let connected = localStorage.getItem("isConnected");
                    console.log(connected);
                    console.log("accountname:");
                    let aname = localStorage.getItem("accountName");
                    console.log(aname);
                    console.log("isUsingXwallet:");
                    let xwalconnect = localStorage.getItem("isUsingXwallet");
                    console.log(xwalconnect);
                }

            } else {
                console.log("Please connect X Wallet");
                localStorage.setItem("accountName", null);
                localStorage.setItem("isConnected", false);
                localStorage.setItem("isUsingXwallet", false);
                state.xwalletconnected = false;
            }
        }


        await kadena.request({method: "kda_connect", networkId: networkId, icon: icon});


    },

    //gets xwallet account
    async getXwalletAccount() {
        let kadena = window.kadena;
        if (state.debug) {
            console.log("getxwalletaccount");
        }


        let accountResult = await kadena.request({method: "kda_getSelectedAccount", networkId: state.networkid});

        if (state.debug) {
            console.log("accountResult:");

            console.log(accountResult);
        }

    },

    //Local call to verify if a coin account exists, used during account login to verify account existance
    async getAccountVerification({commit}, accountName) {
        if (state.debug) {
            console.log("Getting Kadena Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(coin.details ${JSON.stringify(accountName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Account Found");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                dataResult = [{...data.result}];
                exists = true;
            } else {
                if (state.debug) {
                    console.log("No Account Found");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }
        commit("setAccountExists", exists);
        if (exists === true) {
            commit("setAccountData", dataResult);
            commit("setAccountKeys", dataResult[0]["data"]["guard"]["keys"]);
            commit("setAccountPredicate", dataResult[0]["data"]["guard"]["pred"]);
            localStorage.setItem("accountPredicate", dataResult[0]["data"]["guard"]["pred"]);
            localStorage.setItem("accountPublicKey", dataResult[0]["data"]["guard"]["keys"][0]);
            if (state.debug) {
                console.log(dataResult);
                console.log(dataResult[0]["data"]["guard"]["keys"][0]);
                console.log(dataResult[0]["data"]["guard"]["keys"]);
            }
        }
    },

    //gets breed verification data
    async getBreedGallinasVerification({commit}, payload) {
        if (state.debug) {
            console.log("Getting Kadena Account");
        }
        let canbreed = false;
        let gallina1id = payload.gallina1id;
        let gallina2id = payload.gallina2id;

        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.can-breed ${JSON.stringify(gallina1id)} ${JSON.stringify(gallina2id)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("These Gallinas can breed!");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                canbreed = true;
            } else {
                if (state.debug) {
                    console.log("These Gallinas cannot breed!");
                }
                canbreed = false;
            }

        } catch (error) {
            console.log(error);
        }

        commit("setMyGallinasCanBreed", canbreed);
    },

    //Local call to verify if a coin account exists, used during gallina transfers from account to account
    async getTransferVerification({commit}, accountName) {
        if (state.debug) {
            console.log("Getting Kadena Sender Account");
        }
        let exists = false;
        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(coin.details ${JSON.stringify(accountName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Recipient Account Found");
                }
                if (state.debug) {
                    console.log(data.result);
                }
                exists = true;
                dataResult = [{...data.result}];
            } else {
                if (state.debug) {
                    console.log("Recipient Account NOT Found");
                }
                exists = false;
            }

        } catch (error) {
            console.log(error);
        }
        commit("setSenderAccountExists", exists);
        if (exists === true) {
            commit("setReceiverAccountData", dataResult);
        }
    },

    //Resets login, used when disconnecting/canceling the login screen
    async resetAccountExists({commit}) {
        let reset = null;
        this.accountExists = null;
        if (window.kadena && window.kadena.isKadna === true) {
            let result = await window.kadena.request({
                method: "kda_disconnect",
                networkId: state.networkid,
                domain: window.location.hostname
            });

            console.log(result);
        }

        commit("setAccountExists", reset);
        commit("setAccountConfirmed", false);
        commit("setXWalletConnected", false);
        commit("setCloverWalletConnected", false);
        localStorage.setItem("isUsingXwallet", false);
        localStorage.setItem("isUsingCloverwallet", false);
    },

    //Confirms login information and sets it in game state
    async confirmAccountExists({commit}, acctname) {
        if (state.debug) {
            console.log("inside confirm account exists in store");
            console.log("attempting commit account name with acct name:");
            console.log(acctname);
        }
        commit("setAccountName", acctname);
        if (state.debug) {
            console.log("attempting commit account exists with true");
        }
        commit("setAccountExists", true);
        if (state.debug) {
            console.log("attempting commit account confirmed with true");
        }
        commit("setAccountConfirmed", true);
    },

    //Grabs the total gallina account across the entire game
    async getAllGameGallinas({commit}) {
        if (state.debug) {
            console.log("Getting Total Game Gallinas");
        }
        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-total-gallinas)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got Total Gallinas");
                    console.log(data.result);
                    console.log(dataResult[0].data);
                }
            } else {
                if (state.debug) {
                    console.log("Error 2: Could not get total Gallinas.");
                }
            }

        } catch (error) {
            console.log(error);
        }
        if (dataResult !== null) {
            commit("setGameTotalGallinas", dataResult[0].data);
        } else {
            commit("setGameTotalGallinas", 0);
        }

    },

    //Grabs all the gallinas for sale
    async getGallinasForSale({commit}) {
        if (state.debug) {
            console.log("Getting Gallina IDs and Prices for sale:");
        }
        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-gallinas-for-sale)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got Gallina IDs and Prices for sale");
                    console.log(data.result);
                    console.log(dataResult[0].data);
                }

                commit("setMarketForSaleList", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error 10: Could not get Gallinas for sale.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Grabs all the gallinas for sale by gene
    async getGallinasForSaleByGene({commit}, payload) {
        if (state.debug) {
            console.log("Getting Gallinas for sale by gene:");
        }

        let gene = payload.gene;
        let geneval = payload.geneval;

        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-marketplace-gallina-by-gene ${JSON.stringify(gene)} ${JSON.stringify(geneval)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got Gallina IDs and Prices for sale by gene");
                    console.log(data.result);
                    console.log(dataResult[0].data);
                }

                commit("setMarketForSaleList", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error 44: Could not get Gallinas for sale by gene.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Grabs the total Egg count for the entire game
    async getAllGameEggs({commit}) {
        if (state.debug) {
            console.log("Getting Total Game Eggs");
        }
        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-total-eggs)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150001, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got Total Eggs");
                    console.log(data.result);
                    console.log(dataResult[0].data);
                }
            } else {
                if (state.debug) {
                    console.log("Error 3: Could not get total Eggs.");
                }
            }

        } catch (error) {
            console.log(error);
        }
        if (dataResult !== null) {
            commit("setGameTotalEggs", dataResult[0].data);
        } else {
            commit("setGameTotalEggs", 0);
        }

    },

    //Grabs the current gift from the gift store
    async getCurrentGift({commit}) {
        if (state.debug) {
            console.log("Getting Current Gift");
        }
        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-current-gift)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got Current Gift");
                    console.log(data.result);
                    console.log(dataResult[0].data);
                }
            } else {
                if (state.debug) {
                    console.log("Error 14: Could not get current gift.");
                }
            }

        } catch (error) {
            console.log(error);
        }
        if (dataResult !== null) {
            commit("setCurrentGift", dataResult[0].data);
        } else {
            commit("setCurrentGift", 0);
        }

    },

    //Grabs the current gift end time from the gift store
    async getCurrentGiftEndTime({commit}) {
        if (state.debug) {
            console.log("Getting Current Gift End Time");
        }
        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-current-gift-end)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got Current Gift End Time");
                    console.log(data.result);
                    console.log(dataResult[0].data);
                }
            } else {
                if (state.debug) {
                    console.log("Error 142: Could not get current gift end time.");
                }
            }

        } catch (error) {
            console.log(error);
        }
        if (dataResult !== null) {
            commit("setCurrentGiftEndTime", dataResult[0].data);
        } else {
            commit("setCurrentGiftEndTime", 0);
        }

    },

    //Grabs the User Gallina Information (Count and Details of Each Gallina, can be time consuming right now)
    async getAllUserGallinas({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Gallina IDs for:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-user-gallinas ${JSON.stringify(accountName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Gallinas");
                    console.log(data.result);
                    console.log(dataResult[0].data);
                }


                //Get all user gallina ids and gallina count
                let ids = [];
                //let eggs = 0;
                let gallinas = 0;
                for (let i = 0; i < dataResult[0].data.length; i++) {
                    ids.push(dataResult[0].data[i].id);
                    if(state.debug){
                        console.log("Added id: " + dataResult[0].data[i].id);
                    }
                    if (dataResult[0].data[i].id !== "Egg") {
                        gallinas = gallinas + 1;
                    }
                }

                //Get details of each individual gallina
                let gallinaDetailsList = [];
                for (let k = 0; k < ids.length; k++) {
                    if (ids[k] !== "Egg") {

                        let gallinaData = await Pact.fetch.local({
                            pactCode: `(free.collect-gallinas.get-gallina-details ${JSON.stringify(ids[k])})`,
                            meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
                        }, state.network);

                        if (gallinaData.result.status === "success") {
                            if (state.debug) {
                                console.log("the result");
                                console.log(gallinaData.result);
                            }

                            let newbirthday = null;
                            if (gallinaData.result.data["birthday"].time !== undefined) {
                                if (state.debug) {
                                    console.log("time");
                                    console.log(gallinaData.result.data["birthday"].time.slice(0, 10));
                                }

                                newbirthday = gallinaData.result.data["birthday"].time.slice(0, 10);

                            }

                            if (gallinaData.result.data["birthday"].timep !== undefined) {
                                if (state.debug) {
                                    console.log("timep");
                                    console.log(gallinaData.result.data["birthday"].timep.slice(0, 10));
                                }

                                newbirthday = gallinaData.result.data["birthday"].timep.slice(0, 10);

                            }

                            let newnbt = null;
                            if (gallinaData.result.data["nextbreed"].time !== undefined) {
                                if (state.debug) {
                                    console.log("time");
                                    console.log(gallinaData.result.data["nextbreed"].time.slice(0, 10));
                                }

                                newnbt = gallinaData.result.data["nextbreed"].time.slice(0, 10);
                            }

                            if (gallinaData.result.data["nextbreed"].timep !== undefined) {
                                if (state.debug) {
                                    console.log("time");
                                    console.log(gallinaData.result.data["nextbreed"].timep.slice(0, 10));
                                }

                                newnbt = gallinaData.result.data["nextbreed"].timep.slice(0, 10);
                            }


                            let gallinaDetailsObject = {
                                id: accountName,
                                gender: gallinaData.result.data.gender,
                                generation: gallinaData.result.data["generation"].int,
                                motherid: gallinaData.result.data["mother-id"],
                                fatherid: gallinaData.result.data["father-id"],
                                birthday: newbirthday,
                                nbd: newnbt,
                                special: gallinaData.result.data["special"].int,
                            };

                            gallinaDetailsList.push(gallinaDetailsObject);


                        } else {
                            if (state.debug) {
                                console.log("Error 5: Could not get a Gallinas Details.");
                                console.log(gallinaData.result);
                            }

                        }

                    }

                }

                if (state.debug) {
                    console.log("GallinaDetailsList:");
                    console.log(gallinaDetailsList);
                }


                commit("setUserGallinaIds", ids);
                commit("setUserTotalGallinas", gallinas);
                commit("setUserGallinasList", gallinaDetailsList);

            } else {
                if (state.debug) {
                    console.log("Error 4: Could not get User Gallinas IDs.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }
        if (dataResult !== null) {
            commit("setUserGallinaIds", dataResult[0].data);
        } else {
            commit("setUserGallinaIds", 0);
        }

    },

    //Grabs all user Gallina Information in a single call
    async getUserAllGallinasDetails({commit}, accountName) {
        if (state.debug) {
            console.log("Getting List of All Gallina Details:");
            console.log("accountName:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-user-gallinas-details ${JSON.stringify(accountName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Gallinas Details:");
                    console.log(data.result);
                    console.log(dataResult[0].data);
                }

                //commit("setMarketForSaleList", dataResult[0].data);

                commit("setUserGallinasList", dataResult[0].data);

            } else {
                if (state.debug) {
                    console.log("Error 892: Could not get user Gallinas Details.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets the Users Gallina Count, also sets user gallina ids list and fills it with their gallina ids
    async getUserGallinaCount({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Gallina Count:");
            console.log(accountName);
        }
        let dataResult = null;
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-user-gallinas ${JSON.stringify(accountName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                dataResult = [{...data.result}];
                if (state.debug) {
                    console.log("Got User Gallina Count");
                    console.log(data.result);
                    console.log(dataResult[0].data);
                }

                //Get gallina count
                let gallinas = 0;
                for (let i = 0; i < dataResult[0].data.length; i++) {
                    if (dataResult[0].data[i].id !== "Egg") {
                        gallinas = gallinas + 1;
                    }
                }

                commit("setUserTotalGallinas", gallinas);

            } else {
                if (state.debug) {
                    console.log("Error 4: Could not get User Gallinas IDs.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }
        if (dataResult !== null) {
            commit("setUserGallinaIds", dataResult[0].data);
        } else {
            commit("setUserGallinaIds", 0);
        }

    },

    //Gets user egg count
    async getAllUserEggs({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Egg Count For:");
            console.log(accountName);
        }
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-balance ${JSON.stringify("Egg")} ${JSON.stringify(accountName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got User Egg Count");
                    console.log(data.result.data);
                    //console.log(dataResult[0].data);
                }


                commit("setUserTotalEggs", data.result.data);

            } else {
                if (state.debug) {
                    console.log("Error 7: Could not get User Egg Count.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets gallina ids from new special-table to check for remints
    async getKeysSpecialTable({commit}) {
        if (state.debug) {
            console.log("Getting Keys from special table:");
        }
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-ids-special)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 180000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {

                if (state.debug) {
                    console.log("getKeysSpecialTable res");
                    console.log(data);
                    console.log("Got Keys from Special Table");
                    console.log(data.result.data);
                    //console.log(dataResult[0].data);
                }


                commit("setKeysSpecialTable", data.result.data);

            } else {
                if (state.debug) {
                    console.log("Error 0456: Could not get ids special.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Gets user gift voucher count
    async getAllUserGiftVouchers({commit}, accountName) {
        if (state.debug) {
            console.log("Getting User Gift Voucher Count For:");
            console.log(accountName);
        }
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;
            let data = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-gift-vouchers ${JSON.stringify(accountName)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (data.result.status === "success") {
                if (state.debug) {
                    console.log("Got User Gift Voucher Count");
                    console.log(data.result.data);
                    //console.log(dataResult[0].data);
                }


                commit("setUserTotalGiftVouchers", data.result.data);

            } else {
                if (state.debug) {
                    console.log("Error 77: Could not get User Gift Voucher Count.");
                    console.log(data.result);
                }
            }

        } catch (error) {
            console.log(error);
        }

    },

    //Grab gallina mate 1s info
    async getGallinaMate1Info({commit}, gallinaId) {
        if (state.debug) {
            console.log("Getting Gallina Mate 1 Info for Gallina ID:");
            console.log(gallinaId);
        }
        let gallinaDetailsList = [];
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;

            let gallinaData = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-gallina-details ${JSON.stringify(gallinaId)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (gallinaData.result.status === "success") {
                if (state.debug) {
                    console.log("Gallina Mate 1 info result");
                    console.log(gallinaData.result);
                }

                let newbirthday = null;
                if (gallinaData.result.data["birthday"].time !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["birthday"].time.slice(0, 10));
                    }

                    newbirthday = gallinaData.result.data["birthday"].time.slice(0, 10);

                }

                if (gallinaData.result.data["birthday"].timep !== undefined) {
                    if (state.debug) {
                        console.log("timep");
                        console.log(gallinaData.result.data["birthday"].timep.slice(0, 10));
                    }

                    newbirthday = gallinaData.result.data["birthday"].timep.slice(0, 10);

                }


                let newnbt = null;
                if (gallinaData.result.data["nextbreed"].time !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["nextbreed"].time.slice(0, 10));
                    }

                    newnbt = gallinaData.result.data["nextbreed"].time.slice(0, 10);
                }

                if (gallinaData.result.data["nextbreed"].timep !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["nextbreed"].timep.slice(0, 10));
                    }

                    newnbt = gallinaData.result.data["nextbreed"].timep.slice(0, 10);
                }

                if (state.debug) {
                    console.log("generation");
                    console.log(gallinaData.result.data["generation"].int);
                }


                let gallinaDetailsObject = {
                    id: gallinaId,
                    gender: gallinaData.result.data.gender,
                    generation: gallinaData.result.data["generation"].int,
                    motherid: gallinaData.result.data["mother-id"],
                    fatherid: gallinaData.result.data["father-id"],
                    birthday: newbirthday,
                    nbd: newnbt,
                    special: gallinaData.result.data["special"].int,
                };

                gallinaDetailsList.push(gallinaDetailsObject);


            } else {
                if (state.debug) {
                    console.log("Error 8: Could not get Gallina Mate Details for ID:");
                    console.log(gallinaId);
                    console.log("Response:");
                    console.log(gallinaData.result);
                }

            }

        } catch (error) {
            console.log(error);
        }
        if (gallinaDetailsList.length > 0) {
            if (state.debug) {
                console.log("Got Gallina Mate Details for id:");
                console.log(gallinaId);
                console.log(gallinaDetailsList);
            }
            commit("setMate1List", gallinaDetailsList);
        }

    },

    //Grab gallina mate 2s info
    async getGallinaMate2Info({commit}, gallinaId) {
        if (state.debug) {
            console.log("Getting Gallina Mate 2 Info for Gallina ID:");
            console.log(gallinaId);
        }
        let gallinaDetailsList = [];
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;

            let gallinaData = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-gallina-details ${JSON.stringify(gallinaId)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (gallinaData.result.status === "success") {
                if (state.debug) {
                    console.log("Gallina Mate 2 info result");
                    console.log(gallinaData.result);
                }

                let newbirthday = null;
                if (gallinaData.result.data["birthday"].time !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["birthday"].time.slice(0, 10));
                    }

                    newbirthday = gallinaData.result.data["birthday"].time.slice(0, 10);

                }

                if (gallinaData.result.data["birthday"].timep !== undefined) {
                    if (state.debug) {
                        console.log("timep");
                        console.log(gallinaData.result.data["birthday"].timep.slice(0, 10));
                    }

                    newbirthday = gallinaData.result.data["birthday"].timep.slice(0, 10);

                }

                let newnbt = null;
                if (gallinaData.result.data["nextbreed"].time !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["nextbreed"].time.slice(0, 10));
                    }

                    newnbt = gallinaData.result.data["nextbreed"].time.slice(0, 10);
                }

                if (gallinaData.result.data["nextbreed"].timep !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["nextbreed"].timep.slice(0, 10));
                    }

                    newnbt = gallinaData.result.data["nextbreed"].timep.slice(0, 10);
                }

                if (state.debug) {
                    console.log("generation");
                    console.log(gallinaData.result.data["generation"].int);
                }


                let gallinaDetailsObject = {
                    id: gallinaId,
                    gender: gallinaData.result.data.gender,
                    generation: gallinaData.result.data["generation"].int,
                    motherid: gallinaData.result.data["mother-id"],
                    fatherid: gallinaData.result.data["father-id"],
                    birthday: newbirthday,
                    nbd: newnbt,
                    special: gallinaData.result.data["special"].int,
                };

                gallinaDetailsList.push(gallinaDetailsObject);


            } else {
                if (state.debug) {
                    console.log("Error 9: Could not get Gallina Mate 2 Details for ID:");
                    console.log(gallinaId);
                    console.log("Response:");
                    console.log(gallinaData.result);
                }

            }

        } catch (error) {
            console.log(error);
        }
        if (gallinaDetailsList.length > 0) {
            if (state.debug) {
                console.log("Got Gallina Mate 2 Details for id:");
                console.log(gallinaId);
                console.log(gallinaDetailsList);
            }
            commit("setMate2List", gallinaDetailsList);
        }

    },

    //Grab gallina info for burn
    async getGallinaBurnInfo({commit}, gallinaId) {
        if (state.debug) {
            console.log("Getting Gallina to Burn Info for Gallina ID:");
            console.log(gallinaId);
        }
        let gallinaDetailsList = [];
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;

            let gallinaData = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-gallina-details ${JSON.stringify(gallinaId)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (gallinaData.result.status === "success") {
                if (state.debug) {
                    console.log("Gallina to Burn info result");
                    console.log(gallinaData.result);
                }

                let newbirthday = null;
                if (gallinaData.result.data["birthday"].time !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["birthday"].time.slice(0, 10));
                    }

                    newbirthday = gallinaData.result.data["birthday"].time.slice(0, 10);

                }

                if (gallinaData.result.data["birthday"].timep !== undefined) {
                    if (state.debug) {
                        console.log("timep");
                        console.log(gallinaData.result.data["birthday"].timep.slice(0, 10));
                    }

                    newbirthday = gallinaData.result.data["birthday"].timep.slice(0, 10);

                }

                let newnbt = null;
                if (gallinaData.result.data["nextbreed"].time !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["nextbreed"].time.slice(0, 10));
                    }

                    newnbt = gallinaData.result.data["nextbreed"].time.slice(0, 10);
                }

                if (gallinaData.result.data["nextbreed"].timep !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["nextbreed"].timep.slice(0, 10));
                    }

                    newnbt = gallinaData.result.data["nextbreed"].timep.slice(0, 10);
                }

                if (state.debug) {
                    console.log("generation");
                    console.log(gallinaData.result.data["generation"].int);
                }


                let gallinaDetailsObject = {
                    id: gallinaId,
                    gender: gallinaData.result.data.gender,
                    generation: gallinaData.result.data["generation"].int,
                    motherid: gallinaData.result.data["mother-id"],
                    fatherid: gallinaData.result.data["father-id"],
                    birthday: newbirthday,
                    nbd: newnbt,
                    special: gallinaData.result.data["special"].int,
                };

                gallinaDetailsList.push(gallinaDetailsObject);


            } else {
                if (state.debug) {
                    console.log("Error 2963: Could not get Gallina to burn Details for ID:");
                    console.log(gallinaId);
                    console.log("Response:");
                    console.log(gallinaData.result);
                }

            }

        } catch (error) {
            console.log(error);
        }
        if (gallinaDetailsList.length > 0) {
            if (state.debug) {
                console.log("Got Gallina to burn Details for id:");
                console.log(gallinaId);
                console.log(gallinaDetailsList);
            }
            commit("setGallinaBurnList", gallinaDetailsList);
        }

    },

    //Grab gallinas owner info
    async getGallinaOwnerInfo({commit}, gallinaId) {
        if (state.debug) {
            console.log("Getting Gallina owner info for ID:");
            console.log(gallinaId);
        }
        let gallinaDetailsList = [];
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;

            let gallinaData = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-gallina-owner ${JSON.stringify(gallinaId)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (gallinaData.result.status === "success") {
                if (state.debug) {
                    console.log("Gallina owner info result");
                    console.log(gallinaData.result);
                }

                gallinaDetailsList.push(gallinaData.result);


            } else {
                if (state.debug) {
                    console.log("Error 2963: Could not get Gallina owner info for ID:");
                    console.log(gallinaId);
                    console.log("Response:");
                    console.log(gallinaData.result);
                }

            }

        } catch (error) {
            console.log(error);
        }
        if (gallinaDetailsList.length > 0) {
            if (state.debug) {
                console.log("Got Gallina owner info for id:");
                console.log(gallinaId);
                console.log(gallinaDetailsList);
            }
            commit("setGallinaOwnerInfoList", gallinaDetailsList);
        }

    },

    async storeGetUserRequestInfo({commit}, address) { //gets all of a users info

        try {
            if (address !== null && address !== undefined) {

                if (state.debug) {
                    console.log("inside storeGetUserTransactionInfo");
                    console.log("address: " + address);
                }


                const addressForId = address;

                let idToAdd = addressForId;

                if (state.debug) {
                    console.log("Getting user requests for address: ");
                    console.log(idToAdd);
                }


                const getUser = {
                    request: 22,
                    address: idToAdd,
                };

                let responseData;
                let result = null;

                result = axios.post("/phpstuff/ajaxfile.php", getUser)
                    .then(async (response) => {

                        if (state.debug) {
                            console.log("User requests response data: ");
                            console.log(response.data);
                        }

                        responseData = response.data;
                        try {
                            await commit("setUserRequests", responseData);
                        } catch (error) {
                            if (state.debug) {
                                console.log("setUserTransactions error");
                                console.log(error);
                            }

                        }


                    })
                    .catch(async (error) => {
                        console.log(error);
                    });
                if (result !== null) {

                    let info = this.getUserRequests();

                    if (state.debug) {
                        console.log("info from getUserTransactions test: " + info);
                    }

                    return responseData;
                }
            }
        } catch (error) {

            if (state.debug) {
                console.log("storeGetUserRequestInfo error");
                console.log(error);
            }
        }


    },

    async storeNewTransaction(context, payload) {
        //console.log("Inside storeNewUser");
        //console.log("addressToAdd:" + payload.account);
        //console.log("userInfoToAdd:" + payload.name);
        return await this.dispatch("bindNewTransaction", payload);
    },

    async storeSetTransactionPending(context, payload) {
        return await this.dispatch("bindTransactionPending", payload);
    },
    async bindTransactionPending({commit}, payload) {
        await commit("setRequestPending", payload);
    },


    async bindNewTransaction(context, payload) {
        if(state.debug){
            console.log("inside bindNewTransaction");
            console.log("payload hash: " + payload.hash);
            console.log("payload command: " + payload.command);
        }


        let idToAdd = localStorage.getItem("accountName");

        if (idToAdd !== null && idToAdd !== "") {

            if(state.debug){
                console.log("Adding pending transaction for address: ");
                console.log(idToAdd);
            }


            let currentBlock = 0;
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;

            if(state.debug){
                console.log("Getting chain-data");
            }


            await Pact.fetch.local({
                pactCode: `(chain-data)`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network).then((res) => {

                if(state.debug){
                    console.log("chain-data res");
                    console.log(res);
                }


                if (res) {

                    let resresult = [{...res.result}];

                    if (resresult[0]["data"]["block-height"].int) {
                        currentBlock = resresult[0]["data"]["block-height"].int;
                    }

                    const newTransaction = {
                        request: 6,
                        hash: payload.hash,
                        address: idToAdd,
                        command: payload.command,
                        blockrequested: currentBlock,
                    };

                    let transactionAddedToDb = false;

                    if(state.debug){
                        console.log("sending new payload to phpstuff");
                        console.log(newTransaction);
                    }


                    axios.post("/phpstuff/ajaxfile.php", newTransaction)
                        .then(async (response) => {

                            console.log(response.data);
                            if (response.data === 1) {
                                console.log("Transaction pending..");
                                transactionAddedToDb = true;
                                await this.dispatch("accounts/storeSetTransactionPending", true);
                            } else if (response.data === 0) {
                                console.log("Transaction failed - transaction already found.");
                                transactionAddedToDb = false;
                                await this.dispatch("accounts/storeSetTransactionPending", false);
                            }
                        })
                        .catch(async (error) => {
                            console.log(error);
                            await this.dispatch("accounts/storeSetTransactionPending", false);
                        });

                    return transactionAddedToDb;

                }
            }).catch(async (err) => {
                if(state.debug){
                    console.log("(chain-data) Error");
                    console.log(err);
                }
            });

        } else {
            await this.$router.push({path: `/`});
        }
    },

    //Transaction polling
    async pollTransactionHash({commit}, hash) {
        if (state.debug) {
            console.log("Polling blockchain every 20 seconds for transaction confirmation:");
            console.log(hash);
        }
        let dataResult = null;
        try {

            let pollRes = await Pact.fetch.poll({
                requestKeys: [hash]
            }, state.network);

            if (state.debug) {
                console.log(pollRes[hash]);
            }

            if (pollRes[hash] === undefined) {
                console.log("Transaction is still confirming.. Checking again in 20 seconds..");
            } else {
                console.log(pollRes[hash]);
            }

            if (pollRes[hash] !== undefined) {

                if (pollRes[hash].result.status === "success") {

                    dataResult = [{...pollRes[hash].result}];
                    commit("setTransactionFailed", false);
                    commit("setTransactionConfirmedResult", dataResult);


                    await this.dispatch("accounts/getAllUserGallinas", state.accountName);
                    await this.dispatch("accounts/getAllUserEggs", state.accountName);
                    await this.dispatch("accounts/getAllUserGiftVouchers", state.accountName);

                    commit("setTransactionPolling", false);


                    //Here we now need to check the database and see if it too has confirmed the users transaction
                    //If it has, we then need to load the new data, and allow this function to complete..

                    if (state.debug) {
                        console.log("Transaction Confirmed via blockchain:");
                        console.log(pollRes[hash].result);
                    }


                } else {
                    commit("setTransactionFailed", true);
                    commit("setTransactionConfirmedResult", dataResult);
                    if (state.debug) {
                        console.log("Could not confirm recent transaction:");
                        console.log(pollRes[hash].result);
                    }
                }

            } else {

                setTimeout(async () => {
                    this.dispatch("accounts/pollTransactionHash", hash);
                }, 20000);

            }


        } catch (error) {
            if(state.debug){
                console.log("TX Confirmation ERROR:");

                console.log(error);
                console.log(error.json());
            }

        }

    },

    //Transaction poll resetting
    async clearTransactionPoll({commit}) {
        await commit("setTransactionHash", null);

        await commit("setTransactionPolling", false);

        await commit("setTransactionConfirmed", false);

        await commit("setTransactionFailed", null);

        await commit("setTransactionConfirmedResult", null);

    },

    async setXWalletIsNotConnected({commit}) {
        await commit("setXWalletConnected", false);

    },

    async setXWalletIsConnected({commit}) {
        await commit("setXWalletConnected", true);

    },

    async setCloverWalletIsConnected({commit}) {
        await commit("setCloverWalletConnected", true);

    },

    async setCloverWalletIsNotConnected({commit}) {
        await commit("setCloverWalletConnected", false);

    },


    //Buy an egg
    async buynewegg({commit}, accountName) {
        if (state.debug) {
            console.log("Buying new egg");
        }
        try {

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(publickey);
                console.log(accountName2);
            }
            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }


            const gallinabank = "gallinas-io-bank";
            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.collect-gallinas.buy-egg ${JSON.stringify(accountName2)} (read-keyset "user-ks") 1.00)`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Transfer Capability", "Agreement to Transfer 1 KDA to Gallinas.io", "coin.TRANSFER", [accountName2, gallinabank, 1.00])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);

                console.log("cloverwalletconnected:");
                console.log(this.getters["accounts/getCloverWalletConnected"]);
                console.log(state.cloverwalletconnected);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.buy-egg ${JSON.stringify(accountName2)} (read-keyset 'userks) 1.00)`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Transfer Capability", "Agreement to Transfer 1 KDA to Gallinas.io", "coin.TRANSFER", [accountName2, gallinabank, 1.00])],
                        pactCode: xwalletcode,
                        envData: {
                            userks: accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }


            try{

                let res = null;

                if (state.xwalletconnected === true) {
                    if (cmd.status === "success") {
                        if (state.debug) {
                            console.log("sign success");
                            console.log("cmd:");
                            console.log(cmd);
                            console.log("cmd signed cmd");
                            console.log(cmd.signedCmd);
                            console.log("cmd signed type");
                            console.log(typeof (cmd.pactCode));
                        }
                        res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                    }
                } else {
                    res = await Pact.wallet.sendSigned(cmd, state.network);
                }



                if (state.debug) {
                    console.log("RES response");
                    console.log(res);
                }


                if (res !== undefined && res !== null) {

                    if (state.debug) {
                        console.log("RES:");
                        console.log(res);
                        if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                            console.log(res.signedCmd);
                        }
                        console.log(res.requestKeys[0]);

                    }

                    if (state.xwalletconnected !== true) {
                        if (res.requestKeys[0]) {
                            await commit("setTransactionHash", res.requestKeys[0]);
                            await commit("setTransactionPolling", true);
                            await commit("setTransactionConfirmed", false);
                            await commit("setTransactionFailed", null);
                            await commit("setTransactionConfirmedResult", null);
                            await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);

                            let payloadtx = {
                                hash: res.requestKeys[0],
                                command: "GALLINASIO_BUY_EGG"
                            };

                            await this.dispatch("accounts/bindNewTransaction", payloadtx);
                        }
                    } else {
                        await commit("setTransactionHash", cmd.signedCmd.hash);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);

                        let payloadtx = {
                            hash: cmd.signedCmd.hash,
                            command: "GALLINASIO_BUY_EGG"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }

                }

            }catch(error){
                if(state.debug){
                    console.log(error);
                }

            }



        } catch (error) {
            console.log(error);
        }
    },

    //Hatch an egg
    async hatchnewegg({commit}, payload) {
        if (state.debug) {
            console.log("Hatching egg");
        }
        try {

            let accountName = payload.accountName;
            let newGallinaName = payload.newGallinaName;

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(accountName2);
                console.log(publickey);
            }
            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;
            const eggstring = "Egg";


            const pactCode = `(free.collect-gallinas.hatch-egg ${JSON.stringify(accountName2)} ${JSON.stringify(newGallinaName)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Verify Egg Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [eggstring, accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.hatch-egg ${JSON.stringify(accountName2)} ${JSON.stringify(newGallinaName)})`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Verify Egg Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [eggstring, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            userks: accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_HATCH"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_HATCH"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }


        } catch (error) {
            console.log(error);
        }

    },

    //Breed gallinas
    async breedGallinas({commit}, payload) {
        if (state.debug) {
            console.log("Breeding Gallinas");
        }
        try {

            let accountName = payload.accountName;
            let gallina1id = payload.gallina1id;
            let gallina2id = payload.gallina2id;

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(accountName2);
                console.log(publickey);
                console.log(gallina1id);
                console.log(gallina2id);
            }
            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }

            const gallinabank = "gallinas-io-bank";
            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.collect-gallinas.breed-gallinas ${JSON.stringify(gallina1id)} ${JSON.stringify(gallina2id)} ${JSON.stringify(accountName2)} 1.00 0 0)`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Transfer Capability", "Agreement to transfer 1.00 KDA Breed Fee to Gallinas.io", "coin.TRANSFER", [accountName2, gallinabank, 1.00]), Pact.lang.mkCap("Account Capability", "Prove Gallina 1 Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallina1id, accountName2]), Pact.lang.mkCap("Account Capability", "Prove Gallina 2 Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallina2id, accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.breed-gallinas ${JSON.stringify(gallina1id)} ${JSON.stringify(gallina2id)} ${JSON.stringify(accountName2)} 1.00 0 0)`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Transfer Capability", "Agreement to transfer 1.00 KDA Breed Fee to Gallinas.io", "coin.TRANSFER", [accountName2, gallinabank, 1.00]), Pact.lang.mkCap("Account Capability", "Prove Gallina 1 Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallina1id, accountName2]), Pact.lang.mkCap("Account Capability", "Prove Gallina 2 Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallina2id, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            userks: accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_BREED"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_BREED"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }


        } catch (error) {
            console.log(error);
        }

    },

    //Change Gallina name
    async changeGallinaName({commit}, payload) {
        if (state.debug) {
            console.log("Changing Gallina Name");
        }
        try {

            let accountName = payload.accountName;
            let newGallinaName = payload.newGallinaName;
            let gallinaId = payload.gallinaId;

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(accountName2);
                console.log(publickey);
                console.log(newGallinaName);
                console.log(gallinaId);
            }

            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = "1";
            const NETWORKID = state.networkid;


            const pactCode = `(free.collect-gallinas.change-gallina-name ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)} ${JSON.stringify(newGallinaName)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                creationTime: Math.round(new Date().getTime() / 1000) - 10,
                ttl: 700,
                envData: {
                    userks: accountGuard,
                    gallinaname: newGallinaName,
                    accountname: accountName2,
                    gallinaid: gallinaId,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.change-gallina-name ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)} ${JSON.stringify(newGallinaName)})`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            userks: accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                if (state.debug) {
                    console.log("CMD::");
                    console.log(cmd);
                }

                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_NAME"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_NAME"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }

        } catch (error) {
            console.log(error);
        }

    },

    //Burn a gallina for a gift voucher
    async burnGallina({commit}, payload) {
        if (state.debug) {
            console.log("Burning Gallina");
        }
        try {

            let accountName = payload.accountName;
            let gallinaId = payload.gallinaId;

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(accountName2);
                console.log(publickey);
                console.log(gallinaId);
            }

            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = "1";
            const NETWORKID = state.networkid;


            const pactCode = `(free.collect-gallinas.buy-gift-voucher ${JSON.stringify(accountName2)} ${JSON.stringify(gallinaId)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                creationTime: Math.round(new Date().getTime() / 1000) - 10,
                ttl: 700,
                envData: {
                    userks: accountGuard,
                    accountname: accountName2,
                    gallinaid: gallinaId,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.buy-gift-voucher ${JSON.stringify(accountName2)} ${JSON.stringify(gallinaId)})`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            userks: accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                if (state.debug) {
                    console.log("CMD::");
                    console.log(cmd);
                }

                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_BURN"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_BURN"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }

        } catch (error) {
            console.log(error);
        }

    },

    //Remint a gallina
    async remintGallina({commit}, payload) {
        if (state.debug) {
            console.log("Reminting Gallina");
        }
        try {

            let accountName = payload.accountName;
            let gallinaId = payload.gallinaId;

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(accountName2);
                console.log(publickey);
                console.log(gallinaId);
            }

            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = "1";
            const NETWORKID = state.networkid;


            const pactCode = `(free.collect-gallinas.remint ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                creationTime: Math.round(new Date().getTime() / 1000) - 10,
                ttl: 700,
                envData: {
                    userks: accountGuard,
                    accountname: accountName2,
                    gallinaid: gallinaId,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.remint ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)})`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            userks: accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                if (state.debug) {
                    console.log("CMD::");
                    console.log(cmd);
                }

                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_REMINT"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_REMINT"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }

        } catch (error) {
            console.log(error);
        }

    },

    //Resolves a gallina
    async resolveGallina({commit}, payload) {
        if (state.debug) {
            console.log("Resolving Gallina");
        }
        try {

            let accountName = payload.accountName;
            let gallinaId = payload.gallinaId;

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(accountName2);
                console.log(publickey);
                console.log(gallinaId);
            }

            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = "1";
            const NETWORKID = state.networkid;


            const pactCode = `(free.collect-gallinas.resolve ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                creationTime: Math.round(new Date().getTime() / 1000) - 10,
                ttl: 700,
                envData: {
                    userks: accountGuard,
                    accountname: accountName2,
                    gallinaid: gallinaId,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.resolve ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)})`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            userks: accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                if (state.debug) {
                    console.log("CMD::");
                    console.log(cmd);
                }

                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_RESOLVE"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_RESOLVE"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }

        } catch (error) {
            console.log(error);
        }

    },

    //Spend a gift voucher on a gift for a gallina
    async buyGallinaGift({commit}, payload) {
        if (state.debug) {
            console.log("Buying Gallina Gift");
        }
        try {

            let accountName = payload.accountName;
            let gallinaId = payload.gallinaId;

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(accountName2);
                console.log(publickey);
                console.log(gallinaId);
            }

            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = "1";
            const NETWORKID = state.networkid;


            const pactCode = `(free.collect-gallinas.spend-10-gift-vouchers ${JSON.stringify(accountName2)} ${JSON.stringify(gallinaId)})`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                sender: accountName2,
                gasLimit: 150000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                creationTime: Math.round(new Date().getTime() / 1000) - 10,
                ttl: 700,
                envData: {
                    userks: accountGuard,
                    accountname: accountName2,
                    gallinaid: gallinaId,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.spend-10-gift-vouchers ${JSON.stringify(accountName2)} ${JSON.stringify(gallinaId)})`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 150000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            userks: accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                if (state.debug) {
                    console.log("CMD::");
                    console.log(cmd);
                }

                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_GIFT"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_GIFT"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }

        } catch (error) {
            console.log(error);
        }

    },

    //Sell a Gallina
    async sellGallina({commit}, payload) {
        if (state.debug) {
            console.log("Selling Gallina");
        }
        try {

            let accountName = payload.accountName;
            let gallinaId = payload.gallinaId;
            let price = payload.price;

            if (state.debug) {
                console.log(price);
                console.log(typeof price);
            }


            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(accountName2);
                console.log(publickey);
                console.log(gallinaId);
            }
            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.collect-gallinas.sell-my-gallina ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)} (read-decimal 'price) true)`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "price": price,
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.sell-my-gallina ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)} (read-decimal 'price) true)`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "price": price,
                            "user-ks": accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_SELL"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_SELL"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }

        } catch (error) {
            console.log(error);
        }

    },

    //Cancel a Gallina sale off the market
    async cancelGallinaSale({commit}, payload) {
        if (state.debug) {
            console.log("Selling Gallina");
        }
        try {

            let accountName = payload.accountName;
            let gallinaId = payload.gallinaId;
            let price = payload.price;

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(accountName2);
                console.log(publickey);
                console.log(gallinaId);
            }
            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.collect-gallinas.sell-my-gallina ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)} ${payload.price} false)`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "price": price,
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.sell-my-gallina ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)} ${payload.price} false)`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Account Capability", "Prove Gallina Ownership", "free.collect-gallinas.ACCOUNT_GUARD", [gallinaId, accountName2])],
                        pactCode: xwalletcode,
                        envData: {
                            "price": price,
                            "user-ks": accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_SELL"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_SELL"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }

        } catch (error) {
            console.log(error);
        }

    },

    //Buy gallinas
    async buyGallina({commit}, payload) {
        if (state.debug) {
            console.log("Buying Gallina");
        }
        try {

            let accountName = payload.accountName;
            let gallinaId = payload.gallinaId;
            let price = payload.price;
            let seller = payload.seller;

            if (state.debug) {
                console.log("PRICE:");
                console.log(price);
                console.log("TYPE:");
                console.log(typeof price);
                console.log(parseFloat(price));
                console.log(typeof parseFloat(price));
            }

            price = parseFloat(price);

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(accountName2);
                console.log(publickey);
                console.log(gallinaId);
                console.log(price);
            }
            if (accountName2 !== accountName) {
                if (state.debug) {
                    console.log("ACCOUNT NAMES DIDNT MATCH!");
                    console.log("accountName: " + accountName);
                    console.log("accountName2: " + accountName2);
                }
            }

            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;

            let description_text = "Agreement to Transfer " + price.toString() + " KDA to Seller " + seller.toString();


            const pactCode = `(free.collect-gallinas.buy-gallina-off-market ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)} (read-keyset 'user-ks) (read-decimal 'price))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas capability", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer Capability", description_text, "coin.TRANSFER", [accountName2, seller, price])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "price": price,
                    "user-ks": accountGuard
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            }; //alert to sign tx

            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.buy-gallina-off-market ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)} (read-keyset 'user-ks) (read-decimal 'price))`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas capability", "Agreement to Pay Gas Fee", "coin.GAS", []), Pact.lang.mkCap("Transfer Capability", description_text, "coin.TRANSFER", [accountName2, seller, price])],
                        pactCode: xwalletcode,
                        envData: {
                            "price": price,
                            "user-ks": accountGuard
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_BUY"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_BUY"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }

        } catch (error) {
            console.log(error);
        }

    },

    //Transfer Gallina to another account
    async transferGallina({commit}, payload) {
        if (state.debug) {
            console.log("Transfering Gallina");
        }
        try {

            let accountName = payload.accountName;
            let gallinaId = payload.gallinaId;
            let receiverAccountName = payload.receiverAccountName;
            let receiverAccountGuard = state.receiverAccountData[0]["data"]["guard"];

            const publickey = state.accountData[0]["data"]["guard"]["keys"][0];
            const accountName2 = localStorage.getItem("accountName");
            const accountGuard = state.accountData[0]["data"]["guard"];
            if (state.debug) {
                console.log(accountGuard);
                console.log(gallinaId);
                console.log(receiverAccountName);
                console.log(receiverAccountGuard);
            }
            if (accountName2 !== accountName) {
                console.log("ACCOUNT NAMES DIDNT MATCH!");
                console.log("accountName: " + accountName);
                console.log("accountName2: " + accountName2);
            }


            const GAS_PRICE = 0.000001;
            const chainId = state.chainId;
            const NETWORKID = state.networkid;


            const pactCode = `(free.collect-gallinas.transfer-create ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)} ${JSON.stringify(receiverAccountName)} (read-keyset 'receiver-ks) 1.0)`;
            const signCmd = {
                pactCode: pactCode,
                caps: [Pact.lang.mkCap("Gas Capability", "Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Transfer Capability", "Transfer Gallina", "free.collect-gallinas.TRANSFER", [gallinaId, accountName2, receiverAccountName, 1.0])],
                sender: accountName2,
                gasLimit: 15000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    "user-ks": accountGuard,
                    "receiver-ks": receiverAccountGuard,
                },
                signingPubKey: publickey,
                networkId: NETWORKID
            };


            if (state.debug) {
                console.log("signCmd:");
                console.log(signCmd);
            }

            let cmd = null;

            if (state.debug) {
                console.log("xwalletconnected:");
                console.log(this.getters["accounts/getXWalletConnected"]);
            }

            if (state.xwalletconnected === true) {
                const xwalletcode = `(free.collect-gallinas.transfer-create ${JSON.stringify(gallinaId)} ${JSON.stringify(accountName2)} ${JSON.stringify(receiverAccountName)} (read-keyset 'receiver-ks) 1.0)`;
                const XWalletRequest = {

                    networkId: NETWORKID,
                    signingCmd: {
                        sender: accountName2,
                        chainId: "1",
                        gasPrice: 0.000001,
                        gasLimit: 15000,
                        ttl: 600,
                        caps: [Pact.lang.mkCap("Gas Capability", "Pay Gas", "coin.GAS", []), Pact.lang.mkCap("Transfer Capability", "Transfer Gallina", "free.collect-gallinas.TRANSFER", [gallinaId, accountName2, receiverAccountName, 1.0])],
                        pactCode: xwalletcode,
                        envData: {
                            "user-ks": accountGuard,
                            "receiver-ks": receiverAccountGuard,
                        },
                        networkId: NETWORKID,
                        signingPubKey: publickey,

                    } //alert to sign tx

                };

                cmd = await window.kadena.request({
                    method: "kda_requestSign",
                    networkId: NETWORKID,
                    data: XWalletRequest
                });

            }else if (state.cloverwalletconnected === true) {

                if(state.debug){
                    console.log("CLOVER WALLET SIGNING");
                }

                cmd = await window.clover_kadena.sign(signCmd);
            }else {
                if(state.debug){
                    console.log("OTHER WALLET SIGNING");
                }
                cmd = await Pact.wallet.sign(signCmd);
            }

            if (state.debug && state.xwalletconnected === true) {
                console.log("cmd response:");
                console.log(cmd);
                console.log("is xwallet connected:");
                console.log(state.xwalletconnected);
            }

            let res = null;


            if (state.xwalletconnected === true) {
                if (cmd.status === "success") {
                    if (state.debug) {
                        console.log("sign success");
                        console.log("cmd:");
                        console.log(cmd);
                        console.log("cmd signed cmd");
                        console.log(cmd.signedCmd);
                        console.log("cmd signed type");
                        console.log(typeof (cmd.pactCode));
                    }
                    res = await Pact.wallet.sendSigned(cmd.signedCmd, state.network);
                }
            } else {
                res = await Pact.wallet.sendSigned(cmd, state.network);
            }

            if (state.debug) {
                console.log("RES response");
                console.log(res);
            }


            if (res !== undefined && res !== null) {

                if (state.debug) {
                    console.log("RES:");
                    console.log(res);
                    if (state.xwalletconnected === true && res.signedCmd !== undefined) {
                        console.log(res.signedCmd);
                    }
                    console.log(res.requestKeys[0]);

                }

                if (state.xwalletconnected !== true) {
                    if (res.requestKeys[0]) {
                        await commit("setTransactionHash", res.requestKeys[0]);
                        await commit("setTransactionPolling", true);
                        await commit("setTransactionConfirmed", false);
                        await commit("setTransactionFailed", null);
                        await commit("setTransactionConfirmedResult", null);
                        await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
                        let payloadtx = {
                            hash: res.requestKeys[0],
                            command: "GALLINASIO_TRANSFER"
                        };

                        await this.dispatch("accounts/bindNewTransaction", payloadtx);
                    }
                } else {
                    await commit("setTransactionHash", cmd.signedCmd.hash);
                    await commit("setTransactionPolling", true);
                    await commit("setTransactionConfirmed", false);
                    await commit("setTransactionFailed", null);
                    await commit("setTransactionConfirmedResult", null);
                    await this.dispatch("accounts/pollTransactionHash", cmd.signedCmd.hash);
                    let payloadtx = {
                        hash: cmd.signedCmd.hash,
                        command: "GALLINASIO_TRANSFER"
                    };

                    await this.dispatch("accounts/bindNewTransaction", payloadtx);
                }

            }

            // if (state.debug) {
            //     console.log("signCmd:");
            //     console.log(signCmd);
            // }
            //
            //
            // const cmd = await Pact.wallet.sign(signCmd);
            //
            // if (state.debug) {
            //     console.log("cmd:");
            //     console.log(cmd);
            // }
            //
            // const res = await Pact.wallet.sendSigned(cmd, state.network);
            //
            // if (res !== undefined) {
            //
            //     if (state.debug) {
            //         console.log("RES:");
            //         console.log(res);
            //         console.log(res.requestKeys[0]);
            //     }
            //
            //     if (res.requestKeys[0]) {
            //         //await this.setTransactionHash(res.requestKeys[0]);
            //         //await this.dispatch("setTransactionHash", res.requestKeys[0]);
            //         await commit("setTransactionHash", res.requestKeys[0]);
            //         //await this.setTransactionPolling(true);
            //         //await this.dispatch("setTransactionPolling", true);
            //         await commit("setTransactionPolling", true);
            //         //await this.setTransactionConfirmed(false);
            //         //await this.dispatch("setTransactionConfirmed", false);
            //         await commit("setTransactionConfirmed", false);
            //         //await this.setTransactionFailed(null);
            //         //await this.dispatch("setTransactionFailed", null);
            //         await commit("setTransactionFailed", null);
            //         //await this.setTransactionConfirmedResult(null);
            //         //await this.dispatch("setTransactionConfirmedResult", null);
            //         await commit("setTransactionConfirmedResult", null);
            //
            //         //await this.pollTransactionHash(res.requestKeys[0]);
            //         await this.dispatch("accounts/pollTransactionHash", res.requestKeys[0]);
            //         //await commit("setTransactionHash", res.requestKeys[0]);
            //     }
            //
            // }


        } catch (error) {
            console.log(error);
        }

    },

    //Grab gallina mate 1s info
    async getInspectGallinaInfo({commit}, gallinaId) {
        if (state.debug) {
            console.log("Getting Gallina Insppect Info for Gallina ID:");
            console.log(gallinaId);
        }
        let gallinaDetailsList = [];
        try {
            let t_creationTime = Math.round(new Date().getTime() / 1000) - 10;

            let gallinaData = await Pact.fetch.local({
                pactCode: `(free.collect-gallinas.get-gallina-details ${JSON.stringify(gallinaId)})`,
                meta: Pact.lang.mkMeta("", "1", state.gasPrice, 150000, t_creationTime, 600)
            }, state.network);

            if (gallinaData.result.status === "success") {
                if (state.debug) {
                    console.log("Gallina to Inspect info result");
                    console.log(gallinaData.result);
                }

                let newbirthday = null;
                if (gallinaData.result.data["birthday"].time !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["birthday"].time.slice(0, 10));
                    }

                    newbirthday = gallinaData.result.data["birthday"].time.slice(0, 10);

                }

                if (gallinaData.result.data["birthday"].timep !== undefined) {
                    if (state.debug) {
                        console.log("timep");
                        console.log(gallinaData.result.data["birthday"].timep.slice(0, 10));
                    }

                    newbirthday = gallinaData.result.data["birthday"].timep.slice(0, 10);

                }

                let newnbt = null;
                if (gallinaData.result.data["nextbreed"].time !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["nextbreed"].time.slice(0, 10));
                    }

                    newnbt = gallinaData.result.data["nextbreed"].time.slice(0, 10);
                }

                if (gallinaData.result.data["nextbreed"].timep !== undefined) {
                    if (state.debug) {
                        console.log("time");
                        console.log(gallinaData.result.data["nextbreed"].timep.slice(0, 10));
                    }

                    newnbt = gallinaData.result.data["nextbreed"].timep.slice(0, 10);
                }

                if (state.debug) {
                    console.log("generation");
                    console.log(gallinaData.result.data["generation"].int);
                }


                let gallinaDetailsObject = {
                    id: gallinaId,
                    gender: gallinaData.result.data.gender,
                    generation: gallinaData.result.data["generation"].int,
                    motherid: gallinaData.result.data["mother-id"],
                    fatherid: gallinaData.result.data["father-id"],
                    birthday: newbirthday,
                    nbd: newnbt,
                    special: gallinaData.result.data["special"].int,
                };

                gallinaDetailsList.push(gallinaDetailsObject);


            } else {
                if (state.debug) {
                    console.log("Error 603: Could not get Gallina to Inspect Details for ID:");
                    console.log(gallinaId);
                    console.log("Response:");
                    console.log(gallinaData.result);
                }

            }

        } catch (error) {
            console.log(error);
        }
        if (gallinaDetailsList.length > 0) {
            if (state.debug) {
                console.log("Got Gallina Info Details for id:");
                console.log(gallinaId);
                console.log(gallinaDetailsList);
            }
            commit("setInspectGallinaList", gallinaDetailsList);
        }

    },

    async changeBreedChicken1Id({commit}, payload) {
        try {
            await commit("setBreedChicken1Id", payload);
            if (state.debug) {
                console.log("Set breed chicken 1 id: " + payload);
            }
        } catch (error) {
            console.log(error);
        }

    },
    async changeBreedChicken2Id({commit}, payload) {
        try {
            await commit("setBreedChicken2Id", payload);
            if (state.debug) {
                console.log("Set breed chicken 2 id: " + payload);
            }
        } catch (error) {
            console.log(error);
        }

    },
    async resetBreedChickenIds({commit}) {
        try {
            await commit("setBreedChicken1Id", null);
            await commit("setBreedChicken2Id", null);
            if (state.debug) {
                console.log("Reset breedchickenids");
            }
        } catch (error) {
            console.log(error);
        }

    },


};


const mutations = {
    setActiveAccount(state, selectedAddress) {
        state.activeAccount = selectedAddress;
    },
    setAccountExists(state, exists) {
        if (state.debug) {
            console.log("inside commit/set account exists");
            console.log("setting accountExists to:");
            console.log(exists);
        }
        state.accountExists = exists;
    },
    setSenderAccountExists(state, exists) {
        if (state.debug) {
            console.log("inside commit/set sender account exists");
            console.log("setting senderAccountExists to:");
            console.log(exists);
        }
        state.senderAccountExists = exists;
    },
    setAccountData(state, data) {
        state.accountData = data;
    },
    setReceiverAccountData(state, data) {
        state.receiverAccountData = data;
    },
    setAccountKeys(state, keys) {
        state.accountKeys = keys;
    },
    setAccountPredicate(state, pred) {
        state.accountPredicate = pred;
    },
    setAccountConfirmed(state, confirmed) {
        if (state.debug) {
            console.log("inside commit/set account confirmed");
            console.log("setting accountConfirmed to:");
            console.log(confirmed);
        }
        state.accountConfirmed = confirmed;
    },
    setAccountName(state, name) {
        if (state.debug) {
            console.log("inside commit/set account name");
            console.log("setting accountname to:");
            console.log(name);
        }
        state.accountName = name;
    },
    setGameTotalGallinas(state, total) {
        state.gameTotalGallinas = total;
    },
    setGameTotalEggs(state, total) {
        state.gameTotalEggs = total;
    },
    setCurrentGift(state, total) {
        state.gameCurrentGift = total;
    },
    setCurrentGiftEndTime(state, total) {
        state.gameCurrentGiftEndTime = total;
    },
    setUserTotalGallinas(state, total) {
        state.userTotalGallinas = total;
    },
    setUserTotalEggs(state, total) {
        state.userTotalEggs = total;
    },
    setKeysSpecialTable(state, keys) {
        state.keysSpecialTable = keys;
    },
    setUserTotalGiftVouchers(state, total) {
        state.userTotalGiftVouchers = total;
    },
    setUserGallinaIds(state, ids) {
        state.userGallinaIds = ids;
    },
    setStoreLoaded(state, isloaded) {
        state.loaded = isloaded;
    },
    setUserGallinasList(state, gallinasList) {
        state.userGallinasList = gallinasList;
    },
    setTransactionPolling(state, polling) {
        state.transactionPolling = polling;
    },
    setTransactionConfirmed(state, confirmed) {
        state.transactionConfirmed = confirmed;
    },
    setTransactionFailed(state, failed) {
        state.transactionFailed = failed;
    },
    setTransactionHash(state, hash) {
        state.transactionHash = hash;
    },
    setTransactionConfirmedResult(state, result) {
        state.transactionConfirmedResult = result;
    },
    setBreedChicken1Id(state, newid) {
        state.breedChicken1Id = newid;
    },
    setBreedChicken2Id(state, newid) {
        state.breedChicken2Id = newid;
    },
    setMate1List(state, list) {
        state.mate1List = list;
    },
    setMate2List(state, list) {
        state.mate2List = list;
    },
    setGallinaBurnList(state, list) {
        state.gallinaburnlist = list;
    },
    setGallinaOwnerInfoList(state, list) {
        state.gallinaownerlist = list;
    },
    setInspectGallinaList(state, list) {
        state.inspectGallinaList = list;
    },
    setMarketForSaleList(state, list) {
        state.marketForSaleList = list;
    },
    setMyGallinasCanBreed(state, canbreed) {
        state.myGallinasCanBreed = canbreed;
    },
    setXWalletConnected(state, isconnected) {
        state.xwalletconnected = isconnected;
    },
    setCloverWalletConnected(state, isconnected) {
        state.cloverwalletconnected = isconnected;
    },
    setUserRequests(state, requests) {
        state.userRequests = requests;
    },
    setRequestPending(state, pending) {
        state.requestPending = pending;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
