<template>
  <div class="main-wrap">
    <div class="loader" v-if="this.showLoadingModal === true">
      <div class="loader__bar"></div>
      <div class="loader__bar loader__bar--delay-1"></div>
      <div class="loader__bar loader__bar--delay-2"></div>
      <div class="loader__bar loader__bar--delay-3"></div>
      <div class="loader__bar loader__bar--delay-4"></div>
      <div class="loader__bar loader__bar--delay-5"></div>
    </div>

    <section class="moveup" v-if="this.showLoadingModal === false && $screen.width > 576">
      <Scroll-Div width="98%" height="85vh" view-class="yourclassname" v-if="this.showLoadingModal === false">
        <div class="container mt-4">

          <div v-if="$screen.width > 576" class="row align-items-center justify-content-center mt-4">
            <div class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalGallinas()+' Gallina(s).'">
            {{ this.getUserTotalGallinas() }} &nbsp; <img src="../../public/icons/chicken-mygallinas-icon.svg"
                                                          alt="Chickens" style="position:relative; top: 3px;"
                                                          width="40px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalEggs() > 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalEggs()+' Egg(s).'">
            {{ this.getUserTotalEggs() }} &nbsp; <img src="../../public/icons/egg-mygallinas-icon.svg"
                                                      style="position:relative; top: 5px;" alt="Eggs"
                                                      width="25px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalGiftVouchers() > 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalGiftVouchers()+' Voucher(s).'">
            {{ this.getUserTotalGiftVouchers() }} &nbsp; <img src="../../public/icons/gift-voucher-icon.svg"
                                                              style="position:relative; top: 5px;" alt="Gift Vouchers"
                                                              width="40px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalEggs() <= 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You dont have any Eggs right now'">
            0 &nbsp; <img src="../../public/icons/egg-mygallinas-icon.svg" style="position:relative; top: 5px;"
                          alt="Eggs"
                          width="25px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalGiftVouchers() <= 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You dont have any Vouchers right now'">
            0 &nbsp; <img src="../../public/icons/gift-voucher-icon.svg" style="position:relative; top: 5px;"
                          alt="Gift Vouchers"
                          width="40px"/>
            </span>
            </div>
          </div>

          <div class="modal-box">
            <div class="mdialog" role="document">
              <div class="modal-content clearfix">
                <div class="modal-body">
                  <div class="row align-items-center justify-content-center mt-1 mb-1">
                    <div @click="showWhatIsGallinas = !showWhatIsGallinas"
                         :class="showWhatIsGallinas ? 'helpselected' : 'dark-text-section' "
                         class="col-11 d-flex align-items-center justify-content-evenly">
                      <img
                          src="../../public/icons/chick-mygallinas-icon.svg" class="flip-horizontally" alt="Gallinas"
                          width="40px">
                      <h3 class="title">Transaction History</h3><img
                        src="../../public/icons/chick-mygallinas-icon.svg" alt="Gallinas" width="40px">
                    </div>
                  </div>
                  <div v-show="showWhatIsGallinas" class="row align-items-center justify-content-center mt-2 mb-2">
                    <div class="col-8 align-items-center justify-content-evenly hidden-section p-3 rcolumn2 mt-4">
                      <button @click="showCompleted = true;" type="button" class="btnbreed"> Completed </button><button @click="showCompleted=false" type="button" class="btnbreed"> Failed </button>
                    </div>
                    <div v-if="showCompleted === true" class="col d-flex align-items-center justify-content-evenly hidden-section p-3 rcolumn mt-4">
                      <div class="panel">
                        <div class="panel-heading">
                          <div class="row">
                            <div class="col-sm-12">
                              <h4 class="title2 pull-left">Completed Transactions:</h4>
                            </div>
                          </div>
                        </div>
                        <div v-if="confirmedTransactionsList.length > 0"  class="panel-body table-responsive">
                          <table class="table">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>TX HASH</th>
                              <th>COMMAND</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(g, index) in confirmedTransactionsList" :key="g.id">

                              <td>{{ index }}</td>
                              <td><a :href="'https://explorer.chainweb.com/mainnet/tx/'+g.hash" target="_blank" style="color: #555">{{ g.hash }}</a></td>
                              <td>
                                <ul class="action-list">
                                  <li> {{ g.command }}</li>
                                </ul>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div v-if="showCompleted === false" class="col d-flex align-items-center justify-content-evenly hidden-section p-3 rcolumn mt-4">
                      <div class="panel">
                        <div class="panel-heading">
                          <div class="row">
                            <div class="col-sm-12">
                              <h4 class="title2 pull-left">Failed Transactions:</h4>
                            </div>
                          </div>
                        </div>
                        <div v-if="failedTransactionsList.length > 0" class="panel-body table-responsive">
                          <table class="table">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>TX HASH</th>
                              <th>COMMAND</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(g, index) in failedTransactionsList" :key="g.id">

                              <td>{{ index }}</td>
                              <td><a :href="'https://explorer.chainweb.com/mainnet/tx/'+g.hash" target="_blank" style="color: #555">{{ g.hash }}</a></td>
                              <td>
                                <ul class="action-list">
                                  <li> {{ g.command }}</li>
                                </ul>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="mt-4">
            <br/>
          </div>
        </div>
      </Scroll-Div>
    </section>



  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ScrollDiv from "vue-scroll-div";


export default {
  name: "HomePage",
  components: {
    ScrollDiv,
  },
  data() {
    return {
      debug: false,
      showLoadingModal: true,
      showWhatIsGallinas: false,
      showCompleted: true,
      showFailed: false,
      changemargin: false,
      confirmedTransactionsList: null,
      failedTransactionsList: null,
    };
  },
  methods: {
    ...mapActions("accounts", ["getAllGameGallinas", "getAllGameEggs", "bindConfirmedTransactions", "bindFailedTransactions"]),
    ...mapGetters("accounts", ["getAccountExists", "getUserTotalGiftVouchers", "getUserTotalEggs", "getAccountConfirmed", "getUserTotalGallinas", "getTransactionHash"]),
  },
  async created() {

    //Confirm account
    let name = localStorage.getItem("accountName");

    if (name === null || name === "") {
      await this.$router.push({path: `/`});
    }


    if(this.debug === true) {
      console.log("Getting user information..");
    }
    //Get number of Eggs from blockchain this user owns, if any
    await this.$store.dispatch("accounts/getAllUserEggs", name);

    //Now we get the complete list of IDs of gallinas this user owns from blockchain, and the total count of gallinas they own
    await this.$store.dispatch("accounts/getUserGallinaCount", name);

    await this.$store.dispatch("accounts/getAllUserGiftVouchers", name);

    this.confirmedTransactionsList = await this.bindConfirmedTransactions(name);

    if(this.debug === true) {
      console.log("CONFIRMED TRANSACTIONS LIST");
      console.log(this.confirmedTransactionsList);
    }

    this.failedTransactionsList = await this.bindFailedTransactions(name);

    if(this.debug === true) {
      console.log("FAILED TRANSACTIONS LIST");
      console.log(this.failedTransactionsList);
    }

    //Close loading screen
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 1000);
  },
};
</script>
<style scoped>






table.comicGreen {
  font-family: "Comic Sans MS", cursive, sans-serif;
  border: 6px solid #ab925a;
  background-color: #EEEEEE;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
table.comicGreen td, table.comicGreen th {
  border: 1px solid #ac7036;
  padding: 3px 2px;
}
table.comicGreen tbody td {
  font-size: 16px;
  font-weight: bold;
  color: #2a4ea1;
}
table.comicGreen tr:nth-child(even) {
  background: #CEE0CC;
}
table.comicGreen thead {
  background: #ac7036;
  background: -moz-linear-gradient(top, #ffb56e 0%,#ac7036  66%,#ffad61 100%);
  background: -webkit-linear-gradient(top, #ffb56e 0%, #ac7036 66%, #ffad61 100%);
  background: linear-gradient(to bottom, #ffb56e 0%, #ac7036 66%, #ffad61 100%);
  border-bottom: 1px solid #ac7036;
}
table.comicGreen thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  border-left: 2px solid #D0E4F5;
}
table.comicGreen thead th:first-child {
  border-left: none;
}

table.comicGreen tfoot td {
  font-size: 16px;
}table.comicGreen {
   font-family: "Comic Sans MS", cursive, sans-serif;
   border: 6px solid #4F7849;
   background-color: #EEEEEE;
   width: 100%;
   text-align: center;
   border-collapse: collapse;
 }
table.comicGreen td, table.comicGreen th {
  border: 1px solid #4F7849;
  padding: 3px 2px;
}
table.comicGreen tbody td {
  font-size: 16px;
  font-weight: bold;
  color: #4F7849;
}
table.comicGreen tr:nth-child(even) {
  background: #CEE0CC;
}
table.comicGreen thead {
  background: #4F7849;
  background: -moz-linear-gradient(top, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  background: -webkit-linear-gradient(top, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  background: linear-gradient(to bottom, #7b9a76 0%, #60855b 66%, #4F7849 100%);
  border-bottom: 1px solid #444444;
}
table.comicGreen thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  border-left: 2px solid #D0E4F5;
}
table.comicGreen thead th:first-child {
  border-left: none;
}

table.comicGreen tfoot td {
  font-size: 16px;
}




.kadenalogo {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 20%;
  box-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 #ccc, 0 7px 0 #ccc, 0 8px 0 #ccc,
  0 9px 0 #ccc, 0 5px 5px rgba(0, 0, 0, 0.2);
}

.helpselected {
  border: 5px #ffda86;
  border-radius: 22px;
  border-style: inset;
  background-color: #699D8FD3;
  margin-left: 2rem;
  margin-right: 2rem;
  cursor: pointer;
}

.dark-text-section {
  border: 5px #ffda86;
  border-radius: 22px;
  border-style: outset;
  background-color: #86bfb0d4;
  margin-left: 2rem;
  margin-right: 2rem;
}


.discordcolor{
  color: #ffffff !important;
}

.bgdiscord{
  background-color: #5631c7;
}


.hidden-section {
  border: 5px #ffda86;
  border-radius: 22px;
  border-style: inset;
  background-color: #86bfb0d4;
  margin-left: 2rem;
  margin-right: 2rem;
}

.small-font-3d {
  font-family: 'Comic Neue', cursive;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 5px 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.panel {
  font-family: 'Comic Neue', sans-serif;
  padding: 0;
  border: none;
}

.panel .panel-heading {
  border-radius: 20px;
  padding: 10px;
  background-color: rgba(176,177,142,.57);
  border-style: inset;
}

.panel .panel-heading .title {
  color: #333;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 33px;
  margin: 0;
}

.panel .panel-heading .btn {
  color: #fff;
  background-color: #6c5ce7;
  font-size: 14px;
  font-weight: 600;
  padding: 7px 15px;
  border: none;
  border-radius: 0;
  transition: all 0.3s ease 0s;
}

.panel .panel-heading .btn:hover {
  box-shadow: 4px 4px 0 #6c5ce7;
}

.panel .panel-body {
  padding: 0;
  border-radius: 0;
}

.panel .panel-body .table thead tr th {
  color: #333;
  font-size: 19px;
  font-weight: 400;
  padding: 12px;
  border-bottom: none;
}

.panel .panel-body .table tbody tr td {
  color: #555;
  font-size: 15px;
  font-weight: 500;
  padding: 13px;
  vertical-align: middle;
  background: rgba(233, 229, 184, 0.36);
}

.panel .panel-body .table tbody tr:nth-child(even) td {
  background: rgba(245, 245, 245, 0.36);
}

.panel .panel-body .table tbody .percentage {
  color: #2ecc71;
  font-size: 17px;
  font-weight: 600;
  padding: 3px 10px 5px;
  border: 2px solid #2ecc71;
  border-radius: 5px;
  display: inline-block;
}

.panel .panel-body .table tbody .percentage.disable {
  color: #fc9f0a;
  border: 2px solid #fc9f0a;
}

.panel .panel-body .table tbody .percentage.failed {
  color: #e74c3c;
  border: 2px solid #e74c3c;
}

.panel .panel-body .table tbody .action-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.panel .panel-body .table tbody .action-list li {
  display: inline-block;
}

.panel .panel-footer {
  color: #333;
  border-radius: 40px;
  font-size: 16px;
  line-height: 33px;
  padding: 25px 15px;
}

.pagination1 {
  margin: 0;
}

.pagination1 li a {
  color: #333;
  font-size: 15px;
  font-weight: 700;
  margin: 0 2px;
  border: 2px solid #ddd;
  border-radius: 0;
  transition: all 0.3s ease 0s;
}

.pagination1 li a:hover,
.pagination1 li a:focus,
.pagination1 li.active a {
  color: #6c5ce7;
  background: none;
  border-color: transparent;
  border-bottom: 2px solid #6c5ce7;
}

@media only screen and (max-width: 479px) {
  .panel .panel-heading {
    text-align: center;
  }

  .panel .panel-heading .title {
    float: none !important;
    margin: 0 0 10px;
  }

  .panel .panel-heading .btn {
    float: none !important;
  }
}


.modal-box .mdialog {
  width: 100%;
  margin: 70px auto 0;
  margin-top: 0px;

}

@media (max-width: 576px) {
  .modal-box .mdialog {
    width: 95%;
  }
}

.modal.fade .mdialog {
  opacity: 0;
  transform: translate(30%, 30%) scale(0.5);
}

.modal.fade.in .mdialog {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.modal-box .mdialog .modal-content {
  border: 5px outset #ffda86;
  border-radius: 35px;
  /*box-shadow: 0px 0px 20px #c2803a, 0px 0px 20px #f6a960;*/
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  background-color: #e8c898;
}

.modal-content-no-shadow {
  border: 5px outset #ffda86;
  border-radius: 35px;
  background-color: #e8c898;
}

.modal-box .mdialog .modal-content .modal-body {
  padding: 65px 45px;
}

.modal-box .mdialog .modal-content .modal-body .icon {
  color: #827f3b;
  font-size: 80px;
  line-height: 145px;
  width: 100px;
  height: 100px;
  padding-left: 0px;
  padding-right: 10px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: 25px;
}

.modal-box .mdialog .modal-content .modal-body .inner-content {
  padding: 0 0 0 170px;
  margin: 0 0 30px;
  margin-top: 0px;
}

.modal-box .mdialog .modal-content .modal-body .title {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 5px 5px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.title-mobile {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 5px 5px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
  line-height: 30px;
}

.modal-box .mdialog .modal-content .modal-body .description {
  color: #ffffff;
  font-size: 18px;
  margin: 0;
  padding-top: 20px;
  font-family: 'Comic Neue', cursive;
}

.modal-box .mdialog .modal-content .modal-body .form-control {
  color: #6d5a36;
  background: #eee8dd;
  font-size: 15px;
  letter-spacing: 1px;
  height: 45px;
  padding: 2px 15px;
  border-radius: 50px 0 0 50px;
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control:focus {
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.modal-box .mdialog .modal-content .modal-body .subscribe {
  color: #fff;
  background: #ebbf53;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px 20px 11px;
  border: none;
  border-radius: 0 50px 50px 0;
  display: block;
  transition: all 0.3s;
}

.modal-box .mdialog .modal-content .modal-body .subscribe:hover,
.modal-box .mdialog .modal-content .modal-body .subscribe:focus {
  background: #827f3b;
}

.rcolumn2 {
  background-color: #edc180;
  border: 5px groove #ffda86;
  border-radius: 40px;
  padding: 0.3rem;
  padding-bottom: 0.6rem;
  font-family: 'Comic Neue', sans-serif;
  color: #b52e2e;
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 1rem;
}

.rcolumn {
  background-color: #efc78a;
  border: 5px solid #ffda86;
  border-radius: 40px;
  padding: 0.3rem;
  padding-bottom: 0.6rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.stat-column2 {
  background-color: #e8c898;
  border: 5px solid #ffda86;
  border-radius: 40px;
  padding: 0.3rem;
  padding-bottom: 0.6rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 1rem;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
}


.btnbreed {
  color: #fff;
  background-color: #e84393;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 13px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnbreed:active {
  margin-left: 10px;
  margin-top: 10px;
}

/*.btnbreed:focus,*/
/*.btnbreed:hover {*/
/*  color: #fff;*/
/*}*/

.btnbreed:focus,
.btnbreed:hover {
  color: #b43171;
  background-color: #42c1a3;
}

.btnbreed:hover:before,
.btnbreed:hover:after {
  content: '';
  background-color: #1dad82;
  z-index: -1;
}

.btnbreed:before,
.btnbreed:after {
  content: '';
  background-color: #ad1d63;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btnbreed:after {
  background-color: #821248;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btnbreed:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnbreed:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}



</style>
