<template>
  <div class="main-wrap">
    <div class="loader" v-if="this.showLoadingModal === true">
      <div class="loader__bar"></div>
      <div class="loader__bar loader__bar--delay-1"></div>
      <div class="loader__bar loader__bar--delay-2"></div>
      <div class="loader__bar loader__bar--delay-3"></div>
      <div class="loader__bar loader__bar--delay-4"></div>
      <div class="loader__bar loader__bar--delay-5"></div>
    </div>

    <section class="moveup" v-if="this.showLoadingModal === false">
      <Scroll-Div width="98%" height="85vh" view-class="yourclassname" v-if="this.showLoadingModal === false">
        <div class="container">
          <div v-if="$screen.width > 576" class="row align-items-center justify-content-center mt-4">
            <div class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalGallinas()+' Gallina(s).'">
            {{ this.getUserTotalGallinas() }} &nbsp; <img src="../../public/icons/chicken-mygallinas-icon.svg"
                                                          alt="Chickens" style="position:relative; top: 3px;"
                                                          width="40px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalEggs() > 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalEggs()+' Egg(s).'">
            {{ this.getUserTotalEggs() }} &nbsp; <img src="../../public/icons/egg-mygallinas-icon.svg"
                                                      style="position:relative; top: 5px;" alt="Eggs"
                                                      width="25px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalGiftVouchers() > 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You have '+this.getUserTotalGiftVouchers()+' Voucher(s).'">
            {{ this.getUserTotalGiftVouchers() }} &nbsp; <img src="../../public/icons/gift-voucher-icon.svg"
                                                              style="position:relative; top: 5px;" alt="Gift Vouchers"
                                                              width="40px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalEggs() <= 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You dont have any Eggs right now'">
            0 &nbsp; <img src="../../public/icons/egg-mygallinas-icon.svg" style="position:relative; top: 5px;"
                          alt="Eggs"
                          width="25px"/>
            </span>
            </div>
            <div v-if="this.getUserTotalGiftVouchers() <= 0"
                 class="col-md-3 d-flex align-items-center justify-content-center stat-column2">
            <span v-tooltip="'You dont have any Vouchers right now'">
            0 &nbsp; <img src="../../public/icons/gift-voucher-icon.svg" style="position:relative; top: 5px;"
                          alt="Gift Vouchers"
                          width="40px"/>
            </span>
            </div>
          </div>

          <div v-if="$screen.width <= 576" class="row align-items-center justify-content-center mt-2">
            <div class="col-md-10 d-flex align-items-center justify-content-center stat-column2"><span class="hugleft">
            {{ this.getUserTotalGallinas() }} &nbsp; <img src="../../public/icons/chicken-mygallinas-icon.svg"
                                                          alt="Chickens" width="40px"/>  </span> &nbsp; <span
                class="hugright">  {{ this.getUserTotalEggs() }} &nbsp; <img
                src="../../public/icons/egg-mygallinas-icon.svg" alt="Chickens" width="25px"/></span>
            </div>
          </div>
          <div class="modal-box">
            <div class="mdialog" role="document">
              <div class="modal-content clearfix">
                <div class="modal-body">
                  <img src="../../public/icons/AdoptionStore.svg" class="mt-3" width="80%"
                       alt="Buy Trade And Hatch Eggs"/>
                  <div class="instructionpanel">
                    <p style="margin-top: 20px;">The pet shop has run out of Gallina gift sets! Please purchase Gallina sets from your local Gallina breeders!</p>
                  </div>

                  <div class="inner-content">
                    <h3 class="title eggsTitle">Pet Adoption + Gift Shop</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

<!--          <div :class="this.showBurnableGallina !== false ? 'grid-container mt-5' : 'grid-container2 mt-5'">-->

<!--            <div v-if="this.showBurnableGallina !== false" class="grid-child purple">-->

<!--              <div v-if="this.showBurnableGallina !== false" class="container">-->
<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <div class="pricingTable">-->
<!--                      <div class="pricingTable-header">-->
<!--                        <h3 class="title">Trade Pet</h3>-->
<!--                      </div>-->
<!--                      <div class="pricing-content">-->
<!--                        <div class="price-value">-->
<!--                          <span class="amount"><span-->
<!--                              style="position: relative; z-index: 3; left: 40px; top: 9px;">+{{genToVoucher2}} </span> <img-->
<!--                              src="../../public/icons/gift-voucher-icon.svg"-->
<!--                              style="position:relative; top: -7px; right:40px; z-index: 0;" alt="Gift Vouchers"-->
<!--                              width="100px"/></span>-->
<!--                          <span class="month">1 Voucher Paid Per Gallina</span>-->
<!--                        </div>-->

<!--                        <div class="row">-->

<!--                          <div v-for="g in gallinasToShowNowList2" :key="g.id" class="col d-flex align-items-center justify-content-center mt-4 mb-4">-->
<!--                            <FlipCard-->
<!--                                      :showbuttons="false"-->
<!--                                      :showbuybutton="false"-->
<!--                                      shadow="1"-->
<!--                                      :name="g.name" :id="g.id"-->
<!--                                      :gender="g.gender" :birthday="g.birthday" :nbd="g.nbd"-->
<!--                                      :motherid="g.motherid" :fatherid="g.fatherid"-->
<!--                                      :price="g.price"-->
<!--                                      :generation="g.generation.toString()"-->
<!--                                      :special="g.special.toString()"-->
<!--                                      :setcount="g.setcount.toString()"-->
<!--                                      :rarity="g.rarity.toString()"-->
<!--                                      :gene1h1="g.gene1h1.toString()" :gene2h1="g.gene2h1.toString()" :gene3h1="g.gene3h1.toString()"-->
<!--                                      :gene4h1="g.gene4h1.toString()" :gene5h1="g.gene5h1.toString()" :gene6h1="g.gene6h1.toString()"-->
<!--                                      :gene7h1="g.gene7h1.toString()" :gene8h1="g.gene8h1.toString()" :gene9h1="g.gene9h1.toString()"-->
<!--                                      :gene10h1="g.gene10h1.toString()"-->
<!--                                      :gene1h2="g.gene1h2.toString()" :gene2h2="g.gene2h2.toString()" :gene3h2="g.gene3h2.toString()"-->
<!--                                      :gene4h2="g.gene4h2.toString()" :gene5h2="g.gene5h2.toString()" :gene6h2="g.gene6h2.toString()"-->
<!--                                      :gene7h2="g.gene7h2.toString()" :gene8h2="g.gene8h2.toString()" :gene9h2="g.gene9h2.toString()"-->
<!--                                      :gene10h2="g.gene10h2.toString()"-->
<!--                                      :gene1h3="g.gene1h3.toString()" :gene2h3="g.gene2h3.toString()" :gene3h3="g.gene3h3.toString()"-->
<!--                                      :gene4h3="g.gene4h3.toString()" :gene5h3="g.gene5h3.toString()" :gene6h3="g.gene6h3.toString()"-->
<!--                                      :gene7h3="g.gene7h3.toString()" :gene8h3="g.gene8h3.toString()" :gene9h3="g.gene9h3.toString()"-->
<!--                                      :gene10h3="g.gene10h3.toString()"-->
<!--                                      :beak="g.gene1p.toString()" :belly="g.gene2p.toString()" :chest="g.gene3p.toString()"-->
<!--                                      :comb="g.gene4p.toString()" :eyes="g.gene5p.toString()" :feet="g.gene6p.toString()"-->
<!--                                      :head="g.gene7p.toString()" :legs="g.gene8p.toString()" :wattle="g.gene9p.toString()"-->
<!--                                      :wings="g.gene10p.toString()">-->
<!--                            </FlipCard>-->
<!--                          </div>-->
<!--                          <br/>-->
<!--                          <p class="white">[Gallinas.io will trade you {{ genToVoucher2 }} Voucher(s) for this Gallina]</p>-->

<!--                        </div>-->

<!--                      </div>-->
<!--                      <a href="#" @click.prevent="clickTrade" class="pricingTable-signup">Trade</a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->

<!--            <div class="grid-child green">-->

<!--              <div class="container" :class="this.showBurnableGallina !== false ? '' : 'giftcolwidth'">-->
<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <div class="pricingTable">-->
<!--                      <div class="pricingTable-header">-->
<!--                        <h3 class="title">Buy Gift</h3>-->
<!--                      </div>-->
<!--                      <div class="pricing-content">-->
<!--                        <div class="price-value">-->
<!--                          <span class="amount"><span-->
<!--                              style="position: relative; z-index: 3; left: 40px; top: 7px;">-1</span> <img-->
<!--                              src="../../public/icons/gift-voucher-icon.svg"-->
<!--                              style="position:relative; top: -7px; right:35px; z-index: 0;" alt="Gift Vouchers"-->
<!--                              width="100px"/></span>-->
<!--                          <span class="month"><countdown :time="thisweeksgiftendtime">-->
<!--                            <template slot-scope="props">Ends In {{ props.days }}:{{-->
<!--                                props.hours-->
<!--                              }}:{{ props.minutes }}:{{ props.seconds }}</template>-->
<!--                          </countdown></span>-->
<!--                        </div>-->

<!--                        <div class="row">-->

<!--                          <div class="col d-flex align-items-center justify-content-center mt-4 mb-4">-->
<!--                            <FlipCard :name="'Set #: '+ thisweeksgiftid.toString()" id="Gallinas.io" @flipped="isflipped = !isflipped"-->
<!--                                      :showbuttons="false"-->
<!--                                      :useexpand="false"-->
<!--                                      :generation="0"-->
<!--                                      shadow="1"-->
<!--                                      :special="thisweeksgiftid.toString()"-->
<!--                                      gender="?" birthday="?" nbd="?"-->
<!--                                      motherid="Gallinas.io" fatherid="Gallinas.io"-->
<!--                                      :setcount="giftGallinaSetCount"-->
<!--                                      rarity="1"-->
<!--                                      :gene1h1="randomDNA.toString()" :gene2h1="randomDNA.toString()"-->
<!--                                      :gene3h1="randomDNA.toString()"-->
<!--                                      :gene4h1="randomDNA.toString()" :gene5h1="randomDNA.toString()"-->
<!--                                      :gene6h1="randomDNA.toString()"-->
<!--                                      :gene7h1="randomDNA.toString()" :gene8h1="randomDNA.toString()"-->
<!--                                      :gene9h1="randomDNA.toString()"-->
<!--                                      :gene10h1="randomDNA.toString()"-->
<!--                                      :gene1h2="randomDNA.toString()" :gene2h2="randomDNA.toString()"-->
<!--                                      :gene3h2="randomDNA.toString()"-->
<!--                                      :gene4h2="randomDNA.toString()" :gene5h2="randomDNA.toString()"-->
<!--                                      :gene6h2="randomDNA.toString()"-->
<!--                                      :gene7h2="randomDNA.toString()" :gene8h2="randomDNA.toString()"-->
<!--                                      :gene9h2="randomDNA.toString()"-->
<!--                                      :gene10h2="randomDNA.toString()"-->
<!--                                      :gene1h3="randomDNA.toString()" :gene2h3="randomDNA.toString()"-->
<!--                                      :gene3h3="randomDNA.toString()"-->
<!--                                      :gene4h3="randomDNA.toString()" :gene5h3="randomDNA.toString()"-->
<!--                                      :gene6h3="randomDNA.toString()"-->
<!--                                      :gene7h3="randomDNA.toString()" :gene8h3="randomDNA.toString()"-->
<!--                                      :gene9h3="randomDNA.toString()"-->
<!--                                      :gene10h3="randomDNA.toString()"-->
<!--                                      :beak="randomDNA.toString()" :belly="randomDNA.toString()" :chest="randomDNA.toString()"-->
<!--                                      :comb="randomDNA.toString()" :eyes="randomDNA.toString()" :feet="randomDNA.toString()"-->
<!--                                      :head="randomDNA.toString()" :legs="randomDNA.toString()" :wattle="randomDNA.toString()"-->
<!--                                      :wings="randomDNA.toString()">-->
<!--                            </FlipCard>-->
<!--                          </div>-->
<!--                          <br/>-->
<!--                          <p class="white">[Gallina not included]</p>-->

<!--                        </div>-->

<!--                      </div>-->
<!--                      <a href="#" @click.prevent="clickShowBuyGift" class="pricingTable-signup">Buy</a>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <br/>-->
<!--                <br/>-->
<!--                <br/>-->

<!--              </div>-->

<!--            </div>-->

<!--          </div>-->

        </div>
      </Scroll-Div>
    </section>

    <vmodalwaitsignature v-if="showTransactionModal" :show-gold-header="false" content-class="darkMode"
                         :prevent-click="true" class-name="connect-wallet-modal" confirm-name="Ok"
                         v-model="showTransactionModal" @confirm="showTransactionModal = false"
                         @cancel="showTransactionModal = false" name="intro" cancel-name="Cancel">

      <template v-slot:title>
        <div class="pt-2">
          Awaiting wallet response
        </div>
      </template>

      <div class="displayGuard">
        <div class="row align-items-center justify-content-center mt-4 mb-0">
          <div class="p-0 border-gold text-center connect-wallet-text alert col-10 mt-4">
            We just sent a request to your wallet.<br/>
            <b>Always review your wallet's transactions.</b> When you're ready, sign, come back and click OK!
          </div>
        </div>
      </div>

    </vmodalwaitsignature>

    <vmodalgiftgallina v-if="showGiftModal" :show-gold-header="false" content-class="darkMode" :show-cancel="false"
                       :show-confirm="false"
                       :prevent-click="true" class-name="connect-wallet-modal"
                       cancel-name="Nevermind" v-model="showGiftModal" name="gift" modaltitle="Buy Gallina Gift">
      <template v-slot:title>
        <div class="text3d titleareagift">
          Buy your Gallina a gift
        </div>
      </template>

      <div v-if="giftGallinaFound === false" class="chicken-breed-first-modal">
        <Chicken setwidth="150px" :beak="randomDNA.toString()" :belly="randomDNA.toString()" :chest="randomDNA.toString()" :comb="randomDNA.toString()" :eyes="randomDNA.toString()" :feet="randomDNA.toString()"
                 :head="randomDNA.toString()"
                 :legs="randomDNA.toString()" :wattle="randomDNA.toString()" :wings="randomDNA.toString()" :special="thisweeksgiftid.toString()"/>
      </div>

      <section v-if="giftGallinaFound === true">
        <div v-for="g in this.giftGallinaList" :key="g.id" class="chicken-breed-first-modal">
          <Chicken setwidth="150px" :beak="g.gene1p.toString()" :belly="g.gene2p.toString()" :chest="g.gene3p.toString()" :comb="g.gene4p.toString()" :eyes="g.gene5p.toString()" :feet="g.gene6p.toString()"
                   :head="g.gene7p.toString()"
                   :legs="g.gene8p.toString()" :wattle="g.gene9p.toString()" :wings="g.gene10p.toString()" :special="thisweeksgiftid.toString()"/>
        </div>
      </section>


      <div class="row align-items-center justify-content-center">
        <div v-if="giftGallinaFound === true" class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-notfound mt-2">
          {{ this.giftGallinaList[0].name }}
        </div>
        <div v-if="giftGallinaFound === false" class="p-0 border-20 text-center connect-wallet-text alert col-10 bubble-bg-notfound mt-2">
          Buy this gift for one of your Gallinas?
        </div>
        <div class="col-10">
          <input @input="giftGallinaId = $event.target.value;autoPlug()"
                 :v-model="giftGallinaId" type="text" class="form__input" id="mateinput"
                 placeholder="My Gallinas ID" required=""/>
          <label for="mateinput" class="form__label">Enter the ID of your Gallina to buy this gift for</label>
        </div>
      </div>

      <div class="breed-modal-buttons">
        <button v-if="giftGallinaFound === true" class="p-1 m-2 breed-modal-buttons-style eggOne"
                @click="clickOkBuyGift">Buy Gift
        </button>
        <button class="p-1 m-2 breed-modal-buttons-style eggTwo" @click="clickCancelGift">Cancel</button>
      </div>


    </vmodalgiftgallina>


  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ScrollDiv from "vue-scroll-div";
// import VueCountdown from "@chenfengyuan/vue-countdown";
// import FlipCard from "@/components/FlipCard";
import Chicken from "@/components/Chicken";
import vmodalwaitsignature from "@/components/VModalWaitSignature";
import vmodalgiftgallina from "@/components/VModalGiftGallina";


export default {
  name: "BuyEggsPage",
  components: {
    ScrollDiv,
    vmodalwaitsignature,
    // FlipCard,
    Chicken,
    vmodalgiftgallina,
    // "countdown": VueCountdown,

  },
  props: {
    x: {
      type: String,
      default: "0",
      required: false,
    },
  },
  data() {
    return {
      showLoadingModal: true,
      showTransactionModal: false,
      showGiftModal: false,
      gallinaidtoburn: null,
      newGallinaName: "",
      thisweeksgiftid: 3,
      thisweeksgiftendtime: "1",
      gallinaToShowNowList: [],
      allUserGallinasList: [],
      marketGallinasForSaleListgio: [],
      gallinasToShowNowList2: [],
      giftGallinaId: null,
      giftGallinaList:[],
      gallinaInfoList:[],
      showBurnableGallina: false,
      chickenMateIdInput: '',
      giftGallinaSetCount: 1,
      gallinaGeneration: 0,
      randomDNA: 0,
      isflipped: false,
      giftGallinaFound: false,
      debug: false,
    };
  },
  watch: {
    transactionPolling: function (newValue, oldValue) { //transactionpending indicates we should start a timer for 2 minutes
      if(this.debug){
        console.log("BurnPage getTransactionPending change detected");
        console.log("BurnPage getTransactionPending newValue: " + newValue);
        console.log("BurnPage getTransactionPending oldValue: " + oldValue);
      }
      if(newValue === false){
        this.checkOwnership();
      }
    },
  },
  computed: {
    ...mapState("accounts", ["transactionPolling"]),

    genToVoucher2(){
      if(this.gallinaGeneration === 0){
        return 0;
      }else {
        return 1;
      }
    },

  },
  methods: {
    ...mapActions("accounts", ["getAllGameGallinas", "getAllGameEggs", "getGallinaBurnInfo", "bindInspectGallina", "bindInspectGallina", "bindMarketGallinas", "bindGallinaSetCount", "bindGallinaRarity", "bindCheckPending", "bindGiftGallinaVerification" , "getGallinaOwnerInfo"]),
    ...mapGetters("accounts", ["getAccountExists", "getGameTotalGallinas", "getGameTotalEggs", "getAccountConfirmed", "getTransactionHash", "getUserTotalGallinas", "getUserTotalEggs", "getUserGallinasList", "getUserGallinaCount", "getGallinaBurnList", "getUserTotalGiftVouchers", "getGameCurrentGift", "getGameCurrentGiftEndTime", "getGallinaOwnerInfoList", "getRequestPending", "getTransactionPolling"]),



    async randomIntFromInterval(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    async autoPlug(){

      this.giftGallinaFound = false;
      this.gallinaInfoList=null;
      try{
        this.gallinaInfoList = await this.bindGiftGallinaVerification(this.giftGallinaId);
      }catch{
        console.log("ERROR GETTING GIFT GALLINA ID");
      }

      let rarity = [];
      let gallinaDetailsList = [];
      let forsale = false;
      let price = 0;
      let seller = null;

      if(this.gallinaInfoList !== undefined && this.gallinaInfoList !== null && this.gallinaInfoList[0] !== undefined && this.gallinaInfoList[0]["dna"] !== undefined){


        let rarityPayload = {
          dna: this.gallinaInfoList[0]["dna"],
          special: this.gallinaInfoList[0]["special"]
        };

        if(this.debug === true) {
          console.log("RARITY PAYLOAD");
          console.log(rarityPayload);

          console.log("DNA");
          console.log(this.gallinaInfoList[0]["dna"]);
          console.log(this.gallinaInfoList[0]["special"]);
        }


        await this.bindGallinaRarity(rarityPayload).then((response) => {
          if(this.debug === true) {
            console.log("GALLINA 1 RARITY RESPONSE");
            console.log(response);
          }
          rarity = response;
        });

        await this.bindGallinaSetCount().then((response) => {

          let set = "col" + this.gallinaInfoList[0]["special"].toString();

          if(this.debug === true) {
            console.log("SET");
            console.log(set);
            console.log("SETCOUNT");
            console.log(response);

            console.log("RARITY");
            console.log(rarity);

            console.log("RARITY LENGTH");
            console.log(rarity.length);
          }


          let gallinaDetailsObject = {
            name: this.gallinaInfoList[0].name,
            id: this.gallinaInfoList[0].gid,
            account: this.gallinaInfoList[0].owner,
            gender: this.gallinaInfoList[0].gender,
            motherid: this.gallinaInfoList[0]["mother_id"],
            fatherid: this.gallinaInfoList[0]["father_id"],
            birthday: this.gallinaInfoList[0]["birthday"],
            nbd: this.gallinaInfoList[0]["next_breed_time"],
            generation: this.gallinaInfoList[0]["generation"],
            special: this.gallinaInfoList[0]["special"],
            setcount: response[0][set],
            rarity: rarity.length,
            gene1p: this.gallinaInfoList[0]["gene_1_p"],
            gene2p: this.gallinaInfoList[0]["gene_2_p"],
            gene3p: this.gallinaInfoList[0]["gene_3_p"],
            gene4p: this.gallinaInfoList[0]["gene_4_p"],
            gene5p: this.gallinaInfoList[0]["gene_5_p"],
            gene6p: this.gallinaInfoList[0]["gene_6_p"],
            gene7p: this.gallinaInfoList[0]["gene_7_p"],
            gene8p: this.gallinaInfoList[0]["gene_8_p"],
            gene9p: this.gallinaInfoList[0]["gene_9_p"],
            gene10p: this.gallinaInfoList[0]["gene_10_p"],
            gene1h1: this.gallinaInfoList[0]["gene_1_h1"],
            gene2h1: this.gallinaInfoList[0]["gene_2_h1"],
            gene3h1: this.gallinaInfoList[0]["gene_3_h1"],
            gene4h1: this.gallinaInfoList[0]["gene_4_h1"],
            gene5h1: this.gallinaInfoList[0]["gene_5_h1"],
            gene6h1: this.gallinaInfoList[0]["gene_6_h1"],
            gene7h1: this.gallinaInfoList[0]["gene_7_h1"],
            gene8h1: this.gallinaInfoList[0]["gene_8_h1"],
            gene9h1: this.gallinaInfoList[0]["gene_9_h1"],
            gene10h1: this.gallinaInfoList[0]["gene_10_h1"],
            gene1h2: this.gallinaInfoList[0]["gene_1_h2"],
            gene2h2: this.gallinaInfoList[0]["gene_2_h2"],
            gene3h2: this.gallinaInfoList[0]["gene_3_h2"],
            gene4h2: this.gallinaInfoList[0]["gene_4_h2"],
            gene5h2: this.gallinaInfoList[0]["gene_5_h2"],
            gene6h2: this.gallinaInfoList[0]["gene_6_h2"],
            gene7h2: this.gallinaInfoList[0]["gene_7_h2"],
            gene8h2: this.gallinaInfoList[0]["gene_8_h2"],
            gene9h2: this.gallinaInfoList[0]["gene_9_h2"],
            gene10h2: this.gallinaInfoList[0]["gene_10_h2"],
            gene1h3: this.gallinaInfoList[0]["gene_1_h3"],
            gene2h3: this.gallinaInfoList[0]["gene_2_h3"],
            gene3h3: this.gallinaInfoList[0]["gene_3_h3"],
            gene4h3: this.gallinaInfoList[0]["gene_4_h3"],
            gene5h3: this.gallinaInfoList[0]["gene_5_h3"],
            gene6h3: this.gallinaInfoList[0]["gene_6_h3"],
            gene7h3: this.gallinaInfoList[0]["gene_7_h3"],
            gene8h3: this.gallinaInfoList[0]["gene_8_h3"],
            gene9h3: this.gallinaInfoList[0]["gene_9_h3"],
            gene10h3: this.gallinaInfoList[0]["gene_10_h3"],
            isforsale: forsale,
            price: price,
            seller: seller,
          };

          if(this.debug === true) {
            console.log("SPECIAL");
            console.log(this.gallinaInfoList[0]["special"]);
            console.log("SPECIAL TYPOF");
            console.log(typeof (this.gallinaInfoList[0]["special"]));
            console.log("gallinaDetailsObject");
            console.log(gallinaDetailsObject);
          }


          gallinaDetailsList.push(gallinaDetailsObject);

        }).catch((err) => {

          if(this.debug === true) {
            console.log("Get gallina info error");
            console.log(err);
          }

        });

        if(this.debug === true) {
          console.log("GallinaListToShowForGift:");
          console.log(gallinaDetailsList);
        }


        this.giftGallinaList = gallinaDetailsList;

        this.giftGallinaFound = true;


      }else {
        this.giftGallinaFound = false;
      }

    },

    async clickOkBuyGift(){


      if(this.getUserTotalGiftVouchers() > 0){

        let name = localStorage.getItem("accountName");
        if (name === null || name === "") {
          await this.$router.push({path: `/`});
        }

        if(this.giftGallinaFound === true){
          await this.bindCheckPending(name).then(async(response)=> {

            if(this.debug === true) {
              console.log("PENDING TX CHECK RESPONSE");
              console.log(response);
            }

            if (response !== undefined && response[0] !== undefined && response[0].length === 0 || response === 0) {


              await this.$store.dispatch("accounts/bindUserInfoNew", name).then(async()=>{

                let allUserGallinas = this.getUserGallinasList();
                this.allUserGallinasList = allUserGallinas;

                let ownerCheck = false;

                if(this.debug === true){
                  console.log("PERFORMING OWNER CHECK");
                }

                for(let i = 0; i < this.allUserGallinasList.length; i++){

                  if(this.debug === true){
                    console.log("CHECKING IF "+ this.allUserGallinasList[i].gid +" IS EQUAL TO "+ this.chickenMateIdInput);
                    console.log("CHECKING IF "+ this.allUserGallinasList[i].owner +" IS EQUAL TO "+ name);
                  }



                  if(this.allUserGallinasList[i].gid === this.giftGallinaId && this.allUserGallinasList[i].owner === name){
                    //We found the gallina and this user owns it, so lets go ahead and buy a gift for it!
                    ownerCheck = true;
                  }
                }

                if(ownerCheck === true){
                  let payload = {
                    accountName: name,
                    gallinaId: this.giftGallinaId,
                  };

                  if(this.debug) {
                    console.log("BUY GIFT PAYLOAD");
                    console.log(payload);
                  }

                  this.showTransactionModal = true;


                  await this.$store.dispatch("accounts/buyGallinaGift", payload);
                }else {
                  alert("You can only buy gifts for Gallinas that you own.");
                }

              });




            } else {
              alert("Please wait for your current gallinas.io transaction to confirm before making another.");
            }
          }).catch((error)=>{

            if(this.debug) {
              console.log("Gallinas.io communication error");
              console.log(error);
            }

          });
        }else {
          alert("No Gallina found matching that ID. Please check the ID and try again.");
        }

      }else {
        alert("You do not have enough Gift Vouchers to buy this gift.");
      }

      this.showGiftModal = false;
    },

    async clickCancelGift(){
      this.showGiftModal = false;
    },

    async clickShowBuyGift(){
      this.showGiftModal=true;
    },

    async clickTrade() {

      let name = localStorage.getItem("accountName");
      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      await this.bindCheckPending(name).then(async(response)=>{

        if(this.debug) {
          console.log("PENDING TX CHECK RESPONSE");
          console.log(response);
        }

        if(response !== undefined && response[0] !== undefined && response[0].length === 0 || response !== undefined && response === "0" || response !== undefined && response === 0) {
          this.showSignTransactionModal = true;
          if (this.debug) {
            console.log(this.seller);
          }

          let theAccountName = localStorage.getItem("accountName");


          let payload = {
            accountName: theAccountName,
            gallinaId: this.x,
          };

          if(this.debug) {
            console.log("BURN PAYLOAD");
            console.log(payload);
          }


          this.showTransactionModal = true;
          await this.$store.dispatch("accounts/burnGallina", payload);
        }else {
          alert("Please wait for your current gallinas.io transaction to confirm before making another.")
        }
      });

    },

    async checkOwnership(){

      let name = localStorage.getItem("accountName");

      if (name === null || name === "") {
        await this.$router.push({path: `/`});
      }

      if(this.x !== 0){
        await this.$store.dispatch("accounts/getGallinaOwnerInfo", this.x);

        let gallinaBurnBC = this.getGallinaOwnerInfoList();

        if(this.debug){
          console.log("GALLINABURNBC");
          console.log(gallinaBurnBC);

          console.log("GALLINABURNBC LENGTH > 0");
          console.log(gallinaBurnBC[0]["data"].length > 0);
        }


        if(gallinaBurnBC[0]["data"].length === 0){

          this.showBurnableGallina = false;

        }
      }


    }


  },

  async created() {

    let name = localStorage.getItem("accountName");

    if (name === null || name === "") {
      await this.$router.push({path: `/`});
    }

    if(this.debug) {
      console.log("Getting user information..");
    }
    //Get number of Eggs from blockchain this user owns, if any
    await this.$store.dispatch("accounts/getAllUserEggs", name);

    await this.$store.dispatch("accounts/getCurrentGift");

    await this.$store.dispatch("accounts/getAllUserGiftVouchers", name);

    await this.$store.dispatch("accounts/getUserGallinaCount", name);

    await this.$store.dispatch("accounts/getCurrentGiftEndTime");

    this.thisweeksgiftid = await this.getGameCurrentGift();

    this.thisweeksgiftendtime = await this.getGameCurrentGiftEndTime();

    this.randomDNA = await this.randomIntFromInterval(1,20);

    let t_thisweeksgiftendtime = Date.now();
    let t_now = Date.now();

    if(this.debug){
      console.log("THIS WEEKS GIFT");
      console.log(this.thisweeksgiftid);

      console.log("CURRENT PREVIOUS TIME");
      console.log(2 * 24 * 60 * 60 * 1000);

      console.log("THIS WEEKS GIFT END TIME 1");
      console.log(t_thisweeksgiftendtime);
    }


    if(this.thisweeksgiftendtime.time !== undefined){
      t_thisweeksgiftendtime = new Date(this.thisweeksgiftendtime.time).getTime() - t_now;
    }else if(this.thisweeksgiftendtime.timep !== undefined){
      t_thisweeksgiftendtime = new Date(this.thisweeksgiftendtime.timep).getTime() - t_now;
    }

    if(this.debug) {
      console.log("THIS WEEKS GIFT END TIME 2");
      console.log(t_thisweeksgiftendtime);
    }


    this.thisweeksgiftendtime = t_thisweeksgiftendtime;

    //Now we get the complete list of IDs of gallinas this user owns from blockchain, and the total count of gallinas they own
    await this.$store.dispatch("accounts/getUserGallinaCount", name);

    await this.bindGallinaSetCount().then((response) => {

      if(this.debug) {
        console.log(response);
      }

      this.giftGallinaSetCount = response[0]["col"+this.thisweeksgiftid.toString()];

      if(this.debug) {
        console.log("BIND SET COUNT");
        console.log(this.giftGallinaSetCount);
      }

    }).catch((error)=>{
      if(this.debug) {
        console.log("Could not get gift gallina rarity");
        console.log(error);
      }
    });

    this.gallinaidtoburn = this.x;

    if(this.debug) {
      console.log("gallinaidtoburn");
      console.log(this.gallinaidtoburn);

      console.log("x:");
      console.log(this.x);
    }

    if (this.x !== "0") {
      await this.$store.dispatch("accounts/getGallinaOwnerInfo", this.x);

      let gallinaBurnBC = this.getGallinaOwnerInfoList();

      if(this.debug){
        console.log("GALLINABURNBC");
        console.log(gallinaBurnBC);

        console.log("GALLINABURNBC 1");
        console.log(gallinaBurnBC[0]["data"].length > 0);
      }


      if(gallinaBurnBC[0]["data"].length > 0){

        if(gallinaBurnBC[0]["data"][0].account === name){
          this.showBurnableGallina = true;
        }

        //Next we get the gallinas info to show on this page
        this.gallinaToShowNowList = await this.bindInspectGallina(this.x);

        //Next we get complete list of Gallinas that are for sale from gallinasio to list prices
        this.marketGallinasForSaleListgio = await this.bindMarketGallinas();

        let rarity = [];
        let gallinaDetailsList = [];
        let forsale = false;
        let price = 0;
        let seller = null;

        for (let m = 0; m < this.marketGallinasForSaleListgio.length; m++) {

          if (this.gallinaToShowNowList[0].gid === this.marketGallinasForSaleListgio[m].gid) {
            forsale = true;
            price = this.marketGallinasForSaleListgio[m].price;
            seller = this.marketGallinasForSaleListgio[m].owner;
          }

        }

        let rarityPayload = {
          dna: this.gallinaToShowNowList[0]["dna"],
          special: this.gallinaToShowNowList[0]["special"]
        };

        if(this.debug) {
          console.log("RARITY PAYLOAD");
          console.log(rarityPayload);

          console.log("DNA");
          console.log(this.gallinaToShowNowList[0]["dna"]);
          console.log(this.gallinaToShowNowList[0]["special"]);
        }


        await this.bindGallinaRarity(rarityPayload).then((response) => {
          if(this.debug) {
            console.log("GALLINA 1 RARITY RESPONSE");
            console.log(response);
          }
          rarity = response;
        });

        await this.bindGallinaSetCount().then((response) => {

          let set = "col" + this.gallinaToShowNowList[0]["special"].toString();

          if(this.debug) {
            console.log("SET");
            console.log(set);
            console.log("SETCOUNT");
            console.log(response);

            console.log("RARITY");
            console.log(rarity);

            console.log("RARITY LENGTH");
            console.log(rarity.length);
          }

          this.gallinaGeneration = this.gallinaToShowNowList[0]["generation"];

          if(this.debug){
            console.log("gallinaGeneration");
            console.log(this.gallinaGeneration);
          }


          let gallinaDetailsObject = {
            name: this.gallinaToShowNowList[0].name,
            id: this.gallinaToShowNowList[0].gid,
            account: this.gallinaToShowNowList[0].owner,
            gender: this.gallinaToShowNowList[0].gender,
            motherid: this.gallinaToShowNowList[0]["mother_id"],
            fatherid: this.gallinaToShowNowList[0]["father_id"],
            birthday: this.gallinaToShowNowList[0]["birthday"],
            nbd: this.gallinaToShowNowList[0]["next_breed_time"],
            generation: this.gallinaToShowNowList[0]["generation"],
            special: this.gallinaToShowNowList[0]["special"],
            setcount: response[0][set],
            rarity: rarity.length,
            gene1p: this.gallinaToShowNowList[0]["gene_1_p"],
            gene2p: this.gallinaToShowNowList[0]["gene_2_p"],
            gene3p: this.gallinaToShowNowList[0]["gene_3_p"],
            gene4p: this.gallinaToShowNowList[0]["gene_4_p"],
            gene5p: this.gallinaToShowNowList[0]["gene_5_p"],
            gene6p: this.gallinaToShowNowList[0]["gene_6_p"],
            gene7p: this.gallinaToShowNowList[0]["gene_7_p"],
            gene8p: this.gallinaToShowNowList[0]["gene_8_p"],
            gene9p: this.gallinaToShowNowList[0]["gene_9_p"],
            gene10p: this.gallinaToShowNowList[0]["gene_10_p"],
            gene1h1: this.gallinaToShowNowList[0]["gene_1_h1"],
            gene2h1: this.gallinaToShowNowList[0]["gene_2_h1"],
            gene3h1: this.gallinaToShowNowList[0]["gene_3_h1"],
            gene4h1: this.gallinaToShowNowList[0]["gene_4_h1"],
            gene5h1: this.gallinaToShowNowList[0]["gene_5_h1"],
            gene6h1: this.gallinaToShowNowList[0]["gene_6_h1"],
            gene7h1: this.gallinaToShowNowList[0]["gene_7_h1"],
            gene8h1: this.gallinaToShowNowList[0]["gene_8_h1"],
            gene9h1: this.gallinaToShowNowList[0]["gene_9_h1"],
            gene10h1: this.gallinaToShowNowList[0]["gene_10_h1"],
            gene1h2: this.gallinaToShowNowList[0]["gene_1_h2"],
            gene2h2: this.gallinaToShowNowList[0]["gene_2_h2"],
            gene3h2: this.gallinaToShowNowList[0]["gene_3_h2"],
            gene4h2: this.gallinaToShowNowList[0]["gene_4_h2"],
            gene5h2: this.gallinaToShowNowList[0]["gene_5_h2"],
            gene6h2: this.gallinaToShowNowList[0]["gene_6_h2"],
            gene7h2: this.gallinaToShowNowList[0]["gene_7_h2"],
            gene8h2: this.gallinaToShowNowList[0]["gene_8_h2"],
            gene9h2: this.gallinaToShowNowList[0]["gene_9_h2"],
            gene10h2: this.gallinaToShowNowList[0]["gene_10_h2"],
            gene1h3: this.gallinaToShowNowList[0]["gene_1_h3"],
            gene2h3: this.gallinaToShowNowList[0]["gene_2_h3"],
            gene3h3: this.gallinaToShowNowList[0]["gene_3_h3"],
            gene4h3: this.gallinaToShowNowList[0]["gene_4_h3"],
            gene5h3: this.gallinaToShowNowList[0]["gene_5_h3"],
            gene6h3: this.gallinaToShowNowList[0]["gene_6_h3"],
            gene7h3: this.gallinaToShowNowList[0]["gene_7_h3"],
            gene8h3: this.gallinaToShowNowList[0]["gene_8_h3"],
            gene9h3: this.gallinaToShowNowList[0]["gene_9_h3"],
            gene10h3: this.gallinaToShowNowList[0]["gene_10_h3"],
            isforsale: forsale,
            price: price,
            seller: seller,
          };

          if(this.debug) {
            console.log("SPECIAL");
            console.log(this.gallinaToShowNowList[0]["special"]);
            console.log("SPECIAL TYPOF");
            console.log(typeof (this.gallinaToShowNowList[0]["special"]));
            console.log("gallinaDetailsObject");
            console.log(gallinaDetailsObject);
          }


          gallinaDetailsList.push(gallinaDetailsObject);

        }).catch((err) => {

          console.log("Get gallina info error");
          console.log(err);

        });

        if(this.debug) {
          console.log("GallinaListToShowThisPage:");
          console.log(gallinaDetailsList);
        }


        this.gallinasToShowNowList2 = gallinaDetailsList;
        this.showBurnableGallina = true;

      }else {
        this.showBurnableGallina = false;
      }


    }

    //Close loading screen
    setTimeout(async () => {
      this.showLoadingModal = false;
    }, 200);

  },
};
</script>
<style lang="scss" scoped>

$background: #A69A8E;
$accent: #E2D3C2;
$text: #55565D;

.flippedmargingift {
  margin-top: -100px !important;
}

.flippedmargin {
  margin-top: 108px;
}

.giveupmargin {
  top: 37px;
  position: relative;
}

.giftweekmargin {
  top: 20px;
  position: relative;
}

.giftgallina {
  height: 632px;
  width: 350px;
  position: relative;
  transform-origin: center;
  left: 7vw;
  top: 41px;
}

@media (min-width: 2500px) {
  .giftgallina {
    left: 5vw !important;
  }
  .adopt-column {
    left: 4vw !important;
  }
}

.small-font-3d {
  font-family: 'Comic Neue', cursive;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 transparent, 0 3px 0 transparent, 0 4px 0 transparent,
  0 5px 0 transparent, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 5px 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px 0;
}


.chicken-breed-first-modal {
  position: relative;
}

.connect-wallet-text {
  color: #f6f6f6;
  background-color: #2d89a7;
}


.hugleft {
  position: relative;
  right: 15%;
}

.hugright {
  position: relative;
  left: 15%;
}

.adopt-column {
  width: 400px;
  background-color: #e8c898;
  border: 5px solid #ffda86;
  border-radius: 40px;
  padding: 1rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  font-size: 21px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 1rem;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
  position: relative;
  left: 5vw;
  top: 40px;

}

.stat-column2 {
  background-color: #e8c898;
  border: 5px solid #ffda86;
  border-radius: 40px;
  padding: 1rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 1rem;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
}

.timeline-year {
  color: #fff;
  background-color: #d13847;
  font-size: 2vh;
  line-height: 70px;
  text-wrap: none;
  height: 60px;
  width: 45%;
  transform: rotate(7deg);
  position: relative;
  left: -15px;
  top: 10px;
}

.timeline-year:before {
  content: '';
  background-color: #fcfcfc;
  height: 12px;
  width: 12px;
  border: 3px solid #aaa;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  position: absolute;
  left: 8px;
  top: 8px;
}

.timertop {
  z-index: 0;
  position: relative;
  background-color: #c93232;
  border-radius: 10px;
  display: flex;
  justify-content: left;
  padding-left: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
  top: 10px;
}

.timeline-year {
  transform: rotate(0deg);
  left: 172px;
  z-index: 0;
  top: -35px;
}

.timeline-year:before {
  left: auto;
  right: 8px;
}

.timeline-year {
  background-color: #20b583;
}

.btn {
  color: #fff;
  background-color: #e84393;
  font-family: 'Comfortaa', cursive;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px 12px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btn:active {
  margin-left: 10px;
  margin-top: 10px;
}

.btn:focus,
.btn:hover {
  color: #fff;
}

.btn:before,
.btn:after {
  content: '';
  background-color: #ad1d63;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btn:after {
  background-color: #821248;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btn:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btn:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}

.newGallinaNameInputArea {
  position: relative;
  top: 45px;
  width: 100%;
  left: 0;
}

.buyeggbutton {
  position: relative;
}

.btnsearch {
  color: #fff;
  background-color: #e84393;
  font-family: 'Comfortaa', cursive;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 10px 12px 6px;
  border: none;
  border-radius: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btnsearch:active {
  margin-left: 10px;
  margin-top: 10px;
  background-color: #ad1d63;
}

.btnsearch:focus,
.btnsearch:hover {
  color: #ffffff;
  font-family: 'Mochiy Pop P One', sans-serif;
}

.btnsearch:hover:before,
.btnsearch:hover:after {
  content: '';
  z-index: -1;
}

.btnsearch:before,
.btnsearch:after {
  content: '';
  background-color: #ad1d63;
  height: 7px;
  width: 100%;
  transform: skewX(45deg);
  position: absolute;
  right: -3.5px;
  bottom: -7px;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.btnsearch:after {
  background-color: #821248;
  height: 100%;
  width: 7px;
  transform: skewY(45deg);
  right: -7px;
  bottom: -3.5px;
}

.btnsearch:active:before {
  height: 3px;
  right: -1.5px;
  bottom: -3px;
}

.btnsearch:active:after {
  width: 3px;
  bottom: -1.5px;
  right: -3px;
}

.buyeggwarning {
  position: relative;
  bottom: 80px;
  color: #6d5a36;
  font-size: 15px;
  letter-spacing: 1px;
  font-family: 'Comic Neue', cursive;
}


.form__label {
  margin-top: 0.7rem;
  display: block;
  color: #000000;
  background-color: rgba(206, 173, 232, 0.56);
  height: 23px;
  z-index: 1;
}

.form__input {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #bf9e33;
  font-size: 1rem;
  margin: 0 auto;
  border-radius: 1rem;
  background-color: #ffffbb;
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;

}

.box {
  background: green;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.circle {
  background-color: red;
  height: 5em;
  width: 5em;
  border-radius: 100%
}

.stat-column {
  border: 5px solid #ffda86;
  background-color: #e8c898;
  border-radius: 40px;
  padding: 1rem;
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.frontfooter {
  display: flex;
  position: relative;
  justify-content: center;
}

.modal-box .mdialog {
  width: 80%;
  margin: 70px auto 0;
  margin-top: 0px;

}

@media (max-width: 576px) {
  .modal-box .mdialog {
    width: 95%;
  }
}

.modal.fade .mdialog {
  opacity: 0;
  transform: translate(30%, 30%) scale(0.5);
}

.modal.fade.in .mdialog {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.modal-box .mdialog .modal-content {
  border: 5px solid #ffda86;
  border-radius: 35px;
  box-shadow: 14px 14px 20px #c2803a, -14px -14px 20px #f6a960;
  background-color: #e8c898;
}

.instructionpanel{
  border: 5px outset #e27a3f;
  border-radius: 40px;
  background-color: #fdd798;
}

.modal-box .mdialog .modal-content .modal-body {
  padding: 65px 45px;
}

.modal-body p {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #653800;
}

.modal-box .mdialog .modal-content .modal-body .icon {
  color: #827f3b;
  font-size: 80px;
  line-height: 145px;
  width: 100px;
  height: 100px;
  padding-left: 0px;
  padding-right: 10px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: 25px;
}

.modal-box .mdialog .modal-content .modal-body .inner-content {
  padding: 0 0 0 0px;
  margin: 0 0 30px;
  margin-top: 0px;
  position: absolute;
  top: 20px;
  left: 15%;
}

.eggsTitle {
  left: 120px;
  position: relative;
  margin-bottom: 2rem;
}

.modal-box .mdialog .modal-content .modal-body .title {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #ffffff;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
  0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
  0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.modal-box .mdialog .modal-content .modal-body .description {
  color: #534f47;
  font-size: 15px;
  margin: 0;
  padding-top: 20px;
  font-family: 'Comic Neue', cursive;
}

.modal-box .mdialog .modal-content .modal-body .form-control {
  color: #6d5a36;
  background: #eee8dd;
  font-size: 15px;
  letter-spacing: 1px;
  height: 45px;
  padding: 2px 15px;
  border-radius: 50px 0 0 50px;
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control:focus {
  border: 1px solid #ddd8cf;
  box-shadow: none;
}

.modal-box .mdialog .modal-content .modal-body .form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.modal-box .mdialog .modal-content .modal-body .subscribe {
  color: #fff;
  background: #ebbf53;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px 20px 11px;
  border: none;
  border-radius: 0 50px 50px 0;
  display: block;
  transition: all 0.3s;
}

.modal-box .mdialog .modal-content .modal-body .subscribe:hover,
.modal-box .mdialog .modal-content .modal-body .subscribe:focus {
  background: #827f3b;
}


.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.grid-container2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  justify-items: center;
}

.pricingTable {
  font-family: 'Mochiy Pop P One', sans-serif;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
}

.pricingTable .pricingTable-header {
  color: #fff;
  background-color: #1f8a70;
  padding: 20px 10px;
  position: relative;
  z-index: 1;
}

.pricingTable .pricingTable-header:before,
.pricingTable .pricingTable-header:after {
  content: '';
  background: linear-gradient(225deg, #3E0E0C 49%, transparent 50%);
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
  bottom: -20px;
}

.pricingTable .pricingTable-header:after {
  transform: rotateY(180deg);
  left: auto;
  right: 0;
}

.pricingTable .title {
  font-size: 30px;
  margin: 0;
}

.pricingTable .pricing-content {
  padding: 30px 0 20px;
  margin: 0 20px;
  position: relative;
  background-color: #aa724b;
  background-image: linear-gradient(90deg,rgb(189 158 115 / 24%) 50%,transparent 0),linear-gradient(rgba(209,172,118,.58) 50%,transparent 0);
  background-size: 100px 50px;
  box-shadow: inset 0 30px 60px -12px rgb(50 50 93 / 25%), inset 0 18px 36px -18px rgb(0 0 0 / 30%);
}

.pricingTable .price-value {
  color: #fff;
  margin: 0 40px 20px;
}

.pricingTable .amount {
  font-size: 30px;
  line-height: 50px;
  padding-bottom: 25px;
  margin: 0 0 10px;
  border-bottom: 2px solid #fff;
  display: block;
}

.pricingTable .month {
  font-size: 20px;
  line-height: 30px;
}

.pricingTable .pricing-content ul {
  color: #fff;
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 40px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.pricingTable .pricingTable-signup {
  color: #fff;
  background-color: #1f8a70;
  font-size: 25px;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 14px 18px 14px;
  margin: -3px auto 0;
  display: block;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.pricingTable .pricingTable-signup:hover {
  color: #6cffdc;
  background-color: #8b5232;
}

.pricingTable .pricingTable-signup:before,
.pricingTable .pricingTable-signup:after {
  content: '';
  background: linear-gradient(315deg, #3E0E0C 49%, transparent 50%);
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
  top: -20px;
}

.pricingTable .pricingTable-signup:after {
  transform: rotateY(180deg);
  left: auto;
  right: 0;
}

.pricingTable.orange .pricingTable-header {
  background-color: #FFAC1E;
}

.pricingTable.orange .pricingTable-signup {
  background-color: #FFAC1E;
}

.pricingTable.orange .pricingTable-header:before,
.pricingTable.orange .pricingTable-header:after {
  background: linear-gradient(225deg, #8E580A 49%, transparent 50%);
}

.pricingTable.orange .pricing-content {
  background-color: #C57B12;
}

.pricingTable.orange .pricingTable-signup:before,
.pricingTable.orange .pricingTable-signup:after {
  background: linear-gradient(315deg, #8E580A 49%, transparent 50%);
}

.pricingTable.orange .pricingTable-signup:hover {
  color: #8E580A;
  background-color: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5) inset;
}

.pricingTable.green .pricingTable-header {
  background-color: #00B197;
}

.pricingTable.green .pricingTable-signup {
  background-color: #00B197;
}

.pricingTable.green .pricingTable-header:before,
.pricingTable.green .pricingTable-header:after {
  background: linear-gradient(225deg, #015951 49%, transparent 50%);
}

.pricingTable.green .pricing-content {
  background-color: #007E73;
}

.pricingTable.green .pricingTable-signup:before,
.pricingTable.green .pricingTable-signup:after {
  background: linear-gradient(315deg, #015951 49%, transparent 50%);
}

.pricingTable.green .pricingTable-signup:hover {
  color: #015951;
  background-color: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5) inset;
}

@media only screen and (max-width: 990px) {
  .pricingTable {
    margin: 0 0 30px;
  }
}

::v-deep .form-container {
  box-shadow: none;
}

::v-deep .card .card__content .card__name{
  font-size: 1rem !important;
}

.giftcolwidth{
  width: 500px;
}

.titleareagift {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: #FFF;
  font-size: 1rem;
  margin: 0 auto;
  border-radius: 1rem;
  background-color: #3aafc2;
  border: none;
  width: 90%;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  position: relative;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;
}

.eggOne, .eggTwo, .eggThree {
  font-family: 'Mochiy Pop P One', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  overflow: hidden;
  display: inline-block;
  width: 160px;
  height: 60px;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.1);
  top: -100px;
  border: 5px solid #c99a50;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;

}

.eggOne {
  left: -300px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
      #90be6d 10px, #90be6d 20px);
  transition: all .5s ease-out;
}

.eggOne:focus {
  color: #fff;
}

.eggOne:hover {
  top: 175px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
      #90be6d 10px, #90be6d 20px);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggOne:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggOne:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

.eggOne2, .eggTwo2, .eggThree2 {
  font-family: 'Mochiy Pop P One', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  overflow: hidden;
  display: inline-block;
  width: 160px;
  height: 60px;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.1);
  border: 5px solid #c99a50;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;

}

.eggOne2 {
  left: -300px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
      #90be6d 10px, #90be6d 20px);
  transition: all .5s ease-out;
}

.eggOne2:focus {
  color: #fff;
}

.eggOne2:hover {
  top: 175px;
  background: repeating-linear-gradient(#80a861, #658b48 10px,
      #90be6d 10px, #90be6d 20px);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggOne2:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggOne2:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

@media only screen and (max-width: 767px) {
  .eggOne {
    margin-bottom: 20px;
  }
}

.eggTwo {
  left: -100px;
  background-color: #f94144;
  transition: all .5s ease-out;
}

.eggTwo:focus {
  color: #fff;
}

.eggTwo:hover {
  top: 175px;
  background-color: #f94144;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggTwo:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggTwo:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

@media only screen and (max-width: 767px) {
  .eggTwo {
    margin-bottom: 20px;
  }
}


.eggTwo:after {
  content: "";
  position: absolute;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.02);
  width: 126px;
  height: 180px;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;
}

.eggThree {
  left: 100px;
  background: repeating-linear-gradient(-45deg, #70c1b3, #70c1b3 20px,
      #247ba0 20px, #247ba0 40px);
}

.eggThree:focus {
  color: #fff;
}

.eggThree:hover {
  top: 175px;
  background-color: #f94144;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 6px rgba(255, 113, 41, 0.3);
}

.eggThree:before {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 10px 10px #fff, -10px -10px #fff, -10px 10px #fff, 10px -10px #fff,
  20px 0 #fff, -20px 0 #fff, 30px -10px #fff, 30px 10px #fff, -30px 10px #fff,
  -30px -10px #fff, 40px 0 #fff, -40px 0 #fff;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all .3s ease-out;
}

.eggThree:hover:before {
  opacity: 0.15;
  transform: translateX(-50%) translateY(-50%) scale(1.5);
}

@media only screen and (max-width: 767px) {
  .eggThree {
    margin-bottom: 20px;
  }
}


.eggThree:after {
  content: "";
  position: absolute;
  box-shadow: inset -10px -10px rgba(0, 0, 0, 0.02);
  width: 126px;
  height: 180px;
  border-radius: 60% 40% 40% 60% / 50% 50% 50% 50%;
}

.white{
  color: #FFF;
}

</style>
