<template>
  <vue-final-modal
      v-slot="{ params, close }"
      v-bind="$attrs"
      classes="modal-container"
      :content-class="className"
      v-on="$listeners"
  >
    <div v-if="showGoldHeader" class="gradient"></div>
    <div class="wrapper">
      <div class="main-element">

        <div class="paper">
          <div class="lines">
            <div class="text">
              <slot name="title"></slot>
              <br/><br/>
              <slot v-bind:params="params"></slot>
            </div>
          </div>
          <div class="holes hole-top"></div>
          <div class="holes hole-middle"></div>
          <div class="holes hole-bottom"></div>
        </div>
        <div class="modal__action mb-4">
          <button v-if="showConfirm" class="p-1 m-2 text-success confirm-style" @click="$emit('confirm', close)">
            {{ confirmName }}
          </button>
          <button v-if="showCancel" class="p-1 m-2 text-danger cancel-style" @click="$emit('cancel', close)">
            {{ cancelName }}
          </button>
        </div>
      </div>
    </div>
    <!--    <button class="modal__close" @click="close">
          Close
        </button>-->
  </vue-final-modal>
</template>

<script>
export default {
  name: "RulesModal",
  props: {
    showGoldHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    showConfirm: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
    className: {
      type: String,
      required: false,
      default: "intro",
    },
    confirmName: {
      type: String,
      required: false,
      default: "Confirm",
    },
    cancelName: {
      type: String,
      required: false,
      default: "Cancel",
    }
  }
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Indie+Flower);

.paper {
  position: absolute;
  height: 550px;
  width: 450px;
  background: rgba(255, 255, 255, 0.9);
  margin: -275px -225px;
  left: 50%;
  top: 50%;
  box-shadow: 0px 0px 5px 0px #888;
}

.paper::before {
  content: '';
  position: absolute;
  left: 45px;
  height: 100%;
  width: 2px;
  background: rgba(255, 0, 0, 0.4);
}

.lines {
  margin-top: 40px;
  height: calc(100% - 40px);
  width: 100%;
  background-image: repeating-linear-gradient(white 0px, white 24px, steelblue 25px);
}

.text {
  position: absolute;
  top: 65px;
  left: 55px;
  bottom: 10px;
  right: 10px;
  line-height: 25px;
  font-family: 'Indie Flower';
  overflow: hidden;
  outline: none;
}

.holes {
  position: absolute;
  left: 10px;
  height: 25px;
  width: 25px;
  background: lightgoldenrodyellow;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 0px #888;
}

.hole-top {
  top: 10%;
}

.hole-middle {
  top: 50%;
}

.hole-bottom {
  bottom: 10%;
}


.main-element {
  position: absolute;
  top: 5%;
  left: 4%;
  display: block;
  height: 92%;
  width: 92%;
  align-self: center;
  background: repeating-linear-gradient(
      to top left,
      beige 0,
      beige 2em,
      moccasin 2em,
      moccasin 4em,
      wheat 4em,
      wheat 6em
  ),
  repeating-linear-gradient(
      to left,
      white 0,
      white 2em,
      wheat 2em,
      wheat 4em,
      beige 4em,
      beige 6em
  ), white;

  background-blend-mode: multiply;
  z-index: 1;
  padding: 0.2rem;
  align-items: center;
  border-radius: 20px;

}

.modal__action {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.wrapper {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50%), -50%);
  box-sizing: content-box;
  padding: 1rem;
  height: 170px;
  max-width: 540px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.75);
  margin-bottom: 30px;
  width: 90%;
  height: 62%;
  border-radius: 10%;
  background: repeating-linear-gradient(
      to top left,
      transparent,
      transparent 5em,
      #96526B 5em,
      #96526B 10em,
      transparent 10em,
      transparent 15em,
      #F5CF66 15em,
      #F5CF66 20em
  ),
  repeating-linear-gradient(
      to top right,
      transparent,
      transparent 5em,
      #EBAD60 5em,
      #EBAD60 10em,
      transparent 10em,
      transparent 15em,
      #8BAB8D 15em,
      #8BAB8D 20em
  ), wheat;

  background-blend-mode: multiply;
}

button {
  padding: 0.75rem 1.5rem;
  font-family: inherit;
  background-color: #1f1918;
  border: 6px solid;
  border-image: linear-gradient(#4e4024, #353932) 6;
  color: white;
  cursor: pointer;
  box-shadow: 0 8px 2px rgba(0, 0, 0, 0.75);
}

button:hover,
button:active {
  background-color: #410000;
  border-color: #410000;
}

.flat {
  background-color: transparent;
  color: #610000;
  border: none;
}

.flat:hover,
.flat:active {
  background-color: #ffd2d2;
}

.gradient {
  position: fixed;
  background: #999955;
  background-image: linear-gradient(#DAB046 20%,
  #be9736 20%,
  #a07e28 40%,
  #997825 40%,
  #735812 60%,
  #4f3b07 60%,
  #533c05 80%,
  #302400 80%);
  margin: 0 auto;
  width: 100%;
  height: 50px;
  top: 65px;
  left: 0;

}
</style>
