import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import {store} from './store/index.js'
import router from './router.js'
import VueScreen from 'vue-screen'; //vue-screen, returns $screen.width/$screen.height var in vue, yay!
import Pact from "pact-lang-api"; //pact js library
import VueFinalModal from "vue-final-modal";
import ScrollDiv from 'vue-scroll-div';  //vue scroll div
import { gsap } from "gsap"; //animation library
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import vSuggest from 'v-suggest'



Vue.config.productionTip = false

Vue.use(Vuex);
Vue.use(VueScreen);
Vue.use(Pact);
Vue.use(VueFinalModal());
Vue.use(ScrollDiv);
Vue.use(gsap);
Vue.use(Tooltip);
Vue.use(vSuggest);



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
