<template>
  <div id="app">
    <BreedPage :x="this.x" :y="this.y"></BreedPage>
  </div>
</template>

<script>
import BreedPage from '../components/BreedPage.vue'

export default {
  name: 'Breed',
  props: {
    x: {
      type: String,
      default: "0"
    },
    y: {
      type: String,
      default: "0"
    }
  },
  components: {
    BreedPage
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
